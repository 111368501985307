/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import fileLoaderSvg from '../../images/file_upload.svg'
import { StoreContext } from "../Store";
import { useNavigate } from 'react-router-dom';
import { Select } from "../Input/Input.js";
import "../main.css";
import { postAdditionalFileUpload, getDocumentTypes } from "../apiCalls";
import { LoadUntilReady } from "../Util/Loaders";


const FileLoader = () => {
	const [selectedFile, setSelectedFile] = useState();
	const [selectedFileType, setSelectedFileType] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const store = useContext(StoreContext);
	const navigate = useNavigate()
	const [requestFailed, setRequestFailed] = useState(false); 
	const [loading, setLoading] = useState(false);

    const [documentType, setDocumentType] = useState(0);
    const [documentOptions, setDocumentOptions] = useState([]);
    const [documentTypeSelected, setDocumentTypeSelected] = useState(false);

	const handleChange = e => {
		setSelectedFile(e.target.files[0]);
		setIsFilePicked(true);
		setSelectedFileType(e.target.files[0].name.slice(-4) === 'xlsx' ? 'xls' : e.target.files[0].name.slice(-3));
	};

	useEffect(() => {
		store.infoText[1]("Please upload your additional documents here. Select the document type from the dropdown.")
	}, [isFilePicked]);

    useEffect(() => {
        async function fetchData() {
            try {
              getDocumentTypes().then((data) => { setDocumentOptions(data); });      
            } catch (err) {
              console.log(err);
            }
          }
          fetchData();
    }, [])
	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append('file', selectedFile); 
        formData.append('documentType', documentType);
        formData.append('newLoadID', store.newLoadID[0]);
		try {
			setLoading(true);
			const data = await postAdditionalFileUpload(formData)
			navigate("/listloads");
			setLoading(false);
		} catch (err) {
            console.log(err);
			store.infoText[1]("We could not process your file, please review the file and try again.")
			setLoading(false);
			setRequestFailed(true);
		}
	};

	return (
		<>
			{isFilePicked ? (
				<div>
					<LoadUntilReady loading={loading} loadingText='Please wait...' style={{ marginTop: '6rem' }}>
						<p className="section-title">File name</p>
						<p>{selectedFile.name}</p>
						<p className="section-title">File type</p>
						<p>{selectedFileType}</p>
						<p className="section-title">File size (bytes)</p>
						<p>{selectedFile.size}</p>
						<p className="section-title">File last modified</p>
						{selectedFile.lastModifiedDate.toLocaleDateString()}
						<div>
						{!requestFailed && (
							<button className="blue-button-newload file-loader-btn-submit" onClick={handleSubmit}>Submit</button>)}
							{requestFailed && (
								<div style={{ marginTop: '1rem', fontSize: '0.85rem', color: 'rgb(255, 30, 30)' }}>
									<button className="blue-button-newload file-loader-btn" onClick={() => setIsFilePicked(false) & setRequestFailed(false)}>Back</button>
								</div>
							)}
						</div>
						
					</LoadUntilReady>
				</div>
			) : (<>
				<img src={fileLoaderSvg} height="100" alt="loader" />
                <Select
                  label="Document type"
                  name="docType"
                  data={documentType}
                  setData={(data) => {setDocumentTypeSelected(true); setDocumentType(data)}}
                  required
                  options={documentOptions}
                />
                <label name="name" className="blue-button-newload file-loader-btn">Upload<input type="file" name="fileuploader" disabled={!documentTypeSelected} onChange={handleChange} /></label>
			
			</>

			)}
		</>


	)
}


export default FileLoader;