import DataTable, {useDataTableHandle} from "../../DataTable";
import { ExternalLinkAlt } from "@styled-icons/fa-solid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRegistrationStatuses, postRegistrationListLazy } from "../../apiCalls";
import isoDateConverter from "../../isoDateConverter";
import { useSecurity } from "../../Security/security";

const EpoApplications = () => {

  const [epoApplications, setEpoApplications] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const { validateRole } = useSecurity();
  const [paginationLoaded, setPaginationLoaded] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);

  const dth = useDataTableHandle();

  async function fetchData() {
    try {
      const data = await postRegistrationListLazy({
        skip: dth.current.pagination.pageNo * dth.current.pagination.itemsPerPage, 
        amount: dth.current.pagination.itemsPerPage,
        sort_by: dth.current.sortStatus.currentKey,
        sort_order: dth.current.sortStatus.direction
      });
      dth.current.setServerDataLength(data.totalItems)
      setEpoApplications(data.data);
      setStatuses((await getRegistrationStatuses()).map(status => ({id: status.id, data: status.status})));
    } catch (err) {
      setRequestFailed(true);
    }
  }

  useEffect(() => {
    if (paginationLoaded)
      fetchData();
  }, [paginationLoaded]);

  async function updateData(setPaginationLength, pagination, sortStatus, search, filter) {
    try {
      const data = await postRegistrationListLazy({
        skip: pagination.pageNo * pagination.itemsPerPage, 
        amount: pagination.itemsPerPage,
        sort_by: sortStatus.currentKey,
        sort_order: sortStatus.direction,
        search,
        filter
      })
      setPaginationLength(data.totalItems)
      setEpoApplications(data.data);
    }
    catch (err) {
      setRequestFailed(true);
    }

  }


  if (requestFailed) return <p>Failed!</p>;

  return (
    <>
      <div className="full-width-container">
        <section className="floating-box" style={{ maxWidth: "1500px" }}>
          <div className="floating-box-header-general">
            <span>Customer Applications</span>
          </div>
          <div className="px-4 pt-3">
            <DataTable data={epoApplications} handle={dth}
            className="table-striped" filterable sortable lazyLoading advancedSearch onUpdate={updateData} 
            showAdvnSearch={!validateRole({department: 'TRADER'}) && !validateRole({department: 'LSP'})}
            onPaginationLoaded={() => {
              setPaginationLoaded(true);
            }}
            >
              <DataTable.Headers className=" px-2" style={{fontSize: '14.4px',  backgroundColor: '#fff', paddingBottom: '10px', borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd'}} />
              <DataTable.Pagination itemsPerPage={[10, 25, 50]} className="w-100 d-flex justify-content-end" />
              <DataTable.Column colKey="reference" header="Reference" searchOpts={{type: "text", sendAs: "refno"}} />
              <DataTable.Column colKey="companyName" header="Company Name" searchOpts={{type: "select", src: "data", sendAs: "compName"}}>
                {item => {
                  return item.companyName?.slice(0, 20) + (item.companyName?.length > 20 ? "..." : "")
                }}
              </DataTable.Column>
              <DataTable.Column colKey="eoriNumber" header="Eori Number" searchOpts={{type: "text", sendAs: "eoriNo"}} />
              <DataTable.Column colKey="originator" header="Originator" searchOpts={{type: "text", sendAs: "origin"}} />
              <DataTable.Column colKey="submitter" header="Submitter Name" />
              <DataTable.Column colKey="Status" header="Status" searchOpts={{type: "select", src: statuses, sendAs: "status"}} />
              <DataTable.Column colKey="createdDate" header="created Date">
                {item => (item?.createdDate !== null)  && isoDateConverter(item?.createdDate)}
              </DataTable.Column>
              <DataTable.Column colKey="lastModified" header="Last Updated">
                {item => {
                  return isoDateConverter(item.lastModified)
                }}
              </DataTable.Column>
              {
  (validateRole({department: 'OPS'}) || validateRole({department: 'FIN'}) || validateRole({department: 'CMT'} || validateRole({department: 'QA'}))) && (
    <DataTable.Column header="View" style={{height: '1px', padding: 0}} headerStyle={{textAlign: 'center'}}>
      {(item) => {
        // const logReadOnly = () => {
        //   const readOnly = validateRole({department: "OPS", role: "STANDARD"});
        //   console.log("In Grid", readOnly);
        // };
        // logReadOnly();
        return (
          <Link className="external-table-link" to={{ pathname: `/EpoApplicationDetails/${item.id}`, search: `?refNo=${item.reference}` }}>
            <ExternalLinkAlt width="1.1rem" height="1.1rem" />
          </Link>
        );
      }}
    </DataTable.Column>
  )
}

            </DataTable>
          </div>
        </section>
      </div>
    </>
  );
};

export default EpoApplications;
