import {useState} from "react";
import copyIcon from '../../images/copyIcon.png';

const LoadConfirmation = ({newLoadAction, listLoadAction, titleText, bodyText, portalRef}) => {

  const [buttonText, setButtonText] = useState("Copy Ref");

  const handleCopyRef = () => {
    navigator.clipboard.writeText(portalRef);
    setButtonText("Copied");
  }

  return (
    <div
      className="p-4 d-flex flex-column align-items-center "
      style={{ marginTop: "4rem", marginRight: "4rem" }}
    >
      <h1 style={{ color: "#fff" }}>
        {titleText}
      </h1>
      <div
        className="tick-success-large"
        style={{ marginTop: "0.2rem", marginBottom: "1rem" }}
      ></div>
      <p style={{ fontSize: "110%", color: "#fff" }}>
        {`${portalRef || ""} ${bodyText}`} 
      </p>
      <button onClick={handleCopyRef} className="btn_submitted_copy_ref">
        <img src={copyIcon} alt="copy icon" />
        <p>{buttonText}</p>
        
        </button>
      <div className="gap-3 d-flex ">
        <button
          className="blue-button-newload"
          style={{ width: "auto" }}
          onClick={newLoadAction}
        >
          New Load
        </button>
        <button
          className="blue-button-newload"
          style={{ width: "auto" }}
          onClick={listLoadAction}
        >
          List Loads
        </button>
      </div>
    </div>
  );
};

export default LoadConfirmation;
