const FormatDate = (value, delimeter='/', reverse=false) => {
    const dt = new Date(value)
    const yyyy = dt.getFullYear();
    let mm = dt.getMonth() + 1; // Months start at 0!
    let dd = dt.getDate();
    
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    if (!reverse)
    return dd + delimeter + mm + delimeter + yyyy
    else
    return yyyy + delimeter + mm + delimeter + dd
  }

  export default FormatDate;