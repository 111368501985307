import { useContext, useMemo, useState } from "react";
import DataTable from "../../../DataTable";
// import { EpoJobDetailContext } from "../EpoJobDetails";
import {Input, Select} from "../../../Input/Input.js";
import { Col, Row } from "react-bootstrap";
import { EpoApplicationDetailContext } from "../EpoApplicationDetails";



export default function EpoEnteredData ({localInvoiceData}) {
  const { applicationDetails } = useContext(EpoApplicationDetailContext);

  // const combinedRows = useMemo(() => {
  //   const result = [];
  //   for (const invoice of jobDetails.Invoice) {
  //     if (invoice.Items) {
  //       result.push(...invoice.Items);
  //     }
  //   }
  //   return result;
  // }, [jobDetails.Invoice]);

  const [companyInOurRecords, setCompanyInOurRecords] = useState("");
  const [companyInOurRecords1, setCompanyInOurRecords1] = useState("");

  const ourRecords = [
    { id: "agro", data: "Agro" },
    { id: "oToole", data: "O'Toole" },
  ];

  return (
    <section className="p-2 d-flex flex-column">
      <div>
      <div className='registrationText mb-2'>Company Information</div>
      <Row>
        <Col><Input disabled label="Company Name" value={applicationDetails.companyName} /></Col>
        <Col><Input disabled label="EORI Number" value={applicationDetails.eoriNumber} /></Col>
      </Row>
      <Row>
        <Col><Input disabled label="Company Category" value={applicationDetails.companyCategory} /></Col>
        <Col><Input disabled label="Company Registration Number" value={applicationDetails.companyRegNumber} /></Col>
      </Row>

      <div className='registrationText my-2'>Personal Information</div>
      <Row>
        <Col><Input disabled label="First Name" value={applicationDetails.firstName} /></Col>
        <Col><Input disabled label="Last Name" value={applicationDetails.lastName} /></Col>
      </Row>
      <Row>
        <Col><Input disabled label="Business Phone Number" value={applicationDetails.phoneNumber}/></Col>
        <Col><Input disabled label="Position In Company" value={applicationDetails.companyPosition}/></Col>
      </Row>
      <div className='registrationText my-2'>Other Company Info</div>
      <Row>
        <Col><Input disabled label="Name of Importer/Exporter" value={applicationDetails.companyName} /></Col>
        <Col><Input disabled label="VAT Number" value={applicationDetails.vatNumber} /></Col>
      </Row>
      <Row>
        <Col><Input disabled label="Company Registration Number" value={applicationDetails.companyRegNumber} /></Col>
        <Col><Input disabled label="Legal Name of GB Entity" value={applicationDetails.gbEntity} /></Col>
      </Row>
    </div>
    </section>
  );
}