import { Modal } from "react-bootstrap";
import DataTable from "../DataTable";

const DocRefModal = ({data, onHide, show }) => {
  
  const close = () => {
    onHide();
  };

  return (
  <Modal centered size="lg" animation={false} show={show} onHide={close}>
    <Modal.Header>
      <Modal.Title>Document References</Modal.Title>
      <button type="button" class="btn-close" aria-label="Close" onClick={close} />
    </Modal.Header>
    <Modal.Body>
    <div className="d-flex flex-column justify-content-md-start py-1" >
      <DataTable idKey="forms" data={data} className="table-bordered">
        <DataTable.Headers style={{fontSize: '11px'}} />
        <DataTable.Column colKey="AdditionalInformation" header="Document Type" />
        <DataTable.Column colKey='systemrefNumber' header="Reference Number" />
        <DataTable.Column colKey='lastmodifieddate' header="Date Completed" >
        {({lastmodifieddate}) => new Date(lastmodifieddate).toLocaleDateString()}
        </DataTable.Column>
        <DataTable.Column colKey='formStatus' header="Status" />
      </DataTable>
    </div>
    </Modal.Body>
    <Modal.Footer class="py-3 px-3" style={{ borderBottomLeftRadius: "20px" , borderBottomRightRadius: "20px"}}>  
      {/* <div className='push-left-left'>
        <button type="button" className="stepper-button red" onClick={close}>
          OK
        </button>
      </div> */}
    </Modal.Footer>
  </Modal>
  );
}

export default DocRefModal;
