import { TimesCircle } from "@styled-icons/fa-solid";
import { useContext, useState, useEffect } from "react";
import { Col, OverlayTrigger, PopoverContent, Row, Tab, Popover } from "react-bootstrap";
import { FileInput, Input, Select } from "../../Input/Input";
import { UserProfileContext } from "../UserProfile";
import { useSecurity } from "../../Security/security";

export default function Compliance () {
  const { companyInfo, updateCompanyInfo, complianceError } = useContext(UserProfileContext);
  const { securityState, validateRole } = useSecurity();

  return (
    <div className="px-4 pb-3 pt-2 bg-white">
      <Row className="pt-2">
        <Col>
        <Input
          label={
            <>
              UK DAN Account <span style={{ fontSize: "120%" }}></span>
            </>
          }
          value={companyInfo.dutyDefermentNumber}
          onChange={e => updateCompanyInfo('dutyDefermentNumber', e.target.value)}
          disabled = {validateRole({department: 'TRADER'})}

        /></Col>
        <Col>
        <div className="compliance-checkbox-container">
          <input type="checkbox" name="authorized" class="compliance-checkbox" 
                          checked={companyInfo.authorized}
                          onChange={(e) => {updateCompanyInfo('authorized', e.target.checked)}}
                          disabled = {validateRole({department: 'TRADER'})}
                          />
              <label for="authorized" class="compliance-checkbox-label"
              >Authorised?         
              <OverlayTrigger
            placement="right"
            trigger={["hover", "focus"]}
            overlay={
              <Popover style={{ minWidth: "25rem" }}>
                <PopoverContent>
                  <p>
                  Has the Nee/Nor authorised the declarant to use the DDA?
                  </p>
                </PopoverContent>
              </Popover>
             }
              >
            <span className="info ms-2"></span>
          </OverlayTrigger></label>
        </div>

        </Col>
      </Row>
      <Row className="pt-2">
      <Col>
        <Select
          label={
            <>
              Do you hold a CCG?<span style={{ fontSize: "120%" }}></span>
            </>
          }
          data={companyInfo.CCGHeld}
          setData={e => updateCompanyInfo('CCGHeld', e)}
          options={[{id: 1, data:"No"}, {id: 2, data: "Yes"}]}
          disabled = {validateRole({department: 'TRADER'})}
        /></Col>
        <Col>
        { [2, '2'].includes(companyInfo.CCGHeld)  &&
                <Input
                label={
                  <>
                    CCG Reference <span style={{ fontSize: "120%" }}></span>
                  </>
                }
                value={companyInfo.CCGReference}
                onChange={e => updateCompanyInfo('CCGReference', e.target.value)}
              />
        }
</Col>
        <Row className="pt-2">
          <Col>
          <Input
                label={
                  <>
                    Warehouse ID <span style={{ fontSize: "120%" }}></span>
                  </>
                }
                value={companyInfo.warehouseID}
                onChange={e => updateCompanyInfo('warehouseID', e.target.value)}
                disabled = {validateRole({department: 'TRADER'})}
              />
          </Col>
          <Col className="ml-2">
          <Input
                label={
                  <>
                    Supervising Office <span style={{ fontSize: "120%" }}></span>
                  </>
                }
                value={companyInfo.supervisingOffice}
                onChange={e => updateCompanyInfo('supervisingOffice', e.target.value)}
                disabled = {validateRole({department: 'TRADER'})}
              />
          </Col>
        </Row>
      </Row>
      <Row className="mt-2">
        <Col>
          <span className="red-text">
              {complianceError}&nbsp;
          </span>
        </Col>
      </Row>
    </div>
  );
}