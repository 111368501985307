import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./main.css";
import Footer from './Footer'
import DisplayInvoices from "./DisplayInvoices";
import { invoiceSearch } from "./apiCalls";

const Invoice = () => {

  const [invoices, setInvoices] = useState('[{"":""}]')
  const [requestFailed, setRequestFailed] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await invoiceSearch()
        setInvoices(JSON.stringify(data))
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [])

  if (requestFailed) return <p>Failed!</p>

  return (
    <div className="main">
      <section>
        <DisplayInvoices
          className='results'
          name='results'
          invoices={invoices}
          history={navigate}
          url='/InvoiceDetails' />
      </section>

      <Footer />
    </div>
  );
}

export default Invoice;