import { useContext } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import { Input } from "../../Input/Input";
import { UserProfileContext } from "../UserProfile";

export default function OtherInfo () {
  const { companyInfo, updateCompanyInfo } = useContext(UserProfileContext);

  return (
    <div className="px-4 pb-5 pt-2 bg-white">
      <div className="registrationText">Miscellaneous</div>
      <Row className="pt-2">
        <Col>
        <Input
          label={
            <>
              Shipments Per Month <span style={{ fontSize: "120%" }}>*</span>
            </>
          }
          value={companyInfo.monthlyDecs}
          disabled
          onChange={e => updateCompanyInfo('monthlyDecs', e.target.value)}
        /></Col>
        <Col/>
      </Row>
    </div>
  );
}