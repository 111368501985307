import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { postNBPNotes, getNBPNotes, deleteNBPNote } from '../apiCalls';
import { Trash } from "@styled-icons/fa-solid";
import { useSecurity } from "../Security/security";

const BPModal = ({ onHide, show, customerId }) => {
  const [image, setImage] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [note, setNote] = useState('');
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const { validateRole } = useSecurity();

  useEffect(() => {
    if (customerId != null) {
      getDocsNotes();
    }
  }, [customerId]);

  const handleNoteChange = (e) => setNote(e.target.value);

  const handlePaste = (e) => {
    const { items } = e.clipboardData ?? {};
    for (let index = 0; index < (items?.length ?? 0); index++) {
      if (items[index]?.type.indexOf('image') !== -1) {
        const blob = items[index].getAsFile();
        const url = URL.createObjectURL(blob);
        setImage(url);
        setImageBlob(blob);
      }
    }
  };

  const addDocsNotes = async () => {
    try {
      const formData = new FormData();
      
      //check if imageBlog is not null, and append the image to the form data
      if (imageBlob != null) {
        formData.append('image', imageBlob, 'pasted-image.png');
      }
      formData.append('note', note);
      formData.append('customerId', customerId);

      const response = await postNBPNotes(formData);
      setImage(null);
      setImageBlob(null);
      setNote('');
      getDocsNotes();
    } catch (error) {
      console.error('Error uploading data:', error);
      alert(`Error uploading data: ${error.message}`);
    }
  };

  const getDocsNotes = async () => {
    try {
      const data = await getNBPNotes(customerId);
      setData(data ?? []);
      setError(null);
    } catch (error) {
      console.log('Error fetching data:', error);
      setError(error.message);
    }
  };

  const deleteDocsNotes = async (id) => {
    try {
      const response = await deleteNBPNote(id, customerId);
      getDocsNotes();
    } catch (error) {
      console.error('Error deleting data:', error);
      alert(`Error deleting data: ${error.message}`);
    }
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show ?? false}
      onHide={onHide ?? (() => {})}
      animation={false}
    >
      <Modal.Body>
        <div style={{ width: '85%', height: '100%', margin: '0 auto', marginBottom: '2rem' }}>
          {(validateRole({ department: 'OPS', role: 'SUPERUSER' }) || validateRole({ department: 'QA', role: 'SUPERUSER' }) ) && (
            <>
            <div
            onPaste={handlePaste}
            style={{ width: '100%', height: '7rem', border: '1px solid #a8a8a8', marginBottom: '2rem', marginTop: '2.4rem' }}
          >
            {image ? (
              <img src={image} alt="Pasted" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            ) : (
              <span style={{ marginLeft: '0.3rem', color: '#cdcbcb', fontStyle: 'italic', fontSize: '2rem' }}>Paste your document here</span>
            )}
          </div>
          <textarea
            value={note ?? ''}
            onChange={handleNoteChange}
            placeholder="Enter your note here..."
            style={{ width: '100%', height: '100px', marginTop: '10px', borderColor: '#a8a8a8' }}
          />
          <button onClick={addDocsNotes} className="stepper-button" style={{ marginTop: '20px', marginRight: '5.8rem' }}>
            Upload
          </button>
            </>
          )}
          <div>
            {error && <p>Error: {error}</p>}
            {data?.map((item, index) => (
              <div key={index}>
                <h4>{`Note: ${index + 1}`}
                {(validateRole({ department: 'OPS', role: 'SUPERUSER' }) || validateRole({ department: 'QA', role: 'SUPERUSER' })) && (
                  <button onClick={() => deleteDocsNotes(item.noteId ?? 0)} className="stepper-button red" style={{ width: "2.6rem", marginLeft: '2rem', marginTop: '3rem' }}>
                    <Trash width="1rem" height="1rem" />
                  </button>
                )}
                </h4>
                <p>{item.note ?? ''}</p>
                {item.imageId && <img src={`data:image/png;base64,${item.image ?? ''}`} alt="Note" style={{ maxWidth: '100%' }} />}
              </div>
            ))}
          </div>
        </div>
        <button
          className="stepper-button yellow" onClick={onHide ?? (() => {})}>Back</button>
      </Modal.Body>
    </Modal>
  );
};

export default BPModal;
