import { useContext, useMemo, useState } from "react";
import DataTable from "../../../DataTable";
// import { EpoJobDetailContext } from "../EpoJobDetails";
import {Input, Select} from "../../../Input/Input.js";
import { Col, Row } from "react-bootstrap";
import { EpoApplicationDetailContext } from "../EpoApplicationDetails";



export default function EpoAddressDetails ({localInvoiceData}) {
  const { addressErrors, applicationDetails, updateApplicationDetails, isEditable } = useContext(EpoApplicationDetailContext);



  return (
    <section className="p-2 d-flex flex-column">
      <div>
      <div className='registrationText mb-2'>Address Details</div>
      <Row>
        <Col><Input disabled={!isEditable} onChange={e => updateApplicationDetails('building', e.target.value)}
         label={<>Building name/number <span style={{ fontSize: "120%" }}>*</span></>} value={applicationDetails.building} 
         error={addressErrors.building} /></Col>
        <Col><Input disabled={!isEditable} onChange={e => updateApplicationDetails('street', e.target.value)}
         label={<>Street <span style={{ fontSize: "120%" }}>*</span></>} value={applicationDetails.street} 
         error={addressErrors.street} /></Col>
      </Row>
      <Row>
        <Col><Input  disabled={!isEditable} onChange={e => updateApplicationDetails('town', e.target.value)}
         label={<>Town/City <span style={{ fontSize: "120%" }}>*</span></>} value={applicationDetails.town} 
         error={addressErrors.town}/></Col>
        <Col><Input  disabled={!isEditable} onChange={e => updateApplicationDetails('county', e.target.value)}
         label="County" value={applicationDetails.county} /></Col>
      </Row>
      <Row>
        <Col><Input  disabled={!isEditable} onChange={e => updateApplicationDetails('postcode', e.target.value)}
         label={<>Post Code <span style={{ fontSize: "120%" }}>*</span></>} value={applicationDetails.postcode} 
         error={addressErrors.postcode} /></Col>
        <Col><Input  disabled={!isEditable}onChange={e => updateApplicationDetails('country', e.target.value)}
         label={<>Country <span style={{ fontSize: "120%" }}>*</span></>} value={applicationDetails.country} 
         error={addressErrors.country} /></Col>
      </Row>
    </div>
    </section>
  );
}