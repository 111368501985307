import { useContext, useState } from "react";
// Context
import {BusinessPartnerContext } from './BusinessPartnerCard'
import { useStepper } from "../Stepper";
import { useSecurity } from "../Security/security";

const BPFooter = () => {
  const {
    back,
    handleShowWarningModal,
    setShowUploadDocumentModal
  } = useContext(BusinessPartnerContext);
  const { validateRole } = useSecurity();
  
  const handleDisableSave = () => {
    if(!isSaving && (validateRole({ department: 'OPS', role: 'SUPERUSER' }) || validateRole({ department: 'QA', role: 'SUPERUSER' })) && isEditing) {
      return false;
    }
    return true;
  }
  const handleDisableEdit = () => {
    if(!isSaving && (validateRole({ department: 'OPS', role: 'SUPERUSER' }) || validateRole({ department: 'QA', role: 'SUPERUSER' }))) {
      return false;
    }
    return true;
  }

  const handleDocumentUpload = () => {
    setShowUploadDocumentModal(true);
  }

  const {isEditing, handleEdit, handleSave, isSaving} = useContext(BusinessPartnerContext);
  const { activeStepKey } = useStepper();

  const defaultButtons = (
      <>
        <button className="stepper-button red" 
          onClick={() => handleEdit()}
          disabled={handleDisableEdit()}
          >
            {isEditing ? "Discard" : "Edit"}
          </button>
          <button className="bp-footer-buttons-save-bnt stepper-button" onClick={ (e) => handleSave(e, activeStepKey)} disabled={handleDisableSave()}>
            {isSaving ? "Saving..." : "Save"}
          </button>
      </>
  );
  const bpDocsButtons = (
      <button className="stepper-button red" onClick={() => {handleDocumentUpload()}} disabled={handleDisableEdit()}>Upload</button>
  );

  const showButtons = activeStepKey === "BPDocuments" ? bpDocsButtons : defaultButtons;
  
  return (
    <div className="bp-footer-buttons">
      <button className="bp-footer-buttons-back-bnt stepper-button" onClick={() => {handleShowWarningModal(back);}}>Back</button>
      <div className="bp-footer-buttons-editandsave">
        {showButtons}
      </div>
    </div>
  );
};

export default BPFooter;
