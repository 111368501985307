import { createContext, useEffect, useMemo, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import EpoEnteredData from "./Tabs/EpoEnteredData";
import { getRegistrationDetails, putRegistrationCompliance, putRegistrationEmail, putRegistrationAddress, getEnvelopeStatus } from "../../apiCalls";
import EpoConfirmation from "./Tabs/EpoConfirmation";
import EpoSmartPack from "./Tabs/EpoSmartPack";
import EpoAddressDetails from "./Tabs/EpoAddressDetails";
import EpoInvoiceDetails from "./Tabs/EpoInvoiceDetails";
import EpoConfirmationModal from "./Tabs/EpoConfirmationModal";
import EpoCompliance from "./Tabs/EpoCompliance";
import EpoEmail from "./Tabs/EpoEmail";
import errorIcon from "../../../images/errorIcon.svg";
import { useSecurity } from "../../Security/security";

const useQuery = () =>{
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const EpoApplicationDetailContext = createContext({applicationDetails: null, setApplicationDetails: null, updateApplicationDetails: null});


const EpoApplicationDetails = () => {
  const { validateRole } = useSecurity();
  const [addressErrors, setAddressErrors] = useState({})
  const [modalInfo, setModalInfo] = useState(null)
  const { id } = useParams();
  const [statusText, setStatusText] = useState();
  const [fetchEnvelopeDetails, setFetchEnvelopeDetails] = useState(0);

  const query = useQuery();
  const refNo = query.get("refNo");
  // const [readOnly, setReadOnly] = useState(null);
  const [applicationDetails, setApplicationDetails] = useState({
    decision: "",
    detailsSentTo: "",
    paymentMethod: "",
    c1207nFormValidated: "",
    reasonForRejection: "",
  });

  function updateApplicationDetails(key, value) {
    setApplicationDetails(prev => {
      return {
        ...prev, [key]: value
      }
    })
  }

  const [tab, setTab] = useState('enteredData')
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchDetails() {
      const data = await getRegistrationDetails(id);
      setApplicationDetails(data[0])
      setFetchEnvelopeDetails(1);
    }
    fetchDetails();
  }, [id]);

  useEffect(() => {
    if (fetchEnvelopeDetails === 1 && applicationDetails.envelopeUrl) {
      const fetch = async () => {
        let status = await getEnvelopeStatus(applicationDetails.envelopeUrl);
        if (status.status.toString() === "404") status.status = "Not Found"
        updateApplicationDetails('envelopeStatus', status.status);
        setFetchEnvelopeDetails(10);
      }
      fetch();
    }
    else if (fetchEnvelopeDetails === 1 && !applicationDetails.envelopeUrl && applicationDetails.SA21) {
              updateApplicationDetails('envelopeStatus', 'completed');
              setFetchEnvelopeDetails(10);
            }
  }, [fetchEnvelopeDetails, applicationDetails])

  useEffect(() => {
    if (applicationDetails.Status === "Duplicate") {
      setModalInfo({
        title: "Warning - Duplicate Application",
        icon: errorIcon,
        body: "Warning - the EORI number for this application has already been registered with another organisation. Approval is disabled, please contact the customer for clarification."
      })
    }
  }, [applicationDetails.Status])

 async function onSaveCompliance() {
    const complianceDetails = {
      id: applicationDetails.id,
      dutyDefermentNumber: applicationDetails.dutyDefermentNumber,
      authorized: applicationDetails.authorized,
      CCGHeld: applicationDetails.CCGHeld,
      CCGReference: applicationDetails.CCGReference,
      warehouseID: applicationDetails.warehouseID,
      supervisingOffice: applicationDetails.supervisingOffice,
      cds: applicationDetails.cds
    } 

    const result = await putRegistrationCompliance(complianceDetails);
    if (result.success) {
      setStatusText("Compliance updated")
    }
  }

  async function onSaveEmail() {
    const result = await putRegistrationEmail(applicationDetails);
    if (result.success) {
      setStatusText("Email info updated")
    }
  }

  const validateAddress = () => {
    const freshErrors = {};

    if (!applicationDetails.building) freshErrors.building = "Required";
    if (!applicationDetails.street) freshErrors.street = "Required";
    if (!applicationDetails.town) freshErrors.town = "Required";
    if (!applicationDetails.postcode) freshErrors.postcode = "Required";
    if (!applicationDetails.country) freshErrors.country = "Required";

    return freshErrors
  }
  const onSaveAddress = async () => {
    const errors = validateAddress();
    setAddressErrors(errors);
    if (!Object.values(errors).some(err => !!err)) {
      const result = await putRegistrationAddress(applicationDetails);
      if (result.success) {
        setStatusText("Address updated")
      }
    }
  }

  function backButton() {
    navigate("/EpoApplications")    
  }
  
  const isCmt = useMemo(() => validateRole({department: "CMT"}), [validateRole]);
  const isOpsSuper = useMemo(() => validateRole({department: "OPS", role: "SUPERUSER"}), [validateRole]);
  const isFin = useMemo(() => validateRole({department: "Fin"}), [validateRole]);
  const isEditable  = useMemo(() => validateRole({department: 'OPS', role: "SUPERUSER"}) || validateRole({department: 'FIN'}) || validateRole({department: 'CMT'}) || validateRole({department: 'QA'}), [validateRole]);

  return (
    <EpoApplicationDetailContext.Provider value={{ isEditable, addressErrors, applicationDetails, setApplicationDetails, updateApplicationDetails, modalInfo, setModalInfo }}>
      <div className="full-width-container">
      <EpoConfirmationModal
                  details={modalInfo}
                  onClose={() => {navigate("/EpoApplications")}}
                  onBack={() => {setModalInfo(null)}}
              />
        <section className="floating-box" style={{ maxWidth: "1040px" }}>
          <div className="floating-box-header-general" style={{marginBottom: '20px'}}>
            <span>Application<span style={{fontSize:"20px"}}> {`( ${refNo} )` || ""}</span></span>
          </div>

          <Tabs       
              activeKey={tab}
              onSelect={(k) => {setStatusText(); setTab(k)}}>
            <Tab eventKey="enteredData" title="Entered Data">
              <div className="p-3 bg-white">
                <EpoEnteredData />
              </div>
            </Tab>
            <Tab eventKey="addressDetails" title="Address Details">
              <div className="p-3 bg-white">
                <EpoAddressDetails />
              </div>
            </Tab>
            <Tab eventKey="invoiceDetails" title="Payment Details">
              <div className="p-3 bg-white">
                <EpoInvoiceDetails />
              </div>
            </Tab>
            <Tab eventKey="email" title="Email">
              <div className="p-3 bg-white">
                <EpoEmail />
              </div>
            </Tab>
            <Tab eventKey="compliance" title="Customs &amp; Compliance">
              <div className="p-3 bg-white">
                <EpoCompliance />
              </div>
            </Tab>

            <Tab eventKey="smartPack" title="CABIE Pack">
              <div className="p-3 bg-white">
                <EpoSmartPack />
              </div>
            </Tab>

            {/* Code below commented out until the Credit Check function is completed */}

            {/* <Tab eventKey="creditCheck" title="Credit Check">
              <div className="p-3 bg-white">
                <EpoCreditCheck />
              </div>
            </Tab> */}

            {((isOpsSuper && applicationDetails.Status === "New") || 
            (isCmt && applicationDetails.Status === "New") ||
            (isFin && applicationDetails.Status === "Activated")
            ) &&
            <Tab eventKey="confirm" title="Confirmation">
              <div className="p-3 bg-white">
                <EpoConfirmation />
              </div>
            </Tab>}
          </Tabs>

          <div
            class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
            style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
          >
            <button type="button" class="cancel-button" onClick={backButton} style={{ display: "flex", borderRadius: "10px" }}>
              Back
            </button>
            {(tab === "compliance" || tab==="email" || tab==="addressDetails") && !!statusText && (isOpsSuper || isFin || isCmt) &&
            <span className="text-success bold me-3">{statusText}</span>}

            {tab === "compliance" && (isOpsSuper || isFin || isCmt) &&
                <button type="button" class="blue-button-teams green-btn" style={{ borderRadius: "10px" }} onClick={(onSaveCompliance)}>
                Save
              </button>
            }
            {tab === "email" && (isOpsSuper || isFin || isCmt) &&
                <button type="button" class="blue-button-teams green-btn" style={{ borderRadius: "10px" }} onClick={onSaveEmail}>
                Save
              </button>
            }
            {tab === "addressDetails" && (isOpsSuper || isFin || isCmt) &&
                <button type="button" class="blue-button-teams green-btn" style={{ borderRadius: "10px" }} onClick={(onSaveAddress)}>
                Save
              </button>
            }
          </div>
        </section>
      </div>
    </EpoApplicationDetailContext.Provider>
  );
};

export default EpoApplicationDetails;
