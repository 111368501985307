import { Modal } from "react-bootstrap";
import { StoreContext } from "./Store";
import React, {useContext, useState, useEffect} from "react";
import { Input, Select } from "./Input/Input.js";
import _ from 'lodash'

export const AdjustmentsModal = React.forwardRef(({animation, size, show, onHide, showButtons}, ref) => {
    const store = useContext(StoreContext);

    const [adjustments, setAdjustments] = useState(_.cloneDeep(store.adjustments[0]))

    useEffect(() => {
      if (show) {
        setAdjustments(_.cloneDeep(store.adjustments[0]))
      }
    }, [show])

    const updateAdjustment = (name, type, value) => {
        setAdjustments(adjustments.map((adjustment) => {
            if (adjustment.name === name) {
                if (type === 'amount') {
                    adjustment.amount = value;
                }
                else if (type === 'currency') {
                    adjustment.currency = value;
                }
            }
            return adjustment;
        }))
    }

    const save = () => {
      store.adjustments[1](_.cloneDeep(adjustments.map((adj) => {
        adj.error = undefined;
        return adj;
      })));
      onHide();
    }

    const close = () => {
      setAdjustments(_.cloneDeep(store.adjustments[0]));
      onHide();
    }

  return (
    <Modal centered size={size} animation={false} show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>Adjustments</Modal.Title>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={close}
        />
      </Modal.Header>
      <Modal.Body>
          <form ref={ref}>
            <div className="d-flex justify-content-center">
              <div>
              {adjustments.map((adjustment) => {
            return (
            <>
            <p style={{margin: '0px'}}><b>{adjustment.display}</b></p>
            <section style={{marginTop: '0px'}} className="d-flex flex-row">
                <div className="p-2" style={{width: '50%'}}>
                    <Input
                    label="Amount"
                    name={adjustment.name}
                    type="text"
                    value={adjustment.amount}
                    error={adjustment.error}
                    onChange={(e) => updateAdjustment(adjustment.name, 'amount', e.target.value)}
                    required={adjustment.required}
                    />          
                </div>
                <div className="p-2" style={{width: '50%'}}>
                    <Select
                    label="Currency"
                    name={adjustment.name + "Currency"}
                    data={adjustment.currency}
                    setData={(val) => updateAdjustment(adjustment.name, 'currency', val)}
                    options={store.currencyOptions[0]}
                    required={adjustment.required}
                    />
                </div>
            </section>
            </>
                )
        })}
              </div>

        </div>
        </form>
   
      </Modal.Body>
      <Modal.Footer
        class="py-3 px-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

<div className='push-left-right'>
        <button type="button" className="stepper-button red" onClick={close}>
          Cancel
        </button>

        <button
          class="stepper-button"
          onClick={save}
          style={{ display: (showButtons ? "flex" : "none") }} 
        >Save
        </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});

export default AdjustmentsModal;
