import React from 'react';
import { Outlet } from 'react-router-dom';
import EnvBanner from './components/EnvBanner';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";


const Layout = () => {
    return (
        <div className="d-flex flex-column min-vh-100">
            <EnvBanner />
            <Navbar />
            <Outlet />
            <div className="flex-grow-1" />
            <Footer />
        </div>
    );
}
export default Layout;