import { useState, useRef, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";

const RightClickMenu = ({ tableElement, onPaste, onCopy, onCut, onUndo }) => {
    const [show, setShown] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
  
    const rightClickMenu = useRef(null);
  
    const handleRightClick = useCallback((e) => {
      e.preventDefault();
      setShown(true);
      setPosition({ x: e.pageX, y: e.pageY });
    }, []);
  
    const handleMouseDown = useCallback((e) => {
      if (e.button === 2) {
        e.preventDefault();
        return;
      }
  
      if (rightClickMenu.current) {
        if (rightClickMenu.current.contains(e.target)) {
          e.preventDefault();
          return;
        }
      }
      setShown(false);
    }, []);
  
    const handlePaste = useCallback(async () => {
      setShown(false);
      const text = await navigator.clipboard.readText();
      onPaste({ type: "click-paste", text: text });
    }, [onPaste]);
  
    const handleCopy = useCallback(async () => {
      setShown(false);
      const text = onCopy();
      navigator.clipboard.writeText(text);
    }, [onCopy]);
  
    const handleCut = useCallback(async () => {
      setShown(false);
      const text = onCut();
      navigator.clipboard.writeText(text);
    }, [onCut]);
  
    useEffect(() => {
      if (!tableElement) return;
      tableElement.addEventListener("contextmenu", handleRightClick);
      document.addEventListener("mousedown", handleMouseDown);
      return () => {
        tableElement.removeEventListener("contextmenu", handleRightClick);
        document.removeEventListener("mousedown", handleMouseDown);
      };
    }, [tableElement, handleRightClick, handleMouseDown]);
  
    if (!show) return null;
  
    return ReactDOM.createPortal(
      <div
        className="right-click-menu"
        ref={rightClickMenu}
        style={{
          top: 0,
          left: 0,
          transform: `translate(${position.x}px, ${position.y}px)`,
          zIndex: 200,
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <div onClick={handleCopy}>Copy</div>
        <div onClick={handleCut}>Cut</div>
        <div onClick={handlePaste}>Paste</div>
        <div onClick={onUndo}>Undo</div>
        <spacer />
        {/* PLACEHOLDER */}
        {/* <div onClick={() => setShown(false)}>Need Help?</div> */}
      </div>,
      document.body
    );
  };

  export default RightClickMenu