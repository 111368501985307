import { useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { postCommCode, putCommCode } from "../../apiCalls";
import { Input } from "../../Input/Input";

const CommCodeModal = ({ onSave, onCancel, commCode, }) => {
const [localCommCode, setLocalCommCode] = useState({});

useEffect(() => {
    setLocalCommCode (commCode)
}, [commCode])

async function submitForm(event) {
    event.preventDefault();
    try {
      let data;
      if (localCommCode.id === -1) {
        data = await postCommCode(event.target)
        alert("New record added")
      } else {
        data = await putCommCode(event.target)
      }
       if (data[0].completed === 'Updated') {
          alert("Record updated");
        } 
        onSave()
        onCancel()
      } catch (err) {
        console.log(err);
        alert('Error updating record to DB')
      }
    }

  return (
    <Modal show={localCommCode?.id !== undefined} onHide={onCancel} centered size="lg" contentClassName="editModal" dialogClassName="wide-modal">
      <Modal.Header>
        <Modal.Title>Editing: {localCommCode?.CommLookup} - {localCommCode?.UKGBTDescription}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onCancel} />
      </Modal.Header>
      <Form onSubmit={submitForm} className="flex-grow-1 d-flex flex-column">
        <Modal.Body className="flex-grow-1">
        <Row>
        <input
                    type='number'
                    name = 'id'
                    value = {localCommCode?.id}
                    hidden
                />
            <Col>
                <Input
                    name="CommLookup"
                    type="text"
                    label="Commodity Code"
                    required
                    value={localCommCode?.CommLookup}
                    onChange={e => setLocalCommCode(prev => ({...prev, CommLookup:e.target.value}))}
                />
            </Col>
            <Col>
                <Input
                    name="Comm8"
                    type="text"
                    label="Comm8"
                    required
                    value={localCommCode?.Comm8}
                    onChange={e => setLocalCommCode(prev => ({...prev, Comm8:e.target.value}))}
                />
            </Col>
            </Row>
            <Row>
                <Col>
                <Input
                    name="UKVAT"
                    type="text"
                    label="UK VAT"
                    required
                    value={localCommCode?.UKVAT}
                    onChange={e => setLocalCommCode(prev => ({...prev, UKVAT:e.target.value}))}
                />
            </Col>
            <Col>
                <Input
                    name="UKQ2"
                    type="text"
                    label="UK Q2"
                    required
                    value={localCommCode?.UKQ2}
                    onChange={e => setLocalCommCode(prev => ({...prev, UKQ2:e.target.value}))}
                />
            </Col>
            </Row>
            <Row>
            <Col>
                <Input
                    name="UKQ3"
                    type="text"
                    label="UK Q3"
                    required
                    value={localCommCode?.UKQ3}
                    onChange={e => setLocalCommCode(prev => ({...prev, UKQ3:e.target.value}))}
                />
            </Col>
            <Col>
                <Input
                    name="UKSPS"
                    type="text"
                    label="UK SPS"
                    required
                    value={localCommCode?.UKSPS}
                    onChange={e => setLocalCommCode(prev => ({...prev, UKSPS:e.target.value}))}
                />
            </Col>
            </Row>
            <Row>
                <Col>
                <Input
                    name="UKDutyFlag"
                    type="text"
                    label="UK Duty Flag"
                    required
                    value={localCommCode?.UKDutyFlag}
                    onChange={e => setLocalCommCode(prev => ({...prev, UKDutyFlag:e.target.value}))}
                />
            </Col>
            <Col>
                <Input
                    name="Meursing"
                    type="text"
                    label="Meursing"
                    required
                    value={localCommCode?.Meursing}
                    onChange={e => setLocalCommCode(prev => ({...prev, Meursing:e.target.value}))}
                />
            </Col>
            </Row>
            <Row>
            <Col>
                <Input
                    name="IEDutyFlag"
                    type="text"
                    label="IE Duty Flag"
                    required
                    value={localCommCode?.IEDutyFlag}
                    onChange={e => setLocalCommCode(prev => ({...prev, IEDutyFlag:e.target.value}))}
                />
            </Col>
            <Col>
                <Input
                    name="UKGBTDescription"
                    type="text"
                    label="UK GBT Description"
                    required
                    value={localCommCode?.UKGBTDescription}
                    onChange={e => setLocalCommCode(prev => ({...prev, UKGBTDescription:e.target.value}))}
                />
            </Col>
            </Row>
            <Row>
                <Col>
                <Input
                    name="Good"
                    type="text"
                    label="Good"
                    required
                    value={localCommCode?.Good}
                    onChange={e => setLocalCommCode(prev => ({...prev, Good:e.target.value}))}
                />
            </Col>
            <Col>
                <Input
                    name="Q2"
                    type="text"
                    label="Q2"
                    required
                    value={localCommCode?.Q2}
                    onChange={e => setLocalCommCode(prev => ({...prev, Q2:e.target.value}))}
                />
            </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
          style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
        >
          <button type="button" class="cancel-button" onClick={onCancel} style={{ display: "flex", borderRadius: "10px" }}>
            Cancel
          </button>
          <button type="submit" class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
            Save Client
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default CommCodeModal;