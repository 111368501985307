import { forwardRef, useEffect, useContext } from "react";
import { CallbackContext } from './Spreadsheet';
import ReactDOM from "react-dom";

const FloatingRect = forwardRef(({ className, x_left, x_right, y_top, y_bottom, children, error }, ref) => {
    const { selection, setSelection, getBoundingClientOffset, excelState } = useContext(CallbackContext);
     /**
    * Updates position of 'root cell' floating rect when the row/column index changes.
    * Can be manually triggered by changing value of forceRecalc
    */
   useEffect(() => {
    
    if (
      ref.current &&
      selection.root.row_idx !== null &&
      selection.root.col_idx !== null &&
      className === 'root-overlay'
    ) {
      // `selection.root.row_idx + 1` needed to skip the header
      const target =
        ref?.current?.children?.[selection.root.col_idx]
          ?.children?.[selection.root.row_idx + 1];
      if (!target) return;
      const { x, y, bottom, right } = getBoundingClientOffset(target);
      // Auto scrolls the root into view when the row/col is updated (usually by keyboard)
      if (
        target.getBoundingClientRect().y < 0 ||
        target.getBoundingClientRect().y > window.innerHeight
      ) {
        //target.scrollIntoView();
      }

      let error = target.dataset.error;
      setSelection((prev) => ({
        ...prev,
        root: { ...prev.root, x_left: x, x_right: right, y_top: y, y_bottom: bottom, error },
      }));
    }
  }, [selection.root.row_idx, selection.root.col_idx, excelState.recalc]);

  /**
   * Updates position/size of selection floating rect when the row/column index changes.
   * Can be manually triggered by changing value of forceRecalc
   */
  useEffect(() => {
    if (
      ref.current &&
      selection.bounding.row_idx !== null &&
      selection.bounding.col_idx !== null &&
      className === 'root-overlay'
    ) {
      if (
        !ref.current.children ||
        !ref.current.children[selection.bounding.col_idx] ||
        !ref.current.children[selection.bounding.col_idx]
          .children ||
        !ref.current.children[selection.bounding.col_idx]
          .children[selection.bounding.row_idx + 1]
      )
        return;
      const target =
        ref?.current?.children?.[selection.bounding.col_idx]
          ?.children?.[selection.bounding.row_idx + 1];
      if (!target) return;
      // Auto scrolls the edge of the bounding box into view when the row/col is updated (usually by keyboard)
      if (
        target.getBoundingClientRect().y < 0 ||
        target.getBoundingClientRect().y > window.innerHeight
      ) {
        //target.scrollIntoView();
      }
      const { x, y, bottom, right } = getBoundingClientOffset(target);

      setSelection((prev) => {
        let x_left, x_right, y_top, y_bottom;

        // If the LEFT-SIDE of the newly NEWLY SELECTED CELL is FURTHER RIGHT than the ROOT:
        if (x > prev.root.x_left) {
          // then the LEFT-SIDE of the SELECTION BOX is the same as the ROOT's
          x_left = prev.root.x_left;
          // and the RIGHT-SIDE of the SELECTION BOX is the same as the NEWLY SELECTED CELL's
          x_right = right;
        } else {
          // else the LEFT-SIDE of the SELECTION BOX is the same as the NEWLY SELECTED CELL's
          x_left = x;
          // and the RIGHT-SIDE of the SELECTION BOX is the same as the ROOT's
          x_right = prev.root.x_right;
        }

        // If the TOP-SIDE of the newly NEWLY SELECTED CELL is FURTHER DOWN than the ROOT:
        if (y > prev.root.y_top) {
          // then the TOP-SIDE of the SELECTION BOX is the same as the ROOT's
          y_top = prev.root.y_top;
          // and the BOTTOM-SIDE of the SELECTION BOX is the same as the NEWLY SELECTED CELL's
          y_bottom = bottom;
        } else {
          // else the TOP-SIDE of the SELECTION BOX is the same as the NEWLY SELECTED CELL's
          y_top = y;
          // and the BOTTOM-SIDE of the SELECTION BOX is the same as the ROOT's
          y_bottom = prev.root.y_bottom;
        }

        return { ...prev, bounding: { ...prev.bounding, x_left, x_right, y_top, y_bottom } };
      });
    }
  }, [
    selection.bounding.row_idx,
    selection.bounding.col_idx,
    excelState.recalc,
  ]);



    return ReactDOM.createPortal(
      <div
        className={className + (error ? " error" : "")}
        style={{
          transform: `translate(${x_left}px, ${y_top}px)`,
          top: 0,
          left: 0,
          height: y_bottom - y_top - 1 + "px",
          width: x_right - x_left - 1 + "px",
        }}
      >
        {children}
      </div>,
      document.body
    );
  });

  export default FloatingRect