import { getEPOPortDropdown } from "../../apiCalls";
import { StoreContext } from "../../Store";
import { useEffect, useState, useContext, useRef } from "react";
import Stepper, { useStepper } from "../../Stepper";
import { Select } from "../../Input/Input";
import cargoShip from "../assets/cargo-ship.png";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";

export function ExitPortPage () {
  const { stepBackward, stepForward, localData, setDataByKey, activeStepKey, gotoStep } = useStepper();

  const store = useContext(StoreContext);

  const nextPage = () => {
    if (!localData.exitport?.id) {
      setError("Please select a port to continue.");
      return;
    }
    // setDataByKey("lockDirection", true);
    store.lockDirection[1](true);
    gotoStep("4. AdditionalRequirements")
  };

  useEffect(() => {
    getEPOPortDropdown("Export", store.epoDirection[0]).then((data) => {
      store.exitPortOptions[1](data);
    });
  }, [store.epoDirection[0]]);

  const backwards = () => {
      setError("");
      gotoStep("1. Direction")
};

  
  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)

  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "2. Exit Port" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "2. Exit Port",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  useEffect(() => {
    setError("");
  }, [localData.exitport]);

  const [error, setError] = useState("");

  return (
    <Stepper.Step hideUnlessVisited stepKey="2. Exit Port" text="Place of Exit">
      {/* <div> */}
      <div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{fontSize:"1.8rem"}}>Place of Exit</div>
      <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
  <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
    <input type="radio" disabled checked={localData.movementType === "Import"}/>
    <span>Import</span>
  </label>

  <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px' }}>
    <input type="radio" disabled checked={localData.movementType === "Export"} />
    <span>Export</span>
  </label>
</div>
        {/* <span className="epo-steps-header">Place of Exit</span> */}
        <div className=' px-2 mt-1 pt-2 d-flex flex-column' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
        {/* <div className="d-flex step-banner with-img"> */}
        <div className="d-flex justify-content-center step-banner">
        {/* <div className="flex-grow-1 mt-2 pb-2"/>
        <p style={{opacity: '0'}}>h</p> */}
           <img src={cargoShip} width="150" height="150" alt="port" className="mt-4 mb-4" />
           {/* <div style={{maxWidth: "350px"}} class="d-flex flex-column mb-1 mt-3 px-4">
            <p style={{color: '#0e2554'}}>Please select the expected Place of Exit for this freight movement.</p>
            <div className="flex-grow-1 mt-2"/>
            <p></p>
            </div> */}

        </div>
        <div class="d-flex flex-column align-self-center mt-auto " >
            {/* <span style={{maxWidth: "280px", fontWeight: "500"}}>Please select the expected arrival location for this freight movement. </span> */}
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}}>Please select the expected Place of</span>

<span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}}>Exit for this freight movement. </span>
</div>
        <div className="d-flex flex-column mt-auto" style={{paddingLeft: '6rem', paddingRight: '6rem'}}>
          <Select
            label="Exit Location"
            name="exitport"
            data={localData.exitport?.id}
            onChangeAsObject={(data) => setDataByKey("exitport", data)}
            options={store.exitPortOptions[0]}
            filter={1}
            required
          />
        </div>
        <div className="px-3 mb-3 w-75 mx-auto">
          {error && (
            <div className="alert-danger px-3 py-1 w-100 text-center" style={{borderRadius: '10px'}}>{error}</div>
          )}
        </div>
      {/* </div> */}
      <section className="d-flex justify-content-between px-3 pb-4 mt-auto" >
        <button type="button" className="stepper-button red" onClick={backwards}>
          Back
        </button>
        <button type="button" className="stepper-button" onClick={nextPage}>
          Next
        </button>
      </section>
      </div>
    </Stepper.Step>
  );
}
