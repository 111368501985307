import { useContext } from "react";
import { Alert, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import ConfirmationModal from "../ConfirmationModal";
import { StoreContext } from "../Store";

export default function NewloadError({ error, children, resetErrorBoundary }) {
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  return (
    <>
      <ConfirmationModal
        header="Error Occured"
        show={true}
        onHide={() => null}
        hideCancel={true}
        onConfirm={resetErrorBoundary}
      >
        <p>
        {store.newLoadFromFile[0] ? (
          <span>
            Something went wrong whilst processing your uploaded RPA. Please
            refresh and try again.


          </span>
        ) : (
          <span>
            Something went wrong with your inputted load. Please refresh and
            try again.
          </span>
        )}
        </p>

        <p>
          Error code:
          <pre>
            <code>{JSON.stringify(error?.message)}</code>
          </pre>
        </p>
      </ConfirmationModal>
    </>
  );
}
