import { useContext, useState, useEffect } from "react";
import { EpoApplicationDetailContext } from "../EpoApplicationDetails";
import { Input, InputSelect, Select, TextArea } from "../../../Input/Input";
import { Col, OverlayTrigger, Popover, PopoverContent, Row } from "react-bootstrap";
import { Download, ExternalLinkAlt } from "@styled-icons/fa-solid";
import { putRegistrationDetails } from "../../../apiCalls";
import { useNavigate } from "react-router-dom";
import EpoConfirmationModal from "./EpoConfirmationModal";
import warningIcon from "../../../../images/warningIcon.svg";
import errorIcon from "../../../../images/errorIcon.svg";
import successIcon from "../../../../images/successIcon.svg";
import ConditionalWrapper from "../../../Util/ConditionalWrapper";
import { useSecurity, ShowIf } from "../../../Security/security";

const currencies = [
  { id: "gbp", data: "GBP", char: "£" },
  { id: "eur", data: "EUR", char: "€" },
];

const paymentOptions = [
  { id: "credit", data: "Credit" },
  { id: "payg", data: "Pay As You Go" },
];
export default function EpoConfirmation() {
  const { validateRole } = useSecurity();
  const { applicationDetails, updateApplicationDetails, setModalInfo } = useContext(EpoApplicationDetailContext);
  
  const [decisions, setDecisions] = useState([]);

  useEffect(() => {
    if (validateRole({ department: "OPS", role: "SUPERUSER" })) {
      setDecisions([
        { id: "activated", data: "Activated" },
        { id: "rejected", data: "Rejected" },
      ]);
    } else if (validateRole({ department: "CMT" })) {
        setDecisions([
          { id: "activated", data: "Activated" },
          { id: "rejected", data: "Rejected" },
        ]);
    } else if (validateRole({ department: "FIN" })) {
      setDecisions([
        { id: "accepted", data: "Accepted" },
        { id: "rejected", data: "Rejected" },
      ]);
    }
    

  }, [validateRole, applicationDetails.Status]);


  return (
    <div>
      <div className="registrationText mb-2">Final Confirmation of Registration</div>
      <Row>
        <Col xs="4">
          <Select
            label="Decision"
            name="decision"
            options={decisions}
            data={applicationDetails.finalDecision}
            onChange={(e) => updateApplicationDetails("finalDecision", e.target.value)}
          />
        </Col>
      </Row>
      {applicationDetails.finalDecision === "accepted" && <ApplicationAccepted />}
      {applicationDetails.finalDecision === "rejected" && <ApplicationRejected />}
      {applicationDetails.finalDecision === "activated" && <ApplicationActivated />}
    </div>
  );
}

function ApplicationAccepted() {
  const navigate = useNavigate();
  const { applicationDetails, updateApplicationDetails, setModalInfo } = useContext(EpoApplicationDetailContext);
  const [saveDisabled, setSaveDisabled] = useState(false);

  async function onApprove() {
    setSaveDisabled(true);
    const { shipmentRate, creditLimit, finalDecision, currency, id, paymentMethod } = applicationDetails;
    const data = { shipmentRate, creditLimit, finalDecision, currency, id, paymentMethod, type: "submit" };
    console.log(data);
    const response = await putRegistrationDetails(data);
    updateApplicationDetails('Status', response.Status)

    if (response.finalDecision === "accepted") {
      setModalInfo({
        title: "Application Accepted",
        icon: successIcon,
        body: "The application has been accepted, a confirmation email will be sent to the applicant",
      });
    } else if (response.finalDecision === "AlreadyRegistered") {
      setModalInfo({
        title: "Already Registered",
        icon: warningIcon,
        body: "This application has already been processed so cannot be Accepted.",
      });
    } else {
      setModalInfo({
        title: "Submission Error",
        icon: errorIcon,
        body: "An error has occured, please try again.",
      });
    }
  }

  const downloadFile = (fileName, fileData) => {
    if (!fileName || !fileData) return;
    var fileURL = window.URL.createObjectURL(new Blob([fileData]));
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = fileURL;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  return (
    <>
      <Row xs="4">
        <Col>
          <InputSelect
            label="Shipment Rate"
            prefix={currencies.find((cur) => cur.id === applicationDetails.currency)?.char ?? "-"}
            inputData={applicationDetails.shipmentRate}
            setInputData={(data) => updateApplicationDetails("shipmentRate", data)}
            selectData={applicationDetails.currency}
            setSelectData={(data) => updateApplicationDetails("currency", data)}
            selectOptions={currencies}
          />
        </Col>
      </Row>
      <Row xs="1">
        <Col>
          <label className="mt-4 mb-4 d-flex align-items-center">
            <input type="checkbox" checked={applicationDetails.allReviewed} onClick={(e) => updateApplicationDetails("allReviewed", e.target.checked)} />
            <span>I have reviewed all of the data for this application and I am confident it is correct.</span>
          </label>
        </Col>
      </Row>
      <div className="d-flex">
        <ConditionalWrapper
          condition={!(applicationDetails.allReviewed && applicationDetails.smartpackReviewed)}
          wrapper={(children) => (
            <OverlayTrigger
              trigger="hover"
              placement="auto"
              overlay={
                <Popover id="popover-basic" style={{ maxWidth: "300px" }}>
                  <Popover.Title as="h3">Missing Information</Popover.Title>
                  <Popover.Content className="px-3 py-2">
                    <p>Please confirm both the CABIE pack and overall data checkboxes before approving.</p>
                  </Popover.Content>
                </Popover>
              }
            >
              {children}
            </OverlayTrigger>
          )}
        >
          <div>
            <button
              type="button"
              class="approveRejectButton red"
              style={{ borderRadius: "10px", marginTop: "10px" }}
              disabled={!(applicationDetails.allReviewed && applicationDetails.smartpackReviewed)}
              onClick={() => onApprove()}
            >
              Approve
            </button>
          </div>
        </ConditionalWrapper>
      </div>
    </>
  );
}

function  ApplicationRejected() {
  const { applicationDetails, updateApplicationDetails, setModalInfo } = useContext(EpoApplicationDetailContext);
  const [disabled, setDisabled] = useState(true);

  async function onReject() {
    const { reasonForRejection, emailAddress, finalDecision, id } = applicationDetails;
    const data = { id, reasonForRejection, emailAddress, finalDecision, type: "submit"};
    const response = await putRegistrationDetails(data);
    updateApplicationDetails('Status', response.Status)

    if (response.finalDecision === "rejected") {
      setModalInfo({
        title: "Application Rejected",
        icon: errorIcon,
        body: "The appplication has been rejected, an email will now be sent to the applicant.",
      });
    } else if (response.finalDecision === "AlreadyRegistered") {
      setModalInfo({
        title: "Already Registered",
        icon: warningIcon,
        body: "This application has already been processed so cannot be Rejected.",
      });
    } else {
      setModalInfo({
        title: "Submission Error",
        icon: errorIcon,
        body: "An error has occured, please try again",
      });
    }
  }

  return (
    <>
      <Row>
        <Col xs="4">
          <Input label="Send email to" value={applicationDetails.emailAddress} />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <TextArea
            rows={10}
            style={{ resize: "none", maxHeight: "800px" }}
            label={<>Reason For Rejection<span style={{ fontSize: "120%" }}>*</span></>}
            value={applicationDetails.reasonForRejection}
            onChange={(e) => updateApplicationDetails("reasonForRejection", e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <label className="mt-4 mb-4 d-flex align-items-center">
            <input
              type="checkbox"
              name="c1207nForm"
              // checked={store.tadEns[0]}
              onClick={() => setDisabled(!disabled)}
            />
            <span>I have reviewed the above for the rejection reason and I am confident the wording is fair & accurate.</span>
          </label>
        </Col>
      </Row>
      <button type="button" class="approveRejectButton red" style={{ borderRadius: "10px", marginTop: "10px" }} disabled={disabled || !applicationDetails.reasonForRejection} onClick={onReject}>
        Reject
      </button>
    </>
  );
}

function ApplicationActivated() {
  const { applicationDetails, updateApplicationDetails, setModalInfo } = useContext(EpoApplicationDetailContext);

  async function onApprove() {
    const { finalDecision, emailAddress, currency, id, paymentMethod } = applicationDetails;
    const data = { finalDecision, emailAddress, currency, id, paymentMethod, type: "submit" };
    console.log(data);
    const response = await putRegistrationDetails(data);
    updateApplicationDetails('Status', response.Status)
    if (response.finalDecision === "AlreadyRegistered") {
      setModalInfo({
        title: "Already Registered",
        icon: warningIcon,
        body: "This application has already been processed so cannot be Accepted.",
      });
    }  else if (response.finalDecision === "activated") {
      setModalInfo({
        title: "Account Activated",
        icon: successIcon,
        body: "Great! This account has been activated and the trader will receive their login details through email.",
      });
    } else {
      setModalInfo({
        title: "Submission Error",
        icon: errorIcon,
        body: "An error has occured, please try again.",
      });
    }
  }

  return (
    <>
    <Row>
        <Col xs="4">
          <Input label="Send login details to" value={applicationDetails.emailAddress} />
        </Col>
      </Row>
      <Row xs="1">
        <Col>
          <label className="mt-4 mb-4 d-flex align-items-center">
            <input type="checkbox" checked={applicationDetails.allReviewed} onClick={(e) => updateApplicationDetails("allReviewed", e.target.checked)} />
            <span>I have reviewed all of the data for this application and I am confident it is correct.</span>
          </label>
        </Col>
      </Row>
      <div className="d-flex">
        <ConditionalWrapper
          condition={!(applicationDetails.allReviewed && applicationDetails.smartpackReviewed)}
          wrapper={(children) => (
            <OverlayTrigger
              trigger="hover"
              placement="auto"
              overlay={
                <Popover id="popover-basic" style={{ maxWidth: "300px" }}>
                  <Popover.Title as="h3">Missing Information</Popover.Title>
                  <Popover.Content className="px-3 py-2">
                    <p>Please confirm both the CABIE pack and overall data checkboxes before approving.</p>
                  </Popover.Content>
                </Popover>
              }
            >
              {children}
            </OverlayTrigger>
          )}
        >
          <div>
            <button
              type="button"
              class="approveRejectButton red"
              style={{ borderRadius: "10px", marginTop: "10px" }}
              disabled={!(applicationDetails.allReviewed && applicationDetails.smartpackReviewed)}
              onClick={() => onApprove()}
            >
              Activate
            </button>
          </div>
        </ConditionalWrapper>
      </div>
    </>
  );
}