/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../table.css";
import "../report.css";
import {
  putProduct,
  deleteProductDetails,
  checkProductvalid,
  postProducts
} from "../apiCalls";
import { Input, FilterableSelect, Select } from "../Input/Input.js";
import { useNavigate } from "react-router-dom";
import { ShowIf } from '../Security/security';
import { Modal } from "react-bootstrap";

const ProductDetails = ({product, updateProduct, createOrEdit, originOptions, back}) => {


  const [errors, setErrors] = useState({});
  const [meursingRequired, setMeursingRequired] = useState(false);
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [popupReturns, setPopupReturns] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(false);



  const updateErrors = (key, value) => {
    setErrors(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  useEffect(() => {
      const fetch = async () => {
        await handleCommBlur(product.commodityCode);
      }
      if (product.commodityCode)
        fetch();

  }, [])

  const handleCommBlur = async (code = product.commodityCode) => {
    const data = await checkProductvalid(code);
    if (data[0].completed === "Valid") {
      updateErrors('commodityCode', undefined);
      setMeursingRequired(data[0].MeursingRequired === "1");
      updateProduct('Description', data[0].description);
    } else {
      setMeursingRequired(false);
      updateErrors('commodityCode', "Invalid commodity code.");
      updateProduct('Description', undefined);
    }
  };

  useEffect(() => {
    if (!meursingRequired) {
      updateProduct('meursing', "");
    }
  }, [meursingRequired])


  const handleUpdate = async (event) => {
    event.preventDefault();
    await handleCommBlur();
    if (!errors.commodityCode) {
      try {
        const data = await putProduct(event.target);
        if (data[0].completed === "Updated") {
          setPopupText('Record Updated')
          setShowPopup(true);
          setPopupReturns(true);
        }
        if (data[0].completed !== "Updated") {
          setPopupText('Error Updating Record')
          setShowPopup(true);
          setPopupReturns(false);
        }
        return data;
      } catch (err) {
        console.log(err);
      }
    }
  };

  async function handlePost(event) {
    event.preventDefault();
    await handleCommBlur();
    if (!errors.commodityCode) {
      try {
        const data = await postProducts(event.target)
        if (data[0].completed !== 'Inserted') {
          setPopupText('Product code already exists')
          setShowPopup(true);
          setPopupReturns(false);
        }
        if (data[0].completed === 'Inserted') {
          setPopupText('Record Created')
          setShowPopup(true);
          setPopupReturns(true);
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleDelete = async () => {
    setShowDeletePopup(true);
    setPopupText("Are you sure you wish to delete this product?")
  };

  useEffect(() => {
    const fn = async () => {
      if (deleteRecord) {
        const data = await deleteProductDetails(product.id);
        if (data[0].completed === "Deleted") {
          setPopupText("Record deleted")
          setShowPopup(true);
          setPopupReturns(true);
        }
        if (data[0].completed !== "Deleted") {
          console.log(data[0].completed);
          setPopupText("Error deleting record")
          setShowPopup(true);
          setPopupReturns(false);
        }
        setDeleteRecord(false);
      }
    }
    fn();
  }, [deleteRecord])

  return (
    <div className="full-width-container">
      <section className="floating-box" style={{ maxWidth: "600px" }}>
        <div className="floating-box-header">
          <span>Product Details</span>
        </div>
        <div className="d-flex justify-content-center">
        <form onSubmit={createOrEdit === 'edit' ? handleUpdate : handlePost} id="TeamMemberDetails">
          <div id="userAdmin-form">
            <input type="number" name="id" value={product.id} hidden />
            <Input
              type="text"
              name="product"
              label="Product Code or SKU"
              value={product.product}
              onChange={(e) => updateProduct('product', e.target.value)}
            />
            <Input
              type="text"
              name="commodityCode"
              label="Commodity Code"
              value={product.commodityCode}
              onChange={(e) => updateProduct('commodityCode', e.target.value.trim())}
              onBlur={() => handleCommBlur()}
              error={errors.commodityCode}
              info={(!errors.commodityCode && !!product.commodityCode) ? <span style={{ color: 'rgb(0, 215, 60)', fontWeight: '700' }}>✓</span> : undefined}
            />
            {/* <Input type = 'text' name = 'productName' label = 'productName' value = {productName} onChange={e => setProductName(e.target.value)} /> */}
            <FilterableSelect
              options={originOptions}
              name="origin"
              data={product.origin}
              label="Country of Origin"
              required
              setData={(v) => {updateProduct('origin', v)}}
            />
            <Input
              type="text"
              name="meursing"
              label="Meursing Code"
              value={product.meursing}
              disabled={!meursingRequired}
              onChange={(e) => updateProduct('meursing', e.target.value)}
            />
            <Input
              type="text"
              name='description'
              label="Customs Description"
              value={product.Description}
              disabled={true}
            />
         </div>
            <section className="d-flex flex-row py-3 px-3 mt-4 gap-3 border-top align-items-center">
              <button type="button" className="stepper-button red" onClick={back}>
                Back
              </button>
              <div className="flex-grow-1" />
              <ShowIf or={[{department: 'OPS'}, {department: 'DCT'}, {department: 'QA'}]}>
                {createOrEdit === 'edit' && 
                        <button type="button" className="stepper-button yellow" onClick={handleDelete} >
                        Delete
                      </button>
                }

                <button type='submit' className="stepper-button" >
                  Save
                </button>
              </ShowIf>
          </section>
        </form>
        </div>
      
      </section>
      <Modal centered  animation={false} show={showDeletePopup}>
      <Modal.Header>
        <Modal.Title>Delete?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {popupText}
      </Modal.Body>
      <Modal.Footer
        class="py-3 px-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

<div className='push-left-right'>
        <button
          class="stepper-button"
          onClick={() => {
            setShowDeletePopup(false);
            setDeleteRecord(false)
          }}
        >No
        </button>
        <button
          class="stepper-button red"
          onClick={() => {
            setShowDeletePopup(false);
            setDeleteRecord(true)
          }}
        >Yes
        </button>
        </div>
      </Modal.Footer>
    </Modal>
<Modal centered  animation={false} show={showPopup}>
      <Modal.Header>
        <Modal.Title>Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {popupText}
      </Modal.Body>
      <Modal.Footer
        class="py-3 px-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

<div className='d-flex justify-content-end'>
        <button
          class="stepper-button"
          onClick={() => {
            setShowPopup(false);
            if (popupReturns)
              back();
          }}
        >Ok
        </button>
        </div>
      </Modal.Footer>
    </Modal>
    </div>
  );
};

export default ProductDetails;