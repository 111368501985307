import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container, OverlayTrigger, Popover, PopoverContent, Modal } from "react-bootstrap";
import { postSelectedReport } from "../apiCalls";
import { useSecurity } from "../Security/security";
import { StoreContext } from "../Store";
import { useParams } from "react-router-dom";
import DataTable from "./DataTable1";

const ReportsResults = () => {
    // const { securityState, validateRole } = useSecurity();
    const store = useContext(StoreContext);
    // const history = useHistory();
    const { endPoint, reportName, paramCombination, startDate, endDate, startDate2, endDate2, customer } = useParams();
    const [invoices, setInvoices] = useState([{ "": "" }])
    const navigate = useNavigate();
    const inputStyle = { width: '100%', boxSizing: 'border-box', border: 'none', backgroundColor: 'transparent' }
    // Default Header Styles
   const headerStyle = { fontSize: '12px' };
    // Default Row Styles
   const defaultStyle = { fontSize: '14px', width: '2rem'  };
    // Override individual row styles

    useEffect(() => {
        async function fetchData() {
          try {
            const fd = new FormData();

            console.log("paramCombination", paramCombination);
            console.log("startDate", startDate);
            console.log("endDate", endDate);
            console.log("startDate2", startDate2);
            console.log("endDate2", endDate2);

            fd.append("paramCombination", paramCombination);
            fd.append("startDate", startDate);
            fd.append("endDate", endDate);
            fd.append("startDate2", startDate2);
            fd.append("endDate2", endDate2);
            fd.append("customer", customer);
            // if (user) {
            //     fd.append("userId", user)
            //   }
            const result = await postSelectedReport(endPoint, fd)
            setInvoices(result)
            return result;

          } catch (err) {
            console.log(err);
          }
        }
        fetchData();
      }, [endPoint]);

      const backToParameters = () => {
        console.log('reeeeep ', store.reportParams[0])
        if (store.reportParams[0][0]) {
          navigate(`/ReportsAdmin/${store.reportParams[0][0].data}`)
          return
        }
        else {
          navigate(`/ReportsList`)
          return
        }
      }


    return (
        <>

        <Container className="full-width-container">
          <section className='floating-box' style={{minWidth: '1865px'}}>
            <div className="d-flex flex-row justify-content-between">
                <div  className="floating-box-header-general">
                  <span>{reportName}</span>
                </div>

                {paramCombination === '1' && (      
                                                  <div className="d-flex flex-column " style={{marginRight: '15px', paddingTop: '1.3rem', fontWeight: '400', fontSize: '0.8rem'}}>
<div className="d-flex flex-row justify-content-between ">          
                <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}} >Completed Start: </span>
<span className="d-flex ">{startDate}</span>
</div>
                  <div className="d-flex flex-row justify-content-between">          
                  <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}}>Completed End:</span>
                  <span className="d-flex " >{endDate}</span>
                  </div>
                  </div>

                )}
                {paramCombination === '2' && (   
                  <div className="d-flex flex-column " style={{marginRight: '15px', paddingTop: '1.3rem', fontWeight: '400', fontSize: '0.8rem'}}>

                  <div className="d-flex flex-row justify-content-between ">          
                  <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}} >Created Start: </span>
  <span className="d-flex ">{startDate}</span>
  </div>   
                  <div className="d-flex flex-row justify-content-between">          
                <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}}>Created End:</span>
                <span className="d-flex " >{endDate}</span>
                </div>
                </div>

                )}
                {paramCombination === '3' && (     
                  
                                  <div className="d-flex flex-column " style={{marginRight: '15px', paddingTop: '1.3rem', fontWeight: '400', fontSize: '0.8rem'}}>

<div className="d-flex flex-row justify-content-between ">          
                <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}} >Created Start: </span>
<span className="d-flex ">{startDate}</span>
</div>
<div className="d-flex flex-row justify-content-between">          
                <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}}>Created End:</span>
                <span className="d-flex " >{endDate}</span>
                </div>
                  <div className="d-flex flex-row justify-content-between ">          
                <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}} >Completed Start: </span>
<span className="d-flex ">{startDate2}</span>
</div>
                  <div className="d-flex flex-row justify-content-between">          
                  <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}}>Completed End:</span>
                  <span className="d-flex " >{endDate2}</span>
                  </div>
                  </div>
                )}
                {/* {paramCombination === 4 && (      
                  <div className="d-flex flex-row justify-content-between">          
                <span className="text-eoriblue d-flex bold" style={{marginRight: '10px'}}>Completed End:</span>
                <span className="d-flex " >{completedEndDate}</span>
                </div>
                )} */}

                </div>
                
                <div className='px-3 pt-4' >

                  <DataTable
                    // columns={columns}
                    // containerRef={containerRef} 
                    initialData={invoices} 
                    disabled={true}
                    defaultStyle={defaultStyle}
                    headerStyle={headerStyle}
                    sortable={true}
                    filterable={true}
                    inputStyle={inputStyle}
                    rootClose={true}
                    downloadable={true}
                    returnPrev={() => backToParameters()}
                    />

                </div>
          </section>
        </Container>

             </>
) 
  };


  export default ReportsResults;