import {useState, useEffect} from 'react'
import {getProducts, getNewLoad} from '../apiCalls'
import ProductDetails from './ProductDetails'
import ProductsList from './ProductsList'

function Products() {
  const [products, setProducts] = useState([{ "": "" }])
  const [requestFailed, setRequestFailed] = useState('')
  const [product, setProduct] = useState();
  const [editCreate, setEditCreate] = useState();
  const [originOptions, setOriginOptions] = useState([]);

  async function fetchData() {
    try {
      const data = await getProducts();
      setProducts(data);
      const originOptions = await getNewLoad("countryname");
      setOriginOptions(originOptions);

    } catch (err) {
      setRequestFailed(true);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const updateProduct = (key, value) => {
    setProduct(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }


  if (requestFailed) return <p>Failed!</p>

  return (
    <>
      {!product ? 
      <ProductsList 
      products={products} 
      setProduct={setProduct}
      setEditCreate={setEditCreate}
      setProducts={setProducts}
      />
      :
      <ProductDetails 
      product={product}
      updateProduct={updateProduct}
      originOptions={originOptions}
      back = {() => {fetchData(); setProduct();}}
      createOrEdit={editCreate}
      />
    }
    </>
  )
}

export default Products
