import { Modal } from "react-bootstrap";

export const FormStatusModal = ({ show, onHide, hideHeader, hideCancel, children, title, ...rest }) => {
  return (
    <Modal show={show} onHide={onHide} {...rest}>
    <Modal.Header style={{ display: (hideHeader ? "none" : "flex") }} >
      <Modal.Title>{title}</Modal.Title>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        onClick={onHide}
      />
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer
      class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
      style={{
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
      }}
    >
      <button
        class="blue-button-teams"
        onClick={(event) => {
          onHide(event);  
        }}
        style={{ borderRadius: "10px" }}
      >
        Close    
      </button>
    </Modal.Footer>
  </Modal>
  )
}
