import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { getNewLoadQaVat } from "../../apiCalls";
import copyIcon from '../../../images/copyIcon.png';

const RevenuePayable = ({ consigneeID, totalLiability, customsDuties, vat }) => {
    const fieldStatus_Warning = 'warningHighlight';
    const fieldStatus_Error = 'invalidHighlight';

    const [buttonText, setButtonText] = useState("Copy Ref");
    const [newLoadQaVat, setNewLoadQaVat] = useState({
        id: null,
        vatMessage: '',
        vatValid: true
    });

    useEffect(() => {
        //console.log('RevenuePayable - fetching: ', consigneeID);
        async function fetch() {
            try {
                const data = await getNewLoadQaVat(consigneeID);
                //console.log('data: ', data[0]);
                setNewLoadQaVat({
                    id: data[0].id,
                    vatMessage: data[0].vatMessage,
                    vatValid: data[0].vatValid
                });
          
            } catch (error) {
              console.error('Error fetching NBP list: ', error);
            }
        }
        
        fetch();
    }, [consigneeID]);

    const handleCopyRef = () => {
      console.log('Revenue Payable  - copy customs duties');
      setButtonText("Copied");
    }

    const fieldStatus = !newLoadQaVat.vatValid ? fieldStatus_Error : '';

    return (
        <Container fluid>
            <div className="bp-section-heading">Revenue Payable</div>
            <div className="bp-table-grid bp-grid-default" style={{ display: 'grid', gridTemplateColumns: '45% 55%', padding: '0' }}>
                <div className="bp-table-grid-header bp-grid-default">Total Liability (GBP)</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>- {/* {totalLiability} */}</div>
                <div className={ `bp-table-grid-header bp-grid-default customs-duties ${fieldStatus_Error}` }>
                    <span>Customs Duties</span>
                    <button onClick={handleCopyRef} title={buttonText} className="btn_submitted_copy_ref">
                        <img src={copyIcon} alt="copy icon" />
                    </button>
                </div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>Importers Deferment {/* {customsDuties} */}</div>
                <div className={ `bp-table-grid-header bp-grid-default ${fieldStatus}` }>VAT</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{newLoadQaVat.vatMessage}</div>
            </div>
        </Container>
    );
};

export default RevenuePayable;