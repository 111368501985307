import React, { useState, useEffect } from 'react';
import "../main.css";
import "./neenor.css";
import { getNeeNor } from '../apiCalls';
import NeeNorList from './NeeNorList';
import NeeNorDetails from './NeeNorDetails';


const Neenor = () => {

  const [requestFailed, setRequestFailed] = useState('')
  const [neeNors, setNeeNors] = useState([{ "": "" }])
  const [selectedNeenor, setSelectedNeenor] = useState();
  const [editCreate, setEditCreate] = useState();
  const updateNeenor = (key, value) => {
      setSelectedNeenor(prev => {
        return {
          ...prev,
          [key]: value
        }
      })
  }

 
  if (requestFailed) return <p>Failed!</p>


  return (
    <>
                {!selectedNeenor ?
                <>
              <NeeNorList
              neeNors={neeNors}
              setNeeNor={setSelectedNeenor}
              setNeeNors={setNeeNors}
              setEditCreate={setEditCreate}
              />
               {console.log("NeeNorList is called")}
              </>
              :
              <>
                <NeeNorDetails 
                neenor={selectedNeenor}
                  updateNeeNor={updateNeenor}
                  back={() => {setSelectedNeenor()}}
                  editCreate={editCreate}
                />
                 {console.log("NeeNorDetails is called", selectedNeenor )}
                 </>
                
            }
    </>
      )
    
  
    
    }


export default Neenor;