import React, { useState, useEffect } from 'react';
// import "./main.css";
import "./teams.css";
import { getTeamMembers, postTeamMembers } from "./apiCalls";
import tableHeaders from "./tableHeaders";
import TableDetails from "./TableDetails";
import { useNavigate } from 'react-router-dom';
import { Table, Container, Row, Col, Button } from 'react-bootstrap'



const Team = () => {

  const [teamMembers, setTeammembers] = useState([{ "": "" }])
  const [requestFailed, setRequestFailed] = useState(false);
  const [saved, setSaved] = useState('');
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getTeamMembers()
        setTeammembers((data))
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [saved])

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const data = await postTeamMembers(event.target)
      if (data[0].completed !== 'Inserted') {
        alert('Team member already exists')
      }
      setSaved(data);
      setName('');
      setEmail('');
    } catch (err) {
      console.log(err)
    }
  }

  if (requestFailed) return <p>Failed!</p>

  const returnData = teamMembers;

  return (
    
    <Container  className="teamBackground">
      {/* <style type="text/css">
    {`
    .btn-flat {
      background-color: var(--eori-yellow);
      color: white;
    }

    .btn-xxl {
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
    }
    `}
  </style> */}
      <Row className='background'  >
        <Col className="teamHeader">
          <span>&nbsp;&nbsp;Team members</span>
        </Col>
        <form onSubmit={handleSubmit} className = 'teamForm'>
        <Row >
          <Col xs={{ span: 4}} >
            <Row >
              <lablel className = 'teamsLabel'>Name</lablel>
            </Row>
            <Row>
              <input className = 'teamInputs' name="name" type="text" value={name} required onChange={e => setName(e.target.value)} />
            </Row>
          </Col>
          <Col className = 'teamName' xs={{ span: 4, offset: 1 }} >
            <Row >
              <lablel className = 'teamsLabel'>Email</lablel>
            </Row>
            <Row>
            <input className = 'teamInputs' name="email" label="Email" type="email" value={email} required onChange={e => setEmail(e.target.value)} />
            </Row>
          </Col>
          <Row>
          <Col  xs={{ span: 1, offset: 11 }} >
              <Button variant="default" className="textColor bgColor" type="submit">Add</Button>
          </Col>
          </Row>
        </Row>
          </form>
        <Row>
          <Table striped bordered className="teamTable" >
            <tbody>
              <tr >
                {tableHeaders(returnData)}
              </tr>
              <TableDetails invoices = {teamMembers} history={ navigate } url = '/teamdetails' />
            </tbody>
          </Table>
        </Row>    
      </Row>
    </Container >
  )
}

export default Team;