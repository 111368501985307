/**
 * @typedef {Object} SecurityState
 * @property {string} role
 * @property {string} department
 * @property {string} companyid
 * @property {string} userid
 * @property {boolean} admin
 * @property {boolean} loggedIn
 * @property {0 | 1} C1207N
 */

/**
 *
 * @param {SecurityState} state
 * @param {{type: string} & Record<string, any>} action
 * @returns
 */
export default function securityReducer(state, action) {
  console.debug("Security reducer: ", state, action);
  switch (action.type) {
    case "login":
      return login(action.detail);
    case "loadSession": 
      return loadSession();
    case "logout":
      return logout();
    case "ddaUploaded":
      return ddaUploaded(state)
    default:
      throw new Error("Unrecognised action attempted.");
  }
}

/**
 *
 * @param {{type: string, role: string, department: string, companyid: string, userid: string}} detail
 * @returns {SecurityState} newState
 */
function login(detail) {
  const userdata = { userid: detail.userid, department: detail.department, role: detail.role, companyid: detail.companyid, admin: detail.admin, C1207N: detail.C1207N, token: detail.token };
  sessionStorage.setItem(process.env.REACT_APP_API + 'userdata', JSON.stringify(userdata));
  sessionStorage.setItem('token', detail.token);
  return {...userdata, loggedIn: true};
}

export function logout() {
  sessionStorage.removeItem(process.env.REACT_APP_API + 'userdata');
  sessionStorage.removeItem('token');
  return { loggedIn: false };
}

function loadSession () {
  const userdata = sessionStorage.getItem(process.env.REACT_APP_API + 'userdata');
  if (userdata) {
    try {
      /** @type {SecurityState & {token: string}} */
      const {department, companyid, role, admin, token, userid, C1207N} = JSON.parse(userdata);
      if (department && companyid && role) {
        sessionStorage.setItem('token', token); // needed due to apiCalls.js reading (without rework)
        return {userid, admin, department, companyid, role, C1207N, loggedIn: true};
      }
    } catch {
      console.error("Error loading user state from sessionStorage; logging out.");
    }
  }

  console.debug("No sessionStorage state available, no login.");
  return { loggedIn: false }
}

function ddaUploaded(state) {
  const newState = {...state, C1207N: 1};

  sessionStorage.setItem(process.env.REACT_APP_API + 'userdata', JSON.stringify(newState));
  return newState;
}
