import { useEffect, useState } from "react";
import "./ConditionText.css";

export function ConditionText({ name }) {

  function renderContent(name) {
    switch (name) {
      case "Export Health Certificate":
        return (
          <>
            <p style={{ marginBottom: "0px" }}>
              {/* This requirement is currently waived for imports from the EU. */}
              An Export Health Certificate is required if your goods are Medium or High Risk. 

            </p>
            <p style={{ marginBottom: "0px" }}>
              {/* If you have a dummy health certificate, please upload here. */}
              If you would like us to complete your IPAFFS pre-notification, 
              please upload an Export Health Certificate.
            </p>
          </>
        );
      case "Phytosanitary Certificate":
        return (
          <>
            <p style={{ marginBottom: "1rem" }}>
              {/* This document is required if your products are classed as a high
              priority. */}
              If you would like us to complete your IPAFFS pre-notification, 
              please upload a Phytosanitary Certificate.
            </p>
            {/* <div style={{fontSize: "14px"}}>Please check the link below:</div>
            <div>
              <a
                target="_blank"
                href="https://planthealthportal.defra.gov.uk/trade/imports/imports-from-the-eu/high-priority-plants-list/"
                rel="noreferrer"
              >
               High-Priority Plants
              </a>
            </div>            */}
          </>
        );
      case "Catch Certificate":
        return (
          <p style={{ marginBottom: "0px" }}>
            If this document is not provided the goods will be selected as
            exempt.
          </p>
        );
      case "IPAFFS":
        return (
          <>
            <p style={{ marginBottom: "0px" }}>
              {/* Please upload an Excel file with your reference number(s) and the
              items this relates to. We will complete the process for you if no  */}
              Please upload a file with your reference number(s) and the items this 
              relates to. 
            </p>
            <p style={{ marginBottom: "0px" }}>
              {/* documents are uploaded. */}
              The reference number(s) will be used on the Import declaration.
            </p>
          </>
        );

      case "Original Export Document":
        return (
          <p style={{ marginBottom: "0px" }}>
            Please upload if you would like to claim Returned Goods Relief (RGR).
          </p>
        );
      default:
        return <div></div>;
    }
  }

  return (
    <div className="txt-container">
      <h6>{name}</h6>
      {renderContent(name)}
    </div>
  );
}
