import React from "react";

const LoadHeader = ({ jobRef, portalRef, companyName, eoriNumber }) => {
    return (
        <div className="d-flex flex-row">
            <div className='d-flex flex-column cabie-company-info' style={{marginRight: '10px'}} >
                {portalRef && <span className="cci-header">Portal Ref: </span>}
                {jobRef && <span className="cci-header">Job Ref: </span>}
                {companyName && <span className="cci-header">Company Name: </span>}
                {eoriNumber && <span className="cci-header">EORI Number: </span>}
            </div>
            <div className='d-flex flex-column  cabie-company-info'>
                {portalRef && <span className='pad-out'>{portalRef}</span>}
                {jobRef && <span className='pad-out'>{jobRef}</span>}
                {companyName && <span className='pad-out'> {companyName.slice(0,50)} </span>}
                {eoriNumber && <span className='pad-out'> {eoriNumber} </span>}
            </div>
        </div>
    );
};

export default LoadHeader;