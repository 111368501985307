import { useContext, useEffect, useState, useRef, useMemo } from "react";
import Stepper, { useStepper } from "../../Stepper";
import { Select } from "../../Input/Input";

import cargo from "../assets/catofgoods.png";
import CogModal from "./CategoryOfGoodsModal";
import { CategoryOfGoodsTooltip } from "../../Newload/ProductInfo/Tooltips";
import { HandPointUp } from "@styled-icons/fa-solid";
import { HandPointUpDimensions } from "@styled-icons/fa-solid/HandPointUp";
import { StoreContext } from "../../Store";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";
import { Modal, Form, Popover, PopoverContent, OverlayTrigger } from "react-bootstrap";
import { postCertsList } from "../../apiCalls";


export function CogPage() {
  const { stepBackward, stepForward, localData, setDataByKey, activeStepKey, gotoStep, unvisitStep } = useStepper();
  const [showCogModal, setShowCogModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const store = useContext(StoreContext);  

  const modalProps = {
    title: "Licences and Certificates",
    body: (
      <>
        <p className="bold">Warning - Licences or Certificates you've uploaded</p>
        <p>If you proceed to change the Category of Goods, any Licences or Certificates you may have uploaded will be reset, are you sure you wish to proceed with this change?</p>
      </>
    ),
    showContinue: true
  };

  const anyControlledGoods = useMemo(() => {
    return localData.selectedCategoryOfGoods.some((item) => item.controlled)
  }, [localData.selectedCategoryOfGoods])

  const handleClick = (e) => {
    e.preventDefault();
    setError("");    
    if (localData.documents?.length > 0) {
      setShowConfirmModal(true);
    }else {
      setShowCogModal(true);
    }
  };

  const clearCheckedCategory = () => {
    setDataByKey('categoryOptions', localData.categoryOptions.map(category => {
      return { ...category, checked: false };
    }));
  };

  const nextPage = async () => {    
    if (localData.confirmedNoCG == "" || localData.confirmedNoCG === undefined) {
      setError("Please confirm if your consignment contains controlled goods.")
      return;
    }
    if (localData.confirmedNoCG == "NCG") {
      unvisitStep("9. Documents");
    }

    if (localData.confirmedNoCG == "CG") {
      const fd = new FormData();
      const ids =  localData.selectedCategoryOfGoods.map(good => parseInt(good.id))
      fd.append('controlled', ids);
      fd.append('movementType', localData.movementType)
      fd.append('tad', localData.tad)
      const data = await postCertsList(fd);
      if (localData.documents.length === 0) {
        const formatted = Object.keys(data[0]).filter(key => !key.includes('Tooltip')).map(key => {return {name: key, required: data[0][key], condition: data[0][key + " Tooltip"]}}) 
        setDataByKey('certsList', formatted);
      }     
    }

    setError("");
    stepForward();
  };

  const backwards = () => {
    setError("");
    if (localData.movementType === "Export") {
      gotoStep("4. AdditionalRequirements")
    }
    else if (localData.movementType === "Import") {
      gotoStep("3. Entry Port")
    }
};

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)

  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "5. CategoryOfGoods" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "5. CategoryOfGoods",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  const [error, setError] = useState("");

  return (
    <Stepper.Step text="Category of Goods" stepKey="5. CategoryOfGoods">
      {/* <div> */}
      <div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{ fontSize:"1.8rem"}}>Category of Goods</div>
      <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
        <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
          <input type="radio" disabled checked={localData.movementType === "Import"}/>
          <span>Import</span>
        </label>

        <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px'}}>
          <input type="radio" disabled checked={localData.movementType === "Export"} />
          <span>Export</span>
        </label>
      </div>
        {/* <span className="epo-steps-header">Category of Goods</span> */}
        <div className="px-2 mt-1 pt-2 d-flex flex-column" style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
          <div className="d-flex step-banner justify-content-center flex-row pt-4">
            <img src={cargo} width="150" height="150" alt="port" className="  mb-4"  />
            {/* <p className="d-flex pt-2" style={{marginRight: "25px"}}> */}
          
            {/* <div style={{maxWidth: "290px"}} class="d-flex flex-column mb-1"> */}
           
              {/* <span style={{color: '#0e2554', marginRight: '10px'}}>Category Selection</span> */}
              {/* <div className="flex-grow-1"/> */}
              {/* <span style={{color: '#0e2554'}}>(Multiple selections are allowed)</span> */}
             
              {/* </div> */}
              <CategoryOfGoodsTooltip  />
            {/* </p> */}
          </div>
              
          <div class="d-flex flex-column align-self-center " style={{marginTop: "32px"}} >
            <span style={{maxWidth: "400px", fontWeight: "500"}}>Select ALL categories that describe your shipment. </span>
            <span class="d-flex flex-column align-self-center  " style={{maxWidth: "280px", fontWeight: "500"}}>(Multiple selections are allowed)</span>
            {/* <p></p> */}
            {/* <div className="flex-grow-1 mt-2"/> */}
            {/* <p></p> */}
            </div>

            {/* <div style={{maxWidth: "290px"}} class="d-flex flex-column mb-1">
           
           <span>Select ALL categories that describe your shipment.</span>
           <div className="flex-grow-1"/>
           <span>(Multiple selections are allowed)</span>
          
           </div> */}

        <div className="d-flex justify-content-center " >
          {(localData.confirmedNoCG == "" || localData.confirmedNoCG === undefined) && 
                        <button type="button" style={{ padding: "0.5rem 1.25rem" }} className="doc-add-btn gap-2 text-eoriblue" onClick={(e) => {setError("");setShowCogModal(true);}}>
              Select Goods
            </button>}
           {(localData.confirmedNoCG == "NCG" || anyControlledGoods) && 
           <button type="button" style={{ padding: "0.5rem 1.25rem", width:'200px', fontWeight: '400',fontSize: '1.1rem'}} className={"doc-add-btn gap-2 text-eoriblue light-green-btn mt-3"} onClick={handleClick}>
            {anyControlledGoods ?  "Controlled" : "Non-Controlled"} {(anyControlledGoods || localData.confirmedNoCG == "NCG") && <div className="tick-success"></div>}
            </button>}     
            {/* {(localData.confirmedNoCG == "CG" && !anyControlledGoods) && 
           <button type="button" style={{ padding: "0.5rem 1.25rem", width:'200px', fontWeight: '400',fontSize: '1.1rem'}} className={"doc-add-btn gap-2 text-eoriblue light-green-btn"} onClick={handleClick}>
            {"Controlled"} {(anyControlledGoods || localData.confirmedNoCG == "NCG") && <div className="tick-success"></div>}
            </button>}      */}
          </div> 


          <div className="d-flex justify-content-center mt-auto mb-1" style={{maxWidth: "468px"}}>
          {localData.confirmedNoCG == "NCG" && 
           <span className="general-text">
            This shipment <b>DOES NOT</b> contain goods that are controlled (or require a license).
          </span>
           }
          {anyControlledGoods && 
         <span className="general-text">
            This shipment <b>DOES</b> contain goods that are controlled (or require a license).
          </span>
           }
          {error && (
            <div className="alert-danger px-3 py-1 w-100 text-center mt-1" style={{borderRadius: '10px'}}>{error}</div>
          )}       
 {/* </div>  */}

      </div>
      {/* {anyControlledGoods &&
            <section className="d-flex justify-content-between px-3 pb-4" style={{borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", marginTop: "29px"}} >
            <button type="button" className="stepper-button red" onClick={backwards}>
              Back
            </button>
            <button type="button" className="stepper-button" onClick={nextPage}>
              Next
            </button>
          </section>
                 }
      {localData.confirmedNoCG == "NCG" &&
            <section className="d-flex justify-content-between px-3 pb-4" style={{borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", marginTop: "29px"}} >
            <button type="button" className="stepper-button red" onClick={backwards}>
              Back
            </button>
            <button type="button" className="stepper-button" onClick={nextPage}>
              Next
            </button>
          </section>
                 } */}
      {/* {!anyControlledGoods && localData.confirmedNoCG !== "NCG" && */}
            <section className="d-flex justify-content-between mt-auto px-3 pb-4"  style={{borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px"}}>
            <button type="button" className="stepper-button red" onClick={backwards}>
              Back
            </button>
            <button type="button" className="stepper-button" onClick={nextPage}>
              Next
            </button>
          </section>
                {/* // } */}

      <CogConfirmModal
          show={showConfirmModal}
          redirectReason={modalProps}
          onHide={() => {
            setShowConfirmModal(false);
          }}
          onContinue={() => {
            localData.documents.length = 0;
            clearCheckedCategory();  
            setShowConfirmModal(false);         
            setShowCogModal(true);
          }}
        ></CogConfirmModal>
      <CogModal
        show={showCogModal}
        onSave={(values, confirmedNoCG) => {
          setDataByKey("selectedCategoryOfGoods", values);
          setDataByKey("confirmedNoCG", confirmedNoCG)
          setShowCogModal(false);
        }}
        onCancel={() => {
          setDataByKey("confirmedNoCG", "");
          setDataByKey("selectedCategoryOfGoods", [])
          setShowCogModal(false)}}
      ></CogModal>
              </div>  
    </Stepper.Step>
    
  );
}

export const CogConfirmModal = ({show, redirectReason, onHide, onContinue}) => {

  function cancelClick() {
    onHide();
  }

  return (
    <Modal show={show} centered size={"md"} onHide={onHide} animation={false}>
      <Modal.Header>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="warning" /> <span>
            {redirectReason?.title}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 py-4">
        {redirectReason?.body}
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button class="stepper-button" onClick={cancelClick}>
          Cancel
        </button>
        {redirectReason?.showContinue && <button class="stepper-button yellow" onClick={()=> onContinue()}>
          Continue
        </button>}
      </Modal.Footer>
    </Modal>
  );
}

function PointingClickSvg(props) {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M5.48 10.089l1.583-1.464c1.854.896 3.028 1.578 5.11 3.063 3.916-4.442 6.503-6.696 11.312-9.688l.515 1.186c-3.965 3.46-6.87 7.314-11.051 14.814-2.579-3.038-4.301-4.974-7.469-7.911zm12.52 3.317v6.594h-16v-16h15.141c.846-.683 1.734-1.341 2.691-2h-19.832v20h20v-11.509c-.656.888-1.318 1.854-2 2.915z" />
    </svg>
  );
}

