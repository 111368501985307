import { useContext, useMemo, useState } from "react";
import DataTable from "../../../DataTable";
// import { EpoJobDetailContext } from "../EpoJobDetails";
import {Input, Select} from "../../../Input/Input.js";
import { Col, Row, OverlayTrigger, PopoverContent, Popover } from "react-bootstrap";
import { EpoApplicationDetailContext } from "../EpoApplicationDetails";
import { postSendActivationEmail } from "../../../apiCalls";
import { useSecurity } from '../../../Security/security'



export default function EpoEmail () {
  const { applicationDetails, updateApplicationDetails, isEditable } = useContext(EpoApplicationDetailContext);
  const [emailSent, setEmailSent] = useState(false);

  const { validateRole } = useSecurity();
  const isFIN = validateRole({department:'FIN'})

  async function sendActivationEmail() {
    setEmailSent(true);
    const result = await postSendActivationEmail(applicationDetails);
    if (result.success) {
      alert("Activation email sent!")
    }
  }

  return (
    <section className="p-2 d-flex flex-column">
      <div>
      <div className='registrationText mb-2'>Email Address</div>
      <Row>
        <Col><Input  disabled={!isEditable} label="Email Address" 
        value={applicationDetails.emailAddress} 
        onChange={(e) => {updateApplicationDetails('emailAddress', e.target.value)} }
        /></Col>
        <Col>
        <div className="pt-2">
            <input type="checkbox" name="holdEmail" class="neenor-checkbox" 
                        checked={applicationDetails.holdEmail}
                        // onChange={(e) => {updateApplicationDetails('holdEmail', e.target.checked)} }
                        onChange={(e) => {
                          updateApplicationDetails('holdEmail', e.target.checked);
                          // console.log(applicationDetails.Status); 
                        }}
                        disabled={isFIN || applicationDetails.Status !== 'Activated' && applicationDetails.Status !== 'Approved' || (!isEditable)} 
                        />
            <label for="holdEmail" class="neenor-checkbox-label"
            >Hold Activation Email
           <OverlayTrigger
            placement="right"
            trigger={["hover", "focus"]}
            overlay={
              <Popover style={{ minWidth: "25rem" }}>
                <PopoverContent>
                  <p>
                  Please update this checkbox and press save before activating the account.
                  </p>
                </PopoverContent>
              </Popover>
             }
              >
            <span className="info ms-2"></span>
          </OverlayTrigger></label>
            </div>
            <button disabled={applicationDetails.holdEmail || (
                applicationDetails.Status !== "Activated" && 
                applicationDetails.Status !== "Approved") ||
                !isEditable ||
                emailSent ||
                isFIN
            } 
            onClick={sendActivationEmail} className="mt-2 activation-email-button">Send activation email</button>
        </Col>
      </Row>
       
    </div>
    </section>
  );
}