import { Trash } from "@styled-icons/fa-solid";

const InternalView = ({addFile, removeFile, handleEmailSelected, fileUpload, emailSelected, setError}) => {

  const shortenFileName = (fileName, maxLength) => {
    if (fileName?.length > maxLength) {
      const start = fileName?.slice(0, maxLength / 2);
      const end = fileName?.slice(-maxLength / 2);
      return `${start}...${end}`;
    }
    return fileName;
  };
  
    return(<>
      <div>
          <span className="register-step-header">CABIE Pack</span>
        </div>
        <div className="inputs-group d-flex flex-row p-4 my-3">
          <div>
            If the customer has signed the SA or Account Application these can be
            uploaded below. If the customer still needs to sign the forms please
            select the tick box and CABIE will send the customer an email to
            complete the application.         
          </div> 
        </div>
        <div className="d-flex my-4 mt-4 gap-1">
          <div style={{ cursor: "pointer", width: "75%" }}>
            <label className="d-flex align-items-center gap-1">
              <div className="d-flex justify-content-center  doc-upload-button" style={{ cursor: "pointer" }}>
                <label
                  name="name"
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  SA 2.1
                 {!emailSelected && (<input
                    type="file"
                    name="fileuploader"
                    onChange={(e) => addFile(e)}
                    onClick={(e) => {
                      setError("");
                      e.target.value = null;
                    }}
                    accept=".pdf"
                  />)}
                </label>
              </div>
            </label>
          </div>
          <div
            className="d-flex justify-content-between align-items-center doc-upload-bg"
            style={{ cursor: "pointer" }}
          >
            <div className="d-flex">
              <label>{shortenFileName(fileUpload?.name, 40)}</label>
            </div>
            <div className="d-flex justify-content-end">
            {fileUpload && (<Trash width={"1rem"} height={"1rem"} style={{ color: "red" }}  onClick={removeFile}/>)}
            </div>
          </div>
        </div>
        <label className="d-flex align-items-center ms-2">
            <input type="checkbox" checked = {emailSelected} onChange = {handleEmailSelected } disabled={fileUpload}/>
            <span style={{cursor: "pointer", marginLeft:"10px"}} >Email links to customer to continue with CABIE sign up</span>
          </label>        

    </>)
  }

  export default InternalView;
