import { useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Row, Col, Tab, Tabs, Collapse } from "react-bootstrap";
import { getCommodityDetail } from "../apiCalls";
import { Input, Select, Switch, TextArea } from "../Input/Input";

const CommodityModal = ({ onClose, commodity, measure }) => {
  const [commodityData, setCommodityData] = useState([]);
  const [requestFailed, setRequestFailed] = useState(false);

  useEffect(() => {
    if (!commodity?.cId) {
      setCommodityData({});
      return;
    }
    async function fetchData() {
      try {
        const data = await getCommodityDetail(commodity.cId);
        setCommodityData(data.Parents[0]);
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [commodity?.cId]);

  if (!commodity?.cId) {
    return null;
  }
  return (
    <Modal
      show={commodity?.cId !== undefined}
      onHide={onClose}
      centered
      size="lg"
      contentClassName="editModal"
      dialogClassName="wide-modal"
      className="dataEnrichment"
    >
      <Modal.Header>
        <Modal.Title>Viewing: {commodity.commCode}</Modal.Title>{" "}
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onClose}
        />
      </Modal.Header>
      <Form className="flex-grow-1 d-flex flex-column">
        {commodity.mId !== -1 && (
          <input type="hidden" name="id" value={commodity.cId} />
        )}
        <Modal.Body className="flex-grow-1">
          <Tabs>
            
            {/* TODO We will need to check the need/functionality for this in another release */}

            {/* <Tab title="Import Controls" eventKey="commodity">
              <section className="p-2 d-flex flex-column">
                <Row>
                  <Col>
                    <Input value={"CITES"} label={"Control Type"} disabled />
                  </Col>
                  <Col>
                    <Input
                      value={"Yes or No depending on the documents"}
                      label={"Exemption Available"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      value={"Organic Products"}
                      label={"Control Type"}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Input
                      value={"Yes or No depending on the documents"}
                      label={"Exemption Available"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      value={"Veterinary Control"}
                      label={"Control Type"}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Input
                      value={"Yes or No depending on the documents"}
                      label={"Exemption Available"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      value={"Illegal, unreported and unregulated fishing"}
                      label={"Control Type"}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Input
                      value={"Yes or No depending on the documents"}
                      label={"Exemption Available"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input value={"CITES"} label={"Control Type"} disabled />
                  </Col>
                  <Col>
                    <Input
                      value={"Yes or No depending on the documents"}
                      label={"Exemption Available"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input value={"CITES"} label={"Control Type"} disabled />
                  </Col>
                  <Col>
                    <Input
                      value={"Yes or No depending on the documents"}
                      label={"Exemption Available"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input value={"CITES"} label={"Control Type"} disabled />
                  </Col>
                  <Col>
                    <Input
                      value={"Yes or No depending on the documents"}
                      label={"Exemption Available"}
                      disabled
                    />
                  </Col>
                </Row>
              </section>
            </Tab> */}
            <Tab title="Commodity Data" eventKey="importcontrols">
              <section className="p-2 d-flex flex-column">
                <Row>
                  <Col>
                    <Input
                      value={commodityData.commCodeDesc}
                      label={"Commodity Description"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      value={commodityData.commDuty}
                      label={"Duty"}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Input
                      value={commodityData.commVAT}
                      label={"VAT"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextArea
                      rows={10}
                      style={{ resize: "none", maxHeight: "800px" }}
                      value={commodityData.chapterNotes}
                      label={"Chapter Notes"}
                      disabled
                    />
                  </Col>
                </Row>
              </section>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <button
            type="submit"
            onClick={onClose}
            class="blue-button-teams green-btn"
            style={{ borderRadius: "10px" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default CommodityModal;
