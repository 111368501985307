import { Alert, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router";

/**
 * Extremely generic error fallback, this is the last resort if an error makes it way up the tree without being handled.
 * It lets the user know that something went wrong with their last request, and gives them option to navigate to homepage, or do a hard reload.
 * Either way, the app does a full page reload so all state is reset to defaults.
 */
export default function GenericErrorFallback({ error }) {
  const navigate = useNavigate();

  return (
    <Container style={{ maxWidth: "768px" }}>
      <div class="py-5">
        <Alert variant="danger">
          <Alert.Heading>Something Went Wrong</Alert.Heading>
          <p>
            Oh dear, looks like something went wrong with your last request.
            This error will automatically be reported to our IT boffins to
            investigate, but if you want to get direct support then please send
            an email detailing the problem and what you were doing when it
            occured to <a href="mailto:CABIEsupport@eori.uk">CABIEsupport@eori.uk</a>
          </p>
          <p>
            Error code:
            <pre>
              <code>{JSON.stringify(error?.message)}</code>
            </pre>
          </p>

          <div className="d-flex justify-content-end gap-2">
            <Button
              onClick={() => window.location.reload()}
              variant="secondary"
            >
              Reload Page
            </Button>
            <Button
              onClick={() => {
                navigate("/main");
                window.location.reload();
              }}
              variant="primary"
            >
              Go to Homepage
            </Button>
          </div>
        </Alert>
      </div>
    </Container>
  );
}
