import { useState, useEffect, useMemo } from "react";
import { Modal, Form, Row, Col, Tab, Tabs, OverlayTrigger, PopoverContent, Popover } from "react-bootstrap";
import { getMeasureDetail } from "../apiCalls";
import { Input} from "../Input/Input";
import "../DataValidation/measureModal.css"

const MeasureModal = ({ onClose, measure }) => {

  const [measureMetaDetails, setMeasureMetaDetails] = useState(null)
  
  useEffect(() => {
    if(measure?.mId) { 
      getMeasureDetail(measure.mId)
      .then (data => {
        setMeasureMetaDetails(data.Parents[0])
      }) 
    } else {
      setMeasureMetaDetails(null)
    }
  }, [measure?.mId])
  
  const uniqueGeoAreas = useMemo(() => {
    if(!measureMetaDetails?.geoAreas) return[]
    const lookup = {}
    for (const geoArea of measureMetaDetails?.geoAreas) {
      if (lookup[geoArea.geoAreaId]) continue;
      lookup[geoArea.geoAreaId] = geoArea
    } return Object.values(lookup)
  }, [measureMetaDetails])
  if(!measure?.mId) {

  return null
}

return (
    <Modal show={measure?.mId !== undefined} onHide={onClose} centered size="lg" contentClassName="editModal overflow-auto" dialogClassName="wide-modal">
      <Modal.Header>
        <Modal.Title>Viewing ID: {measure.measureId}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onClose} />
      </Modal.Header>
      <Form className="flex-grow-1 d-flex flex-column">
        {measure.mId !== -1 && <input type="hidden" name="id" value={measure.mId} />}
        <Modal.Body className="flex-grow-1">
          <Tabs>
            <Tab title="Measure Data" eventKey="measure">
              <section className="p-2 d-flex flex-column">
                <Row>
                  <Col>
                <Input 
                  value={measure.mId}
                  label={"ID"}
                  disabled
                />
                <Input 
                  value={measure.measureDuty}
                  label={"Duty"}
                  disabled
                  />
                <Input 
                  value={measure.measureExcise}
                  label={"Excise"}
                  disabled
                  />
                <Input 
                  value={measure.measureGeoId}
                  label={"Geo ID"}
                  disabled
                  />
                <Input 
                  value={measure.measureId}
                  label={"Measure ID"}
                  disabled
                />
                <Input 
                  value={measure.measureImport}
                  label={"Import"}
                  disabled
                  />
                <Input 
                  value={measure.measureMeursing}
                  label={"Meursing"}
                  disabled
                  />
                  </Col>
                  <Col>
                <Input 
                  value={measure.measureModified}
                  label={"Last Modified"}
                  disabled
                  />
                <Input 
                  value={measure.measureOrigin}
                  label={"Origin"}
                  disabled
                  />
                <Input 
                  value={measure.measureStartDate}
                  label={"Start Date"}
                  disabled
                  />
                <Input 
                  value={measure.measureTypeId}
                  label={"Type ID"}
                  disabled
                  />
                <Input 
                  value={measure.measureVat}
                  label={"VAT"}
                  disabled
                  />
                  </Col>
                  </Row>
               </section>
            </Tab>
            <Tab title="Conditions" eventKey="types">
              <section className="p-2 d-flex flex-column gap-2">
              {measureMetaDetails?.measureCon?.length > 0 ? measureMetaDetails?.measureCon?.map(condition => (
                <Row className="measureInput">
                  <Col
                  style={{width: "35%"}}
                  >
                <Input 
                  value={condition.condAction}
                  label={"Condition Action"}
                  disabled
                />
                </Col>
                <Col
                style={{width: "45%"}}
                >
                <Input 
                  value={condition.condCondition}
                  label={"Condition"}
                  disabled
                  />
                  </Col>
                  <Col
                  style={{width: "20%"}}
                  >
                <Input info={() => 
                  condition?.docCode && 
                    <DocDescToolTip
                    docHeader={condition.docCode}
                    docDescription={condition.condCertDesc}
                    docRequirement={condition.condRequirement}
                    />}
                    value={condition.docCode}
                    label={"Document Codes"}
                    disabled
                    />
                
                  </Col>
                  </Row>
              )): (
                <div>No measure conditions exist for this measure type</div>
              )}
              </section>
            </Tab>
            <Tab title="Types" eventKey="countries" className="p-1">
                <Row>
                  <Col>
                  <Input 
                  value={measure.measureTypeId}
                  label={"Type ID"}
                  disabled
                  />
                  </Col>
                  <Col>
                  <Input 
                  value={measure.typeSeriesId}
                  label={"Series ID"}
                  disabled
                  />
                  </Col>
                  <Input 
                  value={measure.typeDescription}
                  label={"Type Description"}
                  disabled
                  />
                </Row>
            </Tab>
            <Tab title="Origins" eventKey="geoAreas" className="p-1">
            <section className="p-2 d-flex flex-column gap-2">
              {uniqueGeoAreas?.map(geoArea => (
                <Row>
                  <Col>
                <Input 
                  value={geoArea.geoAreaId}
                  label={"Geo Area ID"}
                  disabled
                />
                </Col>
                <Col
                >
                <Input 
                  value={geoArea.geoAreaDesc}
                  label={"Condition"}
                  disabled
                  />
                  </Col>
                  </Row>
              ))}
              </section>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
          style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
        >
          <button type="submit" onClick={onClose}class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
            Close
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const DocDescToolTip = (({docDescription, docRequirement, docHeader}) => {
  return (
    <OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ width: "30rem" }}>
          <PopoverContent>
            <h6>{docHeader} - Document Requirements</h6>
            <p>
              {docDescription}
            </p>
            <p>
              {docRequirement}
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info" style={{ width: "1rem" }}></div>
    </OverlayTrigger>
  );
});

export default MeasureModal;
