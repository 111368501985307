import * as XLSX from 'xlsx';


export const Downloadxls = async (data) => {

    
    // const data = await emitData(initData);
        // const data = await getUsers("search", store?.userReportIdSelected[0])
        console.log('FinalDataHere ', data)
        const workbook = XLSX.utils.book_new();
        // Convert JSON data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // Convert the workbook to a binary string
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        // Convert the binary string to a Blob
        const blob = new Blob([bs2Blob(wbout)], { type: 'application/octet-stream' });
        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'userData.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        // Clean up
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
  }

  const bs2Blob = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };