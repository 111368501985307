import { useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Row, Col, Tab, Tabs, Collapse } from "react-bootstrap";
import { getNewLoad, getUsers, postCutomerData, putCutomerData } from "../apiCalls";
import { Input, Select, Switch } from "../Input/Input";

/* TODO: __Options are hardcoded since we don't have any from the database (yet) */
const cfspOptions = [
  { id: "NO", data: "NO" },
  { id: "YES - EIDR", data: "YES - EIDR" },
  { id: "YES - SDP", data: "YES - SDP"},
  { id: "YES", data: "YES" },
];
const dataMethods = [
  { id: "RPA", data: "RPA" },
  { id: "INVOICE", data: "INVOICE" },
  { id: "EIDR", data: "EIDR" },
];
const dataQualities = [
  { id: "1", data: "Very Poor" },
  { id: "2", data: "Poor" },
  { id: "3", data: "Acceptable" },
  { id: "4", data: "Good" },
  { id: "5", data: "Perfect" },
];
const dataSources = [
  { id: "DIRECT", data: "DIRECT" },
  { id: "CLIENT/HAULIER", data: "CLIENT/HAULIER" },
];
const ipaffsOptions = [
  { id: "CLIENT", data: "CLIENT" },
  { id: "EORI", data: "EORI" },
];
const frontierDecDoneByOptions = [
  { id: "CLIENT", data: "CLIENT" },
  { id: "EORI", data: "EORI" },
];
const EditModal = ({ onSave, onCancel, client, parentsAsDropdown }) => {
  const [localClient, setLocalClient] = useState({});
  const [errors, setErrors] = useState({});

  const [movementTypeOptions, setMovementTypeOptions] = useState([]);
  const [incotermsOptions, setIncotermsOptions] = useState([]);
  // const [ownerOptions, setOwnerOptions] = useState([]);

  const [establishedInGB, setEstablishedInGB] = useState(false);
  const [goodsIncurDuty, setGoodsIncurDuty] = useState(false);
  const [controlledGoods, setControlledGoods] = useState(false);
  const [ieGbExports, setIeGbExports] = useState(false);

  const [readyForPortal, setReadyForPortal] = useState(false);
  const [readyFor2022, setReadyFor2022] = useState(false);

  const [smartPackDone, setSmartPackDone] = useState(false);
  const [standingAuthority, setStandingAuthority] = useState(false);
  const [paymentsUpToDate, setPaymentsUpToDate] = useState(false);
  const [paymentsOver30Days, setPaymentsOver30Days] = useState(false);


  useEffect(() => {
    getNewLoad("movement").then((opts) => {
      setMovementTypeOptions(opts.map(pair => ({id: pair.data, data: pair.data}))); // hack because db is all strings, no ID's
    });

    getNewLoad("incoterm").then((opts) => {
      setIncotermsOptions(opts.map(pair => ({id: pair.data, data: pair.data}))); // hack because db is all strings, no ID's
    });
  }, []);

  useEffect(() => {
    setErrors({});
    setLocalClient(client);
    setEstablishedInGB(client.established_in_GB === "YES"); // hack because db is all strings, no true/false
    setGoodsIncurDuty(client.Dutiable_goods === "YES"); // hack because db is all strings, no true/false
    setControlledGoods(client.Controlled_goods === "YES"); // hack because db is all strings, no true/false
    setIeGbExports(client.ROI_GB_exports === "YES"); // hack because db is all strings, no true/false

    setReadyForPortal(client.Portal_tested === "YES"); // hack because db is all strings, no true/false
    setReadyFor2022(client.ready_for_2022 === "YES"); // hack because db is all strings, no true/false
    
    setSmartPackDone(client.SMART_completed === "YES"); // hack because db is all strings, no true/false
    setStandingAuthority(client.Standing_authority === "YES"); // hack because db is all strings, no true/false
    setPaymentsUpToDate(client.Payments_up_to_date === "YES"); // hack because db is all strings, no true/false
    setPaymentsOver30Days(client.Over_30_Days === "YES"); // hack because db is all strings, no true/false
  }, [client]);

  async function submitForm(e) {
    e.preventDefault();
    // We set id = -1 for new clients, as it is out of range of regular IDs.
    if (localClient.id === -1) {
      await postCutomerData(e.target);
    } else {
      await putCutomerData(e.target);
    }

    // Once saved, call onSave prop (if set).
    onSave?.();
  }

  /**
   * Automatically updates object with form values - shorthand over multiple useStates since we use the form.target for submission anyway.
   */
  function setState(event) {
    const name = event.target.name;
    const value = event.target.value;
    setLocalClient((prev) => ({ ...prev, [name]: value }));
  }

  /*
    We render the modal if the client object has an ID, this lets us hide the modal by setting to {}.
    Whenever the client prop changes, we take a copy of it for local editing and this is attached to the form inputs.
  */
  return (
    <Modal show={client?.id !== undefined} onHide={onCancel} centered size="lg" contentClassName="editModal" dialogClassName="wide-modal">
      <Modal.Header>
        <Modal.Title>Editing: {localClient.Client_name}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onCancel} />
      </Modal.Header>
      <Form onSubmit={submitForm} className="flex-grow-1 d-flex flex-column">
        {localClient.id !== -1 && <input type="hidden" name="id" value={localClient.id} />}
        <Modal.Body className="flex-grow-1">
          <Tabs>
            <Tab title="Client Data" eventKey="client">
              <section className="p-2 d-flex flex-column">
                <Row>
                  <Col>
                    <Input error={errors.Client_name} label="Client Name" name="Client_name" value={localClient.Client_name} onChange={setState} />
                  </Col>
                  <Col>
                    <Input error={errors.GB_EORI} label="GB EORI" name="GB_EORI" value={localClient.GB_EORI} onChange={setState} />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Input label="Deferment Account" name="deferment_account" value={localClient.deferment_account} onChange={setState} />
                  </Col>
                  <Col>
                    <Input label="Who is the Declarant?" name="declarant" value={localClient.declarant} onChange={setState} />
                  </Col>
                </Row>
                <Switch
                  name="established_in_GB"
                  checked={establishedInGB}
                  onChange={(e) => setEstablishedInGB(e.target.value)}
                  className="d-flex gap-3 text-eoriblue align-self-end mt-2"
                >
                  <span>Is this client <b>GB established</b>?</span>
                </Switch>
                <Collapse in={establishedInGB}>
                  <Row>
                    <Col>
                      <Input
                        disabled={!establishedInGB}
                        label="GB Company Number"
                        name="GB_company_Number"
                        value={localClient.GB_company_Number}
                        onChange={setState}
                      />
                    </Col>
                    <Col>
                      {/* TODO: make this a textarea - don't currently have a styled input for this. */}
                      <Input
                        disabled={!localClient.established_in_GB}
                        label="GB Address"
                        name="GB_address"
                        value={localClient.GB_address}
                        onChange={setState}
                      />
                    </Col>
                    <div className="mb-2" />
                  </Row>
                </Collapse>
                <Switch
                  name="Dutiable_goods"
                  checked={goodsIncurDuty}
                  onChange={(e) => setGoodsIncurDuty(e.target.value)}
                  className="d-flex gap-3 text-eoriblue align-self-end mt-2"
                >
                  <span>Do this client's goods <b>incur Duty?</b></span>
                </Switch>
                <Switch
                  name="Controlled_goods"
                  checked={controlledGoods}
                  onChange={(e) => setControlledGoods(e.target.value)}
                  className="d-flex gap-3 text-eoriblue align-self-end mt-2"
                >
                  <span>Does this client ship <b>Controlled Goods</b>?</span>
                </Switch>
                <Switch
                  name="ROI_GB_exports"
                  checked={ieGbExports}
                  onChange={(e) => setIeGbExports(e.target.value)}
                  className="d-flex gap-3 text-eoriblue align-self-end mt-2"
                >
                  <span>Does this client require <b>IE-GB exports</b>?</span>
                </Switch>
              </section>
            </Tab>
            <Tab title="Our Data" eventKey="ourdata">
              <section className="p-2 d-flex flex-column gap-2">
                <Row>
                  <Col>
                    <Select
                      label="Membership"
                      name="parentId"
                      disabled={localClient.hasChildren}
                      options={localClient.hasChildren ? [] : parentsAsDropdown.filter(p => p.id.toString() !== localClient?.id?.toString())}
                      data={localClient.parentId}
                      onChange={setState}
                      nullable
                    />
                  </Col>
                  <Col>
                    <Input label="Owner" name="OWNER" value={localClient.OWNER} onChange={setState} /> {/* hack - this should be a dropdown of potential 'owners' */}
                    {/* <Select label="Owner" name="owner_user_id" options={ownerOptions} data={owner_id} setData={setOwnerId} /> */}
                  </Col>
                </Row>
                <Row>
                <Col>
                    <Select label="CFSP Approval" name="CFSP_approval" options={cfspOptions} data={localClient.CFSP_approval} onChange={setState} nullable/>
                  </Col>
                  <Col>
                    <Select label="Most Common Route" name="Normal_route" options={movementTypeOptions} data={localClient.Normal_route} onChange={setState} nullable/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Select label="How is Data Received?" name="data_format" options={dataMethods} data={localClient.data_format} onChange={setState} nullable/>
                  </Col>
                  <Col>
                    <Select label="Who Sends the Data?" name="Data_source" options={dataSources} data={localClient.Data_source} onChange={setState} nullable/>
                  </Col>
                  <Col>
                    <Select label="Data Quality" name="Data_quality" options={dataQualities} data={localClient.Data_quality} onChange={setState} nullable/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Select nullable label="Who completes IPAFFS?" name="IPAFFs" options={ipaffsOptions} data={localClient.IPAFFs} onChange={setState} />
                  </Col>
                  <Col>
                    <Select nullable label="Who completes Frontier Declaration?" name="frontier_dec" options={frontierDecDoneByOptions} data={localClient.frontier_dec} onChange={setState} />
                  </Col>
                  <Col>
                    <Select nullable label="Incoterms" name="Incoterms" options={incotermsOptions} data={localClient.Incoterms} onChange={setState} />
                  </Col>
                </Row>
                <Switch name="Portal_tested" checked={readyForPortal} onChange={(e) => setReadyForPortal(e.target.value)} className="d-flex gap-3 text-eoriblue align-self-end mt-2">
                  <span>Is this client tested on <b>Portal</b>?</span>
                </Switch>
                <Switch name="ready_for_2022" checked={readyFor2022} onChange={(e) => setReadyFor2022(e.target.value)} className="d-flex gap-3 text-eoriblue align-self-end mt-2">
                  <span>Is this client <b>2022 Ready</b>?</span>
                </Switch>
              </section>
            </Tab>
            <Tab title="Finance Data" eventKey="finance" className="p-1">
            <section className="p-2 d-flex flex-column">
              <Switch name="SMART_completed" checked={smartPackDone} onChange={e => setSmartPackDone(e.target.value)} className="d-flex gap-3 text-eoriblue align-self-end mt-4">
                <span>Is the <b>Smart Pack</b> done for this client?</span>
              </Switch>

              <Switch name="Standing_authority" checked={standingAuthority} onChange={e => setStandingAuthority(e.target.value)} className="d-flex gap-3 text-eoriblue align-self-end mt-4">
                <span>Does this client have <b>Standing Authority</b>?</span>
              </Switch>

              <Input
                    label="Missing Documents"
                    name="missing_docs"
                    value={localClient.missing_docs}
                    onChange={setState}
                  />

              <Switch name="Payments_up_to_date" checked={paymentsUpToDate} onChange={e => setPaymentsUpToDate(e.target.value)} className="d-flex gap-3 text-eoriblue align-self-end mt-4">
                <span>Are <b>Payments Up to Date</b> for this client?</span>
              </Switch>

              <Collapse in={!paymentsUpToDate}>
                <div className="align-self-end">
                <Switch name="Over_30_days" checked={paymentsOver30Days} onChange={e => setPaymentsOver30Days(e.target.value)} className="d-flex gap-3 text-eoriblue mt-4">
                  <span>Are Payments 30+ days overdue?</span>
                </Switch>
                </div>
              </Collapse>
            </section>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
          style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
        >
          <button type="button" class="cancel-button" onClick={onCancel} style={{ display: "flex", borderRadius: "10px" }}>
            Cancel
          </button>
          <button type="submit" class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
            Save Client
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default EditModal;
