import { useContext, useMemo, useState } from "react";
import DataTable from "../../../DataTable";
// import { EpoJobDetailContext } from "../EpoJobDetails";
import {Input, Select} from "../../../Input/Input.js";
import { Col, Row } from "react-bootstrap";
import { EpoApplicationDetailContext } from "../EpoApplicationDetails";



export default function EpoInvoiceDetails ({localInvoiceData}) {
  const { applicationDetails } = useContext(EpoApplicationDetailContext);

  // const combinedRows = useMemo(() => {
  //   const result = [];
  //   for (const invoice of jobDetails.Invoice) {
  //     if (invoice.Items) {
  //       result.push(...invoice.Items);
  //     }
  //   }
  //   return result;
  // }, [jobDetails.Invoice]);

  const [companyInOurRecords, setCompanyInOurRecords] = useState("");
  const [companyInOurRecords1, setCompanyInOurRecords1] = useState("");

  const ourRecords = [
    { id: "agro", data: "Agro" },
    { id: "oToole", data: "O'Toole" },
  ];

  return (
    <section className="p-2 d-flex flex-column">
      <div>
      <div className='registrationText mb-2'>Payment Details</div>
      <Row>
        <Col><Input disabled label="Payment Contact Name" value={`${applicationDetails.paymentName ?? ""} ${applicationDetails.paymentLastName ?? ""}`} /></Col>
        <Col><Input disabled label="Payment Contact Telephone Number" value={applicationDetails.paymentPhone} /></Col>
      </Row>
      <Row>
        <Col><Input disabled label="Estimated Monthly # of Declarations" value={applicationDetails.monthlyDecs} /></Col>
        <Col><Input disabled label="Payment Contact Email" value={applicationDetails.paymentEmail} /></Col>
      </Row>
    </div>
    </section>
  );
}