import { getEPOPortDropdown, getEPOList, postEPOMovementDirection } from "../../apiCalls";
import { StoreContext } from "../../Store";
import { useEffect, useState, useContext, useRef } from "react";
import Stepper, { useStepper } from "../../Stepper";
import { Select } from "../../Input/Input";
import cargoShip from "../assets/cargo-ship.png";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";

export function EntryPortPage({DDAStatus}) {
  const { stepBackward, stepForward, localData, setDataByKey, activeStepKey, gotoStep, unvisitStep} = useStepper();

  const store = useContext(StoreContext);

  const nextPage = async () => {
    if (!localData.entryport?.id) {
      setError("Please select a port to continue.");
      return;
    }
    const fd = new FormData();
    fd.append('fromAreaCode', localData.fromAreaCode);
    fd.append('toAreaCode', localData.toAreaCode)
    const movementData = await postEPOMovementDirection(fd);
    if (movementData) {
      const data = await getEPOList(movementData[0].id);
      // setCategoryOptions(data);
      // setDataByKey("lockDirection", true);
      store.lockDirection[1](true);
      setDataByKey("categoryOptions", data);
      setDataByKey("categoryOptionsDefault", data);
      gotoStep("5. CategoryOfGoods")
    }

  };

  useEffect(() => {
    getEPOPortDropdown("Import", store.epoDirection[0]).then((data) => {
      store.entryPortOptions[1](data);
    });
  }, [store.epoDirection[0]]);


  const backwards = () => {
    setError("");
    if(DDAStatus === 0){
    // check not preferential origin country shortcode for Swiss and Turkey Norway Liechtenstein Iceland North Macedonia
      if (!["ch", "tr", "no", "is", "li", "mk"].includes(localData.exportCountry)) {
        unvisitStep("Preferential Origin");
        stepBackward();
      }else{
        gotoStep("Preferential Origin");
      } 
    }else{
      stepBackward();
    }   
};


  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)

  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "3. Entry Port" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "3. Entry Port",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  useEffect(() => {
    setError("");
  }, [localData.entryport]);

  const [error, setError] = useState("");

  return (
    <Stepper.Step hideUnlessVisited stepKey="3. Entry Port" text="Place of Arrival">
      {/* <div> */}
        {/* <span className="epo-steps-header">Place of Arrival</span> */}
        
        <div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{fontSize:"1.8rem"}}>Place of Arrival</div>
        <div className={`${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}>
          <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
            <input type="radio" disabled checked={localData.movementType === "Import"}/>
            <span>Import</span>
          </label>

          <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px'}}>
            <input type="radio" disabled checked={localData.movementType === "Export"} />
            <span>Export</span>
          </label>
        </div>
        <div className=' px-2 mt-1 pt-2 d-flex flex-column' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
        <div className="d-flex flex-row step-banner justify-content-center">
        {/* <div className="flex-grow-1"/> */}
           <img src={cargoShip} width="150" height="150" alt="port" className="mt-4 mb-4"  />

           {/* <div class="d-flex flex-column mb-1  px-4" > */}
            {/* <p className="" style={{color: '#0e2554', fontSize: '25px', fontWeight: "400"}}>Arrival location</p> */}
            {/* <div className="flex-grow-1 mt-auto"/> */}
            <p></p>
            </div>
            {/* <div className="flex-grow-1 mt-auto" /> */}
        {/* </div> */}
        <div class="d-flex flex-column align-self-center mt-auto " >
            {/* <span style={{maxWidth: "280px", fontWeight: "500"}}>Please select the expected arrival location for this freight movement. </span> */}
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}}>Please select the expected arrival</span>

<span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}}>location for this freight movement. </span>
            {/* <p></p> */}
            {/* <div className="flex-grow-1 mt-2"/> */}
            {/* <p></p> */}
            </div>

        <div className="d-flex flex-column mt-auto  " style={{paddingLeft: '6rem', paddingRight: '6rem'}}>
          <Select
            label="Arrival Location"
            name="entryport"
            data={localData.entryport?.id}
            onChangeAsObject={(data) => setDataByKey("entryport", data)}
            options={store.entryPortOptions[0]}
            filter={1}
            required
          />
        </div>
        <div className="px-3 mb-3 w-75 mx-auto">
          {error && (
            <div className="alert-danger px-3 py-1 w-100 text-center" style={{borderRadius: '10px'}}>{error}</div>
          )}
        </div>
      {/* </div> */}
      <section className="d-flex justify-content-between px-3 pb-4 mt-auto" style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px"}}>
        <button type="button" className="stepper-button red" onClick={backwards}>
          Back
        </button>
        <button type="button" className="stepper-button" onClick={nextPage}>
          Next
        </button>
      </section>
      </div>
    </Stepper.Step>
  );
}
