import React, { useState } from "react";
import {
  Container,
  Popover,
  OverlayTrigger,
  PopoverContent,
} from "react-bootstrap";
import { Input } from "../Input/Input";
import { checkEORIvalid, checkVatValid, postNewWLP } from "../apiCalls";
import { FormStatusModal } from "./FormStatusModal";
import { useEffect } from "react";

const formFields = [    
  "eoriNumber",
  "companyName",
  "companyEmail",
  "feedbackEmail",
  "companyWebsite",
  "street",
  "town",
  "county",
  "country",
  "postcode",    
  "firstName",
  "lastName",
  ];

    //  "personalEmail","companyPosition","phoneNumber"

const Registration = () => {
  const [responseStatus, setResponseStatus] = useState(null);
  const [errors, setErrors] = useState({});

  const [localData, setLocalData] = useState({});
  //const [fetchingEori, setFetchingEori] = useState(false);
  const [fetchingVat, setFetchingVat] = useState(false);

  const [disableSaveBtn, setDisableSave] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [eoriData, setEoriData] = useState({
    valid: false,
    companyName: "",
    eori: "",
  });

  const [vatData, setVatData] = useState({
    valid: false,
    companyName: "",
    vatNumber: "",
  });

  const getNameFromEori = async () => {
    // if (!localData?.eoriNumber?.toUpperCase().startsWith("GB")) {
    //   setErrors((prev) => ({
    //     ...prev,
    //     eoriNumber: "Must start with GB, value reverted",
    //   }));
    //   setDataByKey("eoriNumber", eoriData.eori);
    //   return;
    // }

    //setFetchingEori(true);
    try {
      setErrors((prev) => ({ ...prev, eoriNumber: undefined }));
      const resp = await checkEORIvalid(localData?.eoriNumber);
      if (resp.statusDescr === "In Valid") {
        setErrors((prev) => ({
          ...prev,
          eoriNumber: "Invalid EORI No. value reverted",
        }));
        setDataByKey("eoriNumber", eoriData.eori);
        setEoriData((prev) => ({ ...prev, valid: true }));
      } else {
        setEoriData({
          valid: true,
          companyName: resp.name,
          eori: localData.eoriNumber,
        });
        if (!localData.gbEntity) {
          if (resp.name) {
            setErrors((prev) => ({ ...prev, companyName: undefined }));
          }
        }
        // GB is VAT registered - autofill it.
        if (localData.eoriNumber[2] !== "0") {
          setDataByKey("vatNumber", localData.eoriNumber.slice(2, 11));
          setDataByKey("gbEntity", resp.name ?? "");
          setDataByKey("companyName", resp.name ?? "");
          getNameFromVat(localData.eoriNumber);
        }
      }
    } catch {
      setErrors((prev) => ({
        ...prev,
        eoriNumber: "Unable to validate, value reverted",
      }));
      setDataByKey("eoriNumber", eoriData.eori);
      setEoriData((prev) => ({ ...prev, valid: true }));
    }
    //setFetchingEori(false);
  };

  const getNameFromVat = async (vatNumber = localData.eoriNumber) => {
    if (!vatNumber) {
     // setErrors((prev) => ({ ...prev, vatNumber: undefined }));
      setVatData({ valid: true, companyName: "" });
      //setDataByKey("building", "");
      setDataByKey("street", "");
      setDataByKey("town", "");
      setDataByKey("county", "");
      setDataByKey("postcode", "");
      setDataByKey("country", "");
      return;
    }
    setFetchingVat(true);
    try {
      //setErrors((prev) => ({ ...prev, vatNumber: undefined }));
      const resp = await checkVatValid(vatNumber);
      console.log("resp", resp);
      setVatData({ valid: true, companyName: resp.name });
      //setDataByKey("building", resp.building);
      setDataByKey("street", resp.street);
      setDataByKey("town", resp.city);
      setDataByKey("county", resp.county);
      setDataByKey("postcode", resp.postcode);
      setDataByKey("country", resp.countryCode);
      if (resp.name) {
        setDataByKey("gbEntity", resp.name);
        setDataByKey("companyName", resp.name);
      }
    } catch {
      setErrors((prev) => ({
        ...prev,
        vatNumber: "Unable to validate, value reverted",
      }));
      setDataByKey("vatNumber", vatData.vatNumber);
    }
    setFetchingVat(false);
  };

  const requiredFields = formFields;

  const setDataByKey = (key, value) => {
    setLocalData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleInputFocus = (event) => {
    const { name } = event.target;  
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};
    requiredFields.forEach((field) => {
      if (!localData[field]) {
        formErrors[field] = "Required";
      }
    });

    setErrors(formErrors);
    if (!Object.values(formErrors).some((err) => !!err)) {
      setDisableSave(true);
      const fd = new FormData();
      for (const key of formFields) {
       const formdata = (key === "companyWebsite") ? (localData[key]).toLowerCase() : (localData[key])
        fd.append(key, `${formdata}`);
      }
      setDisabledInputs([...formFields]);
      const result = await postNewWLP(fd);
            
      if(result[0].completed === "Inserted"){
        setResponseStatus(true)
        setShowConfirmModal(true);
      }else{
        setResponseStatus(false)
      }      
    }
  };

  return (
    <Container fluid="lg" className="mb-4 semi-fluid-container">
      {showConfirmModal && <FormStatusModal
        title = {responseStatus? "Success": "Failed"}
        show={true}
        onHide={() => setShowConfirmModal(false) }
        hideCancel={true}
      >
        <>
        <h4 style={{textAlign:"center"}}>{responseStatus? "The form submitted successfully.": "The form submission failed."}</h4>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <div className="success-result-circle">
            <div className={ responseStatus ? "success-result-tick": "failed-result-cross"}></div>
          </div>
        </div>
        </>
      </FormStatusModal>}
      <section className="floating-box-newload">
        <div className="floating-box-header-newload">
          <span>Onboarding</span>
          <div style={{ flexGrow: 1 }}></div>
        </div>
        <form onSubmit={handleSubmit} id="onboard-form">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div id="onboard-info-form">
              <section
                className="onboard-inputs-group"
                style={{ minWidth: "338.66px" }}
              >
                <span className="section-title">Company Information</span>
                <Input
                  name="eoriNumber"
                  label={
                    <>
                      EORI No.<span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  disabled={disabledInputs.includes("eoriNumber")}
                  error={errors.eoriNumber}
                  value={localData.eoriNumber}
                  onChange={(e) => {
                    setEoriData((prev) => ({ ...prev, valid: false }));
                    setDataByKey("eoriNumber", e.target.value);
                  }}
                  onFocus={handleInputFocus}
                  onBlur={() => getNameFromEori()}
                  placeholder="e.g. XX123456789012"
                  info={
                    <OverlayTrigger
                      placement="right"
                      trigger={["hover", "focus"]}
                      overlay={
                        <Popover style={{ minWidth: "15rem" }}>
                          <PopoverContent>
                            <h6>EORI Number</h6>
                            <p>Your EORI number is required here.</p>
                          </PopoverContent>
                        </Popover>
                      }
                    >
                      <div className="important-info"></div>
                    </OverlayTrigger>
                  }
                />
                <Input
                  name="companyName"
                  label={
                    <>
                      Company Trading Name{" "}
                      <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  disabled={!eoriData.valid || fetchingVat || disabledInputs.includes("companyName")}
                  error={errors.companyName && eoriData.valid}
                  value={localData.companyName}
                  onChange={(e) => setDataByKey("companyName", e.target.value)}
                  placeholder="e.g. Example Inc."
                  info={
                    <OverlayTrigger
                      placement="right"
                      trigger={["hover", "focus"]}
                      overlay={
                        <Popover style={{ minWidth: "15rem" }}>
                          <PopoverContent>
                            <h6>Company Trading Name</h6>
                            <p>
                              The name your company trades as, this not
                              necessarily the same as what is listed on your
                              company or VAT registraion.
                            </p>
                          </PopoverContent>
                        </Popover>
                      }
                    >
                      <div className="info"></div>
                    </OverlayTrigger>
                  }
                />
                <Input
                  type="email"
                  name="companyEmail"
                  disabled ={disabledInputs.includes("companyEmail")}
                  label={
                    <>
                      Company Email <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.companyEmail}
                  value={localData.companyEmail}
                  onChange={(e) => setDataByKey("companyEmail", e.target.value)}
                  onFocus={handleInputFocus}
                />
                <Input
                  name="companyWebsite"
                  disabled ={disabledInputs.includes("companyWebsite")}
                  label={
                    <>
                      Company Website{" "}
                      <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.companyWebsite}
                  value={localData.companyWebsite}
                  onChange={(e) =>
                    setDataByKey("companyWebsite", e.target.value)
                  }
                  onFocus={handleInputFocus}
                />
                <Input
                  type="email"
                  name="feedbackEmail"
                  disabled ={disabledInputs.includes("feedbackEmail")}
                  label={
                    <>
                      Feedback by Email{" "}
                      <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.feedbackEmail}
                  value={localData.feedbackEmail}
                  onChange={(e) =>
                    setDataByKey("feedbackEmail", e.target.value)
                  }
                  onFocus={handleInputFocus}
                />
              </section>
              <section
                className="onboard-inputs-group"
                style={{ minWidth: "338.66px" }}
              >
                <span className="section-title">Trading Address</span>
                <Input
                  name="street"
                  disabled ={disabledInputs.includes("street")}
                  label={
                    <>
                      Street <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.street}
                  value={localData.street}
                  onChange={(e) => setDataByKey("street", e.target.value)}
                  onFocus={handleInputFocus}
                />
                <Input
                  name="town"
                  disabled ={disabledInputs.includes("town")}
                  label={
                    <>
                      Town <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.town}
                  value={localData.town}
                  onChange={(e) => setDataByKey("town", e.target.value)}
                  onFocus={handleInputFocus}
                />
                <Input
                  name="county"
                  disabled ={disabledInputs.includes("county")}
                  label={
                    <>
                      County <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.county}
                  value={localData.county}
                  onChange={(e) => setDataByKey("county", e.target.value)}
                  onFocus={handleInputFocus}
                />
                <Input
                  name="country"
                  disabled ={disabledInputs.includes("country")}
                  label={
                    <>
                      Country <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.country}
                  value={localData.country}
                  onChange={(e) => setDataByKey("country", e.target.value)}
                  onFocus={handleInputFocus}
                />

                <Input
                  name="postcode"
                  disabled ={disabledInputs.includes("postcode")}
                  label={
                    <>
                      Postcode <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.postcode}
                  value={localData.postcode}
                  onChange={(e) => setDataByKey("postcode", e.target.value)}
                  onFocus={handleInputFocus}
                />
              </section>
              <section
                className="onboard-inputs-group"
                style={{ minWidth: "338.66px" }}
              >
                <span className="section-title">Personal Information</span>
                <Input
                  name="firstName"
                  disabled ={disabledInputs.includes("firstName")}
                  label={
                    <>
                      First Name <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.firstName}
                  value={localData.firstName}
                  onChange={(e) => setDataByKey("firstName", e.target.value)}
                  onFocus={handleInputFocus}
                />

                <Input
                  name="lastName"
                  disabled ={disabledInputs.includes("lastName")}
                  label={
                    <>
                      Last Name <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.lastName}
                  value={localData.lastName}
                  onChange={(e) => setDataByKey("lastName", e.target.value)}
                  onFocus={handleInputFocus}
                />
                {/* <Input
                  name = "phoneNumber"
                  label={
                    <>
                      Phone Number <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.phoneNumber}
                  value={localData.phoneNumber}
                  onChange={(e) => setDataByKey("phoneNumber", e.target.value)}
                  onFocus={handleInputFocus}
                />        

                <Input
                  name = "companyPosition"
                  label={
                    <>
                      Position in the Company{" "}
                      <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.companyPosition}
                  value={localData.companyPosition}
                  onChange={(e) =>
                    setDataByKey("companyPosition", e.target.value)
                  }
                  onFocus={handleInputFocus}
                />
                <Input
                  name = "personalEmail"
                  type="email"
                  label={
                    <>
                      Email <span style={{ fontSize: "120%" }}>*</span>
                    </>
                  }
                  error={errors.personalEmail}
                  value={localData.personalEmail}
                  onChange={(e) =>
                    setDataByKey("personalEmail", e.target.value)
                  }
                  onFocus={handleInputFocus}
                /> */}
                <button                  
                  className="blue-button-newload"
                  type="submit"
                  style={{ justifySelf: "end", marginTop: "auto" }}
                  disabled = {disableSaveBtn}
                >
                  Save
                </button>
              </section>
            </div>
          </div>
        </form>
      </section>
    </Container>
  );
};

export default Registration;
