import Stepper, { useStepper } from "../../Stepper";
import { Input } from "../../Input/Input";
import refno from "../assets/request.png";
import invoices from "../assets/invoices.png";
import { OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { useRef, useEffect, useState, useContext } from 'react'
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";
import { getEPOList, postEPOMovementDirection } from "../../apiCalls";
import { SealNumberTooltip, OfficeOfDestTooltip, InlandTransportIdTooltip } from "../../Newload/ProductInfo/Tooltips";
import { StoreContext } from "../../Store";

export function AdditionalRequirements() {
  const { stepBackward, stepForward, localData, setDataByKey, activeStepKey, gotoStep } = useStepper();
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const store = useContext(StoreContext);
  const requiredFields = [    
    "officeDest",
    "inlandTransId" ];

  const modalProps = {
    title: "No seal declared",
    body: (
      <>
        <p className="bold"> Please enter your Seal Number. If this is blank, we will assume there is no seal. </p>
      </>
    ),
    showContinue: true,
  };
    
  const validateOfficeDest = (value) => {
    const pattern = /^[A-Za-z]{2}[A-Za-z0-9]{6}$/ // 2 letters followed by 6 letters/digits
    return pattern.test(value);
  };

  const showSealNoConfirmation =()=>{
    localData.openRedirectmodal(modalProps);     
  }

  const nextPage = async (event) => {
    event.preventDefault();

    const fd = new FormData();
    fd.append('fromAreaCode', localData.fromAreaCode);
    fd.append('toAreaCode', localData.toAreaCode)
    const movementData = await postEPOMovementDirection(fd);
    if (movementData) {
      const data = await getEPOList(movementData[0].id);

      setDataByKey("categoryOptions", data);
      setDataByKey("categoryOptionsDefault", data);
      if (!localData.tad && !localData.ens && !localData.addReqNeither) {
        setError("Please select an option.");
        return;
      }
      if (localData.tad) {
        const formErrors = {};
        requiredFields.forEach((field) => {
          if (!localData[field]) {
            formErrors[field] = "Required";
          }
        });
        setErrors(formErrors);
        if (localData.officeDest && !validateOfficeDest(localData.officeDest)) {
          setErrors((prev) => ({
            ...prev,
            officeDest: "Invalid Office of Destination",
          }));
          return;
        }
        
        if (Object.keys(formErrors).length > 0 ) {        
          return;
        }
  
        if (localData.sealNo) {
          gotoStep("5. CategoryOfGoods");             
        }else{
          showSealNoConfirmation();        
          return;
        }
      }
  
      if(!localData.tad || !localData.ens){
        gotoStep("5. CategoryOfGoods");
      }  
    }

  };


  const handleChange = (e) => {

    if (e === "tad") {
      if (localData.tad === true) {
        setDataByKey("tad", false)
        setDataByKey("officeDest", "")
        setDataByKey("inlandTransId", "")
        setDataByKey("sealNo", "")
        setError("");
        setErrors({});
      }
      if (localData.tad === false) {
        setDataByKey("tad", true)
        setDataByKey("addReqNeither", false)
        setError("");
      }

    }
    if (e === "ens") {
      if (localData.ens === true) {
        setDataByKey("ens", false)
      }
      if (localData.ens === false) {
        setDataByKey("ens", true)
        setDataByKey("addReqNeither", false)
        setError("");
      }

    }
    if (e === "neither") {
      if (localData.addReqNeither === true) {
        setDataByKey("addReqNeither", false)
      }
      if (localData.addReqNeither === false) {
        setDataByKey("addReqNeither", true)
        setDataByKey("tad", false)
        setDataByKey("ens", false)
        setError("");
        setErrors({});
        setDataByKey("officeDest", "")
        setDataByKey("inlandTransId", "")
        setDataByKey("sealNo", "")
      }
    }

  };

  const backwards = () => {
        setError("");
        gotoStep("2. Exit Port")

  };

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "4. AdditionalRequirements" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "4. AdditionalRequirements",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized]);

  useEffect(() => {
    setError("");
  }, [localData.tad && localData.ens && localData.addReqNeither]);

  return (
    <Stepper.Step hideUnlessVisited stepKey="4. AdditionalRequirements" text="Additional Requirements">
      <div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{ fontSize:"1.8rem"}}>Additional Requirements</div>
      <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
  <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
    <input type="radio" disabled checked={localData.movementType === "Import"} />
    <span>Import</span>
  </label>

  <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px'}}>
    <input type="radio" disabled checked={localData.movementType === "Export"} />
    <span>Export</span>
  </label>
</div>
      <div className=' px-2 mt-1 pt-2 d-flex flex-column ' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
            <div className="d-flex step-banner justify-content-center">
              <img src={invoices} width="150" height="150" alt="port" className="mt-4 mb-4"/>
              {/* {/* <div style={{maxWidth: "350px"}} className="  px-4 d-flex flex-column"> */}
              {/* <p style={{color: '#0e2554'}}>Please indicate additional documents that are required for this movement.</p>     */}
            {/* </div> */}
          </div>
          <div class="d-flex flex-column align-self-center mt-3 " >
            {/* <span style={{maxWidth: "280px", fontWeight: "500"}}>Please select the expected arrival location for this freight movement. </span> */}
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "320px", fontWeight: "500"}}>Please indicate additional documents</span>

<span class="d-flex align-self-center flex-column  " style={{maxWidth: "320px", fontWeight: "500"}}>that are required for this movement. </span>
</div>

                    <div className="d-flex justify-content-center mt-auto">
            <div className="d-flex flex-column pb-4 mt-2">
              <label className="d-inline-flex align-items-center gap-2 my-2">
                <input type="checkbox" checked={ localData.tad === true} onChange={() => handleChange("tad")} />
                <span >Transit Accompanying Document  (TAD)</span>
              </label>
              {localData.tad && (
              <div className=" px-4">
                <div className="d-flex flex-row px-2">
              <Input
                label= {<>
                Office of Destination<span style={{ fontSize: "120%" }}>*</span>
                 </>}
                type='text' 
                name='officeDest' 
                id='Office of Destination'
                error={errors.officeDest}
                placeholder="e.g. IEDUB100" 
                onChange={e => setDataByKey("officeDest", e.target.value)} />
              <OfficeOfDestTooltip />
                </div>
              <div className="d-flex flex-row px-2">
                <Input
                label={<>Inland Transport ID<span style={{ fontSize: "120%" }}>*</span></>}
                type='text' 
                name='inlandTransId' 
                id='Inland Transport ID' 
                error={errors.inlandTransId}
                onChange={e => setDataByKey("inlandTransId", e.target.value)}/>
              <InlandTransportIdTooltip />
              </div>
              <div className="d-flex flex-row px-2">
                <Input
                label="Seal Number" 
                type='text' 
                name='Seal Number' 
                id='Seal Number' 
                onChange={e => setDataByKey("sealNo", e.target.value)}  />
              <SealNumberTooltip />
              </div>
              </div>
            )}
            
              <label className="d-inline-flex align-items-center gap-2 mb-2">
                <input type="checkbox" checked={localData.ens === true} onChange={() => handleChange("ens")} />
                <span>ENS (Only if via France)</span>
              </label>

              <label className="d-inline-flex align-items-center gap-2">
                <input type="checkbox" checked={localData.addReqNeither === true} onChange={
                  () => handleChange("neither")
                } />
                <span>None of the above</span>
              </label>
            </div>
          </div>
          <div className="px-3 mb-3 w-75 mx-auto">
            {error && (
              <div className="alert-danger px-3 py-1 w-100 text-center" style={{borderRadius: '10px'}}>{error}</div>
            )}
          </div>

          <section className="d-flex justify-content-between px-3 pb-4 mt-auto" >
        <button type="button" className="stepper-button red" onClick={backwards}>
          Back
        </button>
        <button className="stepper-button" onClick={nextPage}>Next</button>
      </section>

      </div>
    </Stepper.Step>
  );
}
