import { Modal } from "react-bootstrap";

const HomeModal = ({ show, onHide, hideHeader, hideCancel, onConfirm, children, header, src , ...rest }) => {
  return (
    <Modal show={show} onHide={onHide} {...rest}>
      <Modal.Header style={{ display: (hideHeader ? "none" : "flex") }} >
        <Modal.Title>{header}</Modal.Title>
        <button
          id="close"
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>{src}</Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
        
      >
        <button
          id="cancel"   
          class="cancel-button"
          onClick={onHide}
          style={{ display: (hideCancel ? "none" : "flex"),
                  borderRadius: "10px" }} 
           >
        
          Cancel
        </button>
        <button
          id="confirm"   
          class="blue-button-teams"
          onClick={(event) => {
            onConfirm();
            onHide(event);  
          }}
          style={{ borderRadius: "10px" }}
        >
          Close      
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default HomeModal;
