import { Image, Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useContext } from 'react';
import Stepper, { useStepper } from "../../Stepper";
import { StoreContext } from "../../Store";
import '../../DataValidation/measureModal.css'

import { Download, FileDownload } from '@styled-icons/fa-solid';
import { OverlayTrigger, Popover, PopoverContent, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';

export default function Confirmation ({ showTrader }) {
  const { stepBackward, stepForward, hideBefore, setStepListVisible, localData } = useStepper();
  const [loading, setLoading] = useState(false);
  const store = useContext(StoreContext);
  const loadNextPage = () => {
    setLoading(true);
    setTimeout(moveToNextPage, 7000)
  }

  const moveToNextPage = () => {
    setLoading(false);
    stepForward()
    showTrader();
  }

  return (
    <Stepper.Step stepKey="confirmation" text="Confirmation" activeIsDone>    
      <div >
      <h3 className={`mb-4 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}>Consignment Submitted</h3>
      <div className={loading && 'blur px-4 py-4 mt-1' || 'px-4 py-4 mt-1' } style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)'}}>
      <p>
        <span className="green-text fw-bold">Thank you</span> for submitting your consignment information. 
      </p>
      <p>Here is a summary of the information you have provided:</p>
      {(localData.movementType === "Export" && localData.returndata?.scoreValue)? <ResultGtoJ/>:<ResultAtoF/>}
      <p>
        {`Our ${localData?.movementType === "Export"? 'export':'import'} declaration charge is based on an individual CABIE submission.`}
        <OverlayTrigger
          placement="right"
          trigger={["hover", "focus"]}
          overlay={
            <Popover style={{ minWidth: "25rem" }}>
              <PopoverContent>
                <h6>Declaration Charges</h6>
                <p>
                This process assumes one consignee (for imports) and one consignor (for exports) per EPO reference regardless of the number of invoices uploaded.
                </p>
                <p> If there are multiple consignors (for imports) or multiple consignees (for exports) they can still be released against one reference but there will be an additional processing charge.</p>
                <p>
                All charges are applied to the consignee (for imports) and consignor (for exports).
                </p>
              </PopoverContent>
            </Popover>
          }
        >
          <span className="info ms-2"></span>
        </OverlayTrigger>
      </p>

      <section className="d-flex justify-content-end mt-2">
        <button type="button" className="stepper-button" onClick={loadNextPage} disabled={loading}>
          OK
        </button>
      </section>
      </div>
      { loading && 
         <div className="centreDiv">
           <Spinner animation="border" role="status"></Spinner>
            <span style={{color: '#395da2'}}>Uploading and processing documentation...</span>
          </div>
        } 
      </div> 
    </Stepper.Step>
  );
}

export const ResultAtoF =()=>{
  const { localData } = useStepper();
  const {jobRef, entryport, euPref, confirmedNoCG, pref} =  localData;

  return(<ol className="text-eoriblue font-monospace" style={{fontSize: '18px'}}>
  <li>Your job reference : {jobRef}</li>        
  <li>Created by : {localData?.returndata?.submittedBy}</li>
  <li>Expected port of arrival : {entryport?.data}</li>
  <li>Expected date of arrival : {new Date(localData?.returndata?.loadDate).toLocaleDateString()}</li>
  {localData?.euPrefSelected ?(<li>Origin of goods status : {euPref === "yes" ? "EU Origin Only" : "Not All EU Origin Only"}</li>):
  (<li>Origin of goods status : {pref === "yes" ? 'All Preferential Origin':'Not all Preferential Origin'}</li>)}
  <li>Type of Goods: {(confirmedNoCG === "NCG") ? "Non-Controlled" : "Controlled"}</li>
  <li>Time and date created : {localData?.returndata?.submittedAt?.toLocaleString()}</li>
 </ol>)
}

export const ResultGtoJ =()=>{
  const { localData } = useStepper();  
  const {jobRef, exitport, confirmedNoCG} = localData;
  const { submittedBy, loadDate, scoreValue, sealNo, officeDest, inlandTransId } = localData?.returndata;  
  const additionalReqText = {
    G: "None",
    H: "ENS Required",
    I: "Transit Required",
    J: "Transit & ENS Required",
  };
  const resultIandJ = () => {
    return (
      <>      
          <li>Office of Destination : {officeDest} </li>
          <li>Inland Transport ID : {inlandTransId} </li>
          <li>Seal Number : {sealNo === "" ? "Not Declared" : sealNo } </li>
      </>
    );
  };

  return(<ol className="text-eoriblue font-monospace" style={{fontSize: '18px'}}>
  <li>Your job reference : {jobRef}</li> 
  <li>created by : {submittedBy}</li>
  <li>Expected port of Exit : {exitport?.data}</li>
  <li>Type of Goods: {confirmedNoCG == "NCG" ? "Non-Controlled" : "Controlled"}</li>
  <li>Additional Requirements: {additionalReqText[scoreValue]}</li>
  {["I","J"].includes(scoreValue) && resultIandJ()}
  <li>Expected date of Exit : {new Date(loadDate).toLocaleDateString()}</li>
  <li>Time and date created : {localData?.returndata?.submittedAt?.toLocaleString()}</li>
</ol>)
}