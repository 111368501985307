import React, { useContext, useEffect, useState } from 'react';
import { getCountryDetail, getNewLoad, postCommodityInfo } from '../apiCalls';
import { Pagination, Spinner} from "react-bootstrap";
import { Autocomplete, Input } from '../Input/Input';
import { CommodityHierarchy } from './commodityHierarchy';
import { StoreContext } from '../Store';

const ITEMS_PER_PAGE = 10;

const SearchData = () => {

  const [commodityData, setCommodityData] = useState([])
  const [pageNo, setPageNo] = useState(0);
  const [requestFailed, setRequestFailed] = useState(false)
  const [error, setError] = useState(null)
  const [origin, setOrigin] = useState(null);
  const [commCode, setCommCode] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false)

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    getCountryDetail().then(data => setCountries(data));
  }, []);

  useEffect(() => {
    console.log(origin);
  }, [origin])

   async function handleSubmit(event) {
    event.preventDefault();
    setError("")
    
    if(!origin?.countryId) {
      setError("Please enter an origin.")
      return;
    }

    if (!commCode && !description) {
      setError("Please enter either a commodity code or description.")
      return;
    }

    if (commCode && commCode.length < 4) {
      setError("Commodity code must be at least 4 characters long.");
      return;
    }

    if (description && description.length < 3) {
      setError("Description must be at least 3 characters if supplied.");
      return;
    }

    const fd = new FormData()
    fd.append('commCode', commCode);
    fd.append('origin', origin?.countryId);
    fd.append('desc', description)
    try {
      setLoading(true)
      const data = await postCommodityInfo(fd, 'search').catch((err) => {setError(err.toString())})
      setLoading(false)
      setCommodityData(data.Parents)
      setPageNo(0)
      return data
    } catch (err) {
      console.log(err)
    }

  }  

  function clearSearch() {
    setError("")
    setCommodityData([])
    setCommCode("");
    setDescription("");
    setOrigin("")
  }

  const lastPage = Math.ceil(commodityData.length / ITEMS_PER_PAGE);

  function nextPage() {
    if (pageNo + 1 < lastPage) {
      setPageNo(prev => prev + 1)
    }
  }

  function prevPage() {
    if (pageNo > 0) {
      setPageNo(prev => prev - 1)
    }
  }


  const ListLoadsPagination = () => (
    <Pagination>
        <Pagination.First  onClick={() => setPageNo(0)} />
        <Pagination.Prev onClick={prevPage} />
        {pageNo + 1 >= lastPage && pageNo - 2 >= 0 &&
          <Pagination.Item onClick={() => setPageNo(pageNo - 2)}>{pageNo - 1}</Pagination.Item>
        }
        
        {pageNo > 0 &&
          <Pagination.Item onClick={() => setPageNo(pageNo - 1)}>{pageNo}</Pagination.Item>
        }
        <Pagination.Item active>{pageNo + 1}</Pagination.Item>

        
        {pageNo + 1 < lastPage &&
          <Pagination.Item onClick={() => setPageNo(pageNo + 1)}>{pageNo + 2}</Pagination.Item>
        }

        {pageNo <= 0 && pageNo + 2 <= lastPage &&
          <Pagination.Item onClick={() => setPageNo(pageNo+ 2)}>{pageNo + 3}</Pagination.Item>
        }
        <Pagination.Next onClick={nextPage} />
        <Pagination.Last onClick={() => setPageNo(lastPage - 1)} />
      </Pagination>
  )

  if (requestFailed) return <p>Failed!</p>

  return (
    <div className="full-width-container .dataEnrichment">
      <section className='floating-box position-relative' style={{ maxWidth: '1040px' }}>
        <div className={loading ? "blur" : ""}>
        <div className='floating-box-header-newload'>
          <span>Tariff Tool Search</span>
        </div>
        <form onSubmit={handleSubmit} id='listload-search-form'>
          <div id="listLoads-form" style={{paddingBottom: '1rem'}}>
          <Input
              name="commCode"
              value={commCode}
              onChange={(e) => setCommCode(e.target.value)}
              type="text"
              label="Commodity Code"
            />
            {/* <Input
              name="origin"
              value={origin}
              onChange={(e) => setOrigin(e.target.value.toUpperCase())}
              type="text"
              label="Origin"
            /> */}

            <Autocomplete
              label="Origin"
              data={origin}
              onChange={(val) => setOrigin(val)}
              options={countries}
              filter={1}
              displayFn={(item) => item ? `${item.countryDescription} - ${item.countryId}` : ""}
              searchFn={(searchStr, item) => {
                if (item?.countryId?.toLowerCase() === searchStr?.toLowerCase()) return 2;
                if (item?.countryDescription?.toLowerCase().startsWith(searchStr?.toLowerCase())) return 1;
                if (item?.countryDescription?.toLowerCase().includes(searchStr?.toLowerCase())) return 0;
              }}
            />
            <Input
              name="desc"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Commodity Description"
              />
          <section className="d-flex justify-content-end gap-2 pb-2 pt-2">
            <button type="button" className="blue-button-teams grey-btn" onClick={clearSearch}>Reset</button>
            <button type="submit" className="blue-button-teams green-btn" >Search</button>
        </section>
            <p style={{color: "red", fontWeight: "500"}}>{error}&nbsp;</p>
        
          </div>
          <CommodityHierarchy
            data={commodityData.slice(pageNo * ITEMS_PER_PAGE, (pageNo + 1) * ITEMS_PER_PAGE)}
          />
        </form>

        <div className="w-100 d-flex justify-content-end pe-3 py-2">
          <ListLoadsPagination />
        </div>
          </div>
         { loading && 
         <div className="centreDiv">
           <Spinner
          animation="border"
          role="status"
          >
        </Spinner>
            Please wait...
            </div>
        }
      </section>
    </div>
  )
}

export default SearchData;