import { Check, CheckCircle } from "@styled-icons/fa-solid";
import React, { useEffect, useContext, useMemo } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import information from '../../images/information.svg'
import { putDocusignSigned } from "../apiCalls";
import { GoogleAnalyticsContext } from "../GoogleAnalytics";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function DocusignSubmitted() {
  const navigate = useNavigate();
  const query = useQuery();
  const ga = useContext(GoogleAnalyticsContext)
  const event = useMemo(() => query.get('event'), [query]);
  const existingCustomer = useMemo(() => query.get('existingCustomer'), [query]);

  useEffect(() => {
    const state = query.get('state');
    const existingCustomer = query.get('existingCustomer');
    putDocusignSigned(state, existingCustomer, event).catch(err => console.error(err));
  }, [query, event]);

  useEffect(() => {
    if (ga.gaInitialized) {
      window?.gtag?.("event", "5. Confirmation",{
        event_category: "Registration"
      })
    }
  }, [ga.gaInitialized])

  return (
    <div className="d-flex w-100 mx-auto justify-content-center align-items-start my-5">
    <div className="bg-white d-flex flex-column justify-content-between" style={{ borderRadius: "20px", padding: "2rem", minWidth: "550px", maxWidth: "550px" }}>
      <div className="d-flex justify-content-center gap-4 align-items-center mb-4">
        <h2 className="text-eoriblue" style={{margin: 0}}>Your registration {event === "signing_complete" ? 'has been received' : 'has timed out'}</h2>
        {event === "signing_complete" && <CheckCircle width="2.5em" height="2.5em" color="green" />}
      </div>

      <div className="inputs-group p-4 d-flex flex-row align-items-start gap-3">
        <img src={information} width="36" alt="info" />
        <div className="d-flex flex-column gap-4">
          {event === "signing_complete" || existingCustomer === "true" ? (<>
            <div>
              Thanks so much for signing up, we'll be in touch should we need to clarify any of the information provided. 
              You should receive your log on codes for our CABIE Portal within the next 24 hours, 
              if you don't then please call us on +44 (0) 333 012 4819
            </div>
            <div>
              In the meantime - why not visit our homepage to get live updates from a variety of transport and customs based
              organisations; or learn about the newest changes to customs in one of our webinars?
            </div>
          </>) : event === 'cancel' ? (
            <>
            <div>
              Thanks for starting your sign up process, please finish your application by signing your registration pack as soon as possible.
            </div>
            <div>
              In the meantime - why not visit our homepage to get live updates from a variety of transport and customs based
              organisations; or learn about the newest changes to customs in one of our webinars?
            </div>
            <p>For more details please contact us at <a className="bold text-eoriblue" href="mailto:cabiesupport@eori.uk?subject=CABIE Issue">CABIEsupport@eori.uk</a>.</p>
            </>
          ) :
          (
            <div>
              Don't worry! We have emailed your registration details and the link required to complete your application. 
            </div>
          )
          }
        </div>
      </div>

      <section className="d-flex justify-content-center mt-5">
        <button className="blue-button-teams green-btn text-decoration-none" onClick={() => navigate('/')}>
          Home
        </button>
      </section>
    </div>
    </div>
  );
}

export default DocusignSubmitted;
