
export default function LspPreCheck() {

  return (
    <div className="px-4 pb-5 pt-2 bg-white">
      <div>
        <h4 className="text-eoriblue mt-2 mb-1">Welcome!</h4>
      </div>

      <div className="inputs-group p-4">
        <div>
        We just require a few extra peices of information to complete you registration. Please review all your inputs and ensure all required fields
        have been filled out. Once you submit you will be redirected to our main page from where you can submit loads through our Express Lane Option - EPO.

        </div>

          <h5 className="d-flex pt-2">Company information</h5>
        <div className="d-flex pt-2">
          <ol className="flex-grow-1">
            <li className="text-eoriblue" style={{fontWeight: '500'}}>
            Company registration - you will need to fill all required fields marked with '*'
            </li>
           
          </ol>
        </div>

        <h5 className="d-flex pt-2">Address Details</h5>
        <div className="pt-1">
        <ol className="flex-grow-1">
            
            <li className="text-eoriblue" style={{fontWeight: '500'}}>
            Address details - again please enter all required fields.
            </li>
          
          </ol>         
        </div>
       
      </div>
      

      <section className={"d-flex justify-content-between align-items-center"}>
        <div/>
        <span className="" style={{fontSize: '77%', color: 'gray'}}>Any questions? Send us an email at <a href="mailto:CABIEsupport@eori.uk">CABIEsupport@eori.uk</a>.</span>
        
    </section>
    </div>
  );
}
