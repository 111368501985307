import { useContext, useState, useEffect, React } from "react";
// import DataTable, {useDataTableHandle} from '../DataTable';
import BPHeader from "./BPHeader";
import BPFooter from "./BPFooter";
import { useStepper } from "../Stepper";
import DataTable from "../Reports/DataTable1";
import { useSecurity } from "../Security/security";
import { useNavigate } from 'react-router-dom';

import {
  fetchBusinessPartnerDocumentDetails,
  fetchBusinessPartnerDocuments,
  fetchDeleteBPDocument,
  fetchRestoreBPDocument,
} from "./BusinessPartnerController";

// Context
import { BusinessPartnerContext } from "./BusinessPartnerCard";

const inputStyle = {
  width: "100%",
  boxSizing: "border-box",
  border: "none",
  backgroundColor: "transparent",
};
// Default Header Styles
const headerStyle = { fontSize: "12px" };
// Default Row Styles
const defaultStyle = { fontSize: '14px', width: 'auto'  };
const buttonStyle = {backgroundColor: 'transparent', border: 'none', height:'4rem', width: '4rem'}
const imageStyle = { height:'1.2rem', width: '1.2rem', marginRight:'8px', marginTop: '2px' };
// Override individual row styles



const BPDocuments = ({ companyId, isModalShowing }) => {
  const { activeStepKey } = useStepper();
  const {
    companyName,
    eoriNumber,
    localBPData,
    handleAddData,
    isEditing,
    handleOnChange,
    handleFetchBusinessPartnerDropdownValues,
    dropdownValues,
    errors,
    handleShowModal,
    setCurrentStep
  } = useContext(BusinessPartnerContext);

  const [documents, setDocuments] = useState([]);
  const { validateRole } = useSecurity();
  const navigate = useNavigate();
  const fetchData = async () => {
    const returnData = await fetchBusinessPartnerDocuments(companyId);
    setDocuments(returnData);
  };

  useEffect(() => {
    if (activeStepKey === "BPDocuments") {
      //if activeStepkey is the current step we are in, then load the data into the context
      fetchData();
      
      setCurrentStep("BPDocuments");
    }
  }, [activeStepKey]);

  useEffect(() => {
    if (!isModalShowing) {
      fetchData();
    }
  }, [isModalShowing]);

  const handleViewDocument = async (event) => {
    const documentId = event.id;
    const returnData = await fetchBusinessPartnerDocumentDetails(documentId);
    const document = returnData[0];

    //if document.name is URL, then open a new tab with the URL that is located in document.data
    if (document.name === "URL") {
      window.open(document.data, "_blank");
    } else {
      const dataBuffered = Buffer.from(document.data.data, "binary").toString(
        "base64"
      );
      //if document.name ends with pdf then open the pdf in a new tab
      if (document.name.endsWith("pdf")) {
        // debugger
        const pdfData = atob(dataBuffered); // Decode base64
        const pdfArray = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          pdfArray[i] = pdfData.charCodeAt(i);
        }
        const blob = new Blob([pdfArray], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl);
      }

      //else, if document.name ends with xlsx or xls, displays the sheet's data in a new tab
      else if (
        document.name.endsWith("xlsx") ||
        document.name.endsWith("xls")
      ) {
        const xlsData = atob(dataBuffered); // Decode base64
        const xlsArray = new Uint8Array(xlsData.length);
        for (let i = 0; i < xlsData.length; i++) {
          xlsArray[i] = xlsData.charCodeAt(i);
        }
        const blob = new Blob([xlsArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const xlsUrl = URL.createObjectURL(blob);

        // Open the sheet in a new window or tab
        window.open(xlsUrl);
      }

      //else if the document.name is an image, then open the image in a new tab
      else if (
        document.name.endsWith("png") ||
        document.name.endsWith("jpg") ||
        document.name.endsWith("jpeg")
      ) {
        const imgData = atob(dataBuffered); // Decode base64
        const imgArray = new Uint8Array(imgData.length);
        for (let i = 0; i < imgData.length; i++) {
          imgArray[i] = imgData.charCodeAt(i);
        }
        const blob = new Blob([imgArray], { type: "image/png" });
        const imgUrl = URL.createObjectURL(blob);

        // Open the image in a new window or tab
        window.open(imgUrl);
      }
    }
  };

  const localDeleteRow = async (event) => {
    const data = await fetchDeleteBPDocument(event.id);
    if (data[0].completed === "Deleted") {
      fetchData();
    } else {
      const params = new URLSearchParams();
      params.append('authError', 'Sorry, something went wrong while performing that action.')
      navigate("/BusinessPartners");
    }
  };

  const localRestoreRow = async (event) => {
    const data = await fetchRestoreBPDocument(event.id);
    if (data[0].completed === "Restored") {
      fetchData();
    } else {
      const params = new URLSearchParams();
      params.append('authError', 'Sorry, something went wrong while performing that action.')
      navigate("/BusinessPartners");
    }
  };

  const eoriNumberDisplay = eoriNumber === "" || eoriNumber === null ? `` : `(${eoriNumber})`;
  const companyNameDisplay = companyName === "" || companyName === null ? `this` : <span class='companyname-eorinumber'><span class='companyname'>{companyName}</span> {eoriNumberDisplay}</span>;

  return (
    <>
      <section className="bp-documents">
        <BPHeader />
        {
          companyId === 0 || companyId == null || documents.length === 0 ? 
            <p>No documents uploaded for {companyNameDisplay} business partner.</p> : 
            (
              <DataTable
                // columns={columns}
                // containerRef={containerRef}
                initialData={documents}
                disabled={true}
                defaultStyle={defaultStyle}
                headerStyle={headerStyle}
                sortable={true}
                filterable={true}
                inputStyle={inputStyle}
                rootClose={true}
                downloadable={false}
                // buttonImage={copyIcon}
                buttonDisabled={false}
                buttonStyle={buttonStyle}
                imageStyle={imageStyle}
                buttonOnClick={handleViewDocument}
                hideNavigation={true}
                handleDeleteRow={localDeleteRow}
                handleRestoreRow={localRestoreRow}
                superOps={validateRole({department: 'OPS', role: 'SUPERUSER'} || validateRole({department: 'QA', role: 'SUPERUSER'})) ? true : false}
                // returnPrev={() => history.push('/ReportsAdmin')}
              />
            )
        }
      </section>
      <BPFooter />
    </>
  );
};

export default BPDocuments;
