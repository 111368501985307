import { forwardRef, useEffect, useContext } from "react";

import { CallbackContext } from './Spreadsheet';
const Functions = () => {
    const { setStopEditing, excelState, excelDispatch, setTemporaryText, temporaryText, setStartEditing, setIsEditing, isEditing, editable, setClearSelection, setGetSelectionAsText, selection} = useContext(CallbackContext);

    useEffect(() => {
        setStopEditing(() => 
        (save = false) => {
            setIsEditing(false);
            if (save) {
              excelDispatch({
                type: "cell",
                location: { x: selection.root.col_idx, y: selection.root.row_idx },
                detail: { ...excelState.rows[selection.root.row_idx][selection.root.col_idx], text: temporaryText, error: false },
              });
            }
            setTemporaryText("");
            excelDispatch({ type: "recalculate" });
          },
        )
    }, [
        excelState.rows,
        temporaryText,
        selection.root.row_idx,
        selection.root.col_idx,
        excelDispatch,
      ] )

  useEffect(() => {
    setStartEditing(() => 
    (clear = false) => {
        if (
          isEditing ||
          !editable ||
          selection.root.row_idx === null ||
          selection.root.col_idx === null
        )
          return;
        setIsEditing(true);
        if (!clear) {
          setTemporaryText(
            excelState.rows[selection.root.row_idx][selection.root.col_idx].text
          );
        }
      },
    )
  },[selection.root.row_idx, selection.root.col_idx, isEditing, excelState.rows])


  useEffect(() => {
    setClearSelection(() => 
    () => {
        const starting_x = Math.min(
          selection.root.col_idx,
          selection.bounding.col_idx
        );
        const starting_y = Math.min(
          selection.root.row_idx,
          selection.bounding.row_idx
        );
    
        const ending_x =
          Math.max(selection.root.col_idx, selection.bounding.col_idx) + 1;
        const ending_y =
          Math.max(selection.root.row_idx, selection.bounding.row_idx) + 1;
    
          
    
        excelDispatch({
          type: "cells",
          location: {
            x: starting_x,
            y: starting_y,
            width: ending_x - starting_x,
            height: ending_y - starting_y,
          },
          detail: "",
        });
      }
    )
  }, [
    selection.root.row_idx,
    selection.root.col_idx,
    selection.bounding.row_idx,
    selection.bounding.col_idx,
  ])


  useEffect(() => {
    setGetSelectionAsText(() => () => {
        const starting_x = Math.min(
          selection.root.col_idx,
          selection.bounding.col_idx
        );
        const starting_y = Math.min(
          selection.root.row_idx,
          selection.bounding.row_idx
        );
    
        const ending_x = Math.max(
          selection.root.col_idx,
          selection.bounding.col_idx
        );
        const ending_y = Math.max(
          selection.root.row_idx,
          selection.bounding.row_idx
        );
    
        let output_matrix = excelState.rows
          .slice(starting_y, ending_y + 1)
          .map((row) =>
            row.slice(starting_x, ending_x + 1).map((cell) => cell.text)
          );
    
        const text = output_matrix.map((row) => row.join("\t")).join("\n");
    
        return text;
      
  })}, [
    selection.root.row_idx,
    selection.root.col_idx,
    selection.bounding.row_idx,
    selection.bounding.col_idx,
    excelState.rows,
  ])

  return null
}

export default Functions