import { useContext } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import { Input, Select } from "../../Input/Input";
import { UserProfileContext } from "../UserProfile";

export default function FinanceDetails() {
  const { companyInfo, updateCompanyInfo } = useContext(UserProfileContext);

  return (
    <div className="px-4 pb-5 pt-2 bg-white">
      <h6 className="text-eoriblue mt-2">Invoicing Contact</h6>
      <Row className="py-2">
        <Col>
          <Input
            label={
              <>
                First Name <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled value={companyInfo.paymentName} onChange={e => updateCompanyInfo('paymentName', e.target.value)}
          />
        </Col>
        <Col>
          <Input
            label={
              <>
                Last Name <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled value={companyInfo.paymentLastName} onChange={e => updateCompanyInfo('paymentLastName', e.target.value)}
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Col>
          <Input
            label={
              <>
                Accounts Contact Telephone Number <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled value={companyInfo.paymentPhone} onChange={e => updateCompanyInfo('paymentPhone', e.target.value)}
          />
        </Col>
        <Col>
          <Input
            type="email"
            label={
              <>
                Accounts Contact Email <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled value={companyInfo.paymentEmail} onChange={e => updateCompanyInfo('paymentEmail', e.target.value)}
          />
        </Col>
      </Row>

      <h6 className="text-eoriblue mt-1">Invoicing Address</h6>
      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Building name/number <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled value={companyInfo.building3} onChange={e => updateCompanyInfo('building3', e.target.value)}
          />
        </Col>
        <Col>
          <Input
            label={
              <>
                Street <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled value={companyInfo.street3} onChange={e => updateCompanyInfo('street3', e.target.value)}
          />
        </Col>
      </Row>
      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Town/City <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled value={companyInfo.town3} onChange={e => updateCompanyInfo('town3', e.target.value)}
          />
        </Col>
        <Col>
          <Input label="County" disabled value={companyInfo.county3} onChange={e => updateCompanyInfo('county3', e.target.value)} /> 
        </Col>
      </Row>

      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Postcode <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled value={companyInfo.postcode3} onChange={e => updateCompanyInfo('postcode3', e.target.value)}
          />
        </Col>
        <Col>
          <Select
            label={
              <>
                Country <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled data={companyInfo.country3}
            setData={data => updateCompanyInfo('country3', data)}
            options={[
              { id: "GB", data: "Great Britain" },
              { id: "IE", data: "Republic of Ireland" },
              { id: "NI", data: "Northern Ireland" },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}
