import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { StoreContext } from "./Store";
import './TableDetails.css'
import UploadIcon from "../images/Upload.svg"

const TableDetails = (tableData) => {

    const navigate = useNavigate();
    const data = tableData.invoices
    const store = useContext(StoreContext);

    const handleClick = (event, target) => {
        if (event) {
            navigate({
                pathname: target, state: { detail: event}
            });
        }
    }
    const handleUploadClick = (rowID) => {
        store.newLoadID[1](rowID);
        navigate('/AdditionalFileUpload')
    }

    const handleDocumentsClick = (rowID) => {
        store.newLoadID[1](rowID);
        navigate('/AdditionalFileDisplay')
    }

    let tableLength = data.length
    let table = []
    for (let i = 0; i < tableLength; i++) {
        let children = []
        let row = Object.values(data[i])
        const dataField = row.slice(0, 6) 
        const statusField = row.slice(5, 6) 
        const percentageField = row.slice(6, 7) 
        const uploadField = row.slice(7, 8)
        const documentsField = row.slice(8,9)
        dataField.map(data => children.push(<td id={row[0]} >{data}</td>))
        percentageField.map(data => children.push(<td id={row[0]} style={{width: '10rem'}}><ProgressBar now={data} label={`${data}%`} /></td>))
        uploadField.map(data => children.push(<td id={row[0]}><img src={UploadIcon} class="uploadIcon" style={{width: "1rem"}} onClick={(event, target) => {handleUploadClick(row[0])}}/> </td>))
        documentsField.map(data => children.push(<td id={row[0]}>{data != 0 ? <div class="documentsLink" onClick={(event, target) => {handleDocumentsClick(row[0])}}>{data}</div> : data }</td>))
        table.push(<tr key={row} onClick={e => handleClick(e.target.id, (statusField[0] === "Draft" ? '/Newload' : tableData.url)) }>{children}</tr>)
    }
    if (table.length === 0) {
         
        return null
    }

    else { 
        return table }
}

export default TableDetails
