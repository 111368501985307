import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container, OverlayTrigger, Popover, PopoverContent, Modal } from "react-bootstrap";
import { postSelectedReport, getReportList, getReportsDropdown} from "../apiCalls.js";
import { useSecurity } from "../Security/security.js";
import { StoreContext } from "../Store.js";
import { Input, Select, FilterableSelect, NeeNorSelect } from "../Input/Input.js";
import { useParams } from "react-router-dom";


export default function ReportsAdmin() {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const { reportName } = useParams();


  const handleSubmit = (event) => {
      event.preventDefault();
      const reportEndPoint = store.reportParams[0][0].endPoint
      console.log('report customer ', store.reportCustomer[0])
      
      if (store.reportCreatedStartDate[0] == '' && store.reportCompletedStartDate[0] !== '' && store.reportCustomer[0] == '') {

        navigate(`/ReportsResults/${reportEndPoint}/${reportName}/1/${store.reportCompletedStartDate[0]}/${store.reportCompletedEndDate[0]}`)
        return
      }
      if (store.reportCompletedStartDate[0] == '' && store.reportCreatedStartDate[0] !== '' && store.reportCustomer[0] == '') {


        navigate(`/ReportsResults/${reportEndPoint}/${reportName}/2/${store.reportCreatedStartDate[0]}/${store.reportCreatedEndDate[0]}`)
        return
      }
      if (store.reportCompletedStartDate[0] !== '' && store.reportCreatedStartDate[0] !== '' && store.reportCustomer[0] == '') {

        navigate(`/ReportsResults/${reportEndPoint}/${reportName}/3/${store.reportCreatedStartDate[0]}/${store.reportCreatedEndDate[0]}/${store.reportCompletedStartDate[0]}/${store.reportCompletedEndDate[0]}`)
        return
      }
      if (store.reportCreatedStartDate[0] == '' && store.reportCompletedStartDate[0] !== '' && store.reportCustomer[0] !== '') {

        navigate(`/ReportsResults/${reportEndPoint}/${reportName}/5/${store.reportCompletedStartDate[0]}/${store.reportCompletedEndDate[0]}/${store.reportCustomer[0]}`)
        return
      }
      if (store.reportCompletedStartDate[0] == '' && store.reportCreatedStartDate[0] !== '' && store.reportCustomer[0] !== '') {

        navigate(`/ReportsResults/${reportEndPoint}/${reportName}/6/${store.reportCreatedStartDate[0]}/${store.reportCreatedEndDate[0]}/${store.reportCustomer[0]}`)
        return
      }
      if (store.reportCompletedStartDate[0] !== '' && store.reportCreatedStartDate[0] !== '' && store.reportCustomer[0] !== '') {

        navigate(`/ReportsResults/${reportEndPoint}/${reportName}/7/${store.reportCreatedStartDate[0]}/${store.reportCreatedEndDate[0]}/${store.reportCompletedStartDate[0]}/${store.reportCompletedEndDate[0]}/${store.reportCustomer[0]}`)
        return
      }
      // if (store.reportCompletedStartDate[0] == '' && store.reportCreatedStartDate[0] == '' && store.reportCustomer[0] !== '') {

      //   navigate(`/ReportsResults/${reportEndPoint}/${reportName}/8/${store.reportCreatedStartDate[0]}/${store.reportCreatedEndDate[0]}/${store.reportCompletedStartDate[0]}/${store.reportCompletedEndDate[0]}`)
      //   return
      // }
      else {
        navigate(`/ReportsResults/${reportEndPoint}/${reportName}/4`)
        return
      }

  };

  const [disableCreatedDateInputs, setDisableCreatedDateInputs] = useState(true);
  const [disableCompletedDateInputs, setDisableCompletedDateInputs] = useState(true);
  const [disableDepInputs, setDisableDepInputs] = useState(true);
  const [disableUserInputs, setDisableUserInputs] = useState(true);
  const [disableCustInputs, setDisableCustInputs] = useState(true);
  const [enableCreated, setEnableCreated] = useState(false);
  const [enableCompleted, setEnableCompleted] = useState(false);
  const [customerCall, setCustomerCall] = useState(false);

  const storeUsers = (data) => {
    store.userOptions[1](data)
}

  useEffect(() => {
    
    try {
      setDisableDepInputs(true)
      setDisableUserInputs(true)
      setDisableCreatedDateInputs(true)
      setDisableCompletedDateInputs(true)
      setEnableCompleted(false)
      setEnableCreated(false)
      setCustomerCall(false)

      if (store.reportParams[0][0].parameters) {
        let tempArray = [];
        JSON.parse(store.reportParams[0][0].parameters).forEach( ddata => {
          console.log('DDDDDAAAATATA HERE ', ddata)
          if (ddata.Type === "Dropdown") 
          {
              setDisableCustInputs(false)
              setCustomerCall(true)
                // tempArray.push(ddata)

            // store.rolesOptions[1](tempArray);
          }
          if (ddata.Type === "Date") 
          {
            if (ddata.data === "Completed") 
            {
              setDisableCompletedDateInputs(false)
              setEnableCompleted(true)
            }
            if (ddata.data === "Created") 
            {
              setDisableCreatedDateInputs(false)
              setEnableCreated(true)
            }
          }
        })
      }


    } catch (err) {
      console.log(err);
    }
    
}, [store.reportParams[0]]);

//   useEffect(() => {
//   getReportsDropdown(store.reportDepartment[0], 'departmentUsers').then((data) => {    storeUsers(data) });
// }, [store.reportDepartment[0]]);

useEffect(() => {
  async function fetchData() {
    console.log('customer call ')
    if (customerCall === true) {

      const dropdownList = await getReportsDropdown(0,'customerDropdown')
      if (dropdownList) {
        store.reportCustomerDropdown[1](dropdownList)
      }
    }
  
} fetchData();
}, [customerCall]);

    const defaultData = useMemo(() => ({
      reportId: {id: undefined},
      reportParameters: [{Nothing: "Nothing"}],
      currentParameters: '[{"Nothing": "Nothing"}]',
      userId: {id: undefined},
      departmentId: {id: undefined},
      startDate: undefined,
      endDate: undefined,
      endPoint: undefined,
      customerId: {id: undefined}

    }), [])


    // if (validateRole({ department: "OPS", role: "SUPERUSER" }))
    const handleEmitData = async (data) => {
      const {startDate, endDate, endPoint, userId} = data;
      const fd = new FormData();
      fd.append("createdStartDate", startDate);
      fd.append("createdEndDate", endDate);
      fd.append("completedStartDate", startDate);
      fd.append("completedEndDate", endDate);
      console.log('usid ', userId)
      if (userId) {
        fd.append("userId", userId.id)
      }


      // fd.append("<name expected by backend>", <field found in data>);
      const result = await postSelectedReport(endPoint, fd)
      return result;
    };


const backToSelection = () => {
  store.reportCreatedStartDate[1]('')
  store.reportCreatedEndDate[1]('')
  store.reportCompletedStartDate[1]('')
  store.reportCompletedEndDate[1]('')
  store.reportUser[1]('')
  store.reportCustomer[1]('')
  
  navigate(`/ReportsList/`)
}

const setDepData = (depData) => {
  store.reportDepartment[1](depData);
  if (depData !== '') {
    setDisableUserInputs(false)
  }
  if (depData === '') {
    setDisableUserInputs(true)
  }

}

    return (
      <div>
      {/* { (store.newLoadFromFile[0] && !!store.clientIDerror[0] && !validateRole({isCustomer: true})) ?  */}
      { 
    <Container  fluid="lg" className=" mt-4 semi-fluid-container" style={{maxWidth: '800px'}}>
            <div className="floating-box-header-newload">

        <div style={{ flexGrow: 1 }}></div>
      </div>
    <section className="floating-box-newload" style={{paddingTop: '1.3rem'}}>
    <span  style={{color:"#0e2554" , fontSize:"1.8rem", marginLeft:"20px"}}>{reportName}</span>
      <form onSubmit={handleSubmit} id="newload">
        <div>
          <div id="reporting-form" className='mt-2'>
            {/* <section className="inputs-group">
              <span className="section-title">Selection</span>

              <Select
            label="Reports"
            name="reportId"
            // data={store.reportSelected[0]}
            data={store.reportSelected[0]}
            setData={store.reportSelected[1]}
            // onChangeAsObject={(data) => changeReports(data)}
            options={store.reportOptions[0]}
            filter={1}
            required
              />
            </section> */}
        {/* {enableCreated &&             <section className="report-inputs-group">
              <span className="report-section-title">Created Date</span>
              <div className="mx-2">
              <Input label="From" 
            type='date' 
            name='createdstartdate' 
            // id='date' 
            value={store.reportCreatedStartDate[0]} 
            onChange={(e) => store.reportCreatedStartDate[1](e.target.value)} 
            disabled={disableCreatedDateInputs}
             />
            </div>
            <div className="mx-2">
            <Input label="To" 
            type='date' 
            name='createdenddate' 
            // id='date' 
            value={store.reportCreatedEndDate[0]} 
            onChange={(e) => store.reportCreatedEndDate[1](e.target.value)} 
            disabled={disableCreatedDateInputs}
            
          />
          </div>


            </section>} */}

            {enableCreated && 
            
            /* <span className="section-title">Identification</span>
            <Select
          label="Department"
          name="departmentId"
          data={store.reportDepartment[0]}
          setData={(data) => {setDepData(data)}}

          // onChangeAsObject={(data) => changeDepartment(data)}
          options={store.rolesOptions[0]}
          filter={1}
          disabled={disableDepInputs}
          required
        />

          <Select
          label="User"
          name="User"
          data={store.reportUser[0]}
          setData={store.reportUser[1]}
          // onChangeAsObject={(data) => changeUser(data)}
          options={store.userOptions[0]}
          filter={1}
          disabled={disableUserInputs}
          required
        /> */
        <div className="report-inputs-group">
           <section className="report-section-title">
          <span className="">Created&nbsp;Date </span>
          </section>
          <section className="report-inputs-group-params">
          <div className="mx-2">
          <Input label="From" 
            type='date' 
            name='createdstartdate' 
            // id='date' 
            value={store.reportCreatedStartDate[0]} 
            onChange={(e) => store.reportCreatedStartDate[1](e.target.value)} 
            disabled={disableCreatedDateInputs}
            reportStyle={true}
           />
           </div>
           <div className="mx-2">
          <Input label="To" 
            type='date' 
            name='createdenddate' 
            // id='date' 
            value={store.reportCreatedEndDate[0]} 
            onChange={(e) => store.reportCreatedEndDate[1](e.target.value)} 
            disabled={disableCreatedDateInputs}
            reportStyle={true}
          
        />
        </div>
            </section>
            </div>
            }

            {enableCompleted && 
            
              /* <span className="section-title">Identification</span>
              <Select
            label="Department"
            name="departmentId"
            data={store.reportDepartment[0]}
            setData={(data) => {setDepData(data)}}

            // onChangeAsObject={(data) => changeDepartment(data)}
            options={store.rolesOptions[0]}
            filter={1}
            disabled={disableDepInputs}
            required
          />

            <Select
            label="User"
            name="User"
            data={store.reportUser[0]}
            setData={store.reportUser[1]}
            // onChangeAsObject={(data) => changeUser(data)}
            options={store.userOptions[0]}
            filter={1}
            disabled={disableUserInputs}
            required
          /> */
          <div className="report-inputs-group">
             <section className="report-section-title">
            <span className="">Completed&nbsp;Date </span>
            </section>
            <section className="report-inputs-group-params">
            <div className="mx-2">
            <Input label="From" 
            type='date' 
            name='completedstartdate' 
            // id='date' 
            value={store.reportCompletedStartDate[0]} 
            onChange={(e) => store.reportCompletedStartDate[1](e.target.value)} 
            disabled={disableCompletedDateInputs}
            reportStyle={true}
             />
             </div>
             <div className="mx-2">
            <Input label="To" 
            type='date' 
            name='completedenddate' 
            // id='date' 
            value={store.reportCompletedEndDate[0]} 
            onChange={(e) => store.reportCompletedEndDate[1](e.target.value)} 
            disabled={disableCompletedDateInputs}
            reportStyle={true}
            
          />
          </div>
              </section>
              </div>
              }
              {customerCall && 
              <div className="report-inputs-group">
              <section className="report-section-title">
             <span className="">Customer&nbsp;Selection </span>
             </section>
             <section className="report-inputs-group-params">
             {/* <div className="mx-2">
             <Input label="From" 
             type='date' 
             name='completedstartdate' 
             // id='date' 
             value={store.reportCompletedStartDate[0]} 
             onChange={(e) => store.reportCompletedStartDate[1](e.target.value)} 
             disabled={disableCompletedDateInputs}
              />
              </div> */}
              <div className="mx-2 w-100">
              <Select
            label="User"
            name="User"
            data={store.reportCustomer[0]}
            setData={store.reportCustomer[1]}
            // onChangeAsObject={(data) => changeUser(data)}
            options={store.reportCustomerDropdown[0]}
            // filter={1}
            disabled={disableCustInputs}
            reportStyle={true}
          />
           </div>
               </section>
               </div>}

              {/* <section className="inputs-group">

<div className="flex-grow-1" />

</section> */}

            {/* {store.details[0][0].Description} */}
          </div>
          <div className="d-flex justify-content-between">
          
        
    
            <button type="button" class="stepper-button" onClick={backToSelection}  style={{ marginBottom: "28px",  marginLeft:"28px", backgroundColor: "#006c63", color: "#f3f4f6"  }}>
              Back
            </button>
  
        <button
  className="stepper-button yellow"
  type="submit"
  style={{ justifySelf: "end", marginBottom: "28px", marginRight:"28px"}} 
  disabled={
    !disableCreatedDateInputs && store.reportCreatedStartDate[0] == '' && store.reportCreatedEndDate[0] !== '' || 
    !disableCreatedDateInputs && store.reportCreatedEndDate[0] == '' && store.reportCreatedStartDate[0] !== '' || 

    !disableCompletedDateInputs && store.reportCompletedStartDate[0] == '' && store.reportCompletedEndDate[0] !== '' || 
    !disableCompletedDateInputs && store.reportCompletedEndDate[0] == '' && store.reportCompletedStartDate[0] !== '' || 

    !disableCompletedDateInputs && disableCreatedDateInputs && (store.reportCompletedEndDate[0] == '' || store.reportCompletedStartDate[0] == '')  ||

    !disableCompletedDateInputs && !disableCreatedDateInputs && 
    store.reportCompletedEndDate[0] == '' && store.reportCompletedStartDate[0] == '' 
    && store.reportCreatedEndDate[0] == '' && store.reportCreatedStartDate[0] == '' || 

    !disableDepInputs && store.reportDepartment[0] == '' ||
    !disableUserInputs && store.reportUser[0] == '' || 
    store.reportSelected[0] == false
    ? true : false
}
>
  Results
</button></div>
        </div>
      </form>
      </section>
      </Container>
      }

  </div>
) 
  };