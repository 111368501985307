import { Modal } from "react-bootstrap";
import "./controlledgoodsmodal.css";
import { Input } from "./Input/Input.js";
import { StoreContext } from './Store';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import isoDateConverter from "./isoDateConverter";


export const ArrivalConfirmation = ({ item, subTitle, animation, size, show, onHide, showButtons, onConfirm, header, defaultDate }) => {
  const store = useContext(StoreContext);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    store.portArrivalDate[1](defaultDate)
  }, [defaultDate])

const validateDateTime = () => {

  const nowDT = new Date();
  const submittedDT = (new Date(item.createdDate));
  const selectedDT = (new Date(`${store.portArrivalDate[0]} ${store.portArrivalTime[0]}`));
  
  if (isNaN(selectedDT)) {
    setErrorText("Please select a valid date and time.");
    return false;
  }
  
  if (selectedDT < submittedDT) {
    setErrorText('Port arrival time must be after submission time.')
    return false;
  }

  if (selectedDT > nowDT) {
    setErrorText('Port arrival time cannot be in the future.')
    return false;
  }
  
  return true;
};

const tz = useMemo(() => {
  let mins = -(new Date()).getTimezoneOffset();
  const isNegative = mins < 0;
  mins = Math.abs(mins);
  let hours = Math.floor(mins / 60);
  mins = mins % 60;
  
  return `GMT${isNegative ? '-' : '+'}${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
}, []);

  return (
    <Modal centered size={size} animation={animation} show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        />
        
      </Modal.Header>

<section>
       
<Modal.Body>
   
<span>{subTitle}</span>
<br />
<small>Please enter the below date in your <span class="boldblue">local timezone</span> ({tz}).</small>
<div className="sub-heading arrival-error">{errorText}</div>



<div className="sub-heading">Load Submitted: {isoDateConverter(item?.createdDate)}</div>
      
<div class='class="d-flex flex-row py-3 px-3 gap-3 '>
<Input
                  label={"Port Arrival Date"}
                  name="date"
                  type="date"
                  required
                  value={store.portArrivalDate[0]}
                  onChange={(e) => store.portArrivalDate[1](e.target.value)}
                />

<Input
                  label={"Port Arrival Time"}
                  name="time"
                  type="time"
                  required
                  value={store.portArrivalTime[0]}
                  onChange={(e) => store.portArrivalTime[1](e.target.value)}
                />
  
</div>
   
      </Modal.Body>
      
      <Modal.Footer
        class="py-3 px-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

<div className='push-left-right'>
        <button 
          className="blue-button-newload red" onClick={(event) => {
          
            onHide(event);
            setErrorText('')
          }}
          style={{ display: (showButtons ? "flex" : "none"), borderRadius: "10px" }} > Back
        </button>
        <button
          className="blue-button-newload green"
          onClick={(event) => {
            setErrorText('')

            if (validateDateTime()) {
              onConfirm();
              onHide(event);
            }
          
          }}
          style={{ display: (showButtons ? "flex" : "none"), borderRadius: "10px" }} 
        >Submit
        </button>
        </div>
      </Modal.Footer>
      </section>
    </Modal>
  );
};

export default ArrivalConfirmation;

