import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Input } from "./Input/Input.js";
import "./login.css";
import 'whatwg-fetch';
import { putPasswordreset } from './apiCalls';
import { Italic } from '@styled-icons/fa-solid';

const ConfirmReset = () => {

  const [params, setParams] = useState(useParams())
  const [passwordOne, setPasswordOne] = useState("")
  const [passwordTwo, setPasswordTwo] = useState("")
  const [passwordText, setPasswordText] = useState("")
  const navigate = useNavigate()

  const validatePassword = () =>{
    if (passwordOne !== passwordTwo) {
     setPasswordText("Passwords do not match")
     setPasswordOne('')
     setPasswordTwo('')
     return false;
    }
    if (passwordOne.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$") == null) {
      setPasswordText("Password must be minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character from (@ $ ! % * ? &)")
      return false;
     }
    return true;
  }
  
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      if (validatePassword()) {
        const data = await putPasswordreset(event.target)
        if (data.message === 'Password used previously.') {
          setPasswordText(data.message)
          return false
        }
        else {
          if (data[0].completed === 'Token is invalid or has expired') {
            setPasswordText('We are sorry but we were unable to reset your password.  Please note that you must complete the process within one hour of receiving our password reset email.')
            navigate("/login");
          }
          if (data[0].completed === 'Password reset') {
            navigate("/login");
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
 }

  return (
    <div className="full-width-container ">
      <div className="floating-box login-container">
        <div className="floating-box-header">
          <span>Create Password</span>
        </div>
        <div className="sub-heading">Create a new password for your existing 
        account.  Password must be minimum 8 characters, at least one uppercase letter, 
        one lowercase letter, one number and one special character from (@ $ ! % * ? &).</div>
        <div className="sub-heading password-error2">{passwordText}</div>
        <form onSubmit={handleSubmit} id='login-contents'>
        <Input label="Choose a password" 
            type='password' 
            name='password' 
            id='password' 
            value={passwordOne} 
            onChange={e => setPasswordOne(e.target.value)} required />
        <Input label="Confirm password" 
            type='password' 
            name='confirm' 
            id='confirm' 
            value={passwordTwo} 
            onChange={e => setPasswordTwo(e.target.value)} required />
           <input id="token" type="hidden" name="token" value={params.token} />
           <div className='push-left-right'>
              <button type="submit" className="blue-button">Continue</button>
           </div>
        </form>
      </div>
    </div>
  );
}




export default ConfirmReset
