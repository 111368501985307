import "./login.css";

const PasswordResetSent = () => {

  return (
    <div className="full-width-container ">
      <div className="floating-box login-container mb-4">
        <div className="floating-box-header push-center">
          <span className='mt-1'>Reset Requested</span>
        </div>
        <span className='completed positions'></span>
        <div className="flex-d sub-heading">Password Reset has been requested. Please now check your email inbox/spam folder for reset email link. You can close this browser screen as the email link will redirect to us.</div>
      </div>
    </div>
  );
}

export default PasswordResetSent

