import customer from "../../images/customer.png";
import { StoreContext } from "../Store";
import { useContext, useState, useEffect } from "react";
import { getCM8Details, getNewLoad } from "../apiCalls";
import DataTable from "../DataTable";
import { PlusCircle } from '@styled-icons/fa-solid'
import checkmark from '../../images/checkmark.png'
import {useNavigate} from 'react-router-dom';

export default function CM8Clients() {
   const store = useContext(StoreContext);
   const [value, setValue] = useState(null);
   const navigate = useNavigate();
   
   useEffect(() => {
    const fetch = async () => {
        const data = await getNewLoad('nbpList');
        store.clientIDoptions[1](data);
    }
      fetch();
   }, [])

   const submit = async () => {
    store.clientID[1](value);
    const data = await getCM8Details(value);
    //On data, check the keys that contains the word 'Valid' and check if they are all true. if that's the case, navigate to 'CM8CreateNewLoad/:id'
    const valid = Object.keys(data).filter(key => key.includes('Valid')).every(key => data[key]);
    if (valid) {
      navigate(`/CM8CreateNewLoad/${value}`);  
    }
    //otherwise, navigate to 'CM8CustomerValidation/:id'
    else 
      navigate(`/CM8CustomerValidation/${value}`);
};

  return (
    <>
      <div className="semi-fluid-container container-xl " style={{ maxWidth: '950px', marginTop: "4rem" }}>
        <div className='floating-box-newload'>
          <div className="d-flex flex-column py-4 px-5" >
              <div className='epo-steps-header'>
                  <span>Select a Customer</span>
                  {/* <p style={{fontSize: "1rem", marginTop: "0.3rem"}} >Select a customer</p> */}
              </div>
              <div className="step-banner with-img"  style={{ minHeight: '0' }}>
                <img src={customer} width="70" height="70" alt="port" />
                <p>Please select the customer from the list below you wish to link to this new load</p>
              </div>
              <div className="d-flex flex-column justify-content-md-start py-1 ">
                <DataTable dontRefreshPagination sortable filterable data={store.clientIDoptions[0] ?? []} className="table-bordered">
                  <DataTable.Pagination itemsPerPage={[5, 10, 20]} />
                  <DataTable.Headers style={{fontSize: '11px'}} />
                  <DataTable.Row className={item => {
                    if (item.id === value) {
                      return 'clientID-selected-row'
                    }
                    return ''
                  }} style={{fontSize: '16px'}} />
                  <DataTable.Column colKey="companyName" header="Companies" />
                  <DataTable.Column colKey='eori' header="EORI" />

                  <DataTable.Column header="Select" style={{height: '1px', padding: 0}} headerStyle={{textAlign: 'center'}}>
                    {query => (
                      <button className="external-table-link" onClick={() => {setValue(query.id) }}>
                        {query.id !== value ? <PlusCircle width="1.1rem" height="1.1rem" />
                                                : <img src={checkmark} width="20px" alt="check" />}
                      </button>
                    )}
                  </DataTable.Column>
                </DataTable>
              </div>
              <section className="d-flex justify-content-end mt-2">
                <button type="button" className="blue-button-teams" style={{ width: "auto", padding: "0.25rem 1.45rem" }} disabled={!value} onClick={submit}>
                  Continue
                </button>
              </section>
            </div>
          </div>
        </div>
    </>)
}