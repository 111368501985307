import { useMemo } from 'react';
import { Container} from 'react-bootstrap'
import DataTable from '../DataTable';


function TraderDetails({trader, back}) {

    const traderRows = useMemo(() => {
        const result = [];

        if (trader.children?.length > 0) {

              result.push(...trader.children);

          return result;
        }
        return result;
      }, [trader]);

  return (
    <Container className="full-width-container">
    <section className='floating-box' style={{maxWidth: '1530px'}}>
      <div className="floating-box-header-general">
      <span>Associated LSPs<span style={{ fontSize: "1.0rem", fontStyle: "italic" }}> </span></span>
      </div>
      <div className='px-3 pt-4'>

    <DataTable filterable sortable data={traderRows} className="table-striped">
    <DataTable.Pagination itemsPerPage={[10, 25, 50]} areaPadding={'0px'} />
    <DataTable.Headers style={{fontSize: "14.4px", backgroundColor: '#fff', paddingBottom: '10px', borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd'}} />
    <DataTable.Column colKey="companyName" header="Company Name" headerStyle={{ whitespace: "nowrap", minWidth: "19.1ch" }} >

    </DataTable.Column>
    <DataTable.Column colKey="firstName" header="First Name" headerStyle={{ whitespace: "nowrap", minWidth: "15ch" }} >


    </DataTable.Column>
    <DataTable.Column colKey="lastName" header="Last Name" headerStyle={{ whitespace: "nowrap", minWidth: "14.6ch" }} >


    </DataTable.Column>
    <DataTable.Column colKey="companyPosition" header="Position" >


    </DataTable.Column>
    <DataTable.Column colKey="building" header="Building" >


    </DataTable.Column>
    <DataTable.Column colKey="town" header="Town" >


    </DataTable.Column>
    <DataTable.Column colKey="county" header="County" >


    </DataTable.Column>
    <DataTable.Column colKey="postcode" header="Postcode" >


    </DataTable.Column>
    <DataTable.Column colKey="country" header="Country" >


    </DataTable.Column>

</DataTable>

</div>
<section className="d-flex flex-row py-3 px-3 mt-4 gap-3 border-top align-items-center">
        <button type="button" className="stepper-button green" onClick={back}>
          Back
        </button>

      </section>
</section>

</Container>
  )
}

export default TraderDetails;
