import { useEffect, useState, useRef, useContext } from "react";
import Stepper, { useStepper } from "../../Stepper";

import customer from "../../../images/customer.png";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";

import DataTable from "../../DataTable";
import { PlusCircle } from '@styled-icons/fa-solid'
import checkmark from '../../../images/checkmark.png'

import { getLspTradersIDs, getDDAStatus } from "../../apiCalls";
import { StoreContext } from "../../Store";

export function LspPage({ DDAStatus, showTrader }) {
  const { stepForward, localData, setDataByKey, activeStepKey, setStepListVisible } = useStepper();

  const store = useContext(StoreContext);

  const nextPage = () => {
    stepForward();
    showTrader(localData.trader);
  };

  useEffect(() => {   

    if (["1. LSP", "confirmation", "congrats"].includes(activeStepKey)) {
      store.epoProgress[1](false) 
      setStepListVisible(false);
    }
    else {
      store.epoProgress[1](true)
      setStepListVisible(true);
    }
   
  }, [activeStepKey])

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)

  useEffect(() => {
    const fetch = async () => {
        const data = await getLspTradersIDs();
        setDataByKey('lsps', data)
    }
    fetch();
  }, [])
  
  useEffect(() => {
    async function fetchDDAStatus() {      
      const data = await getDDAStatus(localData?.lsp);
      if(data && data[0]){       
        DDAStatus(data[0].DDA)
      }
    }
    fetchDDAStatus()
  }, [localData?.lsp]);
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "1. LSP" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "1. LSP",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])


  return (
    <Stepper.Step stepKey="1. LSP" text="LSP" hideFromStepList>
      <div>
        <div className={`epo-steps-header ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`} style={{marginBottom: '15px'}}>Select Trader</div>
        <div style={{borderRadius: '0.9rem', minHeight: '485px'}} className="px-2 bg-white d-flex flex-column">
        <div className="step-banner with-img" style={{minHeight: '0'}}>
           <img src={customer} width="60" height="60" alt="port" />
           <p style={{color: '#0e2554'}}>Select a Trader to create an EXPRESS Load</p>
        </div>
        <div className="d-flex flex-column justify-content-md-start py-1 mt-auto ">
                <DataTable dontRefreshPagination sortable filterable data={localData.lsps ?? []} className="table-bordered">
                  <DataTable.Pagination itemsPerPage={[5, 10, 20]} />
                  <DataTable.Headers style={{fontSize: '11px'}} />
                  <DataTable.Row className={item => {
                    if (item.id === localData.lsp) {
                      return 'clientID-selected-row'
                    }
                    return ''
                  }} style={{fontSize: '16px'}} />
                  <DataTable.Column colKey="companyName" header="Trader" />
                  <DataTable.Column colKey='eori' header="EORI Number" />

                  <DataTable.Column header="Select" style={{height: '1px', padding: 0}} headerStyle={{textAlign: 'center'}}>
                    {query => (
                      <button className="external-table-link"
                      onClick={() => {
                      setDataByKey('lsp', query.id);
                      setDataByKey('trader', query.companyName)}
                     }>
                        {query.id !== localData.lsp ? <PlusCircle width="1.1rem" height="1.1rem" />
                                                : <img src={checkmark} width="20px" alt="check" />}
                      </button>
                    )}
                  </DataTable.Column>
                </DataTable>
              </div>

      <section className="d-flex justify-content-end  px-3 mt-auto pb-4">
        <button type="button" className="stepper-button" disabled={!localData.lsp} onClick={nextPage}>
          Create
        </button>
      </section>
      </div>
      </div>
    </Stepper.Step>
  );
}
