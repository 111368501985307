import React, { useContext, useState, useEffect } from 'react';
import "./newLoad.css";
import FileLoader from '../fileUpload/FileLoader.js';
import "./newloadfileupload.css"
import information from '../../images/information.svg'
import { StoreContext } from "../Store";

const NewLoadOptions = () => {

    const store = useContext(StoreContext);

    useEffect(() => {
		store.resetNewLoad();
	}, []);

    return (
        <div className="semi-fluid-container container-xl file-upload" style={{ maxWidth: '700px', marginTop: '4rem' }}>
            <div className='floating-box-newload'>
                <div className='floating-box-header-newload'>
                    <span>Submit load file</span>
                </div>
                <div id="load-info-form" className='fileloader-header'>
                    <div className="inputs-group">
                        <span className='more-info'><img src={information} height="" width="30" alt="info" />&nbsp;&nbsp;
						<span>
                            {store.infoText[0]}
                            </span>
                            </span>
                    </div>
                    <div className="inputs-group">
                        <FileLoader />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewLoadOptions;