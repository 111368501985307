/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react';
import "./home.css";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { StoreContext } from "./Store";
import HomeModal from "./HomeModal";

const Home = () => {
  const store = useContext(StoreContext);

  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);
  const [src, setSrc] = useState('');
  const [size, setSize] = useState('');

  const renderMainPageText = () => {
    return (
      <div className="middle-left-header" style={store.showAndFormatMainPageText[0] === true ? null : store.showAndFormatMainPageText[0]}>
         Customs And Borders Import Export  
             <li className="sub-line line-one">Knowing the shortest and quickest option is what sets us apart</li>       
             <li className="sub-line line-two">Click-click-clear a consignment with minimal effort</li>
         </div>
    )
  }

  const handleResetPage = () => {
  };

  const handleNavLinkClick = link => {
      try { switch (link) {
        case "rt":
          setSrc(  <div className="d-flex py-4 flex-row space-evenly">
          <iframe src="https://embed.waze.com/iframe?zoom=9&lat=51.2208&lon=000.6803" width="100%" height="615"></iframe>
           </div>);
           setSize('m')
          setShowConfirmResetModal(true);
          break
        case "bs":
          setSrc( <div className="d-flex py-4 flex-row">
          <iframe src="https://live.sixfold.com/" height="615" width="1500" allowFullScreen={true} sandbox='allow-scripts allow-same-origin'></iframe>
          </div>);
           setSize('m')
          setShowConfirmResetModal(true);
        break
        case "mt":
          setSrc(<iframe name="marinetraffic" id="marinetraffic" width="100%" height="615" scrolling="no" frameborder="1" src="https://www.marinetraffic.com/en/ais/embed/zoom:8/centery:51.1208/centerx:001.3403001.3403/maptype:1/shownames:false/mmsi:0/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:/remember:false"></iframe>);
          setSize('m')
          setShowConfirmResetModal(true);
          break
        case "wr":
          setSrc(   <iframe width="100%" height="615" src="https://embed.windy.com/embed2.html?lat=52.455&lon=-1.864&detailLat=52.455&detailLon=-1.864&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=true&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1" frameborder="0"></iframe>);
          setSize('m')
          setShowConfirmResetModal(true);
          break
        case "ms":
          setSrc( <iframe src="https://trafficcameras.uk/map?zoom=7&amp;x=5.2208&amp;y=000.6803;" title="Windy Weather Map"  width="100%" height="615"></iframe>);
          setSize('m')
          setShowConfirmResetModal(true);
          break
        case "tr":
          setSrc(
          <div className="d-flex py-4 flex-row space-evenly">
              <TwitterTimelineEmbed onLoad={function noRefCheck(){}} sourceType="profile" screenName="DFDSChnlFreight"  tweetLimit="3" options={{height: 620, width: 235, }}/>
              <TwitterTimelineEmbed onLoad={function noRefCheck(){}} sourceType="profile" screenName="POFerriesFR8" tweetLimit="3" options={{height: 620, width: 235}}/>
              <TwitterTimelineEmbed onLoad={function noRefCheck(){}} sourceType="profile" screenName="TruckShuttle" tweetLimit="3" options={{height: 620, width: 235}}/>
              {/* <TwitterTimelineEmbed onLoad={function noRefCheck(){}} sourceType="profile" screenName="TransInfoENG1" tweetLimit="3" options={{height: 620, width: 235}}/> */}
              <TwitterTimelineEmbed onLoad={function noRefCheck(){}} sourceType="profile" screenName="StenaLineUKIE" tweetLimit="3" options={{height: 620, width: 235}}/>
          </div>);
           setSize('xl')
          setShowConfirmResetModal(true);
          break
        case "ws":
          setSrc(
                <div id="home-info-imgs" className='fileloader-header'> 
                <iframe className='position' src="https://www.youtube.com/embed/o24mrcd2szE" title="YouTube video player" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen"
                >                                                
                </iframe>  
                <iframe className='position' src="https://www.youtube.com/embed/4TKJH-BRDrI" title="YouTube video player" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen"
                >                                                
                </iframe>
                <iframe className='position' src="https://www.youtube.com/embed/8KEtemZSa8Y" title="YouTube video player" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen">
                </iframe>
                <iframe className='position' src="https://www.youtube.com/embed/ZTmWU9UJFkI" title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen">                                                    
                </iframe>
                <iframe className='position' src="https://www.youtube.com/embed/LYmA8fUQQEc" title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen">                                                     
                </iframe> 
            </div>);
          setShowConfirmResetModal(true);
          setSize('xl')
          break

          default:
            // setSrc(   <iframe src="https://embed.windy.com/embed2.html?lat=50.831;lon=-0.273;zoom=6&amp;level=surface&amp;overlay=wind&amp;menu=&amp;message=&amp;marker=&amp;calendar=&amp;pressure=&amp;type=map&amp;location=coordinates&amp;detail=&amp;detailLat=50.831&amp;detailLon=-0.273&amp;metricWind=default&amp;metricTemp=default&amp;radarRange=-1" title="Windy Weather Map"  width="100%" height="615"></iframe>);
            // setShowConfirmResetModal(true);
          break
        }
      } catch (err) {
    }
};

  return (


<div className="full-page-container sub-menu">
<div class="d-flex justify-content-center sub-menu">
  <div class=" mt-4 ml-2">
        <span class="sub-menu" onClick={() => handleNavLinkClick('rt')}>Road Traffic</span>
        <span class="sub-menu" onClick={() => handleNavLinkClick('bs')}>Border-Sea Crossing Times</span>
        <span class="sub-menu" onClick={() => handleNavLinkClick('mt')}>Marine-Traffic</span>
        <span class="sub-menu" onClick={() => handleNavLinkClick('wr')}>Weather</span>
        <span class="sub-menu" onClick={() => handleNavLinkClick('ms')}>UK Motorways</span>
        {/* <span class="sub-menu" onClick={() => handleNavLinkClick('tr')}>Twitter</span> */}
        {(store.showWebinars[0]) &&
        <span class=""  onClick={() => handleNavLinkClick('ws')}>Webinars</span>
        }
     </div> 

     {(store.showAndFormatMainPageText[0]) &&
        renderMainPageText()
      }

</div>

    <HomeModal
            hideHeader={true}
            show={showConfirmResetModal}
            onHide={() => {
              setShowConfirmResetModal(false);
              setSrc('');
            }}
            onConfirm={handleResetPage}
            animation={false}
            size={size}
            centered={true}
            src={src}
            hideCancel={true}
          >
    </HomeModal>

</div>

  )
}

export default Home;



