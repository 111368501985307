import { Question } from "@styled-icons/fa-solid";
import { OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";

export default function HelpWidget () {
  return (
    <div className="">
      <HelpOverlay>
        <button className="help-widget">
          <Question style={{width: '1.5rem', height: '1.5rem'}} />
        </button>
      </HelpOverlay>
    </div>
  )
}


const helpPopover = (

<Popover style={{ maxWidth: '300px' }}>
  <PopoverContent>
    <h6>Need some help?</h6>
    <div className="mb-2">If you spot something not quite right, or you need help with the site - feel free to contact us using the details below.</div>

    <div className="bold text-popup text-nowrap">P: +44 (0) 333 012 4819</div>
    <div className="bold text-popup mb-2">E: <a className="bold text-popup" href="mailto:CABIEsupport@eori.uk?subject=CABIE Issue">CABIEsupport@eori.uk</a></div>
  </PopoverContent>
</Popover>);

const HelpOverlay = (props) => (
  <OverlayTrigger rootClose trigger="click" placement="left-end" overlay={helpPopover}>
    {props.children}
  </OverlayTrigger>
);