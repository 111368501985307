import React, { useState, useEffect, useContext} from "react";
import {Table, Dropdown } from "react-bootstrap";
import { EllipsisV } from "@styled-icons/fa-solid";
import { getCompanyLoad, deleteCompanyLoad } from '../../apiCalls';
import LoadModal from "./loadModal";
import ConfirmationModal from "../../ConfirmationModal";
import { RefDataContext } from "../Maintenance";

const Load = () => {
  const [load, setLoad] = useState([{ "": "" }])
  const [selectedLoad, setSelectedLoad] = useState("");

  const {setOnAddNew} = useContext(RefDataContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCompanyLoad();
        setLoad(data);
      } catch (err) {
      }
    }
    fetchData();
    setOnAddNew(() => {
      setSelectedLoad({id: -1})
    });
  }, []);

return (<>
<LoadModal load={selectedLoad} onSave={ async () => {
  const data = await getCompanyLoad();
  setLoad(data);
}} onCancel={() => setSelectedLoad(null)} />

<Table className="p-4 rounded-3" striped>
          <thead className="bold fs-5" style={{ color: "rgb(80, 80, 80)" }}>
            <tr style={{display: "flex", justifyContent: "space-between" }}>
          <span className="text-nowrap" style={{ width: "0px" }}>Load</span>
            </tr>
          </thead>
          <tbody>
            {/* TODO:PAGINATE ROWS */}
            {(load).map((load) => ( 
              <React.Fragment key={load.client_id}>
                <LoadRow load={load} setSelectedLoad={setSelectedLoad} onDelete={ async () => {
  const data = await getCompanyLoad();
  setLoad(data);
}} />
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        </>)
}

function LoadRow ({load, setSelectedLoad, child, onDelete }) {
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);

  const handleDelete = async event => {
    try {
      const data = await deleteCompanyLoad(load.id)
      if (data[0].completed === 'deleted') {
        alert("Record Deleted");
        onDelete()
      }
    } catch (err) {
      console.log(err);
      alert('Error, unable to delete')
    }
  }

    return (
      <tr id={load.id} className={`border-top fs-6 ${child ? "" : "bold"}`}>
        <td>{load.type}</td>
        {/* <td>{load.id}</td> */}
        <td>
          <Dropdown className="w-100 h-100 d-grid center">
            <Dropdown.Toggle as="button" className="dot-menu-button">
              <EllipsisV width={"0.5rem"} />
            </Dropdown.Toggle>
  
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={() => setSelectedLoad(load)}>
                View/Edit
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setShowConfirmResetModal(true)}>
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ConfirmationModal
        header="Confirm Deletion"
        show={showConfirmResetModal}
        onHide={() => setShowConfirmResetModal(false)}
        onConfirm={handleDelete}
        annimation={true}
      >
        Are you sure you want to delete this record? 
      </ConfirmationModal>
        </td>
      </tr>
    )
  }

  export default Load; 