import React, { useState, useEffect, useContext } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { EllipsisV } from "@styled-icons/fa-solid";
import { Container } from "react-bootstrap";
import { getAdditionalFileNames, getAdditionalFileData, deleteAdditionalFileUpload } from '../apiCalls';
import ConfirmationModal from "../ConfirmationModal";
import { StoreContext } from "../Store";

const FileDisplay = () => {
  const [files, setFiles] = useState([{ "": "" }])
  const store = useContext(StoreContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAdditionalFileNames(store.newLoadID[0]);
        console.log("Data:" + data);
        setFiles(data);
      } catch (err) {
      }
    }
    fetchData();
  }, []);

return (    
        <Container fluid="sm" className=" mt-5 semi-fluid-container medium-middle-container ">
        <section className="floating-box-userProfile pb-2 px-4">
        <div className="floating-box-header-userProfile ps-0 ms-0">
          <span>Uploaded Files</span>
          <span className="flex-grow-1"></span>
        </div>
        <Table className="p-4 rounded-3" striped>
          <thead className="bold fs-5" style={{ color: "rgb(80, 80, 80)" }}>
            <tr>
              <th>Filename</th>
              <th> Type </th>
              <th className="text-nowrap" style={{ width: "0px" }}></th>
            </tr>
          </thead>
          <tbody>
            {/* TODO:PAGINATE ROWS */}
            {(files).map((file) => ( 
              <React.Fragment>
                <FileRow file={file} onDelete={ async () => {
 const data = await getAdditionalFileNames(store.newLoadID[0]);
  setFiles(data);
}}/>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        </section>
        </Container>)
}


function FileRow ({file, child, onDelete}) {
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);
  const [fileData, setFileData] = useState([{"":""}]);
  const [fileName, setFileName] = useState("");

  const handleDelete = async event => {
    try {
      const data = await deleteAdditionalFileUpload(file.id);
      onDelete()
    } catch (err) {
      console.log(err);
      alert('Error, unable to delete')
    }
  }

  async function clickLink() {
    if (file.id != undefined) {
      let blob = await getAdditionalFileData(file.id);
      console.log(blob);
      var fileURL = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = file.filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
      }, 0);
    }
  }

    return (
      <tr id={file.id} className={`border-top fs-6 ${child ? "" : "bold"}`}>
        <td><a href ="#" onClick={clickLink}>{file.filename}</a></td>
        <td>{file.documentType}</td>
        <td>
          <Dropdown className="w-100 h-100 d-grid center">
            <Dropdown.Toggle as="button" className="dot-menu-button">
              <EllipsisV width={"0.5rem"} />
            </Dropdown.Toggle>
  
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={() => setShowConfirmResetModal(true)}>
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ConfirmationModal
        header="Confirm Deletion"
        show={showConfirmResetModal}
        onHide={() => setShowConfirmResetModal(false)}
        onConfirm={handleDelete}
        annimation={true}
      >
        Are you sure you want to delete this record? 
      </ConfirmationModal>
        </td>
      </tr>
    )
  }

  export default FileDisplay; 