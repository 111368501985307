import DataTable from '../DataTable';
import { Container} from 'react-bootstrap'



function TraderList({loads}) {

  return (
    <Container className="full-width-container">
    <section className='floating-box' style={{maxWidth: '1530px'}}>
      <div className="floating-box-header-general">
        <span>Loads</span>
      </div>
      <div className='px-3 pt-4'>

    <DataTable filterable sortable data={loads} className="table-striped">
    <DataTable.Pagination itemsPerPage={[10, 25, 50]} areaPadding={'0px'} />
    <DataTable.Headers style={{fontSize: "12px", backgroundColor: '#fff', paddingBottom: '10px', borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd'}} />
    <DataTable.Row style={{fontSize: '14px'}} />
    <DataTable.Column colKey="cabieRef" header="CABIE Ref"/>
    <DataTable.Column colKey="trader" header="Trader"/>
    <DataTable.Column colKey="traderRef" header="Trader Ref" />
    <DataTable.Column colKey="arrivalDate" header="Arrival" >
    {item => {
       const date = new Date(item.arrivalDate).toLocaleDateString().toString();
          return date;
            }}
   </DataTable.Column>
    <DataTable.Column colKey="SFDMRN" header="SFD MRN" />
    <DataTable.Column colKey="SDIRRN" header="SDI MRN" />
</DataTable>
</div>

</section>
</Container>
  )
}

export default TraderList
