import { useEffect, useContext } from "react";
import eorilogo from '../images/EORI-COMPUTER-svg.svg'
import './footer.css';
import HelpWidget from './HelpWidget';
import { StoreContext } from "./Store";
import { useState } from 'react';

const LinkedInIcon = () => (
  <a href="https://www.linkedin.com/company/oaklandinvicta/posts/?feedView=all" target="_blank" rel="noreferrer">
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '4rem', padding: '0.25rem' }} fill="currentColor" viewBox="0 0 512 512"><path d="M417.2 64H96.8C79.3 64 64 76.6 64 93.9V415c0 17.4 15.3 32.9 32.8 32.9h320.3c17.6 0 30.8-15.6 30.8-32.9V93.9C448 76.6 434.7 64 417.2 64zM183 384h-55V213h55v171zm-25.6-197h-.4c-17.6 0-29-13.1-29-29.5 0-16.7 11.7-29.5 29.7-29.5s29 12.7 29.4 29.5c0 16.4-11.4 29.5-29.7 29.5zM384 384h-55v-93.5c0-22.4-8-37.7-27.9-37.7-15.2 0-24.2 10.3-28.2 20.3-1.5 3.6-1.9 8.5-1.9 13.5V384h-55V213h55v23.8c8-11.4 20.5-27.8 49.6-27.8 36.1 0 63.4 23.8 63.4 75.1V384z" /></svg>
  </a>
)

const TwitterIcon = () => (
  <a href="https://twitter.com/RobHardyFR8" target="_blank" rel="noreferrer">
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '4rem', padding: '0.5rem' }} fill="currentColor" viewBox="0 0 24 24" version="1.1">
      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
    </svg>
  </a>
)

const Footer = () => {
  const store = useContext(StoreContext);  

  const [wlpDarkBlockText, setWlpDarkBlockText]  = useState(false);

  useEffect(() => {
    try { switch (window.location.host) {

     //-- Test Only

      case "localhost":
        import('./footer-cb.css');
      break

      // case "localhost":
      //   import('./footer-tk.css');
      //   setWlpDarkBlockText(false);
      // break

      // ==================  D E V ==================================

      // DEV - CABIE
      case "dev.eoricustomtech.co.uk":
        import('./footer-cb.css');
      break

       // DEV - WLP - updated for TEK Freight
       case "wdev.eoricustomtech.co.uk":
        import('./footer-tk.css');
        setWlpDarkBlockText(false);
      break
      

      // ==================  U A T  ==================================

       // UAT WLP - updated for TEK Freight
       case "floraline.azurewebsites.net": // uat
        import('./footer-tk.css');
        setWlpDarkBlockText(false);
      break

      // UAT CABIE
      case "https://eori-portal-test.azurewebsites.net": // uat
      import('./footer-cb.css');   
      break

      //==================== P R O D ==================================
   
      // TEK Freight
      case "tekfreight.cabie.uk":
        import('./footer-tk.css');
        setWlpDarkBlockText(false);
      break

      // case "cabie-preprod.azurewebsites.net":    
      case "floralinc.cabie.uk":
        import('./footer-fl.css');
      break

      case "zelir.eoricustomtech.co.uk":
      case "zelir.cabie.uk":  
        import('./footer-zr.css');
      break

      case "lglworldwide.cabie.uk":
        import('./footer-lgl.css');
        break

      case "chillchain.cabie.uk":
          import('./footer-cc.css');
          break

      case "capitaltransport.cabie.uk":
          import('./footer-ct.css');
      break    

        case "theunisse-expeditie.cabie.uk":
          import('./footer-dl.css');
          setWlpDarkBlockText(true)
         break

         case "ponsonby.cabie.uk":
          import('./footer-pp.css');
         break

         case "alcaline.cabie.uk":
          import('./footer-al.css');
         break;

         case "complete3pl.cabie.uk":
          import('./footer-cp.css');
         break

         case "oakland-international.cabie.uk":
          import('./footer-oa.css');
          setWlpDarkBlockText(true);
        break;

      default:
        import('./footer-cb.css');  
      break
      
          }
    } catch (err) {
  } 
}, [])

  return (
    <footer className="footer footer-text">
      <a href="https://www.eori.uk/" target="_blank" rel="noreferrer" className='navbar-brand'>
        <img src={eorilogo} width="52" alt="EORI Logo" />
        <span  className={`logo-text mt-1 ${wlpDarkBlockText ? "dark-block-text" : "light-block-text"}`}>EORI (UK) LIMITED</span>
      </a>
      {/* <a href="#">Contact Us</a> */}
      <span />
      <a target="_blank" href="/assets/terms-and-conditions.pdf" className={`${wlpDarkBlockText ? "dark-block-text" : "light-block-text"}`}>Terms and Conditions</a>
      <span />
      <span className={`${wlpDarkBlockText ? "dark-block-text" : "light-block-text"}`}>CABIE Release: {process.env.REACT_APP_VERSION}</span>
      {/* <a href="#">Cookies Settings</a> */}
      <span />
      <a target="_blank" href="https://www.eori.uk/privacy-policy" className={`${wlpDarkBlockText ? "dark-block-text" : "light-block-text"}`}>Privacy Notice</a>
      <span />
      {/* balancing elements so icons are centered */}
      <HelpWidget />
    </footer>
  );
}

export default Footer;
