import { useEffect, useState, useRef, useContext } from "react";
import Stepper, { useStepper } from "../../Stepper";

import euflag from "../assets/europe-flag.jpg";
import ukflag from "../assets/uk-flag.jpg";
import question from "../assets/question.png";
import dots from "../../../images/3dots.svg";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";

export function DirectionPage() {
  const { stepBackward, stepForward, localData, setDataByKey, activeStepKey } = useStepper();


  const handleChange = (e) => {
    setDataByKey("direction", e.target.value);
  };

  const nextPage = () => {
    if (localData.direction === "yes") {
      stepForward();
      return;
    }
    localData.openRedirectmodal({
      title: "Non EU - GB",
      body: (<>
        <p>This initial version of CABIE is set for EU to GB movements only. Other digital routes will be included in future releases. If your movement is not EU to GB please click <a target="_blank" rel="noreferrer" href="https://www.eori.uk/Customs_Entry">HERE</a> for data capture options.</p>
        <p>For more details please contact us below
          <div className="bold text-eoriblue text-nowrap ms-2 mt-1">P: +44 (0) 333 012 4819</div>
          <div className="bold text-eoriblue ms-2">E: <a className="bold text-eoriblue" href="mailto:cabiesupport@eori.uk?subject=CABIE Issue">CABIEsupport@eori.uk</a></div>
        </p>
    </>)
    });
  };

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "2. Direction" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "2. Direction",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])


  return (
    <Stepper.Step stepKey="2. Direction" text="Type of Movement">
      <div>
        <span className="epo-steps-header">Type of Movement</span>
        <div className="step-banner d-flex mt-4 mb-2 py-4 flex-row justify-content-center flags align-items-center">
          <img src={euflag} alt="" />
          <img src={dots} alt="" width="30" style={{opacity: '60%'}} />
          <img src={ukflag} alt="" />
        </div>

        <div className="d-flex flex-column mb-3 px-5 py-1 ">
          <div className="d-flex flex-row space-start py-3">
            <span className="d-inline-flex justify-content-center w-100 px-2">Is this an EU (Mainland) to GB Import?</span>
          </div>

          <label className="d-inline-flex align-items-center justify-content-center gap-2 my-2 px-4">
            <input type="radio" name="direction" value="yes" checked={localData.direction === "yes"} onChange={handleChange} />
            <span>Yes</span>
          </label>

          <label className="d-inline-flex align-items-center justify-content-center gap-2 px-4">
            <input type="radio" name="direction" value="no" checked={localData.direction === "no"} onChange={handleChange} />
            <span>No</span>
          </label>
        </div>
      </div>
      <section className="d-flex justify-content-between mt-2">
        <div>
        </div>
        <button type="button" className="stepper-button" onClick={nextPage}>
          Next
        </button>
      </section>
    </Stepper.Step>
  );
}
