import React, { useState, useEffect, useContext } from 'react';
import { Container, OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { Input, Select, TextArea } from "../Input/Input.js";
import { getCompanyNameFromId } from "../apiCalls";
import { StoreContext } from "../Store";
import { Trash } from "@styled-icons/fa-solid"
import CustomaiteCustomerValidation from "./CustomaiteCustomerValidation";

const CustomaiteCreateNewLoad = () => {
    const store = useContext(StoreContext);
    const [disableInputs, setDisableInputs] = useState(true);
    const [error, setError] = useState('');
    const [documents, setDocuments] = useState([]);
    const [additionalInformation, setAdditionalInformation] = useState('');

    const handleChange = (e) => {
      const inputValue = e.target.value;
      store.invoicenumber[1](inputValue);
      if (inputValue.length > 35) {
        setError('Cannot exceed 35 characters.');
      } else {
        setError('');
      }
    };

    const handleGetDocuments = () => {
      alert("Get documents from JIRA");
    };
  
    const handleDisableButton = () => {
      return false;
    }
  
    const cancelAction = () => {
      alert("cancelAction");
    };
  
    const submitLoadToCustomaite = () => {
      alert("submitLoadToCustomaite");
    };

    const addFiles = (files) => {
      for (const f of files) {
        //console.log('f', f)
        if (f.name.slice(-3).toLowerCase() === "xls" || f.name.slice(-3).toLowerCase() === "pdf" ||
          f.name.slice(-3).toLowerCase() === "png" || f.name.slice(-3).toLowerCase() === "jpg" ||
          f.name.slice(-4).toLowerCase() === "jpeg" || f.name.slice(-4).toLowerCase() === "xlsx") {
            const newDocument = {
              id: documents.length + 1,
              isSelected: false,
              "Name": f.name,
              "DocType": "PDF document",
              allowDelete: true
            }
            //console.log('newDocument :: ', newDocument);
            setDocuments([...documents, newDocument]);
        }
        else {
          setError("We could not process your file, please review the file and file type.")
        }
      }
    }
  
    const removeFile = (file) => {
      const prevFiles = [...documents];
      //console.log(`file to delete :: ${file.id} - ${file.Name}`);
      setDocuments(documents.filter(document => document.id !== file.id));
    }
  
    const noDocumentsLoaded = documents === null || documents.length === 0;

    const enableFileRetrivalFromJIRA = false;
  
    useEffect(() => {
      const fetch = async () => {
          const data = await getCompanyNameFromId(store.clientID[0])
          store.clientIDname[1](data[0].companyName)
      }
      if (store.clientID[0])
      fetch();
  
      setDisableInputs(false);
  
    }, [store.clientID[0]])

    return (
        <Container fluid className="pt-4">
          <Container fluid className="">
          <Container fluid="lg" className="mb-4 semi-fluid-container">
              <CustomaiteCustomerValidation />
              <section className="floating-box-newload">
                <div className="floating-box-header-newload">
                  <span>Create CM8 Load</span>
                </div>
                <div className="section-group">
                    <div className="section-group">
                        <div className="section">
                            <p className="title">Load Details</p>
                            <div className="content">
                                <div className="form-field">
                                <Input
                                    label="Invoice Customer"
                                    name="invoiceCustomer"
                                    type="text"
                                    required
                                    disabled={disableInputs}
                                />
                                </div>
                                <div className="form-field">
                                <Select
                                    label="Movement"
                                    name="direction"
                                    data={store.directionSelected[0]}
                                    setData={store.directionSelected[1]}
                                    options={store.movementOptions[0]}
                                    required
                                    info={
                                    <OverlayTrigger
                                        placement="right"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                        <Popover style={{ minWidth: "15rem" }}>
                                            <PopoverContent>
                                            <h6>Movement Type</h6>
                                            <p>
                                                A movement type is required before you can begin
                                                the process of entering a New Load.
                                            </p>
                                            </PopoverContent>
                                        </Popover>
                                        }
                                    >
                                        <div className="important-info"></div>
                                    </OverlayTrigger>
                                    }
                                />
                                </div>
                                <div className="form-field">
                                <Input
                                    label="Loading Date"
                                    name="date"
                                    type="date"
                                    value={store.date[0]}
                                    required
                                    onChange={(e) => store.date[1](e.target.value)}
                                    disabled={disableInputs}
                                />
                                </div>
                                <div className="form-field">
                                <Input
                                    label="Job Reference"
                                    name="jobref"
                                    type="text"
                                    required
                                    value={store.jobRef[0]}
                                    onChange={(e) => store.jobRef[1](e.target.value)}
                                    disabled={disableInputs}
                                />
                                </div>
                                <div className="form-field">
                                <Input
                                    label="Invoice Number"
                                    name="invoicenumber"
                                    type="text"
                                    required
                                    value={store.invoicenumber[0]}
                                    // onChange={(e) => store.invoicenumber[1](e.target.value)}
                                    onChange={(e) => handleChange(e)} 
                                    error={error}
                                    disabled={disableInputs}
                                />
                                </div>
                                {
                                    enableFileRetrivalFromJIRA && 
                                        <div className="form-field" style= {{ justifyContent: "flex-end" }}>
                                        <button className="stepper-button" style= {{ width: "auto" }} onClick={() => {handleGetDocuments()}} disabled={handleDisableButton()}>Get documents</button>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="section">
                            <div className="content" style={{ margin: "1rem 0 0 0",  padding: "0" }}>
                                <div className="d-flex flex-column align-items-center mt-3">
                                    <label name="name" className="stepper-button yellow d-flex align-self-auto align-items-center mt-auto mb-2" style={{borderRadius: '15px', cursor: 'pointer' }}>
                                    <span>Select</span>
                                    <input type="file" name="fileuploader" onChange={(e) => addFiles(e.target.files)} onClick={(e) => { e.target.value = null; setError("");}} accept=".pdf,.png,.jpeg,.xls,.xlsx" multiple />
                                    </label>
                                </div>
                                <div className="documents-list">
                                    {
                                        noDocumentsLoaded ? 
                                            <p>No documents.</p> : 
                                            <>
                                                {
                                                    documents.map((data) => (
                                                        <span className="document-line" key={data.id} title={data.Name}>
                                                            <span className="document-line-group">
                                                            <span className="text-nowrap" style={{textOverflow: 'ellipsis', overflow: 'hidden', marginLeft: '.5rem' }}>
                                                                <span style={{ fontWeight: '500' }}>{data.Name}</span> 
                                                                {
                                                                data.DocType && <span style={{ marginLeft: '.5rem' }}>({data.DocType})</span>
                                                                } 
                                                            </span>
                                                            </span>
                                                            {
                                                            (data.allowDelete) &&
                                                                <button style={{appearance: 'none', border: 'none', backgroundColor: 'transparent'}} onClick={() => removeFile(data)}>
                                                                <Trash width={"1rem"} height={"1rem"} style={{color: 'red'}} />
                                                                </button>
                                                            }
                                                        </span>
                                                    ))
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <p className="title">Additional Information</p>
                        <div className="content">
                            <div className="form-field full-width">
                            <TextArea
                                style={{ resize: "none", height: "590px" }}
                                label={"Email or Text supporting shipment"}
                                onChange={(e) => setAdditionalInformation(e.target.value)}
                                value={additionalInformation}
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex pb-3 px-3 w-100 justify-content-between">
                  <button className="epo-continue-button " type="button" onClick={() => {cancelAction(true)}}>Cancel</button>
                  <button className="blue-button-newload" type="button" onClick={() => {submitLoadToCustomaite(true)}}>Submit</button>
                </div>
              </section>
            </Container>
          </Container>
        </Container>
      )
}

export default CustomaiteCreateNewLoad;
