import { useState } from "react";
import {Popover, PopoverContent, OverlayTrigger} from "react-bootstrap";
import copyIcon from "../../images/clipart.png";  
import clickIcon from "../../images/Search.svg";


 const CopyPastePopUp = ({jobRef}) => {
  const [isCopied, setIsCopied] = useState(false);
  const popoverWidth = `${jobRef.length * 15}px`; 

  const copyToClipboard = async() => {    
    try {
      await navigator.clipboard.writeText(jobRef); 
      setIsCopied(true);     
    } catch (err) {
      setIsCopied(false); 
      console('Failed to copy!');
    }  

    setTimeout(() => {
      setIsCopied(false);
    }, 100);
  }


  return (
    <OverlayTrigger
      placement="right"
      trigger={["click"]}
      rootClose
      overlay={
        <Popover style={{ padding: "6px", width:'500px' }}>
        <PopoverContent>
          <div className="d-flex ">
            <p className={isCopied ? "copied-text" : ""}>{jobRef}</p>
            <div style={{ marginLeft: "13px" }}>
              <img
                src={copyIcon}
                alt={'Full Reference'}
                style={{
                  cursor: "pointer",
                  width: "19px",
                  height: "19px",
                  marginBottom: '15px'
                }}
                onClick={(e) => copyToClipboard()}
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>
      
      }
    >
      <img
        src={clickIcon}
        alt={'Copy to clipboard'}
        style={{
          cursor: "pointer",
          width: "14px",
          height: "14px"
        }}
      />
    </OverlayTrigger>
  );
}

export default CopyPastePopUp;