import {Row, Col, Modal} from 'react-bootstrap'
import {Input, PackagesInput} from '../../Input/Input'
import React, {useContext, useState, useEffect} from "react";
import { StoreContext } from "../../Store";
import fileLoaderSvg from '../../../images/file_upload.svg'
// '../../images/information.svg'

function GridTotalsPI({grossKG, netKG, itemCount, value, rows, other=[]}) {

    const store = useContext(StoreContext);
    const [showPackageModal, setShowPackageModal] = useState(false);

    function numberWithCommas(x, toFixed) {
        let floatValue = isNaN(parseFloat(x)) ? 0 : parseFloat(x);
        if (toFixed)
            floatValue = floatValue.toFixed(2);
        return floatValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

   const grossKGformatted = numberWithCommas(grossKG, true);
   const netKGformatted = numberWithCommas(netKG, true);
   const itemCountformatted = numberWithCommas(itemCount, false);
   const valueFormatted = numberWithCommas(value, true);

    // const [packageText, setPackageText] = useState(itemCountformatted)

    useEffect(() => {
    //   setPackageText(itemCountformatted)
      store.packageTotal[1](itemCountformatted)
    }, [itemCount]);

  return (
    <div className="d-flex flex-column" style={{ marginTop: "2rem", marginBottom: "1rem" }} >
            {/* <PackageModal 
      show={showTooltip}
      text={tooltipText}
      conditionName={conditionName}
      onHide={() => {setShowPackageModal(false)}}
      /> */}
            <PackageModal
          show={showPackageModal}
          onHide={() => {
            setShowPackageModal(false);
          }}
          onContinue={() => {
            // localData.documents.length = 0;
            // clearCheckedCategory();  
            // setShowConfirmModal(false);   
            store.packageTotal[1](store.customPackageTotal[0])      
            setShowPackageModal(false);
          }}
        ></PackageModal>
    <Row>
        {
        other.map((item) => {
            return (
                <Col>
                {item}
                </Col>
            )
        })
        }
      {grossKG !== undefined && (<Col>
        <Input disabled label="Gross Mass (kg)" value={grossKGformatted} />
      </Col>
      )}
      {netKG !== undefined && (<Col>
        <Input disabled label="Net Mass (kg)" value={netKGformatted} />
      </Col>
      )}
      {itemCount !== undefined && (<Col>
          <PackagesInput disabled label="Number of Packages" value={store.packageTotal[0]} onChange={e => store.packageTotal[1](e.target.value)} showPackageModal={() => {setShowPackageModal(true)}} />

        </Col>
        
        )}
              {/* {itemCount !== undefined && (<Col style={{maxWidth:"50px", marginTop: "30px"}}>

          <img src={fileLoaderSvg}  height="30" width="30" alt="info" />
        </Col>
        
        )} */}

      {value !== undefined && (<Col>
        <Input disabled label="Value" value={valueFormatted} />
      </Col>
      )}
      {rows !== undefined && (<Col>
        <Input disabled label="Item Rows" value={rows} />
      </Col>
      )}
    </Row>
    </div>
  )
}

const PackageModal = ({ show, onHide, onContinue }) => {
  // const { localData, setDataByKey, unvisitStep } = useStepper();
  const store = useContext(StoreContext);
  

  function cancelClick() {
    onHide();
    store.customPackageTotal[1](0)
  }
  
  // async function continueClick() {
  //   onHide();
        
  // }

  return (
    <Modal show={show} centered size={"md"} onHide={onHide} animation={false} >
      <Modal.Header>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="warning" /> <span>
            Overwrite total number of packages
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 py-4">
          <>
              <p className="bold">Warning - the value entered below will overwrite the total number of packages for this load</p>
              <Input label="Custom Number of Packages" numeric={true} value={store.customPackageTotal[0]} onChange={e => store.customPackageTotal[1](e.target.value)} />
          </>
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

        <button class="stepper-button" onClick={cancelClick}>
          Cancel
        </button>

        <button class="stepper-button yellow" onClick={()=> onContinue()}>
          Confirm
        </button>

      </Modal.Footer>
    </Modal>
  );
};

export default GridTotalsPI;