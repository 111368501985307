import { Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page1: {
    flexDirection: "column",
    backgroundColor: "#E7E7E7",
    fontFamily: "Courier",
    fontSize: "14px",
    padding: 10,
  },
  page2: {
    flexDirection: "column",
    backgroundColor: "#E7E7E7",
    fontFamily: "Helvetica",
    fontSize: "12px",
    padding: 10,
  },
  section: {
    paddingBottom: 10,
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    marginLeft: 5,
  },
  label: {
    fontFamily: "Courier-Bold",
    color: "rgb(3, 50, 73)",
  },
});

const JobReceipt = ({jobDetails, id}) => {  
    const {
      jobRef,
      jobReference,
      epoScore,
      transferId,
      TransferID,
      submittedBy,
      submittedAt,
      isImport,
      controlledGoods
    } = jobDetails;    

    const getEpoRef =()=>{
      if (!["E", "F"].includes(epoScore)) {
        return (
          <>
            <Text style={styles.label}>EPO reference</Text>
            <Text>{TransferID || transferId}</Text>
          </>
        );
      }
      if (["E", "F"].includes(epoScore) && ["confirmation", "listloads"].includes(id) && !(transferId === "EPO"))
        return (
          <>
            <Text style={styles.label}>EPO reference</Text>
            <Text>{TransferID || transferId}</Text>
          </>
        );
    }

    const getJobRef =()=>{
        return(<>
            <Text style={styles.label}>Your job reference</Text>        
            <Text>{jobReference || jobRef}</Text>
            </>)
    }

    const getCreatedBy =()=>{
        return(<>
            <Text style={styles.label}>Created by</Text>
            <Text>{submittedBy}</Text>
            </>)
    }

    const dateTime = new Date(submittedAt).toLocaleString();

  return (
    <Document>    
      {isImport && <ImportsDetails jobDetails={jobDetails} epoRef={getEpoRef()} jobsRef={getJobRef()} createdBy={getCreatedBy()} submitted={dateTime}/>} 
      {!isImport && <ExportDetails jobDetails={jobDetails} epoRef={getEpoRef()} jobsRef={getJobRef()} createdBy={getCreatedBy()} submitted={dateTime}/>}

   {isImport && <Page size="A5" style={styles.page2}>
      {["C","D"].includes(epoScore) && (controlledGoods ? <SfdText jobDetails={jobDetails}/> : <ResultAtoD/>)}
      {["A","B"].includes(epoScore) && (controlledGoods ? <SfdText jobDetails={jobDetails}/> : <ResultAtoD/>)}
      {(id === "congrats") &&  ["E","F"].includes(epoScore) && <ResultEtoF/>}
      {(["confirmation","listloads"].includes(id)) && (transferId === "EPO") && ["E","F"].includes(epoScore) && <ResultEtoF/>}
      {!(transferId === "EPO") && (["listloads", "confirmation"].includes(id)) && (["E", "F"].includes(epoScore)) && (controlledGoods ? <SfdText jobDetails={jobDetails}/> : <ResultAtoD/>)}
    </Page>}
  </Document>
  )
}
export default JobReceipt;

export const ImportsDetails = ({jobDetails, epoRef, jobsRef, createdBy, submitted})=>{
    const {
        euPref,
        controlledGoods, 
        port,
        entryPort,
        estimatedDateOfLoading,
        euPrefSelected,
     } = jobDetails;


    return(<Page size="A5" style={styles.page1}><View style={styles.section}>
        {epoRef}
      </View>
      <View style={styles.section}>      
         {jobsRef}            
      </View>
      <View style={styles.section}>
         {createdBy}
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Expected port of arrival</Text>
        <Text>{port || entryPort}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Expected date of arrival</Text>
        <Text>{new Date(estimatedDateOfLoading).toLocaleDateString()}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Origin of goods</Text>  
        <Text>{euPrefSelected}</Text>       
        {euPrefSelected ? (<Text>{euPref === "yes" ? 'EU Origin Only' : 'Not All EU Origin Only'}</Text>): 
        (<Text>{euPref === "yes" ? 'All Preferential Origin' : 'Not all Preferential Origin'}</Text>)}
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Type of Goods</Text>
        <Text>{controlledGoods ? "Controlled": "Non-Controlled"}</Text>
      </View>
      <View style={{flexGrow: 1}}></View>
      <View style={{paddingBottom: 5, paddingRight: 5, textAlign: "right"}}>
      <Text style={styles.label}>{submitted}</Text> 
      </View>
      </Page>)
}
export const ExportDetails =({jobDetails, epoRef, jobsRef, createdBy, submitted})=>{

    const {
        sealNo,
        officeDest,
        inlandTransId,
        epoScore,
        controlledGoods,
        estimatedDateOfLoading,
        port,
        exitport
      } = jobDetails;

      
      const epoScoresText = {
        G: "None",
        H: "ENS Required",
        I: "Transit Required",
        J: "Transit & ENS Required",
      };

    const resultIandJ = () => {
        return (
          <div style={{marginLeft:'-10px'}}>
            <View style={styles.section}>
              <Text style={styles.label}> Office of Destination</Text>
              <Text style={{marginLeft:'10px'}}>{officeDest}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.label}> Inland Transport ID</Text>
              <Text style={{marginLeft:'10px'}}>{inlandTransId}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.label}> Seal Number</Text>
              <Text style={{marginLeft:'10px'}}>{sealNo === "" ? "Not Declared" : sealNo }</Text>
            </View>
          </div>
        );
      };

    return(<Page size="A5" style={styles.page1}>
      <View style={styles.section}>
            {epoRef}
      </View>
      <View style={styles.section}>
        {jobsRef}
      </View>
      <View style={styles.section}>
       {createdBy}
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Expected Port of Exit</Text>
        <Text>{port}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Type of Goods:</Text>
        <Text>{controlledGoods ? "Controlled" : "Non-Controlled" }</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Additional Requirements</Text>
        <Text>{epoScoresText[epoScore]}</Text>
      </View>
      {["I", "J"].includes(epoScore) && resultIandJ()}
      <View style={styles.section}>
        <Text style={styles.label}>Expected date of Exit</Text>
        <Text>{new Date(estimatedDateOfLoading).toLocaleDateString()}</Text>
      </View>
      <View style={{flexGrow: 1}}></View>
      <View style={{paddingBottom: 5, paddingRight: 5, textAlign: "right"}}>
      <Text style={styles.label}>{submitted}</Text>
      </View></Page>)
}


export function ResultAtoD(){  
    return (<View style={{display: "flex", flexDirection: "column"}}>
        <View style={{padding: 10}}><Text>We'll contact you if we have any queries about the information you've submitted.</Text></View>
        <View style={{padding: 10}}><Text>The reference to enter on GVMS under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.</Text></View>
        <View style={{padding: 10}}><Text>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</Text></View>
    </View>)
}

export function ResultEtoF() {  
    return (<View style={{display: "flex", flexDirection: "column"}}>
        <View style={{padding: 10}}><Text>We have noted that the origin of the goods may attract duty and that we do not have your DDA (duty deferment account) details on file.</Text></View>
        <View style={{padding: 10}}><Text>We are unable to authorise release against our customs approval number until we have assessed the duty that may be due and received confirmation of payment as required.</Text></View>
        <View style={{padding: 10}}><Text>Our declarations team will be in touch with you shortly to confirm next steps.</Text></View>
    </View>)  
}

export function SfdText({jobDetails}) {
  const { transferId, TransferID } = jobDetails;    
   return  ( 
    <>
      <View style={{paddingLeft: 10, paddingTop : 20, paddingBottom : 20}}><Text>Your shipment details have been submitted and your declaration will now be processed.</Text></View>
      <View style={{display: "flex", flexDirection: "column"}}>
      <View style={{paddingLeft: 10, paddingBottom: 10}}><Text>1. We will contact you if we have any questions about the information you</Text><Text style={{paddingLeft: 10}}> have supplied.</Text></View>
      <View style={{paddingLeft: 10, paddingBottom: 10}}><Text>2. You can check the progress of your declaration using the STATUS</Text><Text style={{paddingLeft: 10}}> button.</Text></View>
      <View style={{paddingLeft: 10, paddingBottom: 10}}><Text>3. The customs reference required for GVMS will become available in the</Text><Text style={{paddingLeft: 12}}>Document References column.</Text></View>
      <View style={{paddingLeft: 10, paddingBottom: 20}}><Text>4. This should be added to GVMS under the 'Customs Declaration'</Text><Text style={{paddingLeft: 10}}>option.</Text></View>
      </View >
      <View style={{paddingLeft: 10}}><Text>Your Transaction Reference is: {TransferID || transferId} </Text></View>
    </>)
}
