import React, { useState, useEffect, useContext } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { EllipsisV } from "@styled-icons/fa-solid";
import { getShipStatus, deleteShipStatus } from '../../apiCalls';
import ShipStatusModal from "./shipStatusModal";
import ConfirmationModal from "../../ConfirmationModal";
import { RefDataContext } from "../Maintenance";

const ShipStatus = () => {
  const [shipStatus, setShipStatus] = useState([{ "": "" }])
  const [selectedShipStatus, setSelectedShipStatus] = useState("");

  const {setOnAddNew} = useContext(RefDataContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getShipStatus();
        setShipStatus(data);
      } catch (err) {
      }
    }
    fetchData();
    setOnAddNew(() => {
      setSelectedShipStatus({id: -1})
    });
  }, []);

return (<>
<ShipStatusModal shipStatus={selectedShipStatus} onSave={ async () => {
  const data = await getShipStatus();
  setShipStatus(data);
}} onCancel={() => setSelectedShipStatus(null)} />

<Table className="p-4 rounded-3" striped>
          <thead className="bold fs-5" style={{ color: "rgb(80, 80, 80)" }}>
            <tr>
              <th>Type</th>
              <th className="text-nowrap" style={{ width: "0px" }}></th>
            </tr>
          </thead>
          <tbody>
            {/* TODO:PAGINATE ROWS */}
            {(shipStatus).map((shipStatus) => ( 
              <React.Fragment key={shipStatus.client_id}>
                <ShipStatusRow shipStatus={shipStatus} setSelectedShipStatus={setSelectedShipStatus} onDelete={ async () => {
  const data = await getShipStatus();
  setShipStatus(data);
}}/>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        </>)
}


function ShipStatusRow ({shipStatus, setSelectedShipStatus, child, onDelete}) {
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);

  const handleDelete = async event => {
    try {
      const data = await deleteShipStatus(shipStatus.id)
      if (data[0].completed === 'deleted') {
        alert("Record Deleted");
        onDelete()
      }
    } catch (err) {
      console.log(err);
      alert('Error, unable to delete')
    }
  }
    return (
      <tr id={shipStatus.id} className={`border-top fs-6 ${child ? "" : "bold"}`}>
        <td>{shipStatus.type}</td>
        <td>
          <Dropdown className="w-100 h-100 d-grid center">
            <Dropdown.Toggle as="button" className="dot-menu-button">
              <EllipsisV width={"0.5rem"} />
            </Dropdown.Toggle>
  
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={() => setSelectedShipStatus(shipStatus)}>
                View/Edit
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setShowConfirmResetModal(true)}>
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ConfirmationModal
        header="Confirm Deletion"
        show={showConfirmResetModal}
        onHide={() => setShowConfirmResetModal(false)}
        onConfirm={handleDelete}
        annimation={true}
      >
        Are you sure you want to delete this record? 
      </ConfirmationModal>
        </td>
      </tr>
    )
  }

  export default ShipStatus; 