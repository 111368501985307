import { useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Row, Col} from "react-bootstrap";
import { postCompanyLoad, putCompanyLoad } from "../../apiCalls";
import { Input } from "../../Input/Input";

const LoadModal = ({ onSave, onCancel, load }) => {
const [localLoad, setLocalLoad] = useState({});

useEffect(() => {
    setLocalLoad (load)
}, [load])

  async function submitForm(event) {
    event.preventDefault();
    try {
      let data;
      if (localLoad.id === -1) {
        data = await postCompanyLoad(event.target)
        alert("New record added")
      } else {
        data = await putCompanyLoad(event.target)
      }
       if (data[0].completed === 'Updated') {
          alert("Record updated");
        } 
        onSave()
        onCancel()
      } catch (err) {
        console.log(err);
        alert('Error updating record to DB')
      }
    }

  return (
    <Modal show={localLoad?.id !== undefined} onHide={onCancel} centered size="lg" contentClassName="editModal" dialogClassName="wide-modal">
      <Modal.Header>
        <Modal.Title>Editing: {localLoad?.type}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onCancel} />
      </Modal.Header>
      <Form onSubmit={submitForm} className="flex-grow-1 d-flex flex-column">
        <Modal.Body className="flex-grow-1">
        <Row>
            <Col>
                <input
                    type='number'
                    name = 'id'
                    value = {localLoad?.id}
                    hidden
                />
                <Input
                    name="type"
                    type="text"
                    label="Load"
                    required
                    value={localLoad?.type}
                    onChange={e => setLocalLoad(prev => ({...prev, type:e.target.value}))}
                />
            </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
          style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
        >
          <button type="button" class="cancel-button" onClick={onCancel} style={{ display: "flex", borderRadius: "10px" }}>
            Cancel
          </button>
          <button type="submit" class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
            Save Client
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default LoadModal;