import { useContext } from "react";
import { StoreContext } from "../Store";

const ExternalView = () => {
 const store = useContext(StoreContext);  

  return (
    <>
      <div>
        <span className="register-step-header">CABIE Pack Signature</span>
      </div>

      <div className="inputs-group d-flex flex-row p-4 my-3">
        <div>
          One final step, by clicking Continue below, you will be taken to our
          partner site in order to sign the following Forms:
          <ul className="flex-grow-1 mt-2">
            <li className="text-eoriblue" style={{ fontWeight: "500" }}>
              Standing Authority to Act
            </li>
            {!store.isWLP[0] && (
              <li className="text-eoriblue" style={{ fontWeight: "500" }}>
                Account Application
              </li>
            )}
          </ul>
          <p>
            We have also sent you a ‘Registration Confirmation’ email with a
            link to complete this later.
          </p>
          <p>
            Once we have your confirmation, we'll verify your details in line
            with our{" "}
            <a target="_blank" href="/assets/terms-and-conditions.pdf">
              Terms and Conditions
            </a>
            . As part of this process we will complete a business credit check
            against your company.
          </p>
          <br />
          <p>
            Not quite ready to sign up or have some questions? Send us an email
            at{" "}
            <a href="mailto:cabiesupport@eori.uk?subject=CABIE Questions">
              CABIEsupport@eori.uk
            </a>
          </p>
          {/* <div className="sub-heading-smartPack password-error2">{passwordText}</div> */}
        </div>
      </div>
    </>
  );
};

export default ExternalView;

