import { useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { postCompanyCurrency, putCompanyCurrency } from "../../apiCalls";
import { Input } from "../../Input/Input";

const CurrencyModal = ({ onSave, onCancel, currency, }) => {
const [localCurrency, setLocalCurrency] = useState({});

useEffect(() => {
    setLocalCurrency (currency)
}, [currency])

async function submitForm(event) {
  event.preventDefault();
  try {
    let data;
    if (localCurrency.id === -1) {
      data = await postCompanyCurrency(event.target)
      alert("New record added")
    } else {
      data = await putCompanyCurrency(event.target)
    }
     if (data[0].completed === 'Updated') {
        alert("Record updated");
      } 
      onSave()
      onCancel()
    } catch (err) {
      console.log(err);
      alert('Error updating record to DB')
    }
  }

  return (
    <Modal show={localCurrency?.id !== undefined} onHide={onCancel} centered size="lg" contentClassName="editModal" dialogClassName="wide-modal">
      <Modal.Header>
        <Modal.Title>Editing: {localCurrency?.description}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onCancel} />
      </Modal.Header>
      <Form onSubmit={submitForm} className="flex-grow-1 d-flex flex-column">
        <Modal.Body className="flex-grow-1">
        <Row>
            <input
                    type='number'
                    name = 'id'
                    value = {localCurrency?.id}
                    hidden
                />
            <Col>
                <Input
                    name="description"
                    type="text"
                    label="Description"
                    required
                    value={localCurrency?.description}
                    onChange={e => setLocalCurrency(prev => ({...prev, description:e.target.value}))}
                />
            </Col>
            <Col>
                <Input
                    name="code"
                    type="text"     
                    label="Code"
                    required
                    value={localCurrency?.code}
                    onChange={e => setLocalCurrency(prev => ({...prev, code:e.target.value}))}
                />
            </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
          style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
        >
          <button type="button" class="cancel-button" onClick={onCancel} style={{ display: "flex", borderRadius: "10px" }}>
            Cancel
          </button>
          <button type="submit" class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
            Save Client
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default CurrencyModal;