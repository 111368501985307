import { ChevronRight } from "@styled-icons/fa-solid";

const DashboardButton = ({ title, quantity, titleDescription, clickHandler }) => {
  const setTitle = () => {
    //check if titleDescription is defined
    if (titleDescription !== undefined) {
      return (
        <h1 className="total-jobs__title">{title} <span className="total-jobs__p">{titleDescription}</span></h1>
      );
    }
    else{
      return (<h1 className="total-jobs__title">{title}</h1>)
    }
  }
  return (
    <div className="total-jobs__category" onClick={() => clickHandler(title, titleDescription)}>
      {setTitle()}
      <p className="total-jobs__p">Total Jobs</p>
      <p className="dashboard-qty">
        {quantity}
      </p>
      <ChevronRight width={"0.5rem"} className="gray-text mx-2 dashboard-chevron"/>
    </div> 
  );
};

export default DashboardButton;
