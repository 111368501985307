/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import fileLoaderSvg from '../../images/file_upload.svg'
import { StoreContext } from "../Store";
import { useNavigate } from 'react-router-dom';
import "../main.css";
import { postFileUpload } from "../apiCalls";
import { LoadUntilReady } from "../Util/Loaders";


const FileLoader = () => {
	const [selectedFile, setSelectedFile] = useState();
	const [selectedFileType, setSelectedFileType] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const store = useContext(StoreContext);
	const navigate = useNavigate()
	const [requestFailed, setRequestFailed] = useState(false); 
	const [loading, setLoading] = useState(false);

	const handleChange = e => {
		setSelectedFile(e.target.files[0]);
		setIsFilePicked(true);
		setSelectedFileType(e.target.files[0].name.slice(-4).toLowerCase() === 'xlsx' ? 'xls' : e.target.files[0].name.slice(-3));
	};

	useEffect(() => {
		store.infoText[1]("You can submit your New Load files using our file uploader. Before pressing the final submit button, please verify the file details summary section (displayed upon file selection) are correct.  You will also need to ensure that the file type chosen is currently supported by this upload facility. Currently, file types accepted are: CSV, XLS and XML.")
	}, [isFilePicked]);

	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append('file', selectedFile); 
		// console.log(store.isEidr[0]);
		try {
			setLoading(true);
			const data = await postFileUpload(selectedFileType, formData)
			store.newLoadFileData[1](data);
			store.newLoadFromFile[1](true);
			navigate("/newLoad");
			setLoading(false);
		} catch (err) {
			store.infoText[1]("We could not process your file, please review the file and try again.")
			setLoading(false);
			setRequestFailed(true);
			store.newLoadFromFile[1](false);
		}
	};

	return (
		<>
			{isFilePicked ? (
				<div>
					<LoadUntilReady loading={loading} loadingText='Please wait...' style={{ marginTop: '6rem' }}>
						<p className="section-title">File name</p>
						<p>{selectedFile.name}</p>
						<p className="section-title">File type</p>
						<p>{selectedFileType}</p>
						<p className="section-title">File size (bytes)</p>
						<p>{selectedFile.size}</p>
						<p className="section-title">File last modified</p>
						{selectedFile.lastModifiedDate.toLocaleDateString()}
						<div>
						{!requestFailed && (
							<button className="blue-button-newload file-loader-btn-submit" onClick={handleSubmit}>Submit</button>)}
							{requestFailed && (
								<div style={{ marginTop: '1rem', fontSize: '0.85rem', color: 'rgb(255, 30, 30)' }}>
									<button className="blue-button-newload file-loader-btn" onClick={() => setIsFilePicked(false) & setRequestFailed(false)}>Back</button>
								</div>
							)}
						</div>
						
					</LoadUntilReady>
				</div>
			) : (<>
				<img src={fileLoaderSvg} height="100" alt="loader" />
				<label name="name" className="blue-button-newload file-loader-btn">RPA<input type="file" name="fileuploader" onChange={handleChange} accept=".csv,.xlsx,.xml,.xlsb" /></label>
			</>
			)}
		</>
	)
}


export default FileLoader;