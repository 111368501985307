 const createTable = (tableData) =>{
    let tableLength = tableData.length
    let table = []
    for (let i = 0; i < tableLength; i++) {
        let children = []
        let row = Object.values(tableData[i])
        row.map(data => children.push(<td id={i}>{data}</td>))
        table.push(<tr key={i}>{children}</tr>)
    }
    if (table.length === 0) {
        return null
    }

    else return table
}

export default createTable