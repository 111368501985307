import "./clients.css";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Collapse } from "react-bootstrap";
import EditModal from "./EditModal";
import ConfirmationModal from "../ConfirmationModal";
import { EllipsisV, ChevronDown } from "@styled-icons/fa-solid";
import { getCutomerData, getCutomerDataDetails, deleteCutomerData } from "../apiCalls";

const AllClients = () => {
  const [parents, setParents] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  
  /* Flattens the 2 levels of parents into 1 level so all can be referenced from same array */
  const flattened_parents = parents.reduce((acc, p1) => {
    acc.push({id: p1.id, name: p1.parentName});
    if (p1.children) {
      p1.children.forEach(p2 => {
        acc.push({id: p2.id, name: p2.Client_name});
      })
    }

    return acc;
  }, []);
  /* Takes the parents we already have and puts them into a '<Select />' friendly form.
     Allows us to show parents in dropdown without another API call */
  const parentsAsDropdown = flattened_parents.map((c) => ({ id: c.id, data: c.name}));

  async function fetchAll() {
    const {Parents} = await getCutomerData();
    setParents(Parents);
  }

  useEffect(() => {
    fetchAll(); // load everything on page load
  }, []);

  async function viewEditClient(id, parentId) {
    const [client] = await getCutomerDataDetails(id);
    // If client has a parent, get the full parent object from the parents array and attach it.
    if (parentId) {
      client.parent = flattened_parents.find(p => p.id === parentId);
    } 
    // If client does not have a parent, find it's record in the parents array - attach a flag based on if it has any children.
    else {
      const parentRecord = flattened_parents.find(p => p.id === id);
      client.hasChildren = 0 !== (parentRecord.children?.length ?? 0);
    }
    setSelectedClient(client);
  }

  async function deleteClient(id) {
    await deleteCutomerData(id);
    fetchAll();
  }

  return (
    <Container fluid="2xl" className=" mt-5 semi-fluid-container medium-middle-container ">
      <section className="floating-box-userProfile pb-2 px-4">
        <div className="floating-box-header-userProfile ps-0 ms-0">
          <span>Client List</span>
          <span className="flex-grow-1"></span>
          <button className="blue-button-teams green-btn" onClick={() => setSelectedClient({ id: -1 })}>
            Add Client
          </button>
        </div>

        <div className="p-4 rounded-3">          
          {parents.map((parent, idx) => (
            <React.Fragment key={parent.id}>
              <ClientRow client={parent} viewEditClient={viewEditClient} deleteClient={deleteClient} isLast={idx === parents.length - 1} />
            </React.Fragment>
          ))}
        </div>
      </section>

      <EditModal
        parentsAsDropdown={parentsAsDropdown}
        client={selectedClient}
        onCancel={() => setSelectedClient({})}
        onSave={() => {
          setSelectedClient({});
          fetchAll();
        }}
      />
    </Container>
  );
};

function ClientRow({parent_client, client, viewEditClient, deleteClient, isLast, ...rest}) {
  const [expanded, setExpanded] = useState(false);

  const root_level = !parent_client;

  return (
    <>
      <div className={`d-flex align-items-stretch ${root_level ? 'border-top border-start border-end' : ''} ${root_level && isLast ? 'border-bottom' : ''}`}>
        {!root_level && (
          <div style={{width: '2rem', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr 1fr'}} className="d-grid">
            <div/>
            <div className="w-100 h-100 border-2 border-info border-start"/>
            <div/>
            <div className={`w-100 h-100 border-2 border-info ${isLast ? 'border-top' : 'border-start'}`}/>
          </div> 
        )}

        <div className="w-100 d-flex flex-column">
          <div className={`d-flex w-full border-start border-top ${expanded ? 'border-0' : ''}`} >
            <div onClick={() => setExpanded(prev => !prev)} className={`flex-fill d-inline-flex align-items-center py-1 ${client.children || root_level ? 'text-eoriblue bold' : ''} ${client.children ? 'has-children parent-row ' : ''}`}>
              {client.children ? <ChevronDown width={"1rem"} className="gray-text mx-2" style={{transition: 'transform 0.3s', transform: expanded ? '' : 'rotate(-90deg)'}} /> : <span class="mx-2" style={{width: '1rem'}}/>}
              {client.Client_name || client.parentName}
            </div>
            <KebabMenu viewEditClient={() => viewEditClient(client.id, parent_client?.id)} deleteClient={() => deleteClient(client.id)} />
          </div>

          <Collapse in={expanded}>
            <div className="child-list" onClick={(e) => (e.stopPropagation())}>
              {client.children?.map((child, idx) => (
                <React.Fragment key={child.id}>
                  <ClientRow parent_client={client} client={child} isLast={idx === client.children.length - 1} viewEditClient={(cid) => viewEditClient(cid, child.id)} deleteClient={deleteClient} />
                </React.Fragment>
              ))}
            </div>
          </Collapse>
        </div>
      </div>

    </>
  )
}


// function ParentRow ({client, viewEditClient, deleteClient, ...rest}) {
  

//   return (
//     <>
//     <div id={client.id} className={`d-flex parent-row align-items-center ${client.children ? 'has-children' : ''}`} {...rest}>
//       <Col className="d-inline-flex gap-2 mx-2 bold text-eoriblue" onClick={() => setExpanded(prev => !prev)}>
//         {client.children ? <ChevronDown width={"1rem"} className="gray-text" style={{transition: 'transform 0.3s', transform: expanded ? '' : 'rotate(-90deg)'}} /> : <span style={{width: '1rem'}}/>}
//         {client.parentName || client.Client_name}</Col>
//         <KebabMenu viewEditClient={() => viewEditClient(client.id)} deleteClient={() => deleteClient(client.id)} />
//     </div>

    
    // <Collapse in={expanded}>
    //   <div className="child-list">
    //     {client.children?.map((child, idx) => (
    //       <React.Fragment key={child.id}>
    //         {child.children 
    //         ? (<ParentRow client={child} isLast={idx === client.children.length - 1} viewEditClient={(cid) => viewEditClient(cid, child.id)} deleteClient={deleteClient} />)
    //         : (<ChildRow child={child} isLast={idx === client.children.length - 1} viewEditClient={(cid) => viewEditClient(cid, client.id)} deleteClient={deleteClient} />)}
    //       </React.Fragment>
    //     ))}
    //   </div>
    // </Collapse>
    // </>
//   )
// }

// function ChildRow({child, isLast, viewEditClient, deleteClient, ...rest}) {
//   return (
//     <div id={child.id} className="d-flex border-top" {...rest}>
//       <Col className="d-inline-flex align-items-center gap-2 text-eoriblue">
//         <div style={{width: '2rem', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr 1fr'}} className="h-100 d-grid">
//           <div/>
//           <div className="w-100 h-100 border-2 border-info border-start"/>
//           <div/>
//           <div className={`w-100 h-100 border-2 border-info ${isLast ? 'border-top' : 'border-start'}`}/>
//         </div> 
//         {child.Client_name}</Col>
//       <KebabMenu viewEditClient={() => viewEditClient(child.id)} deleteClient={() => deleteClient(child.id)} />
//     </div>
//   )
// }

function KebabMenu ({viewEditClient, deleteClient, ...rest}) {
  const [showDeleteModal, setShowDeleteModal] = useState();

  return (
    <>
      <ConfirmationModal
        header="Confirm Delete?"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={deleteClient}
      >
        <p>
          Are you sure you want to delete this client? This can't be undone.
        </p>
      </ConfirmationModal>

      
      <Dropdown className="h-100 d-grid center">
        <Dropdown.Toggle as="button" className="dot-menu-button py-1 px-2">
          <EllipsisV width={"0.5rem"} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={viewEditClient}>
            View/Edit
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={(e) => setShowDeleteModal(true)}>
            Remove Client
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
export default AllClients;
