import React, { useState} from 'react';
import "./products.css";
import { Container } from 'react-bootstrap';
import DataTable from '../DataTable';
import { getProductDetails, postProductsSearch } from '../apiCalls';
import { ExternalLinkAlt } from "@styled-icons/fa-solid";
import { useSecurity } from "../Security/security";

const ProductsList = ({products, setProduct, setEditCreate, setProducts}) => {

  const [requestFailed, setRequestFailed] = useState(false);
  const { securityState, validateRole } = useSecurity();

  const getProduct = async (id) => {
      try {
        const data = await getProductDetails(id);
        setProduct(data[0]);
      } catch (err) {
        console.log(err);
        setRequestFailed(true);
      }
    }

    const handleSearch = async (data) => {
        const response = await postProductsSearch(data)
        setProducts(response);
      }

  if (requestFailed) return <p>Failed!</p>;


  return (


    <Container className="container-md">
        <section className='floating-box' style={{maxWidth: '1600px'}}>
          <div className="floating-box-header-general">

            <span>Products</span>
          </div>
            <div className="px-3 pt-4" >
              <DataTable filterable sortable data={products} className="table-striped" advancedSearch={{onSubmit: handleSearch}} showAdvnSearch={!validateRole({department: 'TRADER'}) && !validateRole({department: 'LSP'}) }>
              <DataTable.Headers style={{fontSize: "14.4px", paddingBottom: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', borderTop: '1px solid #ddd'}} />
              <DataTable.Pagination itemsPerPage={[10, 25, 50]} areaPadding={'0px'} />
              <DataTable.Head>
              <section className="d-flex justify-content-end datatable-button">
                  <button type="button" className="stepper-button yellow" onClick={() => {setEditCreate("create"); setProduct({});}} style={{gap: '0.4rem'}}>
                          New
                      </button>
                </section>
              </DataTable.Head>
              <DataTable.Column colKey="Code or SKU" header="Code or SKU" searchOpts={{type: "text", sendAs: "product"}} />
              <DataTable.Column colKey="Commodity Code" header="Commodity Code" searchOpts={{type: "text", sendAs: "commodityCode"}} />
              <DataTable.Column colKey="Country" header="Country" searchOpts={{type: "text", sendAs: "country"}} />
              <DataTable.Column colKey="Customs Description" header="Customs Description" searchOpts={{type: "text", sendAs: "description"}} >
                {item => item['Customs Description']?.slice(0, 30) + (item['Customs Description']?.length > 30 ? "..." : "")}
              </DataTable.Column>
              <DataTable.Column colKey="Meursing Code" header="Meursing Code" searchOpts={{type: "text", sendAs: "meursing"}} />
              <DataTable.Column header="View" style={{height: '1px', padding: 0}} headerStyle={{textAlign: 'center'}}>
                {query => (
                    <button className="external-table-link" onClick={() => {setEditCreate("edit"); getProduct(query.id) }}>
                    <ExternalLinkAlt width="1.1rem" height="1.1rem" color="#00857d" />
                    </button>
                )}
            </DataTable.Column>
              </DataTable>
            </div>
            <span className="bottomAttempt"></span>
        </section>
 
    </Container>


  )
}




export default ProductsList;