import { useContext, useEffect, useMemo, useState } from "react";
import { EpoJobDetailContext } from "../EpoJobDetails";
import { Input, Switch } from "../../../Input/Input";
import { Alert, Collapse } from "react-bootstrap";
import { putEPO, getEPO } from "../../../apiCalls";
import { Col, Modal, Row } from "react-bootstrap";
import successIcon from "../../../../images/successIcon.svg";
import { useNavigate } from "react-router-dom";
import isoDateConverter from "../../../isoDateConverter";
import { ArrowDown, LongArrowAltDown, LongArrowAltRight } from "@styled-icons/fa-solid";
import { ShowIf } from "../../../Security/security";
import { Link } from "react-router-dom";
import { StoreContext } from "../../../Store";
import { putEPOStatus } from "../../../apiCalls";

export default function EpoJobSummary() {
  const { jobDetails, canSave, isCustomer,showArrivalModal, setShowArrivalModal, confirmEPO } = useContext(EpoJobDetailContext);
  const navigate = useNavigate();
  const [epoSubmitModalDetails, setSubmitModalDetails] = useState(null);
  const [epoItems, setEpoItems] = useState(false);

  useEffect(() => {
    async function fetch() {
      const result = await getEPO(jobDetails.newLoadId, "ItemsCheck");
      setEpoItems(result.hasItems)
    }
    fetch();
  }, [jobDetails]);

  const handleSubmit = async ({ date, time }) => {
    const dt = new Date(`${date} ${time}`); // since user enters in local time; get a date object to get UTC (27102022: removed this as don-t want UTC)
    const result = await putEPO({ arrivalDateTime: dt }, "Submit", jobDetails.newLoadId);
        // if (result?.[0]?.completed === "Submitted") {
        //   setSubmitModalDetails({
        //     title: "Express Lane Load Submitted",
        //     icon: successIcon,
        //     body: "This load has been submitted successfully.",
        //   });
        // }
    confirmEPO();
  };
  return (
    
    <div className="d-flex flex-column w-75 mx-auto my-4 p-4 border shadow" style={{ borderRadius: '15px' }}>
       {epoItems === 'true' && (
              <>
              <div className="registrationText mb-2" style={{ fontSize: '1.2rem' }}>Confirmation of Submission</div>
            
              <ShowIf department="DCT">
                <DctConfirmations />
              </ShowIf>

              <ShowIf department="OPS">
                <OpsConfirmations />
              </ShowIf>

              {/* <ShowIf department="FIN">
                <FinanceConfirmations />
              </ShowIf> */}


            <ShowIf or={[{department: "OPS", role: "SUPERUSER"}]}>
                <FinanceConfirmations />
              </ShowIf>

              <EpoConfirmedModal details={epoSubmitModalDetails} onClose={() => navigate("/EpoListLoads")} />
              </>
              )}
              
        {epoItems === 'false' && (
        <div className="inputs-group register-info mb-4 alert-danger" style={{ fontSize: '1rem' }}>
          <div className="warning" />
        
          <span style={{ fontWeight: 400, fontSize: "15px" }}>
                <ul style={{ margin: 0, padding: 0 , listStyleType: 'none' }}>
                <li style={{ fontWeight: 700, fontSize: "16px" }}>Please note the following before job confirmation</li>
                    <li> - A minimum of <b>one</b> invoice item line must be inputted </li>
                    <li> - An invoice is currently saved as a <b>draft</b></li>
                </ul>
            </span>
          </div>
        )} 


    </div>
  );
}

const DctConfirmations = () => {
  const { jobDetails, updateJobDetails, setTab, setSuccessText, confirmEPO } = useContext(EpoJobDetailContext);
  const [clickCount, setClickCount] = useState(0);

  const handleClick = async () => {
    setClickCount(((preCount) => preCount + 1));
    const response = await putEPOStatus({
      id: jobDetails.newLoadId,
      dutyRequired: jobDetails.dutyDue
    });
    setTab('headers');
    setSuccessText("Successfully submitted.")
    setTab('headers');
    updateJobDetails('EPOStatus', response.Status)
    confirmEPO();
  }
  return (
    <div>
      <div className="inputs-group register-info mb-4">
        <div className="warning" />
        <span>
          By pressing submit below, this consignment will be marked as now <b>Ready to be Checked</b>. Please ensure you reviewed all entered data to prevent a false declaration into customs.
        </span>
      </div>
      <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
        <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
        <span>By ticking, you are indicating that all data from uploaded invoices has been entered digitally, and that the entered data is correct and accurate.</span>
      </label>
      <div className="d-flex justify-content-end mt-3">
        <button type="button" class="epo-submit-button" disabled={!jobDetails.everythingChecked || clickCount>=1} onClick={handleClick}>
          Submit
        </button>
      </div>
    </div>
  )
}

const OpsConfirmations = () => {
  const { jobDetails, canSave, isCustomer,updateJobDetails, setShowArrivalModal, setTab, confirmEPO } = useContext(EpoJobDetailContext);
  const [clickCount, setClickCount] = useState(0);

  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const handleClick = async () => {
    setClickCount(((preCount) => preCount + 1));
    const response = await putEPOStatus({
      id: jobDetails.newLoadId,
      dutyRequired: jobDetails.dutyDue
    });
    setTab('headers');
    updateJobDetails('EPOStatus', response.Status)
    confirmEPO();
  }

  const finalSubmit = async () => {
      setClickCount(((preCount) => preCount + 1));
      await putEPO({ arrivalDateTime: null }, "Submit", jobDetails.newLoadId);  
      confirmEPO();
  }

  if (["A","G","H","I","J"].includes(jobDetails.epoScore )) {
    return (
      <div>
        <div className="inputs-group register-info mb-4">
          <div className="warning" />
          <span>
            By pressing submit below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously entered to ensure a false declaration is not produced into customs.
          </span>
        </div>
        <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
          <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
          <span>By ticking, you are confirming that you have reviewed all invoices and inputted data against this consignment.</span>
        </label>
        <div className="d-flex justify-content-end mt-3">
          <button type="button" class="epo-submit-button" disabled={!jobDetails.everythingChecked || clickCount>=1} onClick={finalSubmit}>
            Submit
          </button>
        </div>
      </div>
    )
    
  }

  if (jobDetails.epoScore === "B") {
    return (
      <div>
        <div className="inputs-group register-info mb-2" style={{ fontSize: '0.85rem' }}>
          <div className="warning" />
          <span>
            By pressing submit below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously entered to ensure a false declaration is not produced into customs.
          </span>
        </div>
        <div className="inputs-group register-info mb-4 alert-warning" style={{ fontSize: '0.8rem' }}>
          <span>Please note: this consignment is arriving into a mixed-model port, and may require a C21 process to be completed.</span>
        </div>
        <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
          <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
          <span>By ticking, you are confirming that you have reviewed all invoices and inputted data against this consignment.</span>
        </label>
        <label className="d-flex align-items-center gap-1 mt-3 mb-2 justify-content-start label-text">
          <input type="checkbox" checked={!!jobDetails.c21acknowledged} onChange={e => updateJobDetails('c21acknowledged', e.target.checked)} />
          <span>By ticking, you are acknowledging the possible C21 process requirement for this consignment.</span>
        </label>
        <div className="d-flex justify-content-end mt-3">
          <button type="button" class="epo-submit-button" disabled={!(jobDetails.everythingChecked && jobDetails.c21acknowledged) || clickCount>=1} onClick={finalSubmit}>
            Submit
          </button>
        </div>
      </div>
    )
  }

  if (jobDetails.epoScore === "C") {
    if (jobDetails.ddaAuthorised) {
      // we have the DDA, proceed.
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              By pressing submit below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously entered to ensure a false declaration is not produced into customs.
            </span>
          </div>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.c1207confirmed} onChange={e => updateJobDetails('c1207confirmed', e.target.checked)} />
            <span>By ticking, you are confirming that the trader has uploaded a valid C1207N form.</span>
          </label>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
            <span>By ticking, you are confirming that you have reviewed all invoices and inputted data against this consignment.</span>
          </label>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled={!(jobDetails.everythingChecked && jobDetails.c1207confirmed) || clickCount>=1} onClick={finalSubmit}>
              Submit
            </button>
          </div>
        </div>
      )
    } else {
      // NO C1207N form, do not allow them to submit.
      return (
        <div>
          <div className="inputs-group register-info mb-4 alert-danger" style={{ fontSize: '0.9rem' }}>
            <div className="warning" />
            <span style={{ fontWeight: 500 }}>Warning: This trader does not have a C1207N on file and is submitting goods without EU preference. Please contact the customer and ask them to upload their C1207N and DDA number into their company profile.</span>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled>
              Continue
            </button>
          </div>
        </div>
      )
    }
  }

  if (jobDetails.epoScore === "D") {
    if (jobDetails.ddaAuthorised) {
      // we have the DDA, proceed.
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              By pressing submit below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously entered to ensure a false declaration is not produced into customs.
            </span>
          </div>
          <div className="inputs-group register-info mb-4 alert-warning" style={{ fontSize: '0.8rem' }}>
            <span>Please note: this consignment is arriving into a mixed-model port, and may require a C21 process to be completed.</span>
          </div>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.c1207confirmed} onChange={e => updateJobDetails('c1207confirmed', e.target.checked)} />
            <span>By ticking, you are confirming that the trader has uploaded a valid C1207N form.</span>
          </label>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
            <span>By ticking, you are confirming that you have reviewed all invoices and inputted data against this consignment.</span>
          </label>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.c21acknowledged} onChange={e => updateJobDetails('c21acknowledged', e.target.checked)} />
            <span>By ticking, you are acknowledging the possible C21 process requirement for this consignment.</span>
          </label>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled={!(jobDetails.everythingChecked && jobDetails.c1207confirmed && jobDetails.c21acknowledged) || clickCount>=1} onClick={finalSubmit}>
              Submit
            </button>
          </div>
        </div>
      )
    } else {
      // NO C1207N form, do not allow them to submit.
      return (
        <div>
          <div className="inputs-group register-info mb-4 alert-danger" style={{ fontSize: '0.9rem' }}>
            <div className="warning" />
            <span style={{ fontWeight: 500 }}>Warning: This trader does not have a C1207N on file and is submitting goods without EU preference. Please contact the customer and ask them to upload their C1207N and DDA number into their company profile.</span>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled>
              Continue
            </button>
          </div>
        </div>
      )
    }
  }


  //////////////////
  // S C O R E  -E-
  //////////////////

  if (jobDetails.epoScore === "E") {
    if (jobDetails?.EPOStatus === "On Hold (Potential Duty)" || jobDetails?.EPOStatus ==='Ready to be checked (Potential Duty)') {
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              The goods inputted by the customer may incur duty but no DDA is present for the customer. Please review the invoice(s) and indicate if duty is due below.
            </span>
          </div>

          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column pb-4">
              <div className="d-flex py-1">
                <span className="d-inline-flex align-items-center">Do the invoiced goods incur a non-zero duty rate?</span>
              </div>

              <div className="d-inline-flex align-items-center gap-2 my-2">
                <input type="radio" checked={jobDetails.dutyDue === true} onChange={() => updateJobDetails('dutyDue', true)} />
                <span>Yes (duty &gt; 0%)</span>
              </div>

              <label className="d-inline-flex align-items-center gap-2">
                <input type="radio" checked={jobDetails.dutyDue === false} onChange={() => updateJobDetails('dutyDue', false)} />
                <span>No (duty = 0%)</span>
              </label>
            </div>
          </div>

          <div className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.dutyAck} onChange={e => updateJobDetails('dutyAck', e.target.checked)} />
            <span>By ticking, you are acknowledging that incorrectly answering above could result in EORI being liable to pay duty on these goods.</span>
          </div>
          <div className="d-flex flex-row py-3 px-3 mt-4 gap-3 border-top align-items-center">
            <div className="flex-grow-1" />
            <button type="button" class="stepper-button green" disabled={!(jobDetails.dutyAck && jobDetails.dutyDue === true)} onClick={handleClick}>
              Update
            </button>
            <button type="button" class="stepper-button red" disabled={!(jobDetails.dutyAck && jobDetails.dutyDue === false) || clickCount>=1} onClick={finalSubmit}>
               Submit
            </button>
          </div>
        </div>
      )
    }
    if (jobDetails?.EPOStatus === "Awaiting Proof of Payment") {
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              If you have received a proof of payment from the customer, please <button className="btn-link border-0 bg-transparent p-0" onClick={() => {
                store.newLoadID[1](jobDetails.newLoadId);
                navigate(`/AdditionalEPOFileUpload/${jobDetails.newLoadId}`)
              }}>
                upload this
              </button> against the consignment as "Document for EORI". Once done, confirm the below.
            </span>
          </div>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.proofOfPaymentUploaded} onChange={e => updateJobDetails('proofOfPaymentUploaded', e.target.checked)} />
            <span>By ticking, you are confirming that you have uploaded the traders proof of payment against this consignment.</span>
          </label>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled={!(jobDetails.proofOfPaymentUploaded)} onClick={handleClick}>
              Continue
            </button>
          </div>
        </div>
      )
    }
    if (jobDetails?.EPOStatus === "Ready to be checked") {
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              By pressing submit below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously entered to ensure a false declaration is not produced into customs.
            </span>
          </div>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
            <span>By ticking, you are confirming that you have reviewed all invoices and inputted data against this consignment.</span>
          </label>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.dutyPaid} onChange={e => updateJobDetails('dutyPaid', e.target.checked)} />
            <span>By ticking, you are acknowledging that duty has been paid against this manually. Please refer to the <button className="btn-link border-0 bg-transparent p-0" onClick={() => {
              store.newLoadID[1](jobDetails.newLoadId);
              navigate('/EPODownloads')
            }}>
              attached files
            </button> for more information.</span>
          </label>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled={!(jobDetails.everythingChecked && jobDetails.dutyPaid) || clickCount>=1} onClick={finalSubmit}>
              Submit
            </button>
          </div>
        </div>
      )
    }

    // Finance have now confirmed the duty fully paid and the score 'E' job is free to proceed.
    if (jobDetails?.EPOStatus === "In progress") {
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              By pressing submit below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously entered to ensure a false declaration is not produced into customs.
            </span>
          </div>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
            <span>By ticking, you are confirming that you have reviewed all invoices and inputted data against this consignment.</span>
          </label>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled={!(jobDetails.everythingChecked) || clickCount>=1} onClick={finalSubmit}>
              Submit
            </button>
          </div>
        </div>
      )
    }
  }


  //////////////////
  // S C O R E  -F-
  //////////////////
  if (jobDetails.epoScore === "F") {
    if (jobDetails?.EPOStatus === "On Hold (Potential Duty)" || jobDetails?.EPOStatus === "Ready to be checked (Potential Duty)") {
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              The goods inputted by the customer may incur duty but no DDA is present for the customer. Please review the invoice(s) and indicate if duty is due below.
            </span>
          </div>

          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column pb-4">
              <div className="d-flex py-1">
                <span className="d-inline-flex align-items-center">Do the invoiced goods incur a non-zero duty rate?</span>
              </div>

              <div className="d-inline-flex align-items-center gap-2 my-2">
                <input type="radio"  disabled={!canSave || isCustomer} checked={jobDetails.dutyDue === true} onChange={() => updateJobDetails('dutyDue', true)} />
                <span>Yes (duty &gt; 0%)</span>
              </div>

              <div className="d-inline-flex align-items-center gap-2">
                <input type="radio" checked={jobDetails.dutyDue === false} onChange={() => updateJobDetails('dutyDue', false)} />
                <span>No (duty = 0%)</span>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.dutyAck} onChange={e => updateJobDetails('dutyAck', e.target.checked)} />
            <span>By ticking, you are acknowledging that incorrectly answering above could result in EORI being liable to pay duty on these goods.</span>
          </div>
          <div className="d-flex flex-row py-3 px-3 mt-4 gap-3 border-top align-items-center">
            <div className="flex-grow-1" />
            <button type="button" class="stepper-button green" disabled={!(jobDetails.dutyAck && jobDetails.dutyDue === true)} onClick={handleClick}>
              Update
            </button>
            <button type="button" class="stepper-button red" disabled={!(jobDetails.dutyAck && jobDetails.dutyDue === false) || clickCount>=1} onClick={finalSubmit}>
               Continue
            </button>
          </div>

        </div>
      )
    }
    if (jobDetails?.EPOStatus === "Awaiting Proof of Payment") {
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              If you have received a proof of payment from the customer, please <button className="btn-link border-0 bg-transparent p-0" onClick={() => {
                store.newLoadID[1](jobDetails.newLoadId);
                navigate(`/AdditionalEPOFileUpload/${jobDetails.newLoadId}`)
              }}>
                upload this
              </button> against the consignment as "Document for EORI". Once done, confirm the below.
            </span>
          </div>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.proofOfPaymentUploaded} onChange={e => updateJobDetails('proofOfPaymentUploaded', e.target.checked)} />
            <span>By ticking, you are confirming that you have uploaded the traders proof of payment against this consignment.</span>
          </label>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled={!(jobDetails.proofOfPaymentUploaded) || clickCount>=1} onClick={handleClick}>
              Continue
            </button>
          </div>
        </div>
      )
    }
    if (jobDetails?.EPOStatus === "Ready to be checked") {
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              By pressing submit below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously entered to ensure a false declaration is not produced into customs.
            </span>
          </div>
          <div className="inputs-group register-info mb-4 alert-warning" style={{ fontSize: '0.8rem' }}>
            <span>Please note: this consignment is arriving into a mixed-model port, and may require a C21 process to be completed.</span>
          </div>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
            <span>By ticking, you are confirming that you have reviewed all invoices and inputted data against this consignment.</span>
          </label>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.c21acknowledged} onChange={e => updateJobDetails('c21acknowledged', e.target.checked)} />
            <span>By ticking, you are acknowledging the possible C21 process requirement for this consignment.</span>
          </label>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.dutyPaid} onChange={e => updateJobDetails('dutyPaid', e.target.checked)} />
            <span>By ticking, you are acknowledging that duty has been paid against this manually. Please refer to the <button className="btn-link border-0 bg-transparent p-0" onClick={() => {
              store.newLoadID[1](jobDetails.newLoadId);
              navigate('/EPODownloads')
            }}>
              attached files
            </button> for more information.</span>
          </label>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled={!(jobDetails.everythingChecked && jobDetails.dutyPaid && jobDetails.c21acknowledged) || clickCount>=1} onClick={finalSubmit}>
              Submit
            </button>
          </div>
        </div>
      )
    }
    // Finance have now confirmed the duty fully paid and the score 'E' job is free to proceed.
    if (jobDetails?.EPOStatus === "In progress") {
      return (
        <div>
          <div className="inputs-group register-info mb-4">
            <div className="warning" />
            <span>
              By pressing submit below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously entered to ensure a false declaration is not produced into customs.
            </span>
          </div>
          <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
            <input type="checkbox" checked={!!jobDetails.everythingChecked} onChange={e => updateJobDetails('everythingChecked', e.target.checked)} />
            <span>By ticking, you are confirming that you have reviewed all invoices and inputted data against this consignment.</span>
          </label>
          <div className="d-flex justify-content-end mt-3">
            <button type="button" class="epo-submit-button" disabled={!(jobDetails.everythingChecked) || clickCount>=1} onClick={finalSubmit}>
              Submit
            </button>
          </div>
        </div>
      )
    }
  }

  return null;
}

const FinanceConfirmations = () => {
  const { jobDetails, updateJobDetails, setTab, confirmEPO } = useContext(EpoJobDetailContext);
  const [clickCount, setClickCount] = useState(0);

  const handleClick = async () => {
    setClickCount(((preCount) => preCount + 1));
    const response = await putEPOStatus({
      id: jobDetails.newLoadId,
      dutyRequired: jobDetails.dutyDue
    })
    setTab('headers');
    updateJobDetails('EPOStatus', response.Status)
    confirmEPO();
  }

//   const finalSubmit = async () => {
//     await putEPO({ arrivalDateTime: null }, "Submit", jobDetails.newLoadId);  
//     confirmEPO();
// }

  if (jobDetails?.EPOStatus === "On Hold (Awaiting Duty Invoice)") {
    return (
      <div>
      <div className="inputs-group register-info mb-4">
        <div className="warning" />
        <span>
          By pressing continue below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously
          entered to ensure a false declaration is not produced into customs.
        </span>
      </div>
      <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
        <input type="checkbox" checked={!!jobDetails.invoiceUploaded} onChange={(e) => updateJobDetails("invoiceUploaded", e.target.checked)} />
        <span>I have uploaded a duty invoice to this job</span>
      </label>
      <div className="d-flex justify-content-end mt-3">
        <button type="button" class="epo-submit-button" disabled={!jobDetails.invoiceUploaded || clickCount>=1} onClick={handleClick}>
          Continue
        </button>
      </div>
    </div>
    );
  }
  if (jobDetails?.EPOStatus === "Awaiting Payment Confirmation") {
    return (
      <div>
        <div className="inputs-group register-info mb-4">
          <div className="warning" />
          <span>
            By pressing continue below, you will be producing a declaration into records which cannot be undone. Please carefully review all data previously
            entered to ensure a false declaration is not produced into customs.
          </span>
        </div>
        <label className="d-flex align-items-center gap-1 mt-0 mb-2 justify-content-start label-text">
          <input type="checkbox" checked={!!jobDetails.dutyPaid} onChange={(e) => updateJobDetails("dutyPaid", e.target.checked)} />
          <span>I have validated that the proof of payment is valid, and that duty has been paid for this consignment.</span>
        </label>
        <div className="d-flex justify-content-end mt-3">
          <button type="button" class="epo-submit-button" disabled={!jobDetails.dutyPaid || clickCount>=1} onClick={handleClick}>
            Continue
          </button>
        </div>
      </div>
    );
  }

  return null;
}

const ArrivalTimeModal = ({ show, onHide, onConfirm, header }) => {
  const { jobDetails } = useContext(EpoJobDetailContext);
  const [errorText, setErrorText] = useState("");
  const [arrivalDate, setArrivalDate] = useState();
  const [arrivalTime, setArrivalTime] = useState("00:00");

  useEffect(() => {
    setArrivalDate(jobDetails.newArrivalDate ?? jobDetails.estimatedDateOfLoading);
  }, [jobDetails.newArrivalDate, jobDetails.estimatedDateOfLoading]);

  const validateDateTime = () => {
    const nowDT = new Date();
    const selectedDT = new Date(`${arrivalDate} ${arrivalTime}`);

    if (isNaN(selectedDT)) {
      setErrorText("Please select a valid date and time.");
      return false;
    }

    if (selectedDT > nowDT) {
      setErrorText("Port arrival time cannot be in the future.");
      return false;
    }

    return true;
  };

  const tz = useMemo(() => {
    let mins = -new Date().getTimezoneOffset();
    const isNegative = mins < 0;
    mins = Math.abs(mins);
    let hours = Math.floor(mins / 60);
    mins = mins % 60;

    return `GMT${isNegative ? "-" : "+"}${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  }, []);

  return (
    <Modal centered size="md" animation={false} show={show}>
      <Modal.Header>
        <Modal.Title>Confirm Port Arrival Date</Modal.Title>
        <button type="button" class="btn-close" aria-label="Close" onClick={onHide} />
      </Modal.Header>

      <section>
        <Modal.Body>
          <div class='px-3 d-flex flex-column'>
            <span>Please confirm the arrival date of this load into GB.</span>
            <small>
              Please enter the below date in your <span class="boldblue">local timezone</span> ({tz}).
            </small>
          </div>

          {!!jobDetails.newArrivalDate && (

            <div className="d-flex flex-column mt-4 px-3 mb-2">
              <div>
                <div className="bg-danger px-3 py-2 text-white bold" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", fontSize: '0.85rem' }}>
                  Warning: please note the trader has amended the arrival date.
                </div>
                <div
                  className="alert-secondary px-4 py-2 d-flex justify-content-between align-items-center"
                  style={{
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "1px solid grey",
                    borderRight: "1px solid grey",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <div className="text-center">
                    <header className="fw-bolder">Original Arrival Date</header>
                    <div className="fw-bolder red-text">{jobDetails?.estimatedDateOfLoading}</div>
                  </div>

                  <LongArrowAltRight width="1.5rem" height="1.5rem" />

                  <div className="text-center">
                    <header className="fw-bolder">New Arrival Date</header>
                    <div className="fw-bolder green-text">{jobDetails.newArrivalDate}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div class='py-3 px-3 w-75 mx-auto mb-1 d-flex flex-column gap-2'>
            <Input
              label={"Port Arrival Date"}
              name="date"
              type="date"
              max={new Date().toISOString().slice(0, 10)}
              min={arrivalDate}
              required
              value={arrivalDate}
              onChange={(e) => setArrivalDate(e.target.value)}
            />

            {/* <Input label={`Port Arrival Time`}
              name="time"
              type="time"
              required value={arrivalTime}
              onChange={(e) => setArrivalTime(e.target.value)} /> */}
          </div>

          <div className="px-3 w-75 mx-auto" >
            {errorText && (
              <div className="px-3 py-1 alert-danger w-100" style={{ borderRadius: '10px' }}>{errorText}</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer
          class="py-3 px-3"
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <div className="push-left-right">
            <button
              className="blue-button-newload red"
              onClick={(event) => {
                onHide(event);
                setErrorText("");
              }}
              style={{ display: "flex", borderRadius: "10px" }}
            >
              {" "}
              Back
            </button>
            <button
              className="blue-button-newload green"
              onClick={(event) => {
                setErrorText("");

                if (validateDateTime()) {
                  onConfirm({ date: arrivalDate, time: arrivalTime });
                  onHide(event);
                }
              }}
              style={{ display: "flex", borderRadius: "10px" }}
            >
              Continue
            </button>
          </div>
        </Modal.Footer>
      </section>
    </Modal>
  );
};

const EpoConfirmedModal = ({ onClose, details }) => {
  return (
    <Modal show={details !== null} onHide={() => null} centered size="md">
      <Modal.Header>
        <Modal.Title>{details?.title}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row className="d-flex p-4">
            <Col className="d-flex justify-content-center">
              <img alt="" src={details?.icon} width="75" height="75" />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex p-4 justify-content-center">{details?.body}</Col>
          </Row>
        </div>
      </Modal.Body>

      <Modal.Footer
        class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button type="submit" onClick={onClose} class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};
