import Stepper, { useStepper } from "../../Stepper";
import { Input } from "../../Input/Input";
import refno from "../assets/request.png";
import invoices from "../assets/invoices.png";
import { OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { useRef, useEffect, useState, useContext } from 'react';
import { StoreContext } from "../../Store";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";


export function JobRefPage() {
  const { stepBackward, stepForward, localData, setDataByKey, activeStepKey, gotoStep } = useStepper();
  const [error, setError] = useState("");
  const store = useContext(StoreContext);

  const nextPage = (event) => {
    event.preventDefault();
    if (!localData.jobRef) {
      setError("Please enter a job reference number.");
      return;
    }
    else { if (localData.movementType === "Export") {
      gotoStep("8. EtaExportPort")
    }
  else {
    gotoStep("7. EtaPort")
  }}
  };

  const backwards = () => {
    setError("");
    gotoStep("5. CategoryOfGoods")
};


  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "6. JobReference" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "6. JobReference",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized]);

  useEffect(() => {
    setError("");
  }, [localData.jobRef]);

  return (
    <Stepper.Step stepKey="6. JobReference" text="Your Reference">

<div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{ fontSize:"1.8rem"}}>Your Reference</div>
    <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
      <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
        <input type="radio" disabled checked={localData.movementType === "Import"}/>
        <span>Import</span>
      </label>

      <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px'}}>
        <input type="radio" disabled checked={localData.movementType === "Export"} />
        <span>Export</span>
      </label>
    </div>
        {/* <span className="epo-steps-header">Your Reference</span> */}
        <div className="px-2 mt-1 pt-2 d-flex flex-column" style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
          <div className="d-flex step-banner justify-content-center">
            <img src={invoices} width="150" height="150" alt="port" className=" mt-4 mb-4"  />
            {/* <div class="d-flex flex-column mb-1  px-4" style={{maxWidth: "350px"}}> */}
            {/* <p style={{color: '#0e2554'}}>Please enter your Job Reference or billing ID. This will appear on all future communications and sales invoices.</p> */}
            {/* </div> */}
          </div>
                      <div class="d-flex align-self-center flex-column mt-auto  px-4" style={{maxWidth: "400px"}}>
                      <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}} >Please enter your Job Reference or </span>
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}}>billing ID. This will appear on all future</span>

            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}}>communications and sales invoices.</span>
            {/* <p style={{maxWidth: "280px", fontWeight: "500"}} >Please enter your Job Reference or billing ID. This will appear on all future communications and sales invoices.</p> */}
             </div>
          <div className="d-flex flex-column mt-auto" style={{paddingLeft: '6rem', paddingRight: '6rem'}}>
            <Input
              required
              label="Job Reference or Billing ID"
              labelbold={true}
              name="epojobRef"
              type="text"
              value={localData.jobRef}
              onChange={(e) => setDataByKey("jobRef", e.target.value)}
              className="formattedText"
            />
          </div>
          <div className="px-3 mb-3 w-75 mx-auto">
            {error && (
              <div className="alert-danger px-3 py-1 w-100 text-center" style={{borderRadius: '10px'}}>{error}</div>
            )}
          </div>

          <section className="d-flex justify-content-between px-3 pb-4 mt-auto" >
        <button type="button" className="stepper-button red" onClick={backwards}>
          Back
        </button>
        <button className="stepper-button" onClick={nextPage}>Next</button>
      </section>
      </div>
    </Stepper.Step>
  );
}
