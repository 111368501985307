import DataTable, {useDataTableHandle} from "./DataTable";
import { Trash} from "@styled-icons/fa-solid";
import { Badge, Modal } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import { getEPO, getEPOStatuses, searchEpoList, putArrivalDate, userAdminDeleteEPO, userAdminRestoreEPO } from "./apiCalls";
import { useSecurity } from "./Security/security";
import { StoreContext } from "./Store";
import { useNavigate } from "react-router-dom";

const ConfirmRestorePopup = ({onRestore, EPORef, newLoadId, show, onHide}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Restore {EPORef}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you wish to restore {EPORef}?
      </Modal.Body>
      <Modal.Footer>
        <button className="blue-button-teams green-btn text-decoration-none" onClick={() => {onRestore(newLoadId); onHide()}}>Confirm</button>
        <button className="blue-button-teams green-btn text-decoration-none" onClick={onHide}>Cancel</button>
      </Modal.Footer>
    </Modal>
  )
}

const ConfirmDeletePopup = ({onDelete, EPORef, newLoadId, show, onHide}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Delete {EPORef}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you wish to delete {EPORef}?
      </Modal.Body>
      <Modal.Footer>
        <button className="blue-button-teams green-btn text-decoration-none" onClick={() => {onDelete(newLoadId); onHide()}}>Confirm</button>
        <button className="blue-button-teams green-btn text-decoration-none" onClick={onHide}>Cancel</button>
      </Modal.Footer>
    </Modal>
  )
}

const UserAdminEPO = () => {

  const nonPriorityStatusOrder = {
    "On Hold (Potential Duty)": 1,
    "On Hold (Awaiting Duty Invoice)": 2,
    "Awaiting Proof of Payment": 3,
    "Awaiting Payment Confirmation": 4,
    "New": 5,
    "In progress": 6,
    "submitted": 7,
    "completed": 8
  };

  const [priorityJobs, setPriorityJobs] = useState([]);
  const [showUpdateEta, setShowUpdateEta] = useState(false);
  const [updateEtaRecord, setUpdateEtaRecord] = useState({});

  const { securityState, validateRole } = useSecurity();
  const [statuses, setStatuses] = useState([]);
  const [role, setRole] = useState(false);

  const [EPORefToDelete, setEPORefToDelete] = useState(null);
  const [newLoadIdToDelete, setNewLoadIdToDelete] = useState(null);


  const navigate = useNavigate();
  const [EPORefToRestore, setEPORefToRestore] = useState(null);
  const [newLoadIdToRestore, setNewLoadIdToRestore] = useState(null);


  const openDeletePopup = (newLoadId, EPORef) => {
    setEPORefToDelete(EPORef);
    setNewLoadIdToDelete(newLoadId);
  }


  const closeDeletePopup = () => {
    setEPORefToDelete(null);
    setNewLoadIdToDelete(null);
  }


  const openRestorePopup = (newLoadId, EPORef) => {
    setEPORefToRestore(EPORef);
    setNewLoadIdToRestore(newLoadId);
  }

  const closeRestorePopup = () => {
    setEPORefToRestore(null);
    setNewLoadIdToRestore(null);
  }

  const store = useContext(StoreContext);
  const dth = useDataTableHandle();

  const computeClassName = (value) => {
    if (value === 1) {
      return "bg-success text-uppercase"
    }
    else 
      return " bg-lightblue text-uppercase"
    
  }
  const setEpoJobs = (data) => {
    setPriorityJobs(
      data
        .map((row) => {
          row.newArrivalDate = row.newArrivalDate ?? row.estimatedDateOfLoading;
          return row;
        })
        .sort((a, b) => {
          if (a.Priority > b.Priority) return -1;
          if (a.Priority < b.Priority) return 1;
          const dA = new Date(a.createdDate).getTime();
          const dB = new Date(b.createdDate).getTime();
          if (dA < dB) return -1;
          if (dA > dB) return 1;
          if (dA === dB) return 0;
        })
        .sort((a, b) => {
          if (a.Priority === 0 && b.Priority === 0) {
            const aPriority = nonPriorityStatusOrder[a.epoStatus] || Infinity;
            const bPriority = nonPriorityStatusOrder[b.epoStatus] || Infinity;
            return aPriority - bPriority;
          }
        })
    );
  }


  useEffect(() => {   
      setRole(['LSP', 'TRADER'].some(dep => validateRole({ department: dep})));
      //Reset from previous epo job
      store.sfdSubmitted[1](0);
      store.sdiSubmitted[1](0);
  }, []);

  useEffect(() => {
    async function fetchEPO() {
      const companyid = sessionStorage.getItem('companyid');
      const data = await getEPO(companyid, "HeadersAdmin");
      setEpoJobs(data);
      setStatuses((await getEPOStatuses()).map(status => ({id: status.id, data: status.status})));
    }
    fetchEPO()
  }, [securityState.companyid]);

  const handleSubmit = async (body) => {
    const data = await searchEpoList(body);
    setEpoJobs(data);
  }

  const refreshTable = function() {
    return 1000 - (new Date().getTime() % 1000);
  }

  const deleteEPO = async (id) => {
    await userAdminDeleteEPO(id);

    const companyid = sessionStorage.getItem('companyid');
    const data = await getEPO(companyid, "HeadersAdmin");
    setEpoJobs(data);
  }

  const restoreEPO = async (id) => {
    await userAdminRestoreEPO(id);

    const companyid = sessionStorage.getItem('companyid');
    const data = await getEPO(companyid, "HeadersAdmin");
    setEpoJobs(data);
  }


  return (
    <>
      <ConfirmRestorePopup show={!!newLoadIdToRestore} onHide={closeRestorePopup} onRestore={restoreEPO} EPORef={EPORefToRestore} newLoadId={newLoadIdToRestore} />
      <ConfirmDeletePopup show={!!newLoadIdToDelete} onHide={closeDeletePopup} onDelete={deleteEPO} EPORef={EPORefToDelete} newLoadId={newLoadIdToDelete} />
      <div className="full-width-container">
        <section className="floating-box" style={{ maxWidth: "1530px" }}>
          <div className="floating-box-header-general">
            <span>Express Loads</span>
          </div>
          <div className="px-4 pt-4">
            <div>
            <DataTable dontRefreshPagination idKey="newLoadId" handle={dth} data={priorityJobs} className="table-striped" sortable filterable 
              advancedSearch={{onSubmit: handleSubmit}} refreshTable={refreshTable} showAdvnSearch={!validateRole({department: 'TRADER'}) && !validateRole({department: 'LSP'})}>
              <DataTable.Headers className=" px-2" style={{fontSize: '12.4px',  backgroundColor: '#fff', paddingBottom: '10px', borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd'}} />
              <DataTable.Pagination itemsPerPage={[10, 25, 50]} className="w-100 d-flex justify-content-end" />
              <DataTable.Row style={{fontSize: '14px'}} />
              <DataTable.Column colKey="transferId" header="Our Ref"  searchOpts={{type: "text", sendAs: "refno"}} />
              <DataTable.Column colKey="company" header="Client" />
              <DataTable.Column colKey="originator" header="Created By" />
              <DataTable.Column colKey="jobRef" header="Your Ref" searchOpts={{type: "text", sendAs: "jobReference"}} />
              <DataTable.Column colKey="newArrivalDate" header="Arrival" />
              <DataTable.Column foldable colKey="submittedBy" header="CREATED BY" searchOpts={{type: "select",  src: "data", sendAs: "submitter"}} />
              <DataTable.Column colKey="epoStatus" header="STATUS" searchOpts={{type: "select", src: statuses, sendAs: "status"}} />
              <DataTable.Column foldable colKey="lastModifiedBy" header="UPDATED BY" />
              <DataTable.Column foldable colKey="lastModifiedDate" header="UPDATED">
                {({lastModifiedDate}) => (
                  new Date(lastModifiedDate).toLocaleString()
                )}
              </DataTable.Column>

              { !validateRole({department: 'TRADER'}) && !validateRole({department: 'LSP'}) && 
                <DataTable.Column colKey="Priority" header="Type" searchOpts={{type: "select", src: [{id: 0, data: "Non-Priority"}, {id:1, data: "Priority"}], sendAs: "priority"}}>
                  {({Priority}) => (
                    <Badge className={computeClassName(Priority)}>{Priority === 1 ? 'Priority' : 'Standard'}</Badge>
                  )}
                </DataTable.Column>
              }
              <DataTable.Column header="SFD MRN" colKey="SFDRef"  className="p-0 system-ref" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}} />
              <DataTable.Column header="SDI MRN" colKey="SDIRef"  className="p-0 system-ref" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}} />
            <DataTable.Column foldable header="SDI" colKey="SDI" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
              {({SDI}) => {
                if (SDI === "Completed") return (
                  <div className="tick-success cursor-normal"></div>
                )
                else return null
                }
              }
            </DataTable.Column>
            <DataTable.Column foldable header="SFD" colKey="SFD" className="p-0" style={{ height: "1px" }} headerStyle={{textAlign: 'center'}}>
              {({SFD}) => {
                if (SFD === "Completed") return (
                  <div className="tick-success cursor-normal"></div>
                )
                else return null
                }
                
              }
            </DataTable.Column>
            <DataTable.Column header="Delete" className="p-0">
                {({newLoadId, transferId, deleted}) => {

                  if (!deleted)
                    return (
                        <Trash width="20px" style={{marginTop: '5px', marginLeft: '20px', cursor: 'pointer'}} 
                        onClick={() => openDeletePopup(newLoadId, transferId)}/>
                    )
                    else return (
                      <button style={{marginTop: '5px'}} onClick={() => openRestorePopup(newLoadId, transferId)}>Restore</button>
                    )
                }}

            </DataTable.Column>
            </DataTable>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UserAdminEPO;
