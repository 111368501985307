import React, { useState, useEffect } from 'react';
import './table.css';
import "./report.css";
import { getReportDetails } from './apiCalls';

const ReportDetails = (props) => {

  const [requestFailed, setRequestFailed] = useState(false);
  const [returnData, setReturnData] = useState('');

  const invoiceNumber = props.location.state.detail
  const company = sessionStorage.getItem('company');

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getReportDetails('details', invoiceNumber)
        setReturnData(data[0])
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [invoiceNumber])

  if (requestFailed) return <p>Failed!</p>

  return (
    <div className="main bg-white">
      <section >
        <form id='newload' >
          <div className='reportNewload'>
            <div className='companyname'>
              {company}
            </div>
            <div className='reportyourName'>
              Your Name
            </div>
            <div className='reportnameselect'>
              {returnData.Name}
            </div>
            <div className='movement'>
              Movement Type
            </div>
            <div className='movementselect'>
              {returnData.Movementtype}
            </div>
            <div className='fcl'>
              FCL or LCL
            </div>
            <div className='fclselect'>
              {returnData.fcl}
            </div>
            <div className='truck'>
              Truck/trailer no
            </div>
            <div className='truckselect'>
              {returnData.truck}
            </div>
            <div className='nldate'>
              Loading date
            </div>
            <div className='dateselect'>
              {returnData.Loadingdate}
            </div>
            <div className='countrydespatch'>
              Country of Despatch
            </div>
            <div className='countrydespatchselect'>
              {returnData.Despatch}
            </div>
            <div className='countrydestination'>
              Country of Destination
            </div>
            <div className='countrydestinationselect'>
              {returnData.Destination}
            </div>
            <div className='exitport1'>
              Exit port 1
            </div>
            <div className='exitport1select'>
              {returnData.ExitPort1}
            </div>
            <div className='entryport1'>
              Entry port 1
            </div>
            <div className='entryport1select'>
              {returnData.EntryPort1}
            </div>
            <div className='exitport2'>
              Exit port 2
            </div>
            <div className='exitport2select'>
              {returnData.ExitPort2}</div>
            <div className='entryport2'>
              Entry port 2
            </div>
            <div className='entryport2select'>
              {returnData.EntryPort2}
            </div>
            <div className='reportjobref'>
              Your job ref
            </div>
            <div className='reportjobrefselect'>
              {returnData.Jobreference}
            </div>
            <div className='reportconsignor'>
              Consignor short code
            </div>
            <div className='consignorselect'>
              {returnData.Consignor}
            </div>
            <div className='reportconsignee'>
              Consignee short code
            </div>
            <div className='consigneeselect'>
              {returnData.Consignee}
            </div>
            <div className='reportinvoicenumber'>
              Invoice Number
            </div>
            <div className='reportinvoicenumberselect'>
              {returnData.InvoiceNumber}
            </div>
            <div className='invoicecurr'>
              Invoice Currency
            </div>
            <div className='invoicecurrselect'>
              {returnData.Currency}
            </div>
            <div className='incoterm'>
              Incoterm
            </div>
            <div className='incotermselect'>
              {returnData.Incoterm}
            </div>
            {/* <div className='product1select'>
              {returnData.Movementtype}
              </div>
              <div className='product1cc'>
              {returnData.Movementtype}
              </div>
              <div className='product1origin'>
              {returnData.Movementtype}
              </div>
              <div className='product1descriprion'>
              {returnData.Movementtype}
              </div>
              <div className='product2select'>
              {returnData.Movementtype}
              </div>
              <div className='product2cc'>
              {returnData.Movementtype}
              </div>
              <div className='product2origin'>
              {returnData.Movementtype}
              </div>
              <div className='product2descriprion'>
              {returnData.Movementtype}
              </div>
              <div className='product3select'>
              {returnData.Movementtype}
              </div>
              <div className='product3cc'>
              {returnData.Movementtype}
              </div>
              <div className='product3origin'>
              {returnData.Movementtype}
              </div>
              <div className='product3descriprion'>
              {returnData.Movementtype}
              </div> */}
            <div className='goodsfrom'>
              {returnData.Description}
            </div>
            <div className='truckn'>
              Truck
            </div>
            <div className='truckname'>
              {returnData.truck}
            </div>
            <div className='ead'>
              EAD export declaration
            </div>
            <div className='eaddetails'>
              {returnData.EADexportdeclaration}
            </div>
            <div className='exs'>
              EXS exit safety & security
            </div>
            <div className='exsdetails'>
              {returnData.EXSexitsafetysecurity}
            </div>
            <div className='tad'>
              TAD Transit
            </div>
            <div className='taddetails'>
              {returnData.TADTransit}
            </div>
            <div className='fr'>
              FR import entry
            </div>
            <div className='frdetails'>
              {returnData.FRimportentry}
            </div>
            <div className='ens'>
              ENS entry safety & security
            </div>
            <div className='ensdetails'>
              {returnData.ENSentrysafetysecuritydeclaration}
            </div>
            <div className='eu'>
              EU import entry
            </div>
            <div className='eudetails'>
              {returnData.EUimportentry}
            </div>
            <div className='gb'>
              GB import entry
            </div>
            <div className='gbdetails'>
              {returnData.GBimportentry}
            </div>
            <div className='exp1'>
              Exit port 1 process
            </div>
            <div className='exp1details'>
              {returnData.exitportprocess}
            </div>
            <div className='enp1'>
              Entry port 1 process
            </div>
            <div className='enp1details'>
              {returnData.entryportprocess}
            </div>
            <div className='reportloaddate'>
              Load date
            </div>
            <div className='loaddatedetails'>
              {returnData.Loadingdate}
            </div>
            <div className='traces'>
              TRACES NT
            </div>
            <div className='tracesdetails'>
              {returnData.TRACES}
            </div>
            <div className='ipaffs'>
              IPAFFS
            </div>
            <div className='ipaffsdetails'>
              {returnData.IPAFFS}
            </div>
            <div className='bcp'>
              Border Control Post
            </div>
            <div className='bcpdetails'>
              {returnData.BCP}
            </div>
            <div className='message'>
              * where GVMS is shown this assumes UK port does not have RORO inventory linking
            </div>
            {/* <div className='cc'>
              © Robert Hardy Dec2020
              </div> */}
            {/* <div className='incotermtext'><span className='stretchtext'>{returnData.IncotermText} </span>  </div> */}

          </div>
        </form>
      </section>
    </div>
  )
}

export default ReportDetails;

