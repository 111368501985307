import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './pdf-viewer.css';

// Set the workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({ fileBuffer }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div key={fileBuffer ? fileBuffer.length : 'no-buffer'} >
      <div className='pdf-viewer'
        style={{
          flex: 1,
          overflow: "hidden",
        }}>

        <Document
          file={{ data: fileBuffer }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={3} />
            )
          )}

        </Document>
      </div>
    </div>
  );
};

export default PdfViewer;