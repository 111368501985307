import DataTable from '../DataTable';
import { Container } from 'react-bootstrap'



function TraderList({traders, setTrader}) {


  return (
    <Container className="full-width-container">
    <section className='floating-box' style={{minWidth: '1630px'}}>
      <div className="floating-box-header-general">
        <span>Traders</span>
      </div>
      <div className='px-3 pt-4'>

    <DataTable filterable sortable data={traders} className="table-striped">

    <DataTable.Pagination itemsPerPage={[10, 25, 50]} areaPadding={'0px'} />
    <DataTable.Headers style={{fontSize: "12.4px", backgroundColor: '#fff', paddingBottom: '10px', borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd'}} />
    <DataTable.Row style={{fontSize: '14px'}} />
    
    <DataTable.Column colKey="CompanyName" header="Company Name" headerStyle={{ whitespace: "nowrap", minWidth: "19.1ch" }} ></DataTable.Column>
    <DataTable.Column colKey="firstName" header="First Name" headerStyle={{ whitespace: "nowrap", minWidth: "15ch" }} ></DataTable.Column>
    <DataTable.Column colKey="lastName" header="Last Name" headerStyle={{ whitespace: "nowrap", minWidth: "14.6ch" }} ></DataTable.Column>
    {/* <DataTable.Column colKey="companyPosition" header="Position" ></DataTable.Column> */}
    <DataTable.Column colKey="building" header="Building" ></DataTable.Column>
    <DataTable.Column colKey="street" header="Street" ></DataTable.Column>
    <DataTable.Column colKey="town" header="Town" ></DataTable.Column>
    <DataTable.Column colKey="county" header="County" ></DataTable.Column>
    {/* <DataTable.Column colKey="postcode" header="Postcode" ></DataTable.Column> */}
    {/* <DataTable.Column colKey="country" header="Country" ></DataTable.Column> */}

</DataTable>
</div>

</section>
</Container>
  )
}

export default TraderList
