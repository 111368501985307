import NextIcon from "../../../../images/forward.png";
import PrevIcon from "../../../../images/backward.png";
import DownloadIcon from "../../../../images/download.svg";

const DocsNavControls = ({
  dowloadFile,
  handleNext,
  pageNo,
  handlePrev,
  onClose,
  totalDocs
}) => {
    const pageNumber = `${' '}${pageNo} / ${totalDocs}`;
  return (
    <div className="docs-container xsmall">
      <div className="docs-item">
        <button className="btn" onClick={dowloadFile}>
          <img src={DownloadIcon} width="22" height="22" />
        </button>
      </div>
      <div>
        <button className="btn" onClick={handlePrev} disabled={(pageNo <= 1)? true: false}>
          <img src={PrevIcon} width="22" height="22" />
        </button>
      </div>
      <div>
        <input type="text" id="pageNo" value={pageNumber} readonly="readonly"/>
      </div>
      <div className="docs-item">
        <button className="btn" onClick={handleNext} disabled={(pageNo < totalDocs)? false: true}>
          <img src={NextIcon} width="22" height="22" />
        </button>
      </div>
      <div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default DocsNavControls;
