import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom';
import { Input, Password } from "./Input/Input.js";
import "./login.css";
import 'whatwg-fetch';
import { companylogin } from './apiCalls';
import { useSecurity } from './Security/security.js';
import { MessagingContext} from './MessagingProvider'
import { Popover, OverlayTrigger, PopoverContent } from "react-bootstrap";

const Login = () => {

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate()
  const location = useLocation();
  const { dispatch } = useSecurity();
  const mp = useContext(MessagingContext);

  const [linkWording, setLinkWording] = useState("Forgotten your password?");

  useEffect(() => {
   // Session storage is not updated immediately so need to ensure is purged on mounting
   sessionStorage.removeItem('reg')
   sessionStorage.removeItem('type')
   sessionStorage.removeItem('token')
   sessionStorage.removeItem('company')
   sessionStorage.removeItem('companyid')
   sessionStorage.removeItem('name')
   sessionStorage.removeItem('role')
   sessionStorage.removeItem('trader')
   sessionStorage.removeItem('lsp')
   sessionStorage.removeItem('wlp')
   sessionStorage.removeItem('admin')
   sessionStorage.removeItem('fullName')
   sessionStorage.removeItem('department')
   sessionStorage.removeItem('lastLoggedIn')
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    try {

      const data = await companylogin(event.target)
      sessionStorage.setItem('token', data.token);
      sessionStorage.setItem('company', data.company)
      sessionStorage.setItem('companyid', data.companyid)
      sessionStorage.setItem('name', data.name)
      sessionStorage.setItem('reg', data.reg)
      sessionStorage.setItem('type', data.type)
      sessionStorage.setItem('fullName', data.fullName)
      sessionStorage.setItem('role', data.role)
      sessionStorage.setItem('department', data.department)
      sessionStorage.setItem('admin', data.admin)
      sessionStorage.setItem('trader', data.trader)
      sessionStorage.setItem('lsp', data.lsp)
      sessionStorage.setItem('lsp', data.wlp)
      sessionStorage.setItem('lastLoggedIn', data.lastLoginIn)      
      
        if (data.success === true) {
          dispatch({type: 'login', detail: data});
          mp.login();
          // Redirect to page user originally attempted to access, if set.
          if (location.state && location.state.from) {
            navigate(location.state.from);
          } else 
          if (!data.reg){
            navigate("/userProfile");
           } 
           else {
            navigate("/main");
           }
        }
        else {
          if (data.expired === true) {
            setError("Your password has expired.");
            setLinkWording("Reset Password")
          }
          else {
            setError(data.err);
          }
        }
    } catch (err) {
      console.error(err);
      setError("Server error.");
    }

 
  }
  return (
    <div className="full-width-container ">
      <div className="floating-box login-container">
        <div className="floating-box-header">
          <span>Sign In</span>
        </div>
        <form onSubmit={handleSubmit} id='login-contents'>
          <Input
            name="username"
            className=""
            type="text"
            label="Username"
            value={username}
            onChange={e => setUserName(e.target.value)} />
          <Password
            name="password"
            className=""
            label="Password"
            value={password}
            onChange={e => setPassword(e.target.value)} />
            <span className='text-danger'>{error}&nbsp;</span>
             <div className='push-left-right'>

               <button style={{height: '52px'}} className="blue-button">Login</button>
               <div>
                <div style={{marginTop: '5px'}}>
                <Link className='reset-password' to="/forgotPassword">{linkWording}</Link>
                </div>

               <OverlayTrigger
                placement="auto"
                trigger={["click"]}
                rootClose
                overlay={
                  <Popover style={{ minWidth: "35rem" }}>
                    <PopoverContent>
                      <h6>More info</h6>
                      <p>If you are having trouble logging in please contact us below
          <div className="bold text-eoriblue text-nowrap ms-2 mt-1">P: +44 (0) 333 012 4819</div>
          <div className="bold text-eoriblue ms-2">E: <a className="bold text-eoriblue" href="mailto:cabiesupport@eori.uk?subject=CABIE Issue">CABIEsupport@eori.uk</a></div>
        </p>
                    </PopoverContent>
                  </Popover>
                      }
                    >
                <div className='more-help' style={{marginTop: '5px', marginBottom: '20px', color: "#00857d", fontWeight: "600" }}>Need more help?</div>
              </OverlayTrigger>
               </div>
            </div>
        </form>
      </div>
    </div>
  );
}

export default Login;



