import React, { useState } from 'react';
import { Input } from "../Input/Input.js";

const CustomaiteCustomerValidation = () => {
    const [disableInputs, setDisableInputs] = useState(true);

    const cancelAction = () => {
        alert("cancelAction");
    };

    const createLoad = () => {
        alert("createLoad");
    };

    return (
        <section className="floating-box-newload pt-4" style={{ width: "50%", margin: "0 auto" }}>
            <div className="floating-box-header-newload">
                <span>Create CM8 Validation</span>
            </div>
            <div className="section-group">
                <div className="section">
                    <p className="title">Customer Validation</p>
                    <div className="content">
                        <div className="form-field">
                            <Input
                                label="Invoice Customer"
                                name="invoiceCustomer"
                                type="text"
                                value="PIZZA FRESH LTD"
                                required
                                disabled={true}
                            />
                        </div>
                        <div className="form-field">
                            <Input
                                label="CABIE Status"
                                name="cabieStatus"
                                className="error"
                                type="text"
                                value="On-Hold"
                                required
                                disabled={true}
                            />
                        </div>
                        <div className="form-field">
                            <Input
                                label="Billable Customer"
                                name="billableCustomer"
                                type="text"
                                value="Yes"
                                required
                                disabled={true}
                            />
                        </div>
                        <div className="form-field">
                            <Input
                                label="CM8 Approved"
                                name="customaiteApproved"
                                className="warning"
                                type="text"
                                value="Yes"
                                required
                                disabled={true}
                            />
                        </div>
                        <div className="form-field">
                            <Input
                                label="DB Status"
                                name="dbStatus"
                                type="text"
                                value="Valid"
                                required
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex pb-3 px-3 w-100 justify-content-between">
                <button className="epo-continue-button " type="button" onClick={() => {cancelAction(true)}}>Cancel</button>
                <button className="blue-button-newload" type="button" onClick={() => {createLoad(true)}}>Create Load</button>
            </div>
        </section>
    );
}

export default CustomaiteCustomerValidation;