import React, { useState, useEffect, useContext } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { EllipsisV } from "@styled-icons/fa-solid";
import { getCompanyCountry, deleteCompanyCountry } from '../../apiCalls';
import CountryModal from "./countryModal";
import ConfirmationModal from "../../ConfirmationModal";
import { RefDataContext } from "../Maintenance";

const Country = () => {
  const [country, setCountry] = useState([{ "": "" }])
  const [selectedCountry, setSelectedCountry] = useState("");

  const {setOnAddNew} = useContext(RefDataContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCompanyCountry();
        setCountry(data);
      } catch (err) {
      }
    }
    fetchData();
    setOnAddNew(() => {
      setSelectedCountry({id: -1})
    });
  }, []);

return (<>
<CountryModal country={selectedCountry} onSave={ async () => {
  const data = await getCompanyCountry();
  setCountry(data);
}} onCancel={() => setSelectedCountry(null)} />

<Table className="p-4 rounded-3" striped>
          <thead className="bold fs-5" style={{ color: "rgb(80, 80, 80)" }}>
            <tr>
              <th>Country</th>
              <th>Despatch</th>
              <th>Destinations</th>
              <th>Import Export ID</th>
              <th className="text-nowrap" style={{ width: "0px" }}></th>
            </tr>
          </thead>
          <tbody>
            {/* TODO:PAGINATE ROWS */} 
            {(country).map((country) => ( 
              <React.Fragment key={country.client_id}>
                <CountryRow country={country} setSelectedCountry={setSelectedCountry} onDelete={ async () => {
  const data = await getCompanyCountry();
  setCountry(data);
}}/>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        </>)
}


function CountryRow ({country, setSelectedCountry, child, onDelete}) {
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);

  const handleDelete = async event => {
    try {
      const data = await deleteCompanyCountry(country.id)
      if (data[0].completed === 'deleted') {
        alert("Record Deleted");
        onDelete()
      }
    } catch (err) {
      console.log(err);
      alert('Error, unable to delete')
    }
  }
    return (
      <tr id={country.id} className={`border-top fs-6 ${child ? "" : "bold"}`}>
        <td>{country.country}</td>
        <td>{country.despatch}</td>
        <td>{country.destination}</td>
        <td>{country.importExportId}</td>
        <td>
          <Dropdown className="w-100 h-100 d-grid center">
            <Dropdown.Toggle as="button" className="dot-menu-button">
              <EllipsisV width={"0.5rem"} />
            </Dropdown.Toggle>
  
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={() => setSelectedCountry(country)}>
                View/Edit
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setShowConfirmResetModal(true)}>
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ConfirmationModal
        header="Confirm Deletion"
        show={showConfirmResetModal}
        onHide={() => setShowConfirmResetModal(false)}
        onConfirm={handleDelete}
        annimation={true}
      >
        Are you sure you want to delete this record? 
      </ConfirmationModal>
        </td>
      </tr>
    )
  }

  export default Country; 