import React, { useState, useEffect } from 'react';
import "../main.css";
import "../neeNor/neenor.css";
import { getWLPTraders } from '../apiCalls';
import TraderList from './TraderList';
import TraderDetails from './TraderDetails';


const WlpTrader = () => {

  const [requestFailed, setRequestFailed] = useState('')
  const [traders, setTraders] = useState([{ "": "" }])

  async function fetchData() {
    try {
      const data = await getWLPTraders()
      setTraders(data)
    } catch (err) {
      setRequestFailed(true);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

 
  if (requestFailed) return <p>Failed!</p>


  return (
    <>

              <TraderList
              traders={traders}
              />

    </>
      )
    }


export default WlpTrader;