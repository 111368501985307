import { useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { postCompanyIncoterm, putCompanyIncoterm } from "../../apiCalls";
import { Input } from "../../Input/Input";

const IncotermModal = ({ onSave, onCancel, incoterm, }) => {
const [localIncoterm, setLocalIncoterm] = useState({});

useEffect(() => {
    setLocalIncoterm (incoterm)
}, [incoterm])

async function submitForm(event) {
  event.preventDefault();
  try {
    let data;
    if (localIncoterm.id === -1) {
      data = await postCompanyIncoterm(event.target)
      alert("New record added")
    } else {
      data = await putCompanyIncoterm(event.target)
    }
     if (data[0].completed === 'Updated') {
        alert("Record updated");
      } 
      onSave()
      onCancel()
    } catch (err) {
      console.log(err);
      alert('Error updating record to DB')
    }
  }

  return (
    <Modal show={localIncoterm?.id !== undefined} onHide={onCancel} centered size="lg" contentClassName="editModal" dialogClassName="wide-modal">
      <Modal.Header>
        <Modal.Title>Editing: {localIncoterm?.IncotermCode}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onCancel} />
      </Modal.Header>
      <Form onSubmit={submitForm} className="flex-grow-1 d-flex flex-column">
        <Modal.Body className="flex-grow-1">
        <Row>
            <Col>
            <input
                    type='number'
                    name = 'id'
                    value = {localIncoterm?.id}
                    hidden
                />
                <Input
                    name="incotermCode"
                    type="text"
                    label="Incoterm Code"
                    required
                    value={localIncoterm?.IncotermCode}
                    onChange={e => setLocalIncoterm(prev => ({...prev, IncotermCode:e.target.value}))}
                />
            </Col>
          <Row>
            <Col>
                <Input
                    name="incotermText"
                    type="text"     
                    label="Incoterm Text"
                    required
                    value={localIncoterm?.IncotermText}
                    onChange={e => setLocalIncoterm(prev => ({...prev, IncotermText:e.target.value}))}
                    style={{}}
                />
            </Col>
          </Row>
        </Row>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
          style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
        >
          <button type="button" class="cancel-button" onClick={onCancel} style={{ display: "flex", borderRadius: "10px" }}>
            Cancel
          </button>
          <button type="submit" class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
            Save Client
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default IncotermModal;