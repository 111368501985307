
import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import { Input } from "./Input/Input.js";
import "./login.css";
import 'whatwg-fetch';
import { postPasswordreset } from './apiCalls';


const ForgotPassword = () => {

  const [username, setUserName] = useState("");
  const [passwordText, setPasswordText] = useState("")
  const navigate = useNavigate()

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const data = await postPasswordreset(event.target)
      if (data.email === 'Invalid') {
        setPasswordText('Please check your email address');
        setUserName('');
      }
      if (data.email === 'Password reset email sent.'){
        navigate("/passwordResetSent");
      }
      if (data.email === 'Password was changed only recently.'){
        setPasswordText(data.email);
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="full-width-container ">
      <div className="floating-box login-container">
        <div className="floating-box-header">
          <span>Reset Password</span>
        </div>
        <div className="sub-heading">Enter your registered email address in the field below, then check your email inbox (including spam folders).  </div>
        <div className="sub-heading password-error2">{passwordText}</div>
        <form onSubmit={handleSubmit} id='login-contents'>
          <Input
            name="username"
            className=""
            type="text"
            label="Username"
            value={username}
            onChange={e => setUserName(e.target.value)} required />
             <div className='push-left-right'>
               <button type="submit" className="blue-button">Continue</button>
            </div>
        </form>
      </div>
    </div>
    
  );
}

export default ForgotPassword
