/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import { Container, Table, OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap'
import { Input, Select, InputSelect, FilterableSelect } from "../Input/Input";
import NeeNorClientID from './NeeNorClientID';
import { checkEORIvalid, checkVatValid, putNeeNor, deleteNeeNorDetails, postNeeNor, getNewLoad} from "../apiCalls";
import { Modal } from "react-bootstrap";

function NeeNorDetails({updateNeeNor, neenor, editCreate, back, isRow}) {
  const [eoriData, setEoriData] = useState({
    valid: false,
    companyName: "",
    eori: ""
  });

  const [countryList, setCountryList] = useState([]);
  const [clientIDFormOpen, setClientIDFormOpen] = useState(false);
  const [errors, setErrors] = useState({})
  const [isEditing, setIsEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [popupReturns, setPopupReturns] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [saveClicked, setSavelicked] = useState(false);

  const [isRowCountry, setRowCountry] = useState(false);

  const fieldsEditable = useMemo(() => {
    return editCreate === 'create' || isEditing
  }, [editCreate, isEditing])

  const formFieldCharacterLimit = 30;

  useEffect(() => {
    async function fetch () {
      const data = await getNewLoad('countrycodesNeenor')
      setCountryList(data);
    }
    fetch();
  }, [])


  const validateEori = async () => {   
      try {
        const resp = await checkEORIvalid(neenor.eori);
        if (resp.statusDescr === "In Valid") {
          return {failed: true, text: 'Invalid EORI No.'}
        } else {
          return {failed: false, text: ''}
        }
    } catch {
        return {failed: true, text: 'Unable to validate'}
    }
  }


  const countryChanged = (newSelection) => {
    if (newSelection) {
      setRowCountry(newSelection.ROW);
      // console.log("newSelection.ROW): ", newSelection.ROW)
    } 
  }

  const getNameFromEori = async () => {
    if (!isRowCountry && saveClicked) {
    setErrors((prev => ({...prev, eori: undefined})))
    try {
          const resp = await checkEORIvalid(neenor.eori);
          if (resp.statusDescr === "In Valid") {
            setErrors((prev) => ({ ...prev, eori: "Invalid EORI No." }));
           // updateNeeNor('eori', eoriData.eori);
            setEoriData(prev => ({...prev, valid: true}));
          } else {
            setEoriData({ valid: true, companyName: resp.name, eori: neenor.eori});
            updateNeeNor('companyName', resp.name);
              if (resp.name) {
                setErrors((prev) => ({ ...prev, companyName: undefined }));
              }
              getNameFromVat(neenor.eori);
            
          }
    } catch {
      setErrors((prev) => ({ ...prev, eoriNumber: "Unable to validate" }));
      //updateNeeNor('eori', eoriData.eori);
      setEoriData(prev => ({...prev, valid: true}));
    }
  }
  };

  const getNameFromVat = async (vatNumber) => {
    if (!vatNumber) {
      updateNeeNor("building", "");
      updateNeeNor("street", "");
      updateNeeNor("town", "");
      updateNeeNor("county", "");
      updateNeeNor("postcode", "");
      updateNeeNor("country", "");
      return;
    }
    try {
      setErrors((prev) => ({ ...prev, vatNumber: undefined }));
      const resp = await checkVatValid(vatNumber);
      updateNeeNor("building", resp.building);
      updateNeeNor("street", resp.street);
      updateNeeNor("town", resp.city);
      updateNeeNor("county", resp.county);
      updateNeeNor("postcode", resp.postcode);
      updateNeeNor("country", resp.countryCode);
    } catch {
      console.log("Error")
    }
  };

  const validateInputs = () => {
    
    const freshErrors = {}
    if (!neenor.shortCode) {
      freshErrors.shortCode = "Required"
    } else
    {
      if (neenor.shortCode?.length > formFieldCharacterLimit) freshErrors.shortCode = `Must be no more than ${formFieldCharacterLimit} characters`
    }
    
    if (!isRowCountry) {
        if (!neenor.eori) freshErrors.eori = "Required"
        if (!freshErrors.eori) {
          return validateEori()?.then(validation => {
            const { failed, text } = validation;
            if (failed) freshErrors.eori = text;
            // Resolve the promise with freshErrors
            return freshErrors;
        });
        }
    }else{
      if(neenor.eori){
        return validateEori()?.then(validation => {
          const { failed, text } = validation;
          if (failed) freshErrors.eori = text;
          // Resolve the promise with freshErrors
          return freshErrors;
       });
      }      
    }
    if (!neenor.companyName) freshErrors.companyName = "Required"
    if (!neenor.building) freshErrors.building = "Required"
    if (!neenor.street) freshErrors.street = "Required"
    if (!neenor.town) freshErrors.town = "Required"
    if (!neenor.postcode) freshErrors.postcode = "Required"
    if (!neenor.country) freshErrors.country = "Required"
    return Promise.resolve(freshErrors);
  }

  const handleSubmit = () => {
    const freshErrors = validateInputs();    
    setErrors(freshErrors);
    if (!Object.values(freshErrors).some(err => !!err)) {
      sendUpdate();
    }
  }

  const handlePost = () => {
    setSavelicked(true);
    validateInputs().then(result => {
         setErrors(result);
         if (!Object.values(result).some(err => !!err)) {
           sendPost();
           setSavelicked(false);
         }
        });
  }

  const sendPost = async () => {
        try {
          const data = await postNeeNor(neenor)
          if (data[0].completed !== 'Inserted') {
            const freshErrors = {};
            freshErrors.eori = data[0].eoriError;
            freshErrors.companyName = data[0].companyNameError;
            freshErrors.shortCode = data[0].shortCodeError;
            setErrors(freshErrors);
          }
          else {
            setPopupText('NeeNor inserted')
            setShowPopup(true);
            setPopupReturns(true);
          }
        } catch (err) {
          console.log(err)
        }
  }

  const sendUpdate = async () => {
        try {
      const data = await putNeeNor(neenor)
      if (data[0].completed === 'Updated') {
        setPopupText('Record updated')
        setShowPopup(true);
        setPopupReturns(true);
      }
      if (data[0].completed !== 'Updated') {
        const freshErrors = {};
        freshErrors.eori = data[0].eoriError;
        freshErrors.companyName = data[0].companyNameError;
        freshErrors.shortCode = data[0].shortCodeError;
        setErrors(freshErrors);
    }
    } catch (err) {
      console.log(err)
    }
  }
    const handleDelete = async () => {
        setShowDeletePopup(true);
        setPopupText("Are you sure you wish to delete this NeeNor?")
  }

  useEffect(() => {
    const fn = async () => {
      if (deleteRecord) {
        const data = await deleteNeeNorDetails(neenor.id)
        if (data[0].completed ==='Deleted') {
          setPopupText('Record deleted')
          setShowPopup(true);
          setPopupReturns(true);
        }
        if (data[0].completed !=='Deleted') {
            console.log(data[0].completed)
            setPopupText('Error deleting record')
            setShowPopup(true);
            setPopupReturns(false); 
        }
        setDeleteRecord(false);
      }
    }
    fn();
  }, [deleteRecord])

  if (clientIDFormOpen)
    return (
      <NeeNorClientID 
        updateNeenor={updateNeeNor}
        onHide={() => setClientIDFormOpen(false)}
        neenor={neenor}
      />
    )

  return (
    <Container className="container-md d-flex justify-content-center">
    <section className='neenor-details-container pb-3'>
      <div className="neenor-title">
      <span>Neenor<span style={{ fontSize: "1.0rem", fontStyle: "italic" }}> {(editCreate === 'create' ? ' - New ' : ' - Edit ' ) +  'details'}</span></span>
      </div>
      {editCreate === 'edit' && 
      (
        <div className="d-flex justify-content-end" style={{paddingRight: '20px'}}>
        <button className='stepper-button green'
        disabled={isEditing}
        onClick={() => {setIsEditing(true)}}>Edit</button>
      </div>
      )}

      <div className='neenor-details-section'>

      <div className='d-flex row'>
          <div className='col'>
            <Input 
            label={<>Short Code <span style={{ fontSize: "120%" }}>* <span style={{ fontStyle: "italic", fontSize: "70%" }}>(max. {formFieldCharacterLimit} characters)</span></span></>}
            error={errors.shortCode} 
            disabled={!fieldsEditable}
            value={neenor.shortCode} 
            onChange={(e) => {updateNeeNor('shortCode', e.target.value)}} 
            maxLength={formFieldCharacterLimit}
            />
          </div>
          <div className='col ml-2'>
          </div>
        </div>

        <div className='d-flex row'>
          <div className='col'>
            <Input 
            label={<>NeeNor EORI No. <span style={{ fontSize: "120%" }}>*</span></>}
            error={errors.eori} 
             
            value={neenor.eori} 
            onChange={(e) => {updateNeeNor('eori', e.target.value)}}
            onBlur={getNameFromEori}
            disabled={editCreate !== 'create'} 
            />
          </div>

          <div className='col ml-2'>
            <Input 
            label={<>Company Name <span style={{ fontSize: "120%" }}>*</span></>}
            error={errors.companyName} 
            disabled={!fieldsEditable}
            value={neenor.companyName} 
            onChange={(e) => {updateNeeNor('companyName', e.target.value)}} 
            />
          </div>

        </div>

        <div className='d-flex row'>
          <div className='col'>
            <Input 
            label={<>Building name/number <span style={{ fontSize: "120%" }}>*</span></>}
            error={errors.building} 
            disabled={!fieldsEditable}
            value={neenor.building} 
            onChange={(e) => {updateNeeNor('building', e.target.value)}} 
            />
          </div>
          <div className='col ml-2'>
            <Input 
            label={<>Street <span style={{ fontSize: "120%" }}>*</span></>}
            error={errors.street} 
            disabled={!fieldsEditable}
            value={neenor.street} 
            onChange={(e) => {updateNeeNor('street', e.target.value)}} 
            />
          </div>
        </div>

        <div className='d-flex row'>
          <div className='col'>
            <Input 
            label={<>Town/City <span style={{ fontSize: "120%" }}>*</span></>}
            error={errors.town} 
            disabled={!fieldsEditable}
            value={neenor.town} 
            onChange={(e) => {updateNeeNor('town', e.target.value)}} 
            />
          </div>
          <div className='col ml-2'>
            <Input 
            label={<>County</>}
            error={errors.county} 
            disabled={!fieldsEditable}
            value={neenor.county} 
            onChange={(e) => {updateNeeNor('county', e.target.value)}} 
            />
          </div>
        </div>

        <div className='d-flex row'>
          <div className='col'>
            <Input 
            label={<>Post Code <span style={{ fontSize: "120%" }}>*</span></>}
            error={errors.postcode} 
            disabled={!fieldsEditable}
            value={neenor.postcode} 
            onChange={(e) => {updateNeeNor('postcode', e.target.value)}} 
            />
          </div>
          <div className='col ml-2'>
          <FilterableSelect
              label={<>Country <span style={{ fontSize: "120%" }}>*</span></>}
              data={neenor.country}
              setData={(e) => {updateNeeNor('country', e)}}
              onChangeAsObject={countryChanged}
              options={countryList}
              error={errors.country}
              style={{width: '100px'}}
              disabled={!fieldsEditable}
            />
          </div>
        </div>


      </div>

      {/* <div className="neenor-subtitle">Standing Authority</div> */}
      <div className='neenor-details-section'>

      <div className='d-flex row'>
          <div className='col'>
          <input type="checkbox" name="authorized" class="neenor-checkbox" 
                        checked={neenor.standingAuthority}
                        onChange={(e) => {updateNeeNor('standingAuthority', e.target.checked)}}
                        disabled={!fieldsEditable}
                        />
            <label for="authorized" class="label-text"
            >Standing Authority?      </label>
          </div>
          </div>
        </div>

      <div className="neenor-subtitle">Customs &amp; Compliance</div>
      <div className='neenor-details-section'>

      <div className='d-flex row'>
          <div className='col'>
            <Input 
            label={<>UK DAN Account</>}
            error={errors.danAccount} 
            disabled={!fieldsEditable}
            value={neenor.danAccount} 
            onChange={(e) => {updateNeeNor('danAccount', e.target.value)}} 
            />
          </div>
          <div className='col ml-2'>
            <div className="pt-2">
            <input type="checkbox" name="authorized" class="neenor-checkbox" 
                        checked={neenor.authorized}
                        onChange={(e) => {updateNeeNor('authorized', e.target.checked)}}
                        disabled={!fieldsEditable}
                        />
            <label for="authorized" class="label-text"
            >Authorised?         
            <OverlayTrigger
          placement="right"
          trigger={["hover", "focus"]}
          overlay={
            <Popover style={{ minWidth: "25rem" }}>
              <PopoverContent>
                <p>
                Has the Nee/Nor authorised the declarant to use the DDA?
                </p>
              </PopoverContent>
            </Popover>
          }
        >
          <span className="info ms-2"></span>
        </OverlayTrigger></label>
            </div>
           
          </div>
        </div>

        <div className='d-flex row'>
          <div className='col'>
          <Select
          label={
            <>
              Do you hold a CCG?
            </>
          }
          data={neenor.CCGHeld}
          setData={(e) => {updateNeeNor('CCGHeld', e)}}
          options={[{id: 1, data:"No"}, {id: 2, data: "Yes"}]}
          error={errors.CCGHeld}
          disabled={!fieldsEditable}
          
        />
          </div>
          <div className='col ml-2'>
            {[2,'2'].includes(neenor.CCGHeld) && 
                       <Input 
                       label={<>CCG Reference</>}
                       error={errors.CCGReference} 
                        
                       value={neenor.CCGReference} 
                       onChange={(e) => {updateNeeNor('CCGReference', e.target.value)}} 
                       disabled={!fieldsEditable}
                       />
            }
 
          </div>
        </div>

        <div className='d-flex row'>
          <div className='col'>
            <Input 
            label={<>Warehouse ID</>}
            error={errors.warehouseID} 
            disabled={!fieldsEditable}
            value={neenor.warehouseID} 
            onChange={(e) => {updateNeeNor('warehouseID', e.target.value)}} 
            />
          </div>
          <div className='col ml-2'>
            <Input 
            label={<>Supervising Office</>}
            error={errors.supervisingOffice} 
            disabled={!fieldsEditable}
            value={neenor.supervisingOffice} 
            onChange={(e) => {updateNeeNor('supervisingOffice', e.target.value)}} 
            />
          </div>
        </div>


      </div>

      
      <section className="d-flex flex-row px-3 mt-4 gap-3 border-top bg-light align-items-center">
        <button type="button" className="stepper-button green" onClick={back}>
          Back
        </button>

        <div className="flex-grow-1" />
        {editCreate === 'edit' && 
                <button type="button" className="stepper-button red" onClick={handleDelete} >
                Delete
              </button>
        }

        <button type="button" className="stepper-button yellow" onClick={editCreate === 'edit' ? handleSubmit : handlePost}
        disabled={!fieldsEditable} >
          Save
        </button>
      </section>

</section>
<Modal centered  animation={false} show={showDeletePopup}>
      <Modal.Header>
        <Modal.Title>Delete?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {popupText}
      </Modal.Body>
      <Modal.Footer
        class="py-3 px-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

<div className='push-left-right'>
        <button
          class="stepper-button"
          onClick={() => {
            setShowDeletePopup(false);
            setDeleteRecord(false)
          }}
        >No
        </button>
        <button
          class="stepper-button red"
          onClick={() => {
            setShowDeletePopup(false);
            setDeleteRecord(true)
          }}
        >Yes
        </button>
        </div>
      </Modal.Footer>
    </Modal>
<Modal centered  animation={false} show={showPopup}>
      <Modal.Header>
        <Modal.Title>Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {popupText}
      </Modal.Body>
      <Modal.Footer
        class="py-3 px-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

<div className='d-flex justify-content-end'>
        <button
          class="stepper-button"
          onClick={() => {
            setShowPopup(false);
            if (popupReturns)
              back();
          }}
        >Ok
        </button>
        </div>
      </Modal.Footer>
    </Modal>
</Container>
  )
}

export default NeeNorDetails

