import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import "./register.css";
import { checkEORIvalid, masterData } from '../apiCalls';
import { Input } from "../Input/Input.js";
import ConfirmationModal from "../ConfirmationModal";
import { OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';
import Stepper from '../Stepper';
import BasicDetails from './1-BasicDetails';
import AddressDetails from './2-AddressDetails';
import SmartPack from './4-SmartPack';
import Confirmation from './5-Confirmation';
import RegisteredOfficeDetails from './2.1-RegisteredOfficeDetails';
import PreCheck from './0-PreCheck';
import FinanceInformation from './2.2-InvoiceAddressDetails';
import PersonalDetails from "./PersonalDetails";
import FinanceContact from './FinanceContact';
import EstimatedShipments from './EstimatedShipments';
import { StoreContext } from "../Store.js";

const Register = () => {

  const navigate = useNavigate();

  const [eoriNumber, setEoriNumber] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [isEoriValid, setEoriValid] = useState(null);

  const [passwordOne, setPasswordOne] = useState("")
  const [passwordTwo, setPasswordTwo] = useState("")
  const [passwordText, setPasswordText] = useState("")
  const [showRegEmailModal, setShowRegEmailModal] = useState(false);
  const [isEstablished, setEstablished] = useState(false);


  const validateEoriNumber = async () => {

    setCountryCode(null);
    setEstablished(false);
    setEoriValid(null);
    try {
      const resp = await checkEORIvalid(eoriNumber);
      if (resp.statusDescr === "Valid") {
        setCountryCode(eoriNumber.slice(0, 2));
        setEoriValid(true);
        console.log("Valid EORI number, set the country...");
      } else { 
        console.log("Invalid eori number")
        setEoriValid(false);
      }
    }
    catch (e) {
      console.log("Invalid eori number")
      setEoriValid(false);
    }
  }



  const validatePassword = () =>{
    if (passwordOne !== passwordTwo) {
     setPasswordText("Passwords do not match")
     setPasswordOne('')
     setPasswordTwo('')
     return false;
    }
    if (passwordOne.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$") == null) {
      setPasswordText("Password must be minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character from (@ $ ! % * ? &)")
      return false;
     }
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setPasswordText('');

    try {
      if (!isEoriValid) {

      }

      if (validatePassword() && isEoriValid) {
        // TODO : turn the `state` object into formdata.
        const data = await masterData(event.target)
        console.log(data[0].completed);
          if (data[0].completed === 'Failed') {
            setPasswordText('Registration failed EORI number already registered')
          }
          if (data[0].completed === 'Inserted') {
            setShowRegEmailModal(true)
          }
          if (data[0].completed === 'Account already exists') {
            setPasswordText('Email account already registered')
          }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="full-width-container">
      <div className="floating-box-register">
        <div className="floating-box-header">Company Registration </div>
       
        <ConfirmationModal
                    header="Activate Account"
                    show={showRegEmailModal}
                    onHide={() => setShowRegEmailModal(false)}
                    hideCancel={true}
                    onConfirm={() => navigate("/")}
                  >
                  Please now check your email inbox and spam folder for your account activation email link and close this browser tab.
        </ConfirmationModal>


        <form onSubmit={handleSubmit} id='register-contents'>
          <div className='registrationText colspan-full'>Company Information</div>
          <Input label="Company name" name="company" type='text' id='companyinput' required />
          <Input label="EORI number" name="eori" type='text' id='eoriinput' required
            error={isEoriValid === false && "Invalid EORI Number"}
            value={eoriNumber}
            onChange={e => setEoriNumber(e.target.value)}
            onBlur={validateEoriNumber}
          />
          {countryCode !== null &&
          <div className="align-self-center justify-content-end py-1 colspan-full">
            <label className="d-flex align-items-center gap-1" style={{fontSize: '80%'}}>
              <input
                type="checkbox"
                name="established"
                value={isEstablished}
                onChange={(e) => 
                    setEstablished(e.target.checked)}
              />
              <span className="bold" style={{fontSize: '1.05em'}}>Please check this box if your company was established in {countryCode}.</span>
            </label>
          </div>
          }
          {countryCode !== null && isEstablished && 
          (<div>
            <Input label="Company Registration Number" type='text' name='companyreg' id='companyreg' 
            info={
              <OverlayTrigger
                placement="right"
                trigger={["click"]}
                overlay={
                  <Popover style={{ minWidth: "20rem" }}>
                    <PopoverContent>
                      <h6>Costs Liability</h6>
                      <p>
                      Is this company established in the country of registration? This will determine if we are acting directly or indirectly.
                      </p>
                      <p>
                      An EORI and VAT number can be obtained without an established entity. In order to verify the company's status, please provide the registration number. In the UK this will come from Companies House, Companies Registration Office in Ireland and Sirene in France.
                      </p>
                      <p>
                      If we cannot verify the establishment status we will be forced to act indirectly, which will incur an additional fee.
                      </p>
                      <p><a target="_blank" href="https://www.gov.uk/guidance/check-if-youre-established-in-the-uk-or-eu-for-customs" rel="noreferrer">Please check GOV.uk for more details.</a></p>
                    </PopoverContent>
                  </Popover>
                }
              >
                <div className="info"></div>
              </OverlayTrigger>
            } />
          </div>
          )}
          <div/>
          
          

          <div className='registrationText colspan-full'>Personal Information</div>
          <Input label="First name" name="firstName" type='text' id='firstName' required />
          <Input label="Surname" name="lastName" type='text' id='lastName' required />
          <Input label="Email address" type='email' name='email' id='emailinput' required />
          <div className='registrationText colspan-full'>Password</div>
          <div className="sub-heading password-error colspan-full">{passwordText}</div>
          <Input label="Choose a password" type='password' name='password' id='password' value={passwordOne} onChange={e => setPasswordOne(e.target.value)} required />
          <Input label="Confirm password" type='password' name='confirm' id='confirm' value={passwordTwo} onChange={e => setPasswordTwo(e.target.value)} required />
          <button className='blue-button colspan-full'>Submit</button>
        </form>
      </div>
    </div>
  )
}

const defaultData = {


  firstName:"",
  lastName:"",
  companyPosition:"",
  phoneNumber:"",
  emailAddress:"",
  isFullRegProcess: false,
  useFullRegProcess: false,
  eoriNumber: "",
  vatNumber: "",gbEntity: "",
  companyName: "",
  formation: "",
  companyCategory: "", 
  companyRegNumber: "",
  building: "", 
  street: "", 
  town: "", 
  county: "", 
  postcode: "", 
  country: "",
  sameAddress: false,
  building2: "", 
  street2: "", town2: "",
  county2: "", 
  postcode2: "", 
  country2: "",
  sameInvoiceAddr: false,
  building3: "", 
  street3: "", 
  town3: "", 
  county3: "", 
  postcode3: "", 
  country3: "",
  paymentName: "", 
  paymentLastName:"", 
  paymentPhone: "",
  paymentEmail: "",
  monthlyDecs: 0,
  emailSelected: false,
  standingAuth: ""
}

const RegisterWithStepper = () => {

  const [isSticky, setSticky] = useState(false);
  const store = useContext(StoreContext);
  const [hideConsent, setHideConsent] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      };

      if(sessionStorage.getItem('token')){
        setHideConsent(true);
      }
      
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };      
    }, []);


  return (
    <Stepper
      // defaultStepKey="additional"
      showFuture = {true}
      canSkipForward = {false}
      defaultData={defaultData}
      defaultStepKey={sessionStorage.getItem('token') ? 'personal': 'precheck'}
      stepClassName="bg-white d-flex flex-column justify-content-between"
      stepStyle={{ borderRadius: "20px", padding: "1.5rem", minWidth: "500px", maxWidth: "750px", minHeight: "500px" }}
    >
      <main className="d-flex w-100 mx-auto justify-content-center align-items-start my-5" style={{position:'relative'}}>
      {(<Stepper.StepList isSticky ={isSticky}
            title={<div className="epo-steps-stepper-header" style={{ paddingTop: '1rem', paddingLeft: '1.95rem', paddingBottom: '0.7rem' }}>Registration Steps</div>}
          />)}
        <div style={{ minWidth: "20px" }} />
        {!hideConsent ? (<Stepper.Step text="Before you get started" stepKey='precheck'>
          <PreCheck/>
        </Stepper.Step>): null}      

        <Stepper.Step text="Personal Information" stepKey="personal">
          <PersonalDetails />
        </Stepper.Step>

        <Stepper.Step text="Company Information" stepKey="basic">
          <BasicDetails />
        </Stepper.Step>

        <Stepper.Step text="Trading Address" stepKey="addr">
          <AddressDetails />
        </Stepper.Step>

        <Stepper.Step text="Registered Office" stepKey="addr2">
          <RegisteredOfficeDetails />
        </Stepper.Step>

        {!store.isWLP[0] ? (<Stepper.Step text="Finance Address" stepKey="addr3">
          <FinanceInformation />
        </Stepper.Step>): null}
    
        {!store.isWLP[0] ? (<Stepper.Step text="Finance Contact" stepKey="paymentcontact">
          <FinanceContact />
        </Stepper.Step>): null}
      

        {/* <Stepper.Step text="Non-GB Entities" stepKey="nongb">
          <NonGbEntities />
        </Stepper.Step> */}

        {/* <Stepper.Step text="Shipments" stepKey="estimatedShipments">
          <EstimatedShipments />
        </Stepper.Step> */}
       
        <Stepper.Step text="CABIE Pack" stepKey="smart">
          <SmartPack />
        </Stepper.Step>

        {/* <Stepper.Step text="Confirmation" stepKey="confirmation" activeIsDone>
          <Confirmation />
        </Stepper.Step> */}
    </main>
    </Stepper>
  );
}


export default RegisterWithStepper;
