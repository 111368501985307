import React from 'react';
//import { withRouter } from "react-router-dom";
import './table.css';
import tableHeaders from "./tableHeaders";
import TableDetails from "./TableDetails";

const DisplayInvoices = (data) => {

  const returnData = JSON.parse(data.invoices)

  return (
    <section className='bigTableLayOut'>
      <div className  = 'formPlace'>
        Something here
      </div>
      <table className='bigTablePlace'>
        <tbody>
          <tr>
            {tableHeaders(returnData)}
          </tr>
          {TableDetails(data)}
        </tbody>
      </table>
    </section>
  )
}

export default DisplayInvoices;