import { Modal } from "react-bootstrap";
import "./controlledgoodsmodal.css";
import ControlsGoodsInformationLinks from "./ControlsGoodsInformationLinks";

export const ControlledGoodsModal = ({ onNone, subTitle, animation, size, show, onHide, showButtons, onConfirm, header }) => {
  return (
    <Modal centered size={size} animation={animation} show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{header}</Modal.Title>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>
      
      
<span>{subTitle}</span>
      
<div class='class="d-flex flex-row py-3 px-3 gap-3 '>
    <ControlsGoodsInformationLinks/>
</div>
   
      </Modal.Body>
      <Modal.Footer
        class="py-3 px-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

<div className='push-left-right'>
        <button 
          class="blue-button-newload green" onClick={(event) => {
            onNone(); 
            onHide(event);
          }}
          style={{ display: (showButtons ? "flex" : "none"), borderRadius: "10px" }} > None
        </button>

        <button
          class="blue-button-newload red"
          onClick={(event) => {
            onConfirm();
            onHide(event);
          }}
          style={{ display: (showButtons ? "flex" : "none"), borderRadius: "10px" }} 
        >Contains
        </button>
        </div>

      </Modal.Footer>
    </Modal>
  );
};

export default ControlledGoodsModal;
