import { useEffect, useState, useContext } from "react";
import "./Dashboard.css";
import DashboardSection from "./DashboardSection";
import DashboardButton from "./DashboardButton";
import { getDashboardReport } from "../apiCalls.js";
import { StoreContext } from "../Store";

//library
import {useNavigate} from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  //set the states for the totals and for the breakdown of the data
  const [totals, setTotals] = useState({
    "EPO Priority": 0,
    "EPO Standard (Arrived)": 0,
    "EPO Standard (Not Arrived)": 0,
    "CABIE Loads": 0,
  });
  const [breakdown, setBreakdown] = useState({
    "EPO Priority": {
      "On Hold": 0,
      New: 0,
      "Ready to be Checked": 0,
      "Waiting for Customer": 0,
      "In-Progress": 0,
    },
    "EPO Standard (Arrived)": {
      "On Hold": 0,
      New: 0,
      "Ready to be Checked": 0,
      "Waiting for Customer": 0,
      "In-Progress": 0,
    },
    "EPO Standard (Not Arrived)": {
      "On Hold": 0,
      New: 0,
      "Ready to be Checked": 0,
      "Waiting for Customer": 0,
      "In-Progress": 0,
    },
    "CABIE Loads": {
      Draft: 0,
      "In-Progress": 0,
      'CM8 draft': 0,
    },
  });
  const store = useContext(StoreContext);

  //useEffect to run when the component mounts
  useEffect(() => {
    //fetch the data from the api
    const fetchData = async () => {
      const data = await getDashboardReport();
      store.EPOPersist[1]({})
      populateData(data);
    };
    fetchData();

    //setInterval to run the fetchData function every 5 minutes
    const intervalId = setInterval(fetchData, 300000);

    //return a function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    console.log('breakdown', breakdown)
  }, [breakdown]);

  const handleClick = (title, description) => {
    if(title === 'CABIE Loads'){
      navigate('/ListLoads?dashboard=1')
    }
    else if(title === 'EPO Standard'){
      if(description === '(Arrived)'){
        navigate('/EpoListLoads/?dashboard=1&arrived=1');
      }
      else navigate('/EpoListLoads/?dashboard=1&arrived=0');
    }
    else if (title === 'EPO Priority') navigate('/EpoListLoads/?dashboard=1&priority=1')
  }

  //function to populate the data
  const populateData = (data) => {
    //populate the total
    setTotals({
      "EPO Priority": data["priority_total"],
      "EPO Standard (Arrived)": data["arrived_total"],
      "EPO Standard (Not Arrived)": data["not_arrived_total"],
      "CABIE Loads": data["cabie_total"],
    });

    //populate the breakdowns
    setBreakdown((prevState) => {
      return {
        ...prevState,
        "EPO Priority": {
          "On Hold": data["priority_on hold"],
          New: data["priority_new"],
          "Ready to be Checked": data["priority_ready to be checked"],
          "Waiting for Customer": data["priority_waiting for customer"],
          "In-Progress": data["priority_in progress"],
        },
        "EPO Standard (Arrived)": {
          "On Hold": data["arrived_on hold"],
          New: data["arrived_new"],
          "Ready to be Checked": data["arrived_ready to be checked"],
          "Waiting for Customer": data["arrived_waiting for customer"],
          "In-Progress": data["arrived_in progress"],
        },
        "EPO Standard (Not Arrived)": {
          "On Hold": data["not_arrived_on hold"],
          New: data["not_arrived_new"],
          "Ready to be Checked": data["not_arrived_ready to be checked"],
          "Waiting for Customer": data["not_arrived_waiting for customer"],
          "In-Progress": data["not_arrived_in progress"],
        },
        "CABIE Loads": {
          Draft: data["cabie_draft"],
          "CM8 Draft": data['cabie_CM8 draft'], // TODO: change to data['cabie_CM8 draft']  after the SP is changed
          "In-Progress": data["cabie_in progress"]
          
        },
      };
    });
  };

  return (
    <div className="full-width-container custom-margin">
      <div className="dashboard_main_title"><span>Status Summary Dashboard</span></div>
      <div className="dashboard-main-div">
        <section
          className="floating-box dashboard-floating-box custom-margin"
          style={{ maxWidth: "100%" }}
        >
          <section className="total-jobs">
            <DashboardButton
              title={"EPO Priority"}
              quantity={totals["EPO Priority"]}
              clickHandler={handleClick}
            />
            <DashboardButton
              title={"EPO Standard"}
              quantity={totals["EPO Standard (Arrived)"]}
              titleDescription={"(Arrived)"}
              clickHandler={handleClick}
            />
            <DashboardButton
              title={"EPO Standard"}
              quantity={totals["EPO Standard (Not Arrived)"]}
              titleDescription={"(Not Arrived)"}
              clickHandler={handleClick}
            />
            <DashboardButton
              title={"CABIE Loads"}
              quantity={totals["CABIE Loads"]}
              clickHandler={handleClick}
            />
          </section>

          <section className="dashboard-breakdown">
            <DashboardSection
              dashboardTitle={"EPO Priority"}
              data={breakdown["EPO Priority"]}
            />
            <DashboardSection
              dashboardTitle={"EPO Standard"}
              data={breakdown["EPO Standard (Arrived)"]}
              dashboardTitleDescription={"(Arrived)"}
            />
            <DashboardSection
              dashboardTitle={"EPO Standard"}
              data={breakdown["EPO Standard (Not Arrived)"]}
              dashboardTitleDescription={"(Not Arrived)"}
            />
            <DashboardSection
              dashboardTitle={"CABIE loads"}
              data={breakdown["CABIE Loads"]}
            />
          </section>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
