
export default function tableHeaders(data) {
  let tableHeaders = [];
  if (data.length > 0) {
    let headers = Object.keys(data[0]);
    headers.map(header => tableHeaders.push(<th key={header}>{header}</th>))
  }
  if (tableHeaders.length === 0) {
    return null
  }
  else return tableHeaders
}
