import { createContext, useState, useRef, useEffect } from 'react'
import socketIOClient from "socket.io-client";
import { useSecurity } from './Security/security';

export const MessagingContext = createContext(null)

const MessagingProvider = ({ children }) => {
    const [messages, setMessages] = useState([])
    const [comments, setComments] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [msgId, setMsgId] = useState(null);
    const [commentOpen, setCommentOpen] = useState(false);
    const socket = useRef(null);
    const setServerDataLength = useRef(null);
    const {validateRole} = useSecurity();
    const dataTableHandle = useRef(null);
    const [showUnresolved, setShowUnresolved] = useState(false)

    
    useEffect(() => {
        socket.current = socketIOClient(process.env.REACT_APP_API, {transports: ['websocket']});
    }, []);

    useEffect(() => {
        if (socket.current) {
            socket.current.removeAllListeners("loggedIn");
            socket.current.removeAllListeners("loggedOut");
            socket.current.removeAllListeners("allMessages");
            socket.current.removeAllListeners("comments");
            socket.current.removeAllListeners("newComment");
            socket.current.removeAllListeners("newMessage");
            socket.current.removeAllListeners("error");
            socket.current.removeAllListeners("markCommentsRead");
            socket.current.removeAllListeners("allCustomers");
            socket.current.removeAllListeners("markMessageResolved");
            socket.current.removeAllListeners("commentDeleted");
            socket.current.removeAllListeners("messageUpgraded");
            
            socket.current.on("loggedIn", () => {
                setIsLoggedIn(true);
            })

            socket.current.on("loggedOut", () => {
                setIsLoggedIn(false);
            })

            socket.current.on("allMessages", data => {
            setServerDataLength.current(data.totalItems)
            setMessages(data.data)
            });

            socket.current.on("comments", data => {
                //data.data = data.data.map(comment => comment.isRead = 1)
                setComments(data.data);
                if (validateRole({isCustomer: true})) {
                    setMessages(messages.map(message => {
                        if (message.id === msgId) {
                            message.unreadMessages = 0
                        }
                        return message;
                    }))
                    socket.current.emit('commentsRead', {msgId})
                }
            })
            socket.current.on("newComment", data => {
                const {comment, msg} = data;
                if (msgId === comment.msgId) {
                    appendComment(comment);
                    if(validateRole({isCustomer: true})) {
                        if (commentOpen === true) {
                            socket.current.emit('commentsRead', {msgId: data.msgId})
                        }
                    }
                }
                if (onHomePage()) {
                    if (messages.find((message) => message.id === msg.id)) {
                        setMessages([msg, ...messages.filter((message) => message.id !== msg.id)])
                    }
                    else {
                        setMessages([msg, ...messages.slice(0, dataTableHandle.current.pagination.itemsPerPage-1)])
                    }
                } else {
                    getMessages(setServerDataLength.current, {
                        skip: dataTableHandle.current.pagination.pageNo * dataTableHandle.current.pagination.itemsPerPage, 
                        amount: dataTableHandle.current.pagination.itemsPerPage,
                        sort_by: dataTableHandle.current.sortStatus.currentKey,
                        sort_order: dataTableHandle.current.sortStatus.currentDirection,
                        only_unresolved: !showUnresolved
                    })
                }
            })
            socket.current.on("newMessage", data => {
                setServerDataLength.current((prev) => prev +1)
                if (onHomePage()) {
                    setMessages([data, ...messages.slice(0, dataTableHandle.current.pagination.itemsPerPage-1)]);
                }
            })
            socket.current.on("error", data => {
            })
            socket.current.on("markCommentsRead", data => {
                setMessages(messages.map(message => {
                    if (message.id === data.msgId) {
                        message.unreadMessages = 0
                    }
                    return message;
                }))
            })
            socket.current.on("allCustomers", data => {
                setCustomers(data);
            })

            socket.current.on("markMessageResolved", data => {

                if (msgId === data.comment.msgId) {
                    appendComment(data.comment);
                }
                const messageFound = messages.find((msg) => msg.id === data.msg.id);
                if (messageFound) {
                    messageFound.isResolved = 1;
                }
                if (!showUnresolved) {
                    if (messageFound) {
                        getMessages(setServerDataLength.current, {
                            skip: dataTableHandle.current.pagination.pageNo * dataTableHandle.current.pagination.itemsPerPage, 
                            amount: dataTableHandle.current.pagination.itemsPerPage,
                            sort_by: dataTableHandle.current.sortStatus.currentKey,
                            sort_order: dataTableHandle.current.sortStatus.currentDirection,
                            only_unresolved: !showUnresolved
                        })
                    }
                } else { //If ShowUnresolved
                    if (messageFound) {
                        if (!onHomePage()) {
                            getMessages(setServerDataLength.current, {
                                skip: dataTableHandle.current.pagination.pageNo * dataTableHandle.current.pagination.itemsPerPage, 
                                amount: dataTableHandle.current.pagination.itemsPerPage,
                                sort_by: dataTableHandle.current.sortStatus.currentKey,
                                sort_order: dataTableHandle.current.sortStatus.currentDirection,
                                only_unresolved: !showUnresolved
                            })
                        }
                        else { //If on homepage
                            setMessages([data.msg, ...messages.filter((msg) => msg.id !== data.msg.id)])
                        }
                    }
                    else { //If message not found
                        if (onHomePage()) {
                            setMessages([data.msg, ...messages.slice(0, dataTableHandle.current.pagination.itemsPerPage -1)])
                        }
                    }
                }
            })

            socket.current.on("commentDeleted", data => {
                if (data.msgId == msgId) {
                    setComments(comments.filter((comment) => {
                        return comment.id !== data.commentId
                    }))
                }
                const messageFound = messages.find((msg) => msg.id === data.msgId);
                if (messageFound) {
                    setMessages(messages.map((message) => {
                        if (message.id === data.msgId) {
                            message.latestCommenter = data.latestCommenter;
                            message.latestText = data.latestText;
                            message.newestMessageAt = data.newestMessageAt;
                        }
                        return message;
                    }))
                }
            })

            socket.current.on("messageUpgraded", data => {
                const existingMessage = messages.find((msg) => {
                    return msg.id === data.msg.id;
                })
                if (existingMessage) {
                    existingMessage.companyId = data.msg.companyId;
                    existingMessage.msgUser = data.msg.msgUser;

                    if (!onHomePage()) {
                        getMessages(setServerDataLength.current, {
                            skip: dataTableHandle.current.pagination.pageNo * dataTableHandle.current.pagination.itemsPerPage, 
                            amount: dataTableHandle.current.pagination.itemsPerPage,
                            sort_by: dataTableHandle.current.sortStatus.currentKey,
                            sort_order: dataTableHandle.current.sortStatus.currentDirection,
                            only_unresolved: !showUnresolved
                        })
                    } else {
                        data.msg.latestCommenter = data.comment.commenter;
                        data.msg.latestText = data.comment.msgText;
                        data.msg.newestMessageAt = data.comment.createdAt;
                        setMessages([data.msg, ...messages.filter((msg) => msg.id !== data.msg.id)])
                    }
                }
                else {
                    if (onHomePage()) {
                        data.msg.latestCommenter = data.comment.commenter;
                        data.msg.latestText = data.comment.msgText;
                        data.msg.newestMessageAt = data.comment.createdAt;
                        setMessages([data.msg, ...messages.slice(0, dataTableHandle.current.pagination.itemsPerPage -1)])
                    }
                }

                if (data.msg.id === msgId) {
                    setComments([data.comment, ...comments])
                }
            })
        }
        setIsLoaded(true);
        if (!isLoggedIn) {
            login();
        }
      }, [messages, comments, socket, msgId, commentOpen]);

      const onHomePage = () => {
          return dataTableHandle.current.pagination.pageNo === 0 && (
          (dataTableHandle.current.sortStatus.direction === 'desc' && dataTableHandle.current.sortStatus.currentKey === 'newestMessageAt') ||
          (dataTableHandle.current.sortStatus.direction === undefined && dataTableHandle.current.sortStatus.currentKey === undefined)
          )
      }
      const login = () => {
          socket.current.emit('login', {token: sessionStorage.getItem('token')})
      }

      const logout = () => {
          socket.current.emit('logout');
      }

      const setDataTableHandle = (dth) => {
          dataTableHandle.current = dth;
      }

      const getComments = (msgId) => {
        setMsgId(msgId);
        socket.current.emit('getComments', { msgId: msgId})
      }

      const getMessages = (paginationSetterFunc, data) => {
        setServerDataLength.current = paginationSetterFunc
        socket.current.emit('getMessages', data)
      }
      const appendComment = (comment) => {
          setComments([comment, ...comments])
      }
      const postMessage = (summary, msgType, msgText, companyId, msgUser, internalExternal) => {
        socket.current.emit('postMessage', {
            msgType,
            summary,
            msgText,
            companyId,
            msgUser,
            internalExternal
        })
      }
      const postComment = (msgText, msgId, companyId, isInternal) => {
        socket.current.emit('postComment', {
            msgText,
            msgId,
            companyId,
            isInternal
        })
      }

      const getCustomers = () => {
          socket.current.emit('getCustomers');
      }

      const resolveMessage = (msgId, companyId, msgText) => {
          socket.current.emit('resolveMessage', {msgId, companyId, msgText})
      }

      const deleteComment = (commentId, companyId, msgId) => {
          socket.current.emit('deleteComment', {commentId, companyId, msgId});
      }

      const upgradeMessage = (msgId, companyId, msgText, msgUser) => {
          socket.current.emit('upgradeMessage', {msgId, companyId, msgText, msgUser})
      }

    const data = {
        messages: [messages, setMessages],
        comments: [comments, setComments],
        isLoaded: [isLoaded, setIsLoaded],
        customers: [customers, setCustomers],
        commentOpen: [commentOpen, setCommentOpen],
        isLoggedIn: [isLoggedIn, setIsLoggedIn],
        showUnresolved: [showUnresolved, setShowUnresolved],
        postComment,
        getMessages,
        getComments,
        appendComment,
        postMessage,
        getCustomers,
        resolveMessage,
        deleteComment,
        upgradeMessage,
        login,
        logout,
        setDataTableHandle,
        socket: socket.current
    }

    return <MessagingContext.Provider value={data}>{children}</MessagingContext.Provider>
}

export default MessagingProvider