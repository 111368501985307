import { Text, View } from '@react-pdf/renderer';
import { CheckCircle, Download } from "@styled-icons/fa-solid";
import { Link } from "react-router-dom";
import Stepper, {useStepper} from "../../Stepper";
import { StoreContext } from '../../Store';
import information from "../../../images/information.svg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import { PDFDownloadLink, StyleSheet } from "@react-pdf/renderer";
import JobReceipt from '../../JobReceipt'
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";

const styles = StyleSheet.create({
  cbText: {
    fontFamily: "Helvetica",
    display: "flex",
    flexDirection: "column",
  }
});

export function CongratsPage({validateRole}) {
  const { activeStepKey, localData } = useStepper();
  const [showEpoRef, setShowEpoRef] = useState(false); 

  const isInitialized = useRef(false);
  const store = useContext(StoreContext);
  const ga = useContext(GoogleAnalyticsContext)
  const [jobDetails, setjobDetails] = useState({})  

  useEffect(()=>{
    if (localData?.returndata?.scoreValue) {
      const { jobRef, entryport, exitport, euPref, confirmedNoCG } = localData;
      const { transferid, submittedBy, loadDate, submittedAt, scoreValue, sealNo, officeDest, inlandTransId } = localData.returndata;      
      const details = {
        epoScore: scoreValue,
        jobReference: jobRef,
        TransferID: transferid,
        submittedBy: submittedBy,
        port: exitport?.data || entryport?.data,
        estimatedDateOfLoading: loadDate,
        euPref: euPref,
        controlledGoods: confirmedNoCG == "NCG" ? false : true,
        submittedAt: submittedAt,
        sealNo: sealNo, 
        officeDest: officeDest,
        inlandTransId: inlandTransId
      };
      setjobDetails(details);
    }
    const isScore = ["E","F"].includes(localData.returndata?.scoreValue);
    if(!isScore){
      setShowEpoRef(true);
    }  
   },[localData.returndata?.scoreValue])

  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "confirm" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "8. Congrats",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  const navigate = useNavigate();    

  return (
    <Stepper.Step text="Confirmation" activeIsDone={true} stepKey="congrats" style={{ maxWidth: "850px", marginBottom: "2rem" }}>
      <div>
        <div className="d-flex justify-content-center gap-4 align-items-center mb-4">
          <h2 className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
            All Done
          </h2>
          <CheckCircle width="2.5em" height="2.5em" className="green-text" />
          {/* <h2 className="text-eorired" style={{ margin: 0 }}>
          
          </h2> */}
        </div>
        <div className=' px-4 py-4 mt-1' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)'}}>
        <div className="inputs-group p-4 d-flex flex-row align-items-start gap-3">
          <div className="d-flex flex-column gap-4">
            {(["A","B","C","D"].includes(localData.returndata?.scoreValue)) && (localData?.confirmedNoCG == "NCG" ? <NonCgResult localData = {localData} showEpoRef={showEpoRef}/> : <CgResult localData = {localData} showEpoRef={showEpoRef}/>)}
            {(["G","H","I","J"].includes(localData.returndata?.scoreValue)) && <ResultGtoJ/>}
            {localData.returndata?.scoreValue === "E" && <ResultE />}
            {localData.returndata?.scoreValue === "F" && <ResultF />}
          </div>
        </div>
        <section className="d-flex justify-content-between mt-5 pt-2">
          <PDFDownloadLink
            document={<JobReceipt jobDetails={{...jobDetails, isImport: localData?.movementType === "Import" ? true: false, euPrefSelected: localData?.euPrefSelected}} id = "congrats" />}
            fileName={"confirmation.pdf"}
            className="invoice-add-btn green-text fw-bolder"
            style={{ fontSize: "16px" }}
          >
            {({ loading }) =>
              loading ? (
                "Loading..."
              ) : (
                <>
                  <Download width="1em" height="1em" />
                  <span>Confirmation & Receipt</span>
                </>
              )
            }
          </PDFDownloadLink>
          <button className="blue-button-teams green-btn text-decoration-none" onClick={() => navigate("/EpoListLoads")}>
            Status
          </button>
        </section>
      </div>
      </div>
    </Stepper.Step>
  );
}

export function ResultText({ pdf, localData, children, showEpoRef}){

  if (pdf) {
    return (<View style={styles.cbText}>
        <View style={{padding: 10}}><Text>We'll contact you if we have any queries about the information you've submitted.</Text></View>
        <View style={{padding: 10}}><Text>The reference to enter on GVMS under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.</Text></View>
        <View style={{padding: 10}}><Text>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</Text></View>
    </View>)
  }
  return (
    <div>
      <p>
        Your shipment details have been submitted and your declaration will now be processed.
      </p>
      {children}
      {showEpoRef &&
      <p>
      Your Transaction Reference is: <span className="green-text fw-bold font-monospace" style={{ fontSize: "20px" }}>{localData.returndata?.transferid}</span>
      </p>}
    </div>
  );
}

export function CgResult({pdf=false, localData, showEpoRef}) {
  return (<ResultText localData={localData} showEpoRef={showEpoRef}>    
      <p>1. We will contact you if we have any questions about the information you have supplied.</p>
      <p>2. You can check the progress of your declaration using the STATUS button.</p>
      <p>3. The customs reference required for GVMS will become available in the Document References column.</p>
      <p>4. This should be added to GVMS under the 'Customs Declaration' option.</p>      
      </ResultText>)  
}

export function NonCgResult({pdf=false, localData, showEpoRef}) {  
  return (
    <ResultText localData={localData} showEpoRef={showEpoRef}>
      <p>1. We will contact you if we have any questions about the information you have supplied.</p>
      <p>2. You can check the progress of your declaration using the STATUS button.</p>
      {showEpoRef &&
      <p>3. The reference to enter on GVMS under the EIDR option is: <strong>GB269573944000</strong></p>}
      <p>
        If your transport company asks for a "Customs Entry Number" or "Movement
        Reference Number" please direct them to the following page –{" "}
        <a
          target="_blank"
          href="https://www.eori.uk/eidr"
          rel="noreferrer"
        >
          www.eori.uk/eidr
        </a>
      </p>
      <p className="page-link">
        <strong>IMPORTANT:</strong> You are NOT authorised to use our EIDR approved EORI number
        unless we have provided a unique "EPO" reference for the specific
        movement in question.
      </p>
    </ResultText>
  );  
}

export function ResultA({pdf=false}) {
  if (pdf) {
      return (<View style={styles.cbText}>
      <View style={{padding: 10}}><Text>We'll contact you if we have any queries about the information you've submitted.</Text></View>
      <View style={{padding: 10}}><Text>The reference to enter on GVMS under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.</Text></View>
      <View style={{padding: 10}}><Text>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</Text></View>
  </View>)
  }

  return (
    <div>
      <p>All done! Your load details have been submitted and your declaration will be processed accordingly.</p>
      <p>We'll contact you if we have any queries about the information you've submitted; and you can check up on the progress of your declaration using the STATUS button below.</p>
      <p>The reference to enter on GVMS under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.</p>
      <p>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</p>
    </div>
  )
}

export function ResultB({pdf=false}) {
  if (pdf) {
    return (<View style={styles.cbText}>
        <View style={{padding: 10}}><Text>We'll contact you if we have any queries about the information you've submitted.</Text></View>
        <View style={{padding: 10}}><Text>The reference for GB import release under customs simplified EIDR conditions is “GB269573944000”. We have noted that the GB port of arrival uses a mixture of GVMS and inventory linking (depending on the shipping line involved). Check with your transport provider whether they are working to GVMS or inventory linked conditions for this movement.</Text></View>
        <View style={{padding: 10}}><Text>If GVMS : The reference to enter under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.</Text></View>
        <View style={{padding: 10}}><Text>If inventory linked : Your transport company should arrange release from the inventory using a C21 or we can arrange this on your/their behalf. If you or your transport provider requires release from the port inventory please email CABIEsupport@eori.uk quoting the unique EPO number that identifies this movement, along with the vehicle and expected arrival date, shipping line and port of arrival.</Text></View>
        <View style={{padding: 10}}><Text>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</Text></View>
    </View>)
  }

  return (
    <div>
      <p>All done! Your load details have been submitted and your declaration will be processed accordingly.</p>
      <p>We'll contact you if we have any queries about the information you've submitted; and you can check up on the progress of your declaration using the STATUS button below.</p>
      <p>The reference for GB import release under customs simplified EIDR conditions is “GB269573944000”. We have noted that the GB port of arrival uses a mixture of GVMS and inventory linking (depending on the shipping line involved).   Check with your transport provider whether they are working to GVMS or inventory linked conditions for this movement.</p>
      <p>If GVMS : The reference to enter under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.</p>
      <p>If inventory linked : Your transport company should arrange release from the inventory using a C21 or we can arrange this on your/their behalf.   If you or your transport provider requires release from the port inventory please email CABIEsupport@eori.uk quoting the unique EPO number that identifies this movement, along with the vehicle and expected arrival date, shipping line and port of arrival.</p>
      <p>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</p>
    </div>
  )
}

export function ResultC({pdf=false}) {
  if (pdf) {
    return (<View style={styles.cbText}>
        <View style={{padding: 10}}><Text>We'll contact you if we have any queries about the information you've submitted.</Text></View>
        <View style={{padding: 10}}><Text>The reference to enter on GVMS under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used. </Text></View>
        <View style={{padding: 10}}><Text>We have noted that the origin of the goods may attract duty and by completing this process you authorise us to apply your DDA (duty deferment account) on the import declaration as the method of payment as required.</Text></View>
        <View style={{padding: 10}}><Text>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</Text></View>
    </View>)
  }
  return (
    <div>
      <p>All done! Your load details have been submitted and your declaration will be processed accordingly.</p>
      <p>We'll contact you if we have any queries about the information you've submitted; and you can check up on the progress of your declaration using the STATUS button below.</p>
      <p>The reference to enter on GVMS under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.  We have noted that the origin of the goods may attract duty and by completing this process you authorise us to apply your DDA (duty deferment account) on the import declaration as the method of payment as required.</p>
      <p>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</p>
    </div>
  )
}

export function ResultD({pdf=false}) {

  if (pdf) {

    return (<View style={styles.cbText}>
        <View style={{padding: 10}}><Text>We'll contact you if we have any queries about the information you've submitted.</Text></View>
        <View style={{padding: 10}}><Text>The reference for GB import release under customs simplified EIDR conditions is “GB269573944000”. We have noted that the GB port of arrival uses a mixture of GVMS and inventory linking (depending on the shipping line involved). Check with your transport provider whether they are working to GVMS or inventory linked conditions for this movement.</Text></View>
        <View style={{padding: 10}}><Text>If GVMS : The reference to enter under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.</Text></View>
        <View style={{padding: 10}}><Text>If inventory linked : Your transport company should arrange release from the inventory using a C21 or we can arrange this on your/their behalf. If you or your transport provider requires release from the port inventory please email CABIEsupport@eori.uk quoting the unique EPO number that identifies this movement, along with the vehicle and expected arrival date, shipping line and port of arrival.</Text></View>
        <View style={{padding: 10}}><Text>We have noted that the origin of the goods may attract duty and by completing this process you authorise us to apply your DDA (duty deferment account) to the import declaration as the method of payment as required.</Text></View>
        <View style={{padding: 10}}><Text>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</Text></View>
    </View>)
  }
  return (
    <div>
      <p>All done! Your load details have been submitted and your declaration will be processed accordingly.</p>
      <p>We'll contact you if we have any queries about the information you've submitted; and you can check up on the progress of your declaration using the STATUS button below.</p>      
      <p>The reference for GB import release under customs simplified EIDR conditions is “GB269573944000”. We have noted that the GB port of arrival uses a mixture of GVMS and inventory linking (depending on the shipping line involved). Check with your transport provider whether they are working to GVMS or inventory linked conditions for this movement.</p>    
      <p>If GVMS : The reference to enter under the EIDR option is “GB269573944000”. If your transport company asks for a "Customs Entry Number" or "Movement Reference Number" please direct them to the following page – www.eori.uk/eidr which gives guidance on how to complete the GMR when customs simplifications are being used.</p>      
      <p>If inventory linked : Your transport company should arrange release from the inventory using a C21 or we can arrange this on your/their behalf. If you or your transport provider requires release from the port inventory please email CABIEsupport@eori.uk quoting the unique EPO number that identifies this movement, along with the vehicle and expected arrival date, shipping line and port of arrival.</p>      
      <p>We have noted that the origin of the goods may attract duty and by completing this process you authorise us to apply your DDA (duty deferment account) to the import declaration as the method of payment as required.</p>      
      <p>Please note : you are not authorised to use our EIDR approved EORI number unless we have given you a unique EPO reference for the movement in question.</p>
    </div>
  );
}

export function ResultE ({pdf=false}) {
  if (pdf) {
    return (<View style={styles.cbText}>
        <View style={{padding: 10}}><Text>We have noted that the origin of the goods may attract duty and that we do not have your DDA (duty deferment account) details on file.</Text></View>
        <View style={{padding: 10}}><Text>We are unable to authorise release against our customs approval number until we have assessed the duty that may be due and received confirmation of payment as required.</Text></View>
        <View style={{padding: 10}}><Text>Our declarations team will be in touch with you shortly to confirm next steps.</Text></View>
    </View>)
  }
  return (
    <div>
      <p>All done! Your load details have been successfully submitted.</p>
      <p>We have noted that the origin of the goods may attract duty and that we do not have your DDA (duty deferment account) details on file.</p>
      <p>We are unable to authorise release against our customs approval number until we have assessed the duty that may be due and received confirmation of payment as required.</p>
      <p>Our declarations team will be in touch with you shortly to confirm next steps.</p>
    </div>
  )
}

export function ResultF ({pdf=false}) {
  if (pdf) {
    return (<View style={styles.cbText}>
        <View style={{padding: 10}}><Text>We have noted that the origin of the goods may attract duty and that we do not have your DDA (duty deferment account) details on file.</Text></View>
        <View style={{padding: 10}}><Text>We are unable to authorise release against our customs approval number until we have assessed the duty that may be due and received confirmation of payment as required.</Text></View>
        <View style={{padding: 10}}><Text>Our declarations team will be in touch with you shortly to confirm next steps.</Text></View>
    </View>)
}
  return (
    <div>
      <p>All done! Your load details have been successfully submitted.</p>
      <p>We have noted that the origin of the goods may attract duty and that we do not have your DDA (duty deferment account) details on file.</p>
      <p>We are unable to authorise release against our customs approval number until we have assessed the duty that may be due and received confirmation of payment as required.</p>
      <p>Our declarations team will be in touch with you shortly to confirm next steps.</p>
    </div>
  )
}

export function ResultGtoJ() {
  const { localData } = useStepper();
  return ( <div>
    <p>Your shipment details have been submitted and your declaration will now be processed.</p>
    <ol>
    <li>We'll contact you if we have any questions about the information you have supplied.</li>
    <li>You can check the progress of your declaration using the STATUS button.</li>
    <li>All Customs References will become available in the Document References column.</li>
    </ol>
    Your Transaction Reference is: <span className="green-text fw-bold font-monospace" style={{ fontSize: "20px" }}>{localData.returndata?.transferid}</span>
  </div>)
}