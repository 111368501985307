import "./MyModal.css"
import React, { useMemo } from "react"
import ReactDOM from "react-dom";

const MyModal = ({children, onScroll}) => {

    const {Title, Header, Body, Footer} = useMemo(() => {
        let Title, Header, Body, Footer;
        Body = (<h4>Hello</h4>)
        
        React.Children.forEach(children, (child) => {
            if (child?.type?.displayName === "Header") {
                React.Children.forEach(child.props.children, (grandkid) => {
                    if (grandkid?.type?.displayName === "Title") Title = grandkid.props.children
                })
                Header = React.Children.toArray(child.props.children).filter((gk) => gk?.type?.displayName !== "Title")
            }
            else if (child?.type?.displayName === "Body") Body = child.props.children
            else if (child?.type?.displayName === "Footer") Footer = child.props.children;
        })
        
        return {Title, Header, Body, Footer}
    }, [children])

    return ReactDOM.createPortal(
        <div className="myModal">
            <div className="myModal-content">
                <div className="myModal-header d-flex justify-content-between">
                    <h4 className="myModal-title">{Title}</h4>
                    {Header}
                </div>
                <div className="myModal-body" onScroll={onScroll}>
                    {Body}
                </div>
                <div className="myModal-footer">
                    {Footer}
                </div>
            </div>
        </div>
    , document.body)
}

const MyModalHeader = () => null
MyModalHeader.displayName = "Header"
MyModal.Header = MyModalHeader

const MyModalTitle = () => null
MyModalTitle.displayName = "Title"
MyModal.Title = MyModalTitle

const MyModalBody = () => null
MyModalBody.displayName = "Body"
MyModal.Body = MyModalBody


const MyModalFooter = () => null
MyModalFooter.displayName = "Footer"
MyModal.Footer = MyModalFooter


export default MyModal