import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTitle,
  OverlayTrigger,
} from "react-bootstrap";
import { getNewLoad } from "../../apiCalls";
import ConfirmationModal from "../../ConfirmationModal";

export const CommodityCodeInfoTooltip = React.memo(() => {
  return (
    <OverlayTrigger
      placement="right"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ width: "15rem" }}>
          <PopoverContent>
            <h6>HS (Commodity) Code</h6>
            <p>
              10 digit code which determines various customs tariffs on an item.
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info" style={{ width: "1rem" }}></div>
    </OverlayTrigger>
  );
});

export const PackageTypeInfoTooltip = React.memo(() => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getPackages() {
      const package_types = (await getNewLoad("loadtype")).map(
        (type) => type.data
      );
      package_types.sort();
      setOptions(package_types);
    }
    getPackages();
  }, []);

  return (
    <OverlayTrigger
      placement="right"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ width: "100%", maxWidth: "12rem" }}>
          <PopoverContent>
            <h6>Valid Package Types</h6>
            <ul>
              {options.map((opt) => (
                <li>{opt}</li>
              ))}
            </ul>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info" style={{ width: "1rem" }}></div>
    </OverlayTrigger>
  );
});

export const GrossMassInfoTooltip = React.memo(() => {
  return (
    <OverlayTrigger
      placement="right"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ width: "15rem" }}>
          <PopoverContent>
            <h6>Gross Mass</h6>
            <p>
              The total <b>weight</b> of the items <b>including</b> the{" "}
              <b>packaging</b> in KG.
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info" style={{ width: "1rem" }}></div>
    </OverlayTrigger>
  );
});

export const NetMassInfoTooltip = React.memo(() => {
  return (
    <OverlayTrigger
      placement="right"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ width: "15rem" }}>
          <PopoverContent>
            <h6>Net Mass</h6>
            <p>
              The total <b>weight</b> of the items <b>without</b> the{" "}
              <b>packaging</b> in KG.
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info" style={{ width: "1rem" }}></div>
    </OverlayTrigger>
  );
});

export const PreferentialExplainText = () => {
  return (
    <>
      <h6>Do your goods qualify for Preferential Treatment?</h6>
      <p>
        While some goods may be produced in the UK or EU, due to the inputs or
        products specific rules due under the TCA, they do not qualify for
        preferential treatment.
      </p>
      <p>
        <h6>For UK guidance</h6>
        <a
          href="https://www.gov.uk/guidance/check-your-goods-meet-the-rules-of-origin"
          target="_blank"
          rel="noreferrer"
        >
          Check your goods meet the rules of origin (www.gov.uk)
        </a>
      </p>
      <p>
        <h6>For Irish guidance</h6>
        <a
          href="https://www.revenue.ie/en/customs-traders-and-agents/importing-and-exporting/origin/eu-uk-trade-and-cooperation-agreement/preferential-tariff-treatment.aspx"
          target="_blank"
          rel="noreferrer"
        >
          Check your goods meet the rules of origin (www.revenue.ie)
        </a>
      </p>
    </>
  );
};

export const PreferentialModal = (props) => {
  return (
    <ConfirmationModal header="Please Review the Below" centered {...props}>
      <div className="popover-body py-0">
        <p className="bold">
          You have indicated at least one of your goods qualify for Preferential
          Treatment.
        </p>

        <PreferentialExplainText />

        <p>
          If you have not explicitly stated <b>NO</b> to preferential treatment,
          we have autofilled <b>YES</b>.
          <br />
          <br />
          Please confirm this is correct using the buttons below.
        </p>
      </div>
    </ConfirmationModal>
  );
};

export const PreferentialTooltip = ({children}) => {
  return (
    <OverlayTrigger
      placement="auto"
      trigger={["click", "hover"]}
      rootClose
      overlay={
        <Popover style={{ width: "100%", maxWidth: "15rem" }}>
          <PopoverContent>
            {/* <PreferentialExplainText /> */}
            {children}
          </PopoverContent>
        </Popover>
      }
    >
      <div
        className="info"
        style={{ width: "1rem" }}
        title="Hover to view"
      ></div>
    </OverlayTrigger>
  );
};


export const CategoryOfGoodsTooltip = () => {
  return (
    <OverlayTrigger
      placement="top"
      trigger={["hover"]}
      rootClose
      overlay={
        <Popover style={{ width: "100%", maxWidth: "25rem" }}>
          <PopoverContent>
            {/* <h6>Do your goods qualify for Preferential Treatment?</h6> */}
            <p>
             This will assist us to create the most accurate documents.
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div
        className="info"
        style={{ width: "1rem" }}
        title="Click to view"
      ></div>
    </OverlayTrigger>
  );
};

export const InlandTransportIdTooltip = () => {
  return (
    <OverlayTrigger
      placement="top"
      trigger={["click"]}
      rootClose
      overlay={
        <Popover style={{ width: "100%", maxWidth: "25rem" }}>
          <PopoverContent>
            {/* <h6>Do your goods qualify for Preferential Treatment?</h6> */}
            <p>
             Enter the Transport ID arriving at the Office of Destination. This is usually the Vehicle ID.
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div
        className="info"
        style={{ width: "1rem" }}
        title="Click to view"
      ></div>
    </OverlayTrigger>
  )
};

export const OfficeOfDestTooltip = () => {
  return (
    <OverlayTrigger
      placement="top"
      trigger={["click"]}
      rootClose
      overlay={
        <Popover style={{ width: "100%", maxWidth: "25rem" }}>
          <PopoverContent>
            {/* <h6>Do your goods qualify for Preferential Treatment?</h6> */}
            <p>
              Please select your Office of Destination from the below link.
              <p>
              <a
              href="https://ec.europa.eu/taxation_customs/dds2/rd/rd_list.jsp?Lang=en&DesLang=&AllRoles=Y&Country=&trafficTypeCode=&roles=AUT&roles=CCA&roles=MCA&roles=RCA&roles=REG&roles=ACE&roles=ACP&roles=ACR&roles=ACT&roles=AWB&roles=BTI&roles=CCL&roles=CGU&roles=CVA&roles=CWP&roles=DPO&roles=EIR&roles=ETD&roles=EUS&roles=IPO&roles=OPO&roles=RSS&roles=SAS&roles=SDE&roles=SSE&roles=TEA&roles=TRD&roles=TST&roles=PRT&roles=RAC&roles=RRG&roles=CCD&roles=CND&roles=IPR&roles=EXC&roles=ENL&roles=ENT&roles=PCI&roles=SCI&roles=CAE&roles=EIN&roles=EXL&roles=EXP&roles=EXT&roles=CAU&roles=DEP&roles=DES&roles=ENQ&roles=GUA&roles=INC&roles=REC&roles=TRA&roles=TXT&roles=DIS&roles=PLA&roles=RFC&roles=SCO&Expand=true"
              target="_blank"
              rel="noreferrer"
              >
              List of EU offices.
              </a>
              </p>
            </p>
            <p>
              This is where your Transit will be discharged.
            </p>
            <p>
              Office of Destination code should be typed in the following format:
            </p>
            <p>
              2-digit alpha code (GB/ IE/ FR etc.) + 6-digit alpha-numeric (DUB100 – Dublin port / 620001 – Calais etc.). For example, IEDUB100 (Dublin Port).
            </p>

          </PopoverContent>
        </Popover>
      }
    >
      <div
        className="info"
        style={{ width: "1rem" }}
        title="Click to view"
      ></div>
    </OverlayTrigger>
  )
};

export const SealNumberTooltip = () => {
  return (
    <OverlayTrigger
      placement="top"
      trigger={["click"]}
      rootClose
      overlay={
        <Popover style={{ width: "100%", maxWidth: "25rem" }}>
          <PopoverContent>
            {/* <h6>Do your goods qualify for Preferential Treatment?</h6> */}
            <p>
              Please enter your Seal Number. If this is blank, we will assume there is no seal.
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div
        className="info"
        style={{ width: "1rem" }}
        title="Click to view"
      ></div>
    </OverlayTrigger>
  )
};
