import React, { useState, useEffect, useContext, useMemo } from "react";
//import { withRouter } from "react-router-dom";
import { StoreContext } from "./Store";
import { Select } from "../components/Input/Input";
import { getUsers, getUsersSearch, getCompanyList } from "./apiCalls";
//import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import  DataTable, {useDataTableHandle} from "./DataTable";
import { useSecurity } from "./Security/security";
import isoDateConverter from "./isoDateConverter";
import ConfirmationModal from "./ConfirmationModal";
import * as XLSX from 'xlsx';
import DownloadIcon from "../images/download.svg";

const UserAdminAudit = () => {
  const [items, setItems] = useState([]);
  const [requestFailed, setRequestFailed] = useState(false);
  const { validateRole } = useSecurity();
  const [companyList, setCompanyList] = useState([]);
  const [showUtilitiesModal, setShowUtilitiesModal] = useState(false);

  const dth = useDataTableHandle();

  //const history = useHistory();
  const store = useContext(StoreContext);

  const minWidthValue = validateRole({department: 'IT'}) ? '1500px' : '';

  useEffect(()=>{
    if(validateRole({department: 'IT'})){
      if(store?.userIdSelected[0] !== 0){
        fetchData(store?.userIdSelected[0]); 
      }
    }  
  },[store?.userIdSelected[0]])

  const fetchData = async (id) => {    
    try {
        const data = await getUsers("audit",id);
        setItems(data);
    } catch (err) {
      console.log(err);
      setRequestFailed(true);
    }
  };

  const fetchCompanyList = async () => {
    try {
      const data = await getCompanyList();
      const newData = data?.map(({ id, company }) => ({ id, data: company }));
      setCompanyList(newData);   
    } catch (err) {
      console.log(err);
      setRequestFailed(true);
    }
  };

  function setState(event) {
    const companyId = event.target.value;
    dth.current.resetPagination()
    if(companyId){
      store.userIdSelected[1](parseInt(companyId))
      //fetchData(companyId);
    }

  }
  

  const handleSubmit = async (body) => {
    const data = await getUsersSearch(body);
    setItems(await data);
  }

  useEffect(() => {
    if (validateRole({ department: "IT" })) {
      fetchCompanyList();
    }
  }, []);

  const getCompanyName = () =>{  
    const foundCompany = companyList.find(company => parseInt(company.id) === parseInt(store?.userIdSelected[0]));
    return foundCompany ? foundCompany.data : null;
  }

  const downloadUserDataAsExcel = async() =>{
    const data = await getUsers("audit", store?.userIdSelected[0])
        const workbook = XLSX.utils.book_new();
        // Convert JSON data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // Convert the workbook to a binary string
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        // Convert the binary string to a Blob
        const blob = new Blob([bs2Blob(wbout)], { type: 'application/octet-stream' });
        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'auditData.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        // Clean up
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
  } 

  const bs2Blob = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };


  if (requestFailed) return <p>Failed!</p>;
  return (
    <Container className="teamBackground" style = {{ minWidth: minWidthValue }}>
            <ConfirmationModal
      header={"Utilities"} 
      show={showUtilitiesModal}
      onHide={(event) => {
          setShowUtilitiesModal(false);        
      }}
      onConfirm={() => setShowUtilitiesModal(false)}
      hideCancel={true}
      animation={true}
      >
       <div style={{display: "flex", flexDirection:"column", textAlign: "center"}}>
          <div>Company name: {getCompanyName()}</div>
          <div style={{marginTop:"16px"}}>
            <button
              type="button"
              className="stepper-button yellow"
              style={{ width: "150px" }}
              onClick={downloadUserDataAsExcel}
            >
              <span>
                Download <img src={DownloadIcon} width="22" height="22" />
              </span>
            </button>
          </div>
        </div>
      </ConfirmationModal>
      <Row className="background ">
        <Col className="teamHeader ">
          <div className="d-flex justify-content-between">
            <span>User Admin Audit</span>
            {validateRole({department: 'IT'}) && <div style={{display: "flex", maxWidth: "300px", justifyContent: "center"}}>
        <Select
         label="Company List"
         name="companyList"
         data = {store?.userIdSelected[0]}
         onChange={setState}
         required
         options={companyList}
        />
        </div>}
          </div>
        </Col>

        {/* Class created for table to match margins with form */}
        
        <Row className="adminTable"> 
          <DataTable data={items} handle={dth} sortable filterable dontRefreshPagination advancedSearch={{onSubmit: handleSubmit}} className="table-striped table-bordered" showAdvnSearch={!validateRole({department: 'TRADER'}) && !validateRole({department: 'LSP'})}>
            <Button>Submit</Button>
            <DataTable.Pagination itemsPerPage={[10, 25, 50]} style={{fontSize:"4px"}} />
            <DataTable.Headers style={{backgroundColor: 'white',fontSize: '13px'}} />
            <DataTable.Column colKey="username" header="Username" />
            <DataTable.Column colKey="sceItem" header="Item" />
            <DataTable.Column colKey="sceLastMod" header="Last Modified">{({sceLastMod})=> isoDateConverter(sceLastMod)}</DataTable.Column>
            <DataTable.Column colKey="sceLastModUser" header="Last Modified User" />
            <DataTable.Column colKey="sceOldValue" header="Old Value" />
            <DataTable.Column colKey="sceNewValue" header="New Value" />
          </DataTable>
        </Row>
      </Row>
      { validateRole({department: 'IT'}) && (<section className="py-3 px-3 mt-4 gap-3 border-top" style={{display:"flex"}}>
      <div className="gap-3" style={{display:"flex"}}>
       <button type="button" disabled = { (store?.userIdSelected[0] === 0) ? true : false} className="stepper-button yellow" onClick={() => setShowUtilitiesModal(true)} >
        Utilities
        </button>
        </div>
      </section>)}
    </Container>
  );
};

export default UserAdminAudit;



