import React from 'react';
import "./LoadIcon.css";

import { SyncAlt } from "@styled-icons/fa-solid";

const LoadIcon = ({ className, ...props }) => {
  return (
    <SyncAlt className={`${className} spinner`} {...props} />
  );
}

const LoadUntilReady = ({ children, loading, style, loadingText, ...props }) => {
  if (!children) return null;
  if (loading) {
    return (
      <div className="full-width-container">
        <LoadIcon style={{ width: '2.5rem', ...style }} {...props} /><p className='spinner-text'>{loadingText}</p>
      </div>
    );
  } else {
    return children;
  }
}

export { LoadIcon, LoadUntilReady };