import { Check, ChevronRight, Minus } from "@styled-icons/fa-solid";
import React, { createContext, createElement, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";

import "./Stepper.css";

/** @typedef {{ text: string, stepKey: string, hideUntilActive: boolean, activeIsDone: boolean, hideUnlessVisited: boolean, visited: boolean }} Step */
/** @typedef {{_private: { registerStep: (step: Step) => void, removeStep: (stepKey: string) => void}, setDataByKey: (key: string, value: any) => void, emitData: () => void, localData: Record<string, any>, activeStepKey: string, steps: Step[], showFuture: boolean, canSkipForward: boolean, stepForward: () => void, stepBackward: () => void, gotoStep: (key: string) => void, areStepsDisabled: boolean, setStepsDisabled:boolean, unvisitStep: (stepKey: string) => void, stepClassName: string, stepStyle: Record<string, any>, hideBefore: (stepKey: string) => void, title: string, setTitle: (newTitle: string) => void, isStepListVisible: boolean, setStepListVisible: (visible: boolean) => void}} StepContext */

/** @type {React.Context<StepContext>} */
const StepperContext = createContext();

let nextStepKey = 0;
/**
 *
 * @param {{
 * defaultStepKey?: string,
 * showFuture?: boolean,
 * canSkipForward?: boolean,
 * emitData?: (data: Record<string, any>) => void,
 * defaultData?: Record<string, any>
 * stepClassName?: string,
 * stepStyle?: Record<string, any>,
 * children!: import("react").ReactChild,
 * }} props
 * @returns
 */
const Stepper = ({defaultStepKey, showFuture = true, canSkipForward = true, emitData, children, defaultData, stepClassName, stepStyle, tabValidation={}, useModalToChangeTabs=false, handleShowWarningModal={} }) => {


  /** @type {[Step[], (newSteps: Step[] | (oldSteps: Step[]) => Step[]) => void]} */
  const [steps, setSteps] = useState([]);
  const [activeStepKey, setActiveStepKey] = useState();
  const [isStepListVisible, setStepListVisible] = useState(true);

  const isInitialised = useRef(false);

  const [localData, setLocalData] = useState(defaultData ?? {});
  const [areStepsDisabled, setStepsDisabled] = useState(false);

  const [title, setTitle] = useState("");

  const stepForward = () => {
    const currentIdx = steps.findIndex((step) => step.stepKey === activeStepKey);
    if (currentIdx + 1 >= steps.length) {
      throw new Error("End of steps");
    }

    setActiveStepKey(steps[currentIdx + 1].stepKey);
  };

  const stepBackward = () => {
    const currentIdx = steps.findIndex((step) => step.stepKey === activeStepKey);
    if (currentIdx - 1 < 0) {
      throw new Error("Beginning of steps");
    }

    setActiveStepKey(steps[currentIdx - 1].stepKey);
  };

  const gotoStep = useCallback(
    (stepKey) => {
      if (steps.length === 0) return;
      if (stepKey === undefined) {
        if (activeStepKey === undefined) {
          setActiveStepKey(steps[0].stepKey);
          console.error(`No step supplied to goto function. Recovered by selecting 1st step.`);
          return;
        } else {
          // console.error(`No step supplied to goto function. Recovered by selecting staying on step ${activeStepKey}.`);
          return;
        }
      }

      stepKey = stepKey.toString();
      const idx = steps.findIndex((step) => step.stepKey === stepKey);
      if (canSkipForward === false && activeStepKey) {
        const activeIdx = steps.findIndex((step) => step.stepKey === activeStepKey);
        if (idx > activeIdx) {
          console.warn("Tried to go directly to a future step when prop 'canSkipForward' is false. If this was unintentional, please review.");
        }
      }
      if (idx === -1) {
        throw new Error(`Attempt to go to step ${stepKey} which is not in the steps.`);
      }
      setActiveStepKey(steps[idx].stepKey);
    },
    [canSkipForward, activeStepKey, steps]
  );

  const unvisitStep = (stepKey) => {
    console.log(stepKey);
    setSteps(prev => {
      if (!prev || !prev.length) return prev;
      const stepIdx = prev.findIndex(s => s.stepKey === stepKey);
      if (stepIdx === -1) return prev;
      prev[stepIdx].visited = false;
      return [...prev];
    });
  }

  useLayoutEffect(() => {
    setSteps(prev => {
      if (!prev || !prev.length) return prev;
      const stepIdx = prev.findIndex(s => s.stepKey === activeStepKey);
      if (stepIdx === -1) return prev;
      prev[stepIdx].visited = true;
      return [...prev];
    })
  }, [activeStepKey])

  const context = {
    // _private is not exposed through useStepper, as they are not for use anywhere else.
    _private: {
      registerStep: (step) => {
        // Either get the key from the step, or get the next ID number and convert it to a string.
        let stepKey = step.stepKey;
        if (!step.stepKey) {
          stepKey = nextStepKey++;
        }
        stepKey = stepKey.toString();

        // If we have not yet got a key set; either:
        if (isInitialised.current === false) {
          // Try to set to the default key (when it's added to the steps list)
          if (defaultStepKey) {
            if (defaultStepKey === stepKey) {
              setActiveStepKey(stepKey);
              isInitialised.current = true;
            }
          } 
          // Or just set it to the first one we find.
          else {
            setActiveStepKey(stepKey);
            isInitialised.current = true;
          }
        }
        // Append to the new step to the steps array, using the new key if needed.
        setSteps((prev) => [...prev, { ...step, stepKey }]);
        return stepKey;
      },
      removeStep: (stepKey) => {
        setSteps((prev) => prev.filter((step) => step.stepKey !== stepKey));
      },
    },
    setDataByKey: (key, value) => {
      setLocalData((prev) => {
        return { ...prev, [key]: value };
      });
    },
    emitData: () => emitData?.(localData),
    hideBefore: (stepKey) => setSteps(prev => {
      for (const step of prev) {
        if (step.stepKey === stepKey) break;
        step.hidden = true;
      }
      return [...prev];
    }),
    isStepListVisible,
    setStepListVisible,
    title,
    setTitle,
    unvisitStep,
    localData,
    activeStepKey,
    steps,
    showFuture,
    canSkipForward,
    stepForward,
    stepBackward,
    gotoStep,
    areStepsDisabled,
    setStepsDisabled,
    stepClassName, 
    stepStyle,
    tabValidation,
    useModalToChangeTabs,
    handleShowWarningModal
  };

  return (
    <StepperContext.Provider value={context}>
      <button type="submit" disabled style={{ display: "none" }} aria-hidden="true"></button>
      {children}
    </StepperContext.Provider>
  );
};

/**
 * 
 * @param {{stepKey?: string, text: string, hideUntilActive?: boolean, activeIsDone?: boolean, hideUnlessVisited?: boolean, style: Record<string, any>, children: import("react").ReactChild}} props
 */
const Step = ({ stepKey, children, text, hideUntilActive, activeIsDone, hideUnlessVisited, style, hideFromStepList }) => {
  const { _private, ..._public } = useContext(StepperContext);

  const [calculatedStepKey, setCalculatedStepKey] = useState();

  const { registerStep, removeStep } = _private;

  // Tell the Stepper information about this step, this returns an ID for us to use as a reference.
  useEffect(() => {
    let tempId = registerStep({ text, stepKey, hideUntilActive, activeIsDone, hideUnlessVisited, visited: false, hideFromStepList });
    setCalculatedStepKey(tempId);
    return () => {
      removeStep(tempId);
    };
  }, [stepKey, text]);

  // Apply helper props to children, this saves them the effort of fidling with the context (if they don't want to).
  const childrenWithProps = React.Children.map(children, (child) => {
    if (typeof child.type === "string") {
      return child;
    }
    if (React.isValidElement(child)) {
      return React.cloneElement(child, _public);
    }
    return child;
  });


  const shouldShow = useMemo(() => _public.activeStepKey === calculatedStepKey, [_public.activeStepKey, calculatedStepKey]);

  return (
    <div className={shouldShow ? (_public.stepClassName ?? "") : ''} style={{..._public.stepStyle, ...style, display: shouldShow ? "" : "none" }}>
      {childrenWithProps}
    </div>
  );
};

// -- Prestyled components -- //
const StepList = ({title: defaultTitle, isSticky}) => {
  const { steps, activeStepKey, setTitle, title, isStepListVisible, tabValidation, useModalToChangeTabs, handleShowWarningModal } = useStepper();

  // Memoise the index of the actively selected step; saves calculating this inside each step.
  const activeStepIndex = useMemo(() => steps.findIndex((step) => step.stepKey === activeStepKey), [steps, activeStepKey]);

  useEffect(() => {
    setTitle(defaultTitle)
  }, [defaultTitle, setTitle])

  if (!isStepListVisible) return null;

  return (
    <div className={`steps-box ${title ? 'with-title' : 'no-title'} ${isSticky ? 'sticky' : ''}`}>
      {title}
      {steps.map((currentStep, idx) => {
        if (currentStep.hideFromStepList) return null;
        return (<StepRow key={currentStep.stepKey} currentStep={currentStep} currentStepIndex={idx} activeStepIndex={activeStepIndex} tabValidation={tabValidation} useModalToChangeTabs={useModalToChangeTabs} activeStepKey={activeStepKey} handleShowWarningModal={handleShowWarningModal}/>)
      }
      )
      }
    </div>
  );
};

Stepper.Step = Step;
Stepper.StepList = StepList;
export default Stepper;

const StepRow = ({ currentStep, currentStepIndex, activeStepIndex, tabValidation, useModalToChangeTabs, activeStepKey, handleShowWarningModal }) => {
  const currentKey = currentStep?.stepKey;
    // Warning Component
const Warning = () => (
  <OverlayTrigger
    placement="top"
    trigger={["hover", "focus"]}
    overlay={
      <Popover style={{ minWidth: "auto" }}>
        <PopoverContent>
          <p>There is an error in this tab</p>
        </PopoverContent>
      </Popover>
    }
  >
    <p className="bp-warning-paragraph" style={{marginLeft:'auto' }}>
      <span className="warning bp-warning"></span>
    </p>
  </OverlayTrigger>
);
  const { gotoStep, showFuture, canSkipForward, areStepsDisabled } = useStepper();

  // Works out the current status based on the index of this step; and the active step.
  const stepStatus = useMemo(() => {
    if (currentStepIndex === activeStepIndex) {
      // If being active means the step is done, just return done.
      if (currentStep.activeIsDone) {
        return "done";
      } else {
        return "active";
      }
    }
    if (currentStepIndex < activeStepIndex) return "done";
    if (currentStepIndex > activeStepIndex) return "todo";
    return "active";
  }, [currentStepIndex, activeStepIndex, currentStep.activeIsDone]);

  // Step is in the future
  if (stepStatus === "todo") {
    // If this particular step should be hidden until active, don't show it.
    // Or if we don't want to show any steps that are in the future.
    if (currentStep.hideUntilActive === true || showFuture === false) {
      return null;
    }
  }

  // If this step should remain hidden unless navigated to directly; and we haven't navigated to it - don't show it.
  if (currentStep.hideUnlessVisited && currentStep.visited === false) {
    return null;
  }

  if (currentStep.hidden === true) return null;

  return (
    <button
      disabled={(stepStatus === "todo" && showFuture && !canSkipForward) || areStepsDisabled}
      onClick={() => {
      
        if(useModalToChangeTabs){
          //check if currentStep.stepKey is different than activeStepKey
          if(currentStep.stepKey !== activeStepKey) {
            handleShowWarningModal(() => gotoStep(currentStep.stepKey));
          }
        }
        else {
          gotoStep(currentStep.stepKey)
        }
      }}
      className={`step-row-button ${stepStatus}`}
      type="button"
    >
      {/* <span className="icon">
        {stepStatus === "todo" && <Minus width={"0.75em"} height={"0.75em"} />}
        {stepStatus === "active" && <ChevronRight width={"0.75em"} height={"0.75em"} />}
        {stepStatus === "done" && <Check width={"0.75em"} height={"0.75em"} />}
      </span> */}
      <span className="icons">
        <span className="circle"></span>
        <span className="line"></span>
      </span>
      <span>{currentStep.text}</span>
      {tabValidation[currentKey] === false ? <Warning /> : null}
    </button>
  );
};

/**
 *
 * @returns {Omit<StepContext, '_private'>} public context variables from StepperContext.
 */
export function useStepper() {
  const { _private, ..._public } = useContext(StepperContext);

  return _public;
}
