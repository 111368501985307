import React, { useState, useEffect, useContext } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { EllipsisV } from "@styled-icons/fa-solid";
import { getCommCode, deleteCommCode } from '../../apiCalls';
import CommCodeModal from "./commodityCodeModal";
import ConfirmationModal from "../../ConfirmationModal";
import { RefDataContext } from "../Maintenance";

const CommodityCodes = () => {
  const [commCodes, setCommCodes] = useState([{ "": "" }])
  const [selectedCommCode, setSelectedCommCode] = useState("");

  const {setOnAddNew} = useContext(RefDataContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCommCode();
        setCommCodes(data);
      } catch (err) {
      }
    }
    fetchData();
    setOnAddNew(() => {
      setSelectedCommCode({id: -1})
    });
  }, []);

return (<>
<CommCodeModal commCode={selectedCommCode} onSave={ async () => {
  const data = await getCommCode();
  setCommCodes(data);
}} onCancel={() => setSelectedCommCode(null)} />

<Table className="p-4 rounded-3" striped>
          <thead className="bold fs-5" style={{ color: "rgb(80, 80, 80)" }}>
            <tr>
              <th>Customs Description</th>
              <th>Commodity Code</th>
              <th className="text-nowrap" style={{ width: "0px" }}></th>
            </tr>
          </thead>
          <tbody>
            {/* TODO:PAGINATE ROWS */}
            {(commCodes).slice(0,20).map((commCode) => ( 
              <React.Fragment key={commCode.client_id}>
                <CommCodeRow commCode={commCode} setSelectedCommCode={setSelectedCommCode} onDelete={ async () => {
  const data = await getCommCode();
  setCommCodes(data);
}}/>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        </>)
}


function CommCodeRow ({commCode, setSelectedCommCode, child, onDelete}) {
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);

  const handleDelete = async event => {
    try {
      const data = await deleteCommCode(commCode.id)
      if (data[0].completed === 'Deleted') {
        alert("Record Deleted");
        onDelete()
      }
    } catch (err) {
      console.log(err);
      alert('Error, unable to delete')
    }
  }

    return (
      <tr id={commCode.client_id} className={`border-top fs-6 ${child ? "" : "bold"}`}>
        <td>{commCode.UKGBTDescription}</td>
        <td>{commCode.CommLookup}</td>
        <td>
          <Dropdown className="w-100 h-100 d-grid center">
            <Dropdown.Toggle as="button" className="dot-menu-button">
              <EllipsisV width={"0.5rem"} />
            </Dropdown.Toggle>
  
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={() => setSelectedCommCode(commCode)}>
                View/Edit
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setShowConfirmResetModal(true)}>
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ConfirmationModal
        header="Confirm Deletion"
        show={showConfirmResetModal}
        onHide={() => setShowConfirmResetModal(false)}
        onConfirm={handleDelete}
        annimation={true}
      >
        Are you sure you want to delete this record? 
      </ConfirmationModal>
        </td>
      </tr>
    )
  }

  export default CommodityCodes; 