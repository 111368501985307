import { useContext, useEffect, useMemo, useState } from "react";
import Stepper, { useStepper } from "../Stepper";

import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Popover, PopoverContent, Modal } from "react-bootstrap";

import { EtaExportPortPage, AdditionalRequirements, EpoDirectionPage, ExitPortPage, DirectionPage, EntryPortPage, EtaPortPage, CogPage, ImporterPage, JobRefPage, EuOriginPage, InvoicesUploadPage, CongratsPage, LspPage, DocumentsUploadPage, PreferentialOrigin } from "./Steps";
import { postEPOHeaders, getDDAStatus, postEPOExport} from "../apiCalls";
import { useSecurity } from "../Security/security";
import Confirmation from "./Steps/Confirmation";
import { StoreContext } from "../Store";



export default function EpoExports() {
  const [redirectReason, setRedirectReason] = useState(null);
  const [dutyModalOpen, setDutyModalOpen] = useState(false);
  const [DDAStatus, setDDAStatus] = useState(0)
  const { securityState, validateRole } = useSecurity();
  const [trader, setTrader] = useState("");
  const isLsp = useMemo(() => validateRole({ department: "WLP" }), []);
  const [movementRedirectReason, setMovementRedirectReason] = useState(null);
  
  const store = useContext(StoreContext);


  const defaultData = useMemo(() => ({
    importId: '',
    exportId: '',
    direction: "yes",
    entryport: {id: undefined},
    exitport: {id: undefined},
    importer: "yes",
    selectedCategoryOfGoods: [],
    euPref: "yes",
    pref: "yes",
    jobRef: "",
    date: "",
    lsp: "",
    fromAreaCode: '',
    toAreaCode: '',
    // confirmedNoCG: "",
    movementType: "",
    movementDirection: "",
    invoices: [],
    documents: [],
    certsList: [],
    importCountry: "eu",
    exportCountry: "eu",
    tad: false,
    ens: false,
    addReqNeither: false,
    officeDest: "",
    inlandTransId: '',
    sealNo: "",
    dateText: "",
    categoryOptions: [],
    progress: true,
    ipaffsConfirmation: false,
    ehcConfirmation: false,
    catchConfirmation: false,
    ipaffsConfirmed: false,
    ehcConfirmed: false,
    catchConfirmed: false,
    confirmationModal: false,
    ipaffsConfirmationFinish: false,
    ehcConfirmationFinish: false,
    catchConfirmationFinish: false,
    // lockDirection: false,
    openRedirectmodal: (text) => setRedirectReason(text),
    openDutyModal: () => setDutyModalOpen(true),
    openMovementRedirectModal: (text) => setMovementRedirectReason(text)
  }), [])

  useEffect(() => {
    async function fetchDDAStatus() {  
      const data = await getDDAStatus(securityState?.companyid);
       if(data){       
        setDDAStatus(data[0]?.DDA);
      }
    }
    fetchDDAStatus()
  }, []);

  useEffect(() => {
    store.epoProgress[1](true)
    store.lockDirection[1](false);
  }, []);

  const setDDAFlag = DDA => setDDAStatus(DDA);

  const covertToCamalCase = (sentence) => {
    if(sentence){
      const words = sentence.split(" ");
      const capitalizedWords = words.map((word) => {
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1).toLowerCase();
        return firstLetter + restOfWord;
      });
      return capitalizedWords.join(" ");
    }
  }
  
  const handleEmitData = async (data) => {
    const {officeDest, sealNo, inlandTransId, tad, ens, importId, exportId, entryport, exitport, importer, euPref, jobRef, date, invoices, lsp, confirmedNoCG, certsList, documents, movementType, euPrefSelected, ipaffsConfirmationFinish} = data;
    const fd = new FormData();

    if (movementType === "Export") {
      fd.append("exitPortId", exitport?.id);
    }
    if (movementType === "Import") {
      fd.append("entryPortId", entryport?.id);
    }
    fd.append("type", movementType);
    fd.append("importId", importId);
    fd.append("exportId", exportId);
    fd.append("importer", importer);
    fd.append("euPref", euPref);
    fd.append("jobRef", jobRef);
    fd.append("etaDate", date);
    fd.append("lsp", lsp)
    fd.append("tad", tad);
    fd.append("CABIEIpaffs", ipaffsConfirmationFinish);    
    fd.append("ens", ens);
    fd.append("sealNo", sealNo);
    fd.append("officeDest", officeDest);
    fd.append("inlandTransId", inlandTransId);
    fd.append("controlledGoods", confirmedNoCG == "NCG" ? 'N' : 'Y');
    fd.append("certsList", JSON.stringify(certsList));
    for (const file of invoices) {
      fd.append("invoices", file);
    }
    for (const file of documents) {
      fd.append("documents", file.file);
      fd.append("documentNames", file.name);
    }

    // fd.append("<name expected by backend>", <field found in data>);
    const result = await postEPOHeaders(fd)
    return result;
  };
  return (
    <>
      <DutyRedirectModal
        show={dutyModalOpen}
        onHide={() => setDutyModalOpen(false)}
      />
      {/* <p className="epo-exports-header"> Hello </p> */}
      <Stepper
        showFuture={false}
        emitData={handleEmitData}
        defaultData={defaultData}
        // stepHeader={header}
        // stepClassName=" d-flex flex-column justify-content-between pt-3"
        stepStyle={{
          borderRadius: "20px",
          padding: "0.5rem",
          minWidth: "500px",
          // maxWidth: "500px",
          minHeight: "475px",
        }}
      >
        <RedirectModal
          redirectReason={redirectReason}
          onHide={() => {
            setRedirectReason(null);
          }}
          onContinue={() => {
            setRedirectReason(null);
          }}
        />
        <DirectionRedirectModal
          redirectReason={movementRedirectReason}
          onHide={() => {
            setMovementRedirectReason(null);
          }}
        />

        <main
          className="d-flex w-100 mx-auto justify-content-center"
          style={
            isLsp && trader ? { marginTop: "3rem" } : { marginTop: "2rem" }
          }
        >
          <div
            className="d-flex flex-column"
            style={{ borderRadius: "0.9rem" }}
          >
            {validateRole({ department: "WLP"}) && (
              <div 
                className={`mb-4 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}
                style={{
                  fontSize: "32px",
                  marginLeft:"5px",
                  lineHeight: "20px",
                }}
              >
                {covertToCamalCase(trader)}
              </div>
            )}
            <div className="d-flex">
              {validateRole({ department: "WLP"}) && (
                <LspPage
                  DDAStatus={setDDAFlag}
                  showTrader={(trader) => setTrader(trader)}
                />
              )}
              {/* <DirectionPage /> */}
              <EpoDirectionPage DDAStatus={DDAStatus}/>
              {DDAStatus === 0 && <PreferentialOrigin/>} 
              {DDAStatus === 0 && <EuOriginPage/>}
              <EntryPortPage DDAStatus={DDAStatus}/>
              <ExitPortPage />
              {/* <EuOriginPage /> */}

              {/* <ImporterPage /> */}
              <AdditionalRequirements />
              <CogPage />
              <JobRefPage />
              <EtaPortPage />
              <EtaExportPortPage />
              <DocumentsUploadPage />
              <InvoicesUploadPage />
              <Confirmation showTrader={() => setTrader("")}/>
              <CongratsPage validateRole={validateRole} />
            </div>
          </div>
          <div style={{ minWidth: "20px" }} /> {/* spacer to ensure gap */}
          {/* <div style={isLsp ? {marginTop: "3.5rem"} : {}}> */}
          <div style={{ marginTop: isLsp ? "8.3rem" : "5.56rem" }}>
            <div              
              className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}
              // style={{                
              //   fontSize: "1rem",
              //   opacity: store.epoProgress[0] ? '100%' : '1%',
              // }}
            >
              {/* Process steps */}
            </div>
            <Stepper.StepList
              title={
                <div
                  className="epo-steps-stepper-header"
                  style={{
                    paddingTop: "0.5rem",
                    paddingLeft: "1.95rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  {/* Process Steps */}
                </div>
              }
            />
          </div>
        </main>
      </Stepper>
    </>
  );
}

export const EPOTooltip = () => {
  return (
    <OverlayTrigger
      placement="right"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "35rem", marginRight: "0.5rem !important" }}>
          <PopoverContent>
            <h6>Duty Calculation Required</h6>
            <p>
              If your goods are of mixed origin. you will need to calculate your duty if you do not have a registered DAN account. If you have a DAN account, we
              will automatically calculate the duty for you.
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info" style={{ width: "1rem", marginRight: "0.5rem" }}></div>
    </OverlayTrigger>
  );
};

const RedirectModal = ({ redirectReason, onHide, onContinue }) => {
  const { stepForward } = useStepper();
  function cancelClick() {
    onHide();
  }
  function onContinue() {
    stepForward()
    onHide();    
  }
  return (
    <Modal show={!!redirectReason} centered size={"md"} onHide={onHide} animation={false}>
      <Modal.Header>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="warning" /> <span>
            {redirectReason?.title}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 py-4">
        {redirectReason?.body}
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button class="stepper-button" onClick={cancelClick}>
          Cancel
        </button>
        {redirectReason?.showContinue && <button class="stepper-button yellow" onClick={()=> onContinue()}>
          Continue
        </button>}
      </Modal.Footer>
    </Modal>
  );
};

const DirectionRedirectModal = ({ redirectReason, onHide }) => {
  const { localData, setDataByKey, unvisitStep } = useStepper();
  const store = useContext(StoreContext);

  function cancelClick() {
    onHide();
  }
  
  async function resetClick() {
    onHide();
    store.lockDirection[1](false);
    setDataByKey("confirmedNoCG", undefined);
    setDataByKey("date", "");
    setDataByKey("exportId", "");
    setDataByKey("importId", "");
    setDataByKey("entryport", {id: undefined});
    setDataByKey("exitport", {id: undefined});
    setDataByKey("jobRef", "");
    setDataByKey("addReqNeither", false)
    setDataByKey("tad", false);
    setDataByKey("ens", false);
    setDataByKey("officeDest", "");
    setDataByKey("inlandTransId", "");
    setDataByKey("sealNo", "");
    setDataByKey("dateText", "");
    setDataByKey("categoryOptions", []);
    setDataByKey("categoryOptionsDefault", []);
    setDataByKey("selectedCategoryOfGoods", []);
    setDataByKey("invoices", []);
    setDataByKey("documents", []);
    setDataByKey("certsList", []);
    unvisitStep("9. Documents");
    unvisitStep("6. JobReference");
    unvisitStep("10. Commercial Documents");
    unvisitStep("8. EtaExportPort");
    unvisitStep("7. EtaPort");
    unvisitStep("4. AdditionalRequirements");
    unvisitStep("3. Entry Port");
    unvisitStep("2. Exit Port");
    unvisitStep("5. CategoryOfGoods");
    unvisitStep("eu_origin");
        
    const fd = new FormData();
    fd.append("to", "false");

    if (localData.movementType === "Export") {
      setDataByKey("movementType", "Import")
      // setDataByKey("importCountry", "gb");
      setDataByKey("exportCountry", "eu");
      store.exOrImport[1]("EPO - " + "Import")
      fd.append("movementType", "Import");
      const updateFlags = await postEPOExport('routeChoice', fd)
      if (updateFlags) {
        store.fromFlagOptions[1](updateFlags)

      }
      const fd2 = new FormData();
      fd2.append("to", "true");
      fd2.append("movementType", "Import");
      
      const updateImportDropdown = await postEPOExport('routeChoice', fd2)
      if (updateImportDropdown) { 
        store.toFlagOptions[1](updateImportDropdown)
        updateImportDropdown.forEach( e => {
          if (e.abbreviation === "gb") {
            setDataByKey("importCountry", "gb");
            setDataByKey("importId", e.id)
            setDataByKey("toAreaCode", e.areaCode);
          }
        })
      }


    }
    else {
      setDataByKey("movementType", "Export")
      setDataByKey("importCountry", "eu");
      setDataByKey("exportCountry", "gb");
      store.exOrImport[1]("EPO - " + "Export")
      fd.append("movementType", "Export");

      const updateFlags = await postEPOExport('routeChoice', fd)
      if (updateFlags) {
        store.fromFlagOptions[1](updateFlags)
        updateFlags.forEach( async e => {
          if (e.abbreviation === "gb") {
            setDataByKey("exportId", e.id)
            setDataByKey("fromAreaCode", e.areaCode);
            const fd1 = new FormData();
            fd1.append("from", e.id);
            fd1.append("to", "true");
            fd1.append("movementType", "Export");
            const updateImportDropdown = await postEPOExport('routeChoice', fd1)
            if (updateImportDropdown) { 
              store.toFlagOptions[1](updateImportDropdown)
            }
          }


        })
      }
    }

  }

  return (
    <Modal show={!!redirectReason} centered size={"md"} onHide={onHide} animation={false}>
      <Modal.Header>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="warning" /> <span>
            {redirectReason?.title}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 py-4">
        {redirectReason?.body}
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >

        <button class="stepper-button" onClick={cancelClick}>
          Cancel
        </button>

        <button class="stepper-button yellow" onClick={resetClick}>
          Ok
        </button>

      </Modal.Footer>
    </Modal>
  );
};

const DutyRedirectModal = ({ show, onHide }) => {
  const navigate = useNavigate();

  function cancelClick() {
    onHide();
  }
  function confirmClick() {
    navigate('/Userprofile/compliance');
  }


  return (
    <Modal show={show} centered size={"md"} onHide={onHide} animation={false}>
      <Modal.Header>
        <Modal.Title>
          More Information Required
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Sorry, without your C1207N form and Duty Deferment Number we aren't able to process Non-Preferential goods via EPO.</p>

        <p>
          If you have this information now, please add using the link below.
        </p>
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button class="stepper-button" onClick={cancelClick}>
          Close
        </button>
        <button class="stepper-button" onClick={confirmClick}>
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
};