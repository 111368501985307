import { useEffect, useState, useRef, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { getNewLoad } from "../apiCalls";
import { Input, Select, InputSelect } from "../Input/Input";
import { useStepper } from "../Stepper";
import creditcard from "../Epo/assets/credit-card.png";
import { GoogleAnalyticsContext } from "../GoogleAnalytics";

export default function FinanceContact() {
  const { stepBackward, stepForward, localData, setDataByKey, gotoStep, activeStepKey } = useStepper();

  const [errors, setErrors] = useState({});

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "paymentcontact" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "2.5. FinanceContact",{
          event_category: "Registration"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  const handleNext = async (e) => {
    e.preventDefault();
    const freshErrors = {};

    if (!localData.paymentName) freshErrors.paymentName = "Required";
    if (!localData.paymentLastName) freshErrors.paymentLastName = "Required";
    if (!localData.paymentPhone) freshErrors.paymentPhone = "Required";
    if (!localData.paymentEmail) freshErrors.paymentEmail = "Required";
    
    setErrors(freshErrors);
    if (!Object.values(freshErrors).some(err => !!err)) {
      stepForward();
    }
  };

  const handleBack = () => {
    stepBackward();
  }

  return (
    <form onSubmit={handleNext} className="flex-grow-1 d-flex flex-column">
      <div>
      <span className="register-step-header">Payment Contact Details</span>
        <div className="inputs-group register-info">
          <img src={creditcard} width="70" height="70" alt="" />
          <span>
            We also need to have contact details for the person we can contact for any queries relating to invoicing.
          </span>
        </div>
        <div className="registrationText mt-4">Payment Contact Details</div>
        <Row className="py-2">
          <Col>
            <Input label={<>First Name <span style={{ fontSize: "120%" }}>*</span></>} error={errors.paymentName} value={localData.paymentName} onChange={(e) => setDataByKey('paymentName', e.target.value)} />
          </Col>
          <Col>
            <Input label={<>Last Name <span style={{ fontSize: "120%" }}>*</span></>} error={errors.paymentLastName} value={localData.paymentLastName} onChange={(e) => setDataByKey('paymentLastName', e.target.value)} />
          </Col>
        </Row>
        <Row className="py-2">
          <Col>
            <Input label={<>Payment Contact Telephone Number <span style={{ fontSize: "120%" }}>*</span></>} error={errors.paymentPhone} value={localData.paymentPhone} onChange={(e) => setDataByKey('paymentPhone', e.target.value)} />
          </Col>
          <Col>
          <Input type="email" label={<>Payment Contact Email <span style={{ fontSize: "120%" }}>*</span></>} error={errors.paymentEmail} value={localData.paymentEmail} onChange={(e) => setDataByKey('paymentEmail', e.target.value)} />
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1" />
      <section className="d-flex justify-content-between mt-3">
        <button type="button" className="blue-button-teams green-btn" onClick={handleBack}>
          Back
        </button>
        <span className="py-1 text-danger">{Object.values(errors).some(err => !!err) && "Some errors were found, please correct above."}&nbsp;</span>
        <button type="submit" className="blue-button-teams green-btn">
          Next
        </button>
      </section>
    </form>
  );
}
