import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Input, Select } from "../../Input/Input";
import { UserProfileContext } from "../UserProfile";

export default function AddressDetails() {
  const { companyInfo, updateCompanyInfo, addressDetailsError } = useContext(UserProfileContext);
 
  return (
    
    <div className="px-4 pb-5 pt-2 bg-white">
        {addressDetailsError && (
       <div>
       <span className="d-flex alert-danger justify-content-center mt-2" >
           {addressDetailsError}
       </span>
     </div>
      )}
      <h6 className="text-eoriblue mt-2">Trading Address</h6>
      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Building name/number <span style={{ fontSize: "120%" }}>*</span>
              </> 
            }
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            value={companyInfo.building} 
            onChange={e => updateCompanyInfo('building', e.target.value)}
          />
        </Col>
        <Col>
          <Input
            label={
              <>
                Street <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'}  
            value={companyInfo.street} 
            onChange={e => updateCompanyInfo('street', e.target.value)}
          />
        </Col>
      </Row>
      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Town/City <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'}   
            value={companyInfo.town} onChange={e => updateCompanyInfo('town', e.target.value)}
          />
        </Col>
        <Col>
          <Input label="County" 
             disabled ={sessionStorage.getItem('reg') === 'true'}    
          value={companyInfo.county} onChange={e => updateCompanyInfo('county', e.target.value)} />
        </Col>
      </Row>

      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Postcode <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            value={companyInfo.postcode} onChange={e => updateCompanyInfo('postcode', e.target.value)}
          />
        </Col>
        <Col>
          <Select
            label={
              <>
                Country <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            data={companyInfo.country}
            setData={data => updateCompanyInfo('country', data)}
            options={[
              { id: "GB", data: "Great Britain" },
              { id: "IE", data: "Republic of Ireland" },
              { id: "NI", data: "Northern Ireland" },
            ]}
          />
        </Col>
      </Row>

      <h6 className="text-eoriblue mt-2">Registered Office Address</h6>
      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Building name/number <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            value={companyInfo.building2} onChange={e => updateCompanyInfo('building2', e.target.value)}
          />
        </Col>
        <Col>
          <Input
            label={
              <>
                Street <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            value={companyInfo.street2} onChange={e => updateCompanyInfo('street2', e.target.value)}
          />
        </Col>
      </Row>
      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Town/City <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            value={companyInfo.town2} onChange={e => updateCompanyInfo('town2', e.target.value)}
          />
        </Col>
        <Col>
          <Input label="County" 
               disabled ={sessionStorage.getItem('reg') === 'true'}  
          value={companyInfo.county2} onChange={e => updateCompanyInfo('county2', e.target.value)} />
        </Col>
      </Row>

      <Row className="py-1">
        <Col>
          <Input
            label={
              <>
                Postcode <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            value={companyInfo.postcode2} onChange={e => updateCompanyInfo('postcode2', e.target.value)}
          />
        </Col>
        <Col>
          <Select
            label={
              <>
                Country <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            data={companyInfo.country2}
            setData={data => updateCompanyInfo('country2', data)}
            options={[
              { id: "GB", data: "Great Britain" },
              { id: "IE", data: "Republic of Ireland" },
              { id: "NI", data: "Northern Ireland" },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}
