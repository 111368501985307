import { useEffect, useState, useRef, useContext } from "react";
import Stepper, { useStepper } from "../../Stepper";
import { Input } from "../../Input/Input";
import { StoreContext } from "../../Store";
import eta from "../assets/eta.png";
import { OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";


export function EtaPortPage () {
  const { stepBackward, stepForward, localData, setDataByKey, hideBefore, setTitle, activeStepKey, gotoStep } = useStepper();
  const store = useContext(StoreContext);
  const nextPage = () => {
    if (!localData.date) {
      setError("Please enter a loading date.");
      return;
    }
    const dSelected = new Date(localData.date);
    dSelected.setHours(0, 0, 0, 0);
    const dNow = new Date();
    dNow.setHours(0, 0, 0, 0);
    if (dSelected < dNow) {
      setError("Date must be in the future.")
      return;
    }
    if (localData.confirmedNoCG == "NCG"){     
      // setClickCount(((preCount) => preCount + 1))  
      // const [result] = await emitData(localData);
      // setDataByKey('returndata', {...result, submittedAt: new Date()});
      // setStepListVisible(false);
      gotoStep("10. Commercial Documents");

    }
    if (localData.confirmedNoCG == "CG"){  
    gotoStep("9. Documents")
    }
    // gotoStep("10. Commercial Documents");
  };
  
  const backwards = () => {
    setError("");
    stepBackward();
};



  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "7. EtaPort" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "7. EtaPort",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [localData.date]);

  return (
    <Stepper.Step hideUnlessVisited stepKey="7. EtaPort" text="Date of Arrival">
      {/* <div> */}
      <div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{ fontSize:"1.8rem"}}>Date of Arrival</div>
      <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
          <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
            <input type="radio" disabled checked={localData.movementType === "Import"}/>
            <span>Import</span>
          </label>

          <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px'}}>
            <input type="radio" disabled checked={localData.movementType === "Export"} />
            <span>Export</span>
          </label>
        </div>
        {/* <span className="epo-steps-header">Date of Arrival</span> */}
        <div className=' px-2 mt-1 pt-2 d-flex flex-column' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
        <div className="d-flex step-banner justify-content-center">
           <img src={eta} width="150" height="150" alt="port" className="mt-4 mb-4"/>
           {/* <div class="d-flex flex-column mb-1 px-4" style={{maxWidth: "350px"}}> */}
            {/* <p style={{color: '#0e2554'}}>Please select the Date of Arrival for this shipment</p> */}
            {/* <div className="flex-grow-1 "/> */}
            {/* <p style={{color: '#0e2554'}}>Any changes to this date can be made using the calendar tool on the status screen.</p> */}
            {/* </div> */}
        </div>

        <div class="d-flex align-self-center flex-column mt-auto  px-4" style={{maxWidth: "400px"}}>
                      {/* <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}} >Select an Arrival date for this shipment. Date changes can be made with the calendar tool on the status screen.</span> */}
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}} >Select Arrival date for this shipment.  </span>
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}}>Date changes can be made with the</span>

            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "280px", fontWeight: "500"}}>calendar tool on the status screen.</span>
            {/* <p class="d-flex align-self-center flex-column mt-auto " style={{maxWidth: "420px", fontWeight: "500"}} ></p> */}
             </div>

             {/* <span class="d-flex flex-column align-self-center " style={{maxWidth: "280px", fontWeight: "500"}}>(Multiple selections are allowed)</span> */}
             {/* <div class="d-flex align-self-center flex-column mt-auto  px-4" style={{maxWidth: "400px"}}>
            <p style={{, fontWeight: "500"}} >Please enter your Job Reference or billing ID. This will appear on all future communications and sales invoices.</p>
             </div> */}

        <div className=" d-flex flex-column mt-auto" style={{paddingLeft: '6rem', paddingRight: '6rem'}}>
          <Input
            label="Shipment date of Arrival"
            name="dateOfArrival"
            type="date"
            min={(new Date()).toISOString().slice(0,10)}
            value={localData.date}
            required
            onChange={(e) => setDataByKey("date", e.target.value)}
          
          />
       
        </div>
        <div className="px-3 mb-3 w-75 mx-auto">
          {error && (
            <div className="alert-danger px-3 py-1 w-100 text-center" style={{borderRadius: '10px'}}>{error}</div>
          )}
        </div>



        <section className="d-flex justify-content-between px-3 pb-4 mt-auto">
        <button type="button" className="stepper-button red" onClick={backwards}>
          Back
        </button>
        <button type="button" className="stepper-button" onClick={nextPage}>
          Next
        </button>
      </section>
      </div>
    </Stepper.Step>
  );
};