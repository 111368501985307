import _, { rest } from "lodash";
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
  useRef,
} from "react";
import { getNewLoad, getProducts, getProductsForCompany } from "../../apiCalls";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ProductGridContext } from ".";
import { StoreContext } from "../../Store";
import {
  GrossMassInfoTooltip,
  NetMassInfoTooltip,
  PreferentialTooltip,
} from "./Tooltips";


const BasicSelect = React.memo(
  ({ options, cell_data, onChange, ...rest }) => {
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        show={cell_data?.error ? undefined : false}
        placement="bottom"
        overlay={
          <Popover id="popover-basic">
            <div className="px-2 py-1 alert-warning">{cell_data.error}</div>
          </Popover>
        }
      >
        <select
          {...rest}
          value={cell_data?.id ?? cell_data?.text?.toUpperCase()}
          data-error={cell_data?.error ? true : undefined}
          onChange={(event) => {
            const id = event.target.value;
            const selected = options.find(
              (opt) => opt.id.toString() === id.toString()
            );
            if (selected) {
              onChange({ ...selected, text: selected.data.toUpperCase() });
            } else {
              onChange("");
            }
          }}
        >
          <option value=""> - </option>
          {options.map((opt) => {
            const optValue = isNaN(opt.id) ? opt.id.toUpperCase() : opt.id
           return (
            <option key={opt.id} value={optValue}>
              {opt.data}
            </option>
          )}
          )}
        </select>
      </OverlayTrigger>
    );
  },
  (old_props, new_props) => {
    return (
      _.isEqual(old_props.cell_data, new_props.cell_data) &&
      _.isEqual(old_props.options, new_props.options)
    );
  }
);

const BasicInput =  ({ style, cell_data, onChange, ...rest }) => {
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        show={cell_data?.error ? undefined : false}
        placement="bottom"
        overlay={
          <Popover id="popover-basic">
            <div className="px-2 py-1 alert-warning">{cell_data?.error}</div>
          </Popover>
        }
      >
        <input
          title={cell_data?.text}
          style={{ ...style, color: "rgb(50,50,50)" }}
          data-error={cell_data?.error ? true : undefined}
          value={cell_data?.text}
          onChange={(event) => onChange(event.target.value)}
          {...rest}
        />
      </OverlayTrigger>
    );
  }

const organicOptions = [
  { id: "YES", data: "YES" },
  { id: "NO", data: "NO" },
];

const TableEditorAlt = (props) => {
  const store = useContext(StoreContext);

  const [productCodeOptions, setProductCodeOptions] = useState([]);
  const [packageTypeOptions, setPackageTypeOptions] = useState([]);
  const countryOptions = useRef([]);
  const originsOptions = useRef([]);

  useEffect(() => {
    const getData = async () => {
      const products = await getProducts();
      setProductCodeOptions(
        products.map((product) => ({
          ...product,
          data: product["Code or SKU"],
        }))
      );

      const package_types = await getNewLoad("loadtype");
      setPackageTypeOptions(
        package_types.map((type) => ({
          id: type.data.toUpperCase(),
          data: type.data,
        }))
      );

      const origins = await getNewLoad("countrycode");
      originsOptions.current = origins;

      const countries = await getNewLoad("countryname");
      countryOptions.current = countries;
    };

    getData();
  }, []);

  const isMeursingEditable = React.useMemo(() => {
    return ['5', '6', '7'].includes(store.directionSelected[0])
  }, [store.directionSelected[0]])

  const { productState, productDispatch } = useContext(ProductGridContext);

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{
          boxShadow: "0 0 4px 1px rgba(0 0 0 / 20%)",
          borderRadius: "20px",
        }}
      >
        <TableSetup>
          <Column
            key="productCode"
            type="select"
            minWidth={25}
            align="center"
            options={productCodeOptions}
          >
            Product
          </Column>
          {!store.tadEns[0] && (
            <Column
              key="commoditycode"
              type="text"
              minWidth={13}
              align="center"
              disabled
              value={(row) => row.productCode["Commodity Code"] ?? ""}
            >
              HS Code
            </Column>
          )}
          <Column
            key="origin"
            type="text"
            minWidth={9}
            align="center"
            disabled
            value={(row) => {
              const selectedCountry = row.productCode["Country"];
              if (!selectedCountry) return "";

              const id = countryOptions.current.find(
                (country) => country.data === selectedCountry
              )?.id;
              if (id !== undefined) {
                const code = originsOptions.current.find(
                  (origin) => origin.id === id
                )?.data;
                if (code !== undefined) {
                  return code;
                }
              }
              return "N/A";
            }}
          >
            Origin
          </Column>
          <Column
            key="description"
            type="text"
            stretch
            disabled
            minWidth={12}
            value={(row) => row.productCode["Customs Description"] ?? ""}
          >
            Description
          </Column>
          <Column key="packageCount" type="number" minWidth={10}>
            No. Items
          </Column>
          <Column
            key="packageType"
            type="select"
            minWidth={10}
            align="center"
            options={packageTypeOptions}
          >
            Pkg. Type
          </Column>
          <Column
            key="grossMass"
            type="number"
            minWidth={15}
            tooltip={<GrossMassInfoTooltip />}
          >
            Gross Mass
          </Column>
          <Column
            key="netMass"
            type="number"
            minWidth={14}
            tooltip={<NetMassInfoTooltip />} 
          >
            Net Mass
          </Column>
          <Column key="itemValue" type="number" minWidth={12}>
            Value
          </Column>
          <Column key="quantity2" type="number" minWidth={8} align="right">
            Qty 2
          </Column>
          <Column key="healthCert" type="text" minWidth={13}>
            Health Cert
          </Column>
          <Column
            key="meursing"
            type="text"
            minWidth={10}
            disabled={!isMeursingEditable}
            value={(row) => row.productCode["Meursing Code"] ?? ""}
          >
            Meursing
          </Column>
          <Column
            key="organic"
            minWidth={8}
            type="select"
            options={organicOptions}
            default_text="NO"
          >
            Organic
          </Column>
          <Column
            key="VAT"
            minWidth={8}
            type="select"
            options={store.VatOptions[0]}
            tooltip={<PreferentialTooltip><h6>Valid Options</h6>
              <ul>
                <li>VATZ = Zero Rated</li> 
                <li>VATS = Standard Rated</li>
              </ul></PreferentialTooltip>}>
              VAT
            </Column>
          <Column key="RGR" type="text" minWidth={8}>
            RGR
          </Column>

          <Column
            key="preferential"
            type="select"
            options={store.PreferentialOptions[0]}
            minWidth={20}
            tooltip={<PreferentialTooltip><h6>Valid Options</h6>
            <ul>
              <li>Yes</li> 
              <li>No</li>
            </ul></PreferentialTooltip>}
          >
            Preferential
          </Column>
        </TableSetup>
        {productState.config.length && <TheTable />}
      </div>

      <div className="flex-row-reverse py-3 d-flex">
        <button
          className="blue-button-newload"
          type="button"
          onClick={() => {
            props.validate(productState.rows, productState.config, (newRows) =>
              productDispatch({ type: "set", detail: newRows })
            );
          }}
        >
          Order Form
        </button>
      </div>
    </>
  );
};

function TheTable() {
  const { productState, productDispatch } = useContext(ProductGridContext);
  /**
   * Applies any changes which are relational (function based), adds the keys to the
   * row object, as they wouldn't normally be set.
   */
  useEffect(() => {
    productState.rows.forEach((row, y) =>
      productState.config.forEach(({ key, value }, idx) => {
        if (typeof value === "function") {
          const val = value(row);
          if (row?.[key]?.text !== undefined && val !== row[key].text) {
            productDispatch({
              type: "change",
              location: { y, key },
              detail: value(row),
            });
          }
        }
      })
    );
  }, [productState.rows, productState.config, productDispatch]);

  return (
    <div>
      <div className="commodity-table-headers">
        {productState.config.map(
          ({ minWidth, stretch, align, children, type, tooltip }, idx) => (
            <div
              style={{
                minWidth: `${minWidth}ch`,
                flexGrow: stretch && 1,
                flexBasis: !stretch && "0%",
                textAlign: align ?? (type === "number" ? "right" : "left"),
              }}
            >
              <h1 className="commodity-table-header">
                <span>{children}</span>
                {tooltip}
              </h1>
            </div>
          )
        )}
      </div>

      {productState.rows.map((row, y) => (
        <div className="d-flex bg-white commodity-input-row">
          {productState.config.map(
            (
              { minWidth, stretch, align, options, type, disabled, key, value },
              idx
            ) => {
              if (type === "select") {
                return (
                  <BasicSelect
                    key={key}
                    type={type}
                    disabled={disabled}
                    cell_data={row[key] ?? { id: "", text: "" }}
                    options={options}
                    style={{
                      minWidth: `${minWidth}ch`,
                      flexGrow: stretch && 1,
                      flexBasis: !stretch && "0%",
                      textAlign:
                        align ?? (type === "number" ? "right" : "left"),
                    }}
                    onChange={(selected) =>
                      productDispatch({
                        type: "change",
                        detail: selected,
                        location: { y, key },
                      })
                    }
                  />
                );
              } else {
                return (
                  <BasicInput
                    key={key}
                    type={type}
                    disabled={disabled}
                    cell_data={row[key]}
                    style={{
                      minWidth: `${minWidth}ch`,
                      flexGrow: stretch && 1,
                      flexBasis: !stretch && "0%",
                      textAlign:
                        align ?? (type === "number" ? "right" : "left"),
                    }}
                    onChange={(event) =>
                      productDispatch({
                        type: "change",
                        detail: event,
                        location: { y, key },
                      })
                    }
                  />
                );
              }
            }
          )}
        </div>
      ))}

      <div className="d-flex last-row bg-white commodity-input-row">
        {productState.config.map(
          ({ minWidth, stretch, align, children, type }, idx) => (
            <div
              style={{
                minWidth: `${minWidth}ch`,
                flexGrow: stretch && 1,
                flexBasis: !stretch && "0%",
                textAlign: align ?? (type === "number" ? "right" : "left"),
              }}
            >
              <div onClick={() => productDispatch({ type: "new-row" })}>
                &nbsp;
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

const TableSetup = React.memo(
  (props) => {
    const { productDispatch } = useContext(ProductGridContext);

    const hasRun = useRef(false);

    useEffect(() => {
      const config = [];
      React.Children.forEach(props.children, (child) => {
        if (!!child && typeof child === "object") {
          config.push({ ...child.props, key: child.key });
        }
      });

      // console.log(config);

      productDispatch({ type: "config", detail: config });
      if (hasRun.current) {
        productDispatch({ type: "reset" });
      } else {
        hasRun.current = true;
      }
    }, [props.children, productDispatch]);

    return null;
  },
  (old, updated) => {
    const old_kids = old.children.filter((c) => !!c);
    const new_kids = updated.children.filter((c) => !!c);

    if (old_kids.length !== new_kids.length) return false;
    // Cannot compare functions, so remove them from the props.
    const old_props = old_kids.map((c) => removeFunctionProps(c.props));
    const new_props = new_kids.map((c) => removeFunctionProps(c.props));

    return _.isEqual(old_props, new_props);
  }
);

function Column() {
  return null;
}

function removeFunctionProps(props) {
  const obj = {};
  Object.entries(props).forEach(([key, prop]) => {
    if (typeof prop !== "function" && !React.isValidElement(prop)) {
      obj[key] = prop;
    }
  });
  return obj;
}

export default TableEditorAlt;
