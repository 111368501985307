import React from "react";

const DashboardSection = ({dashboardTitle, data, dashboardTitleDescription}) => {
  const setTitle = () => {
    if(dashboardTitleDescription !== undefined){
      return (
        <h1 className="dashboard-breakdown__title">{dashboardTitle} <span className="total-jobs__p">{dashboardTitleDescription}</span></h1>
      );
    }
    else{
      return (<h1 className="dashboard-breakdown__title">{dashboardTitle}</h1>)
    }
  }

  return (
    <div className="dashboard-breakdown__section">
      {setTitle()}
      {Object.keys(data).map((key, index) => (
        <div key={index} className="dashboard-breakdown__information">
          <p className="dashboard-breakdown__status__text">{key}</p>
          <p className="dashboard-qty">{data[key]}</p>
        </div>
      ))}
    </div>
  );
};

export default DashboardSection;
