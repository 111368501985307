import './main.css';

export default function EnvBanner() {
  if (process.env.REACT_APP_ENV === "prod") return null;

  return (
    <>
    {process.env.REACT_APP_ENV === "dev" && (
    <div className="env-banner w-100 d-flex bold alert-success justify-content-center align-items-center">
        <span style={{ fontSize: "28px", color: "red"}} >   ** DEV Environment ** </span> &nbsp;
    </div>)}

    {process.env.REACT_APP_ENV === "test" && (
    <div className="env-banner w-100 d-flex bold alert-danger justify-content-center align-items-center">
        <span style={{ fontSize: "28px", color: "red"}} >  ** UAT Environment **</span> &nbsp;
      {/* UAT ENVIRONMENT - FOR TESTING ONLY - NO LIVE JOBS! */}
    </div>)}

    {process.env.REACT_APP_ENV === "demo" && (
    <div style={{ backgroundColor: "#b4b4b4"}} className="w-100 d-flex bold justify-content-center align-items-center">
        <span></span> &nbsp;Customer Demo Platform
    </div>)}

    </>
  )
}