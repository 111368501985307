function isoDateConverter (str, dateOnly, timeOnly, dateDiffFormat) {
    
    let date = new Date(str);
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();
        let hour = date.getHours();
        let minutes = date.getMinutes();    

        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }

        if (hour < 10) {
            hour = '0' + hour;
        }

        if (dateOnly && dateDiffFormat) {
            return date = `${day}/${month}/${year}`
            }
            else if (dateOnly) {
            return date = `${year}-${month}-${day}`
            }
            else if (timeOnly) {
            return date = `${hour}:${minutes}`
            }
            else {
        return date = `${day}/${month}/${year} - ${hour}:${minutes}`
         }
    
};

export default isoDateConverter;


