import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import DataTable from "../DataTable";
import { ExternalLinkAlt, PlusCircle, Trash } from "@styled-icons/fa-solid";
import { Badge, Form, Modal, ModalBody, ModalTitle, Tab, Table, Tabs } from "react-bootstrap";
import { getEPO } from "../apiCalls";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../Store";
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function EpoJobInvoices() {
  const store = useContext(StoreContext);
  const [jobDetails, setJobDetails] = useState(null);
  const navigate = useNavigate();

  const [openedInvoice, setOpenedInvoice] = useState(null);

  const viewInvoice = (invoice) => {
    setOpenedInvoice(invoice);
  };

  function goBack() {
    navigate("/EpoListLoads")
  }

  useEffect(() => {
    async function fetchDetails() {
      const data = await getEPO(store.newLoadID[0], "Download");
      setJobDetails(data);
    }
    fetchDetails();
  }, [store.newLoadID[0]]);

  if (!jobDetails) return null;

  return (
    <>
    <div className="full-width-container">
        <section className="floating-box" style={{ maxWidth: "1040px" }}>
          <div className="floating-box-header">
            <span>Downloads</span>
          </div>

      <EpoInvoiceModal invoice={openedInvoice} onClose={() => setOpenedInvoice(null)} />
      <div>
        <DataTable data={jobDetails} className="table-striped table-bordered">
          <DataTable.Column colKey="filename" header="Filename" />
          <DataTable.Column colKey="invStatus" header="Status" />
          <DataTable.Column header="View" style={{ height: "1px", padding: 0 }} headerStyle={{ textAlign: "center" }}>
            {(invoice) => (
              <button className="external-table-link" onClick={() => viewInvoice(invoice)}>
                <ExternalLinkAlt width="1.1rem" height="1.1rem" color="#00857d" />
              </button>
            )}
          </DataTable.Column>
        </DataTable>
      </div>
      <button className="blue-button-teams green-btn" style={{margin: "10px"}} onClick={goBack} >Back</button>
      </section>
      </div>
    </>
  );
}

function EpoInvoiceModal({ invoice, onClose }) {
  const [localInvoiceData, setLocalInvoiceData] = useState();
  const [pdfData, setPdfData] = useState();

  useEffect(() => {
    setLocalInvoiceData(invoice);
    if (invoice?.invoiceId) {
      getEPO(invoice.invoiceId, "Invoice").then((res) => {  
        const b = Buffer.from(res[0].invoice.data);

        setPdfData({
          id: invoice.invoiceId,
          filename: invoice.filename,
          data: b.buffer.slice(b.byteOffset, b.byteOffset + b.byteLength),
        });
      });
    } else {
      setPdfData({
        id: null,
        filename: null,
        data: null,
      });
    }
  }, [invoice]);

  if (!localInvoiceData?.invoiceId) return null;

  return (
    <Modal show={localInvoiceData?.invoiceId} centered contentClassName="editModal" dialogClassName="superwide-modal">
      <Modal.Header>
        <Modal.Title>Viewing: {localInvoiceData?.filename}</Modal.Title> <button type="button" class="btn-close" aria-label="Close" onClick={onClose} />
      </Modal.Header>
      <div className="flex-grow-1 d-flex flex-column">
        <Modal.Body className="flex-grow-1">
            <EpoInvoiceSummary localInvoiceData={localInvoiceData} pdfData={pdfData} />
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light align-items-center"
          style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
        >
          <div className="d-flex gap-2 align-items-center">
            <span className="bold" style={{fontSize: '16px'}}>Totals:</span>
            <span className="badge bg-success" style={{fontSize: '14px'}}>Gross Mass: {localInvoiceData.invGrossMass ?? 0}kg</span>
            <span className="badge bg-success" style={{fontSize: '14px'}}>Net Mass: {localInvoiceData.invNetMass ?? 0}kg</span>
            <span className="badge bg-success" style={{fontSize: '14px'}}>Value: {(localInvoiceData.invValue ?? 0).toFixed(2)}</span>
            <span className="badge bg-success" style={{fontSize: '14px'}}># Rows: {localInvoiceData.itemRows ?? 0}</span>
          </div>
          <div className="flex-grow-1" />
          <button type="button" class="cancel-button" onClick={onClose} style={{ display: "flex", borderRadius: "10px" }}>
            Cancel
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

function EpoInvoiceSummary({ localInvoiceData, pdfData }) {
  const file = useMemo(() => {
    return pdfData.data;
  }, [pdfData.id]);

  const dowloadFile = () => {
    if (!pdfData.id && !pdfData.data) return;
    var fileURL = window.URL.createObjectURL(new Blob([pdfData.data]));
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = fileURL;
    a.download = pdfData.filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(a);
    }, 0);
  };

  
  const [numberOfPages, setNumberOfPages] = useState(null);

  return (
    <section className="p-2 d-flex flex-column justify-content-around gap-2 mt-3">
      <div className="p-3 rounded-3" style={{ height: "550px", overflowY: "auto", border: '2px solid hsl(0, 0%, 75%)' }}>
        <div
          className="invoice-preview"
          onClick={() => {
            dowloadFile();
          }}
        >
          <Document file={file} onLoadSuccess={({numPages}) => setNumberOfPages(numPages)}>
            {numberOfPages && [...Array(numberOfPages)].map((_, i) => <Page pageNumber={i + 1} scale={1.8} />)}
          </Document>
        </div>
      </div>
    </section>
  );
}