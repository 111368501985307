import ReactDOM from "react-dom";

import _ from "lodash";

import React, { useState, useEffect, useRef, useCallback } from "react";
import "./TableEditor.css";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { useContext } from "react";
import { StoreContext } from "../../Store";
import FileUploader from "../../FileUploader";
import { ExcelGridContext } from "./index";
import {
  CommodityCodeInfoTooltip,
  GrossMassInfoTooltip,
  NetMassInfoTooltip,
  PackageTypeInfoTooltip,
  PreferentialTooltip,
} from "./Tooltips";

/**
 * Correctly gets the position bounding rectangle from the top-left of the document.
 * (as the floating rects are absolutely position)
 */
const getBoundingClientOffset = (element) => {
  let bodyRect = document.body.getBoundingClientRect(),
    elemRect = element.getBoundingClientRect(),
    y = elemRect.y - bodyRect.y,
    x = elemRect.x - bodyRect.x,
    right = elemRect.right - bodyRect.x,
    bottom = elemRect.bottom - bodyRect.y;

  return { x, y, right, bottom };
};

const CallbackContext = React.createContext();

const TableEditor = (props) => {
  const { visible } = props;
  const [selection, setSelection] = useState({
    root: { x1: 0, x2: 0, y1: 0, y2: 0, row_idx: 0, col_idx: 0 },
    bounding: { x1: 0, x2: 0, y1: 0, y2: 0, row_idx: 0, col_idx: 0 },
  });
  const [isEditing, setIsEditing] = useState(false);
  const [temporaryText, setTemporaryText] = useState("");
  const commodity_table_ref = useRef(null);
  const edit_input_el = useRef(null);

  const store = useContext(StoreContext);

  const { excelState, excelDispatch } = useContext(ExcelGridContext);

  /** Other */
  const stopEditing = useCallback(
    (save = false) => {
      setIsEditing(false);
      if (save) {
        excelDispatch({
          type: "cell",
          location: { x: selection.root.col_idx, y: selection.root.row_idx },
          detail: { ...excelState.rows[selection.root.row_idx][selection.root.col_idx], text: temporaryText, error: false },
        });
      }
      setTemporaryText("");
      excelDispatch({ type: "recalculate" });
    },
    [
      excelState.rows,
      temporaryText,
      selection.root.row_idx,
      selection.root.col_idx,
      excelDispatch,
    ]
  );

  const startEditing = useCallback(
    (clear = false) => {
      if (
        isEditing ||
        selection.root.row_idx === null ||
        selection.root.col_idx === null
      )
        return;
      setIsEditing(true);
      if (!clear) {
        setTemporaryText(
          excelState.rows[selection.root.row_idx][selection.root.col_idx].text
        );
      }
    },
    [selection.root.row_idx, selection.root.col_idx, isEditing, excelState.rows]
  );

  /** Mouse events */

  const lastRowClicked = useCallback(
    (event) => {
      excelDispatch({ type: "new-row" });
      const { colIdx } = event.target.dataset;
      setSelection((prev) => ({
        ...prev,
        root: {
          ...prev.root,
          col_idx: colIdx,
          row_idx: excelState.rows.length,
        },
        bounding: {
          ...prev.root,
          col_idx: colIdx,
          row_idx: excelState.rows.length,
        },
      }));
    },
    [excelState.rows, excelDispatch]
  );

  const handleMouseMove = useCallback(
    _.throttle((event) => {
      const { cellType, rowIdx, colIdx } = event.target.dataset;
      if (!cellType) return;

      const { x, y, bottom, right } = getBoundingClientOffset(event.target);

      setSelection((prev) => {
        let x1, x2, y1, y2;

        // If the LEFT-SIDE of the newly MOUSED OVER CELL is FURTHER RIGHT than the ROOT:
        if (x > prev.root.x1) {
          // then the LEFT-SIDE of the SELECTION BOX is the same as the ROOT's
          x1 = prev.root.x1;
          // and the RIGHT-SIDE of the SELECTION BOX is the same as the MOUSED OVER CELL's
          x2 = right;
        } else {
          // else the LEFT-SIDE of the SELECTION BOX is the same as the MOUSED OVER CELL's
          x1 = x;
          // and the RIGHT-SIDE of the SELECTION BOX is the same as the ROOT's
          x2 = prev.root.x2;
        }

        // If the TOP-SIDE of the newly MOUSED OVER CELL is FURTHER DOWN than the ROOT:
        if (y > prev.root.y1) {
          // then the TOP-SIDE of the SELECTION BOX is the same as the ROOT's
          y1 = prev.root.y1;
          // and the BOTTOM-SIDE of the SELECTION BOX is the same as the MOUSED OVER CELL's
          y2 = bottom;
        } else {
          // else the TOP-SIDE of the SELECTION BOX is the same as the MOUSED OVER CELL's
          y1 = y;
          // and the BOTTOM-SIDE of the SELECTION BOX is the same as the ROOT's
          y2 = prev.root.y2;
        }


        return {
          ...prev,
          bounding: {
            col_idx: parseInt(colIdx),
            row_idx: parseInt(rowIdx),
            x1,
            x2,
            y1,
            y2,
          },
        };
      });
    }, 50),
    []
  );

  const handleMouseUp = useCallback(
    (event) => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    },
    [handleMouseMove]
  );

  const handleMouseDown = useCallback(
    (event) => {
      commodity_table_ref.current.focus();
      event.preventDefault();
      if (event.button !== 0) {
        return;
      }

      const { cellType, rowIdx, colIdx } = event.target.dataset;
      const { x, y, bottom, right } = getBoundingClientOffset(event.target);

      setSelection((prev) => ({
        ...prev,
        root: {
          ...prev.root,
          x1: x,
          x2: right,
          y1: y,
          y2: bottom,
          col_idx: parseInt(colIdx),
          row_idx: parseInt(rowIdx),
        },
        bounding: {
          x1: x,
          x2: right,
          y1: y,
          y2: bottom,
          col_idx: parseInt(colIdx),
          row_idx: parseInt(rowIdx),
        },
      }));

      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("mousemove", handleMouseMove);

      return () => {
        window.removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("mousemove", handleMouseMove);
      };
    },
    [handleMouseUp, handleMouseMove]
  );

  /** Keyboard events */
  const handlePaste = useCallback(
    (event) => {
      let text = "";
      if (event.type === "paste") {
        if (document.activeElement !== commodity_table_ref.current) return;
        event.preventDefault();
        text = event.clipboardData.getData("text");
      } else if (event.type === "click-paste") {
        text = event.text;
      }
      const starting_x = Math.min(
        selection.root.col_idx,
        selection.bounding.col_idx
      );
      const starting_y = Math.min(
        selection.root.row_idx,
        selection.bounding.row_idx
      );

      // If the user is pasting data containing tab characters, see if it matches an Excel selection format
      if (text.includes("\t") || text.includes("\n")) {
        const matrix = text
          .replaceAll("\r", "")
          .split("\n")
          .map((row) => row.split("\t"));

        if (
          matrix[matrix.length - 1].length === 1 &&
          matrix[matrix.length - 1][0] === ""
        ) {
          matrix.pop();
        }
        const matrix_height = matrix.length;

        // validate paste matrix - ! MUST BE A 2D ARRAY WITH NO HOLES
        let matrix_width;
        for (let j = 0; j < matrix_height; j++) {
          if (matrix_width === undefined) {
            matrix_width = matrix[j].length;
          } else if (matrix_width !== matrix[j].length) {
            console.error(
              "I can't paste this, the rows are different lengths!",
              matrix
            );
            return;
          }
        }

        excelDispatch({
          type: "cells",
          location: {
            x: starting_x,
            y: starting_y,
            width: matrix_width,
            height: matrix_height,
          },
          detail: matrix,
        });

        setSelection((prev) => ({
          root: { ...prev.root, col_idx: starting_x, row_idx: starting_y },
          bounding: {
            ...prev.bounding,
            col_idx: starting_x + matrix_width - 1,
            row_idx: starting_y + matrix_height - 1,
          },
        }));
      } else {
        const size_x =
          Math.abs(selection.root.col_idx - selection.bounding.col_idx) + 1;
        const size_y =
          Math.abs(selection.root.row_idx - selection.bounding.row_idx) + 1;
        if (size_x > 1 || size_y > 1) {
          excelDispatch({
            type: "cells",
            location: {
              x: starting_x,
              y: starting_y,
              width: size_x,
              height: size_y,
            },
            detail: text,
          });
        } else {
          excelDispatch({
            type: "cell",
            location: { x: starting_x, y: starting_y },
            detail: { text: text },
          });
        }
      }
    },
    [selection.root, selection.bounding]
  );

  const clearSelection = useCallback(() => {
    const starting_x = Math.min(
      selection.root.col_idx,
      selection.bounding.col_idx
    );
    const starting_y = Math.min(
      selection.root.row_idx,
      selection.bounding.row_idx
    );

    const ending_x =
      Math.max(selection.root.col_idx, selection.bounding.col_idx) + 1;
    const ending_y =
      Math.max(selection.root.row_idx, selection.bounding.row_idx) + 1;

      

    excelDispatch({
      type: "cells",
      location: {
        x: starting_x,
        y: starting_y,
        width: ending_x - starting_x,
        height: ending_y - starting_y,
      },
      detail: "",
    });
  }, [
    selection.root.row_idx,
    selection.root.col_idx,
    selection.bounding.row_idx,
    selection.bounding.col_idx,
  ]);

  const getSelectionAsText = useCallback(() => {
    const starting_x = Math.min(
      selection.root.col_idx,
      selection.bounding.col_idx
    );
    const starting_y = Math.min(
      selection.root.row_idx,
      selection.bounding.row_idx
    );

    const ending_x = Math.max(
      selection.root.col_idx,
      selection.bounding.col_idx
    );
    const ending_y = Math.max(
      selection.root.row_idx,
      selection.bounding.row_idx
    );

    let output_matrix = excelState.rows
      .slice(starting_y, ending_y + 1)
      .map((row) =>
        row.slice(starting_x, ending_x + 1).map((cell) => cell.text)
      );

    const text = output_matrix.map((row) => row.join("\t")).join("\n");

    return text;
  }, [
    selection.root.row_idx,
    selection.root.col_idx,
    selection.bounding.row_idx,
    selection.bounding.col_idx,
    excelState.rows,
  ]);

  const handleCopy = useCallback(
    (event) => {
      if (document.activeElement !== commodity_table_ref.current) return;
      event.preventDefault();

      const text = getSelectionAsText();
      event.clipboardData.setData("text/plain", text);
    },
    [getSelectionAsText]
  );

  const handleCut = useCallback(
    (event) => {
      if (document.activeElement !== commodity_table_ref.current) return;
      event.preventDefault();

      const text = getSelectionAsText();
      clearSelection();
      event.clipboardData.setData("text/plain", text);
    },
    [getSelectionAsText, clearSelection]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (isEditing) {
        switch (event.key) {
          case "Escape":
            stopEditing(false);
            break;
          case "Enter":
            stopEditing(true);
            if (selection.root.row_idx + 1 === excelState.rows.length) {
              excelDispatch({ type: "new-row" });
            }
            setSelection((prev) => ({
              ...prev,
              root: {
                ...prev.root,
                row_idx: prev.root.row_idx +1,
              },
            }));
            break;
          case "Tab":
            event.preventDefault();
            stopEditing(true);
            if (event.shiftKey) {
              if (selection.root.col_idx === 0) {
                if (selection.root.row_idx !== 0) {
                  setSelection((prev) => ({
                    ...prev,
                    root: {
                      ...prev.root,
                      col_idx: excelState.config.length - 1,
                      row_idx: prev.root.row_idx - 1,
                    },
                  }));
                }
              } else {
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, col_idx: prev.root.col_idx - 1 },
                }));
              }
            } else {
              if (selection.root.col_idx === excelState.config.length - 1) {
                if (selection.root.row_idx + 1 === excelState.rows.length) {
                  excelDispatch({ type: "new-row" });
                }
                setSelection((prev) => ({
                  ...prev,
                  root: {
                    ...prev.root,
                    col_idx: 0,
                    row_idx: prev.root.row_idx + 1
                  },
                }));
              } else {
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, col_idx: prev.root.col_idx + 1 },
                }));
              }
            }
            break;
          default:
            break;
        }

        setSelection((prev) => ({ ...prev, bounding: { ...prev.root } }));
      } else {
        if (event.key.length === 1 && !event.ctrlKey) {
          startEditing(true);
          return;
        }
        let shrinkSelection = false;
        switch (event.key) {
          case "z":
          case "Z":
            if (event.ctrlKey) {
              excelDispatch({ type: "undo" });
            }
            break;
          case "Home":
            event.preventDefault();
            if (event.shiftKey) {
              setSelection((prev) => ({
                ...prev,
                bounding: { ...prev.bounding, col_idx: 0 },
              }));
            } else {
              shrinkSelection = true;
              setSelection((prev) => ({
                ...prev,
                root: { ...prev.root, col_idx: 0 },
              }));
            }
            break;
          case "End":
            event.preventDefault();
            if (event.shiftKey) {
              setSelection((prev) => ({
                ...prev,
                bounding: {
                  ...prev.bounding,
                  col_idx: excelState.config.length - 1,
                },
              }));
            } else {
              shrinkSelection = true;
              setSelection((prev) => ({
                ...prev,
                root: { ...prev.root, col_idx: excelState.config.length - 1 },
              }));
            }
            break;
          case "F2":
            startEditing();
            break;
          case "Tab":
            event.preventDefault();
            shrinkSelection = true;
            if (event.shiftKey) {
              if (selection.root.col_idx === 0) {
                if (selection.root.row_idx !== 0) {
                  setSelection((prev) => ({
                    ...prev,
                    root: {
                      ...prev.root,
                      col_idx: excelState.config.length - 1,
                      row_idx: prev.root.row_idx - 1,
                    },
                  }));
                }
              } else {
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, col_idx: prev.root.col_idx - 1 },
                }));
              }
            } else {
              if (selection.root.col_idx === excelState.config.length - 1) {
                if (selection.root.row_idx + 1 === excelState.rows.length) {
                  excelDispatch({ type: "new-row" });
                }

                setSelection((prev) => ({
                  ...prev,
                  root: {
                    ...prev.root,
                    col_idx: 0,
                    row_idx: prev.root.row_idx + 1,
                  },
                }));
              } else {
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, col_idx: prev.root.col_idx + 1 },
                }));
              }
            }

            break;
          case "Enter":
            event.preventDefault();
            shrinkSelection = true;
            if (selection.root.row_idx + 1 === excelState.rows.length) {
              excelDispatch({ type: "new-row" });
            }
            setSelection((prev) => ({
              ...prev,
              root: {
                ...prev.root,
                row_idx: prev.root.row_idx +1,
              },
            }));

            break
          case "ArrowRight":
            event.preventDefault();
            if (event.shiftKey) {
              // extend selection, leave root alone
              if (selection.bounding.col_idx !== excelState.config.length - 1) {
                setSelection((prev) => ({
                  ...prev,
                  bounding: {
                    ...prev.bounding,
                    col_idx: prev.bounding.col_idx + 1,
                  },
                }));
              }
            } else {
              shrinkSelection = true;
              if (selection.root.col_idx !== excelState.config.length - 1) {
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, col_idx: prev.root.col_idx + 1 },
                }));
              }
            }
            break;
          case "ArrowLeft":
            event.preventDefault();
            if (event.shiftKey) {
              // extend selection, leave root alone
              if (selection.bounding.col_idx !== 0) {
                setSelection((prev) => ({
                  ...prev,
                  bounding: {
                    ...prev.bounding,
                    col_idx: prev.bounding.col_idx - 1,
                  },
                }));
              }
            } else {
              shrinkSelection = true;
              if (selection.root.col_idx !== 0) {
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, col_idx: prev.root.col_idx - 1 },
                }));
              }
            }
            break;
          case "ArrowUp":
            event.preventDefault();
            if (event.shiftKey) {
              // extend selection, leave root alone
              if (selection.bounding.row_idx !== 0) {
                setSelection((prev) => ({
                  ...prev,
                  bounding: {
                    ...prev.bounding,
                    row_idx: prev.bounding.row_idx - 1,
                  },
                }));
              }
            } else {
              shrinkSelection = true;
              if (selection.root.row_idx !== 0) {
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, row_idx: prev.root.row_idx - 1 },
                }));
              }
            }
            break;
          case "ArrowDown":
            event.preventDefault();
            if (event.shiftKey) {
              // extend selection, leave root alone
              if (selection.bounding.row_idx !== excelState.rows.length - 1) {
                setSelection((prev) => ({
                  ...prev,
                  bounding: {
                    ...prev.bounding,
                    row_idx: prev.bounding.row_idx + 1,
                  },
                }));
              } else {
                excelDispatch({ type: "new-row" });
                setSelection((prev) => ({
                  ...prev,
                  bounding: {
                    ...prev.bounding,
                    row_idx: prev.bounding.row_idx + 1,
                  },
                }));
              }
            } else {
              shrinkSelection = true;
              if (selection.root.row_idx !== excelState.rows.length - 1) {
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, row_idx: prev.root.row_idx + 1 },
                }));
              } else {
                excelDispatch({ type: "new-row" });
                setSelection((prev) => ({
                  ...prev,
                  root: { ...prev.root, row_idx: prev.root.row_idx + 1 },
                }));
              }
            }
            break;
          case "Delete":
          case "Backspace":
            event.preventDefault();
            clearSelection();
            break;
          default:
            break;
        }

        if (shrinkSelection) {
          setSelection((prev) => ({ ...prev, bounding: { ...prev.root } }));
        }
      }
    },
    [
      selection,
      isEditing,
      startEditing,
      stopEditing,
      clearSelection,
      excelState.rows,
      excelState.config,
      excelDispatch,
    ]
  );

  /** Window events */
  const handleResize = useCallback(
    _.debounce(
      () => {
        excelDispatch({ type: "recalculate" });
      },
      250,
      { leading: true, trailing: true }
    ),
    []
  );

  /**
   * Binds window resize to retrigger bounding box calculations.
   */
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  /** Binds copying/pasting events to document */
  useEffect(() => {
    document.addEventListener("copy", handleCopy);
    document.addEventListener("cut", handleCut);
    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("cut", handleCut);
      document.removeEventListener("paste", handlePaste);
    };
  }, [handlePaste, handleCopy, handleCut]);
  /**
   * Updates position of 'root cell' floating rect when the row/column index changes.
   * Can be manually triggered by changing value of forceRecalc
   */
  useEffect(() => {
    if (
      commodity_table_ref.current &&
      selection.root.row_idx !== null &&
      selection.root.col_idx !== null
    ) {
      // `selection.root.row_idx + 1` needed to skip the header
      const target =
        commodity_table_ref?.current?.children?.[selection.root.col_idx]
          ?.children?.[selection.root.row_idx + 1];
      if (!target) return;
      const { x, y, bottom, right } = getBoundingClientOffset(target);
      // Auto scrolls the root into view when the row/col is updated (usually by keyboard)
      if (
        target.getBoundingClientRect().y < 0 ||
        target.getBoundingClientRect().y > window.innerHeight
      ) {
        target.scrollIntoView();
      }

      let error = target.dataset.error;
      setSelection((prev) => ({
        ...prev,
        root: { ...prev.root, x1: x, x2: right, y1: y, y2: bottom, error },
      }));
    }
  }, [selection.root.row_idx, selection.root.col_idx, excelState.recalc]);

  /**
   * Updates position/size of selection floating rect when the row/column index changes.
   * Can be manually triggered by changing value of forceRecalc
   */
  useEffect(() => {
    if (
      commodity_table_ref.current &&
      selection.bounding.row_idx !== null &&
      selection.bounding.col_idx !== null
    ) {
      if (
        !commodity_table_ref.current.children ||
        !commodity_table_ref.current.children[selection.bounding.col_idx] ||
        !commodity_table_ref.current.children[selection.bounding.col_idx]
          .children ||
        !commodity_table_ref.current.children[selection.bounding.col_idx]
          .children[selection.bounding.row_idx + 1]
      )
        return;
      const target =
        commodity_table_ref?.current?.children?.[selection.bounding.col_idx]
          ?.children?.[selection.bounding.row_idx + 1];
      if (!target) return;
      // Auto scrolls the edge of the bounding box into view when the row/col is updated (usually by keyboard)
      if (
        target.getBoundingClientRect().y < 0 ||
        target.getBoundingClientRect().y > window.innerHeight
      ) {
        target.scrollIntoView();
      }
      const { x, y, bottom, right } = getBoundingClientOffset(target);

      setSelection((prev) => {
        let x1, x2, y1, y2;

        // If the LEFT-SIDE of the newly NEWLY SELECTED CELL is FURTHER RIGHT than the ROOT:
        if (x > prev.root.x1) {
          // then the LEFT-SIDE of the SELECTION BOX is the same as the ROOT's
          x1 = prev.root.x1;
          // and the RIGHT-SIDE of the SELECTION BOX is the same as the NEWLY SELECTED CELL's
          x2 = right;
        } else {
          // else the LEFT-SIDE of the SELECTION BOX is the same as the NEWLY SELECTED CELL's
          x1 = x;
          // and the RIGHT-SIDE of the SELECTION BOX is the same as the ROOT's
          x2 = prev.root.x2;
        }

        // If the TOP-SIDE of the newly NEWLY SELECTED CELL is FURTHER DOWN than the ROOT:
        if (y > prev.root.y1) {
          // then the TOP-SIDE of the SELECTION BOX is the same as the ROOT's
          y1 = prev.root.y1;
          // and the BOTTOM-SIDE of the SELECTION BOX is the same as the NEWLY SELECTED CELL's
          y2 = bottom;
        } else {
          // else the TOP-SIDE of the SELECTION BOX is the same as the NEWLY SELECTED CELL's
          y1 = y;
          // and the BOTTOM-SIDE of the SELECTION BOX is the same as the ROOT's
          y2 = prev.root.y2;
        }

        return { ...prev, bounding: { ...prev.bounding, x1, x2, y1, y2 } };
      });
    }
  }, [
    selection.bounding.row_idx,
    selection.bounding.col_idx,
    excelState.recalc,
  ]);

  useEffect(() => {
    if (isEditing) {
      edit_input_el.current.focus();
    } else {
      commodity_table_ref.current.focus();
    }
  }, [isEditing]);

  const callbackContext = {
    lastRowClicked,
    handleMouseDown,
  };
  return (
    <CallbackContext.Provider value={callbackContext}>
      <div
        style={{
          boxShadow: "0 0 4px 1px rgba(0 0 0 / 20%)",
          borderRadius: "20px",
        }}
        className="d-flex w-100 commodity-table "
        ref={commodity_table_ref}
        tabIndex="0"
        onKeyDown={handleKeyDown}
        onDoubleClick={() => startEditing()}
      >
        {visible && (
          <>
            <RightClickMenu
              tableElement={commodity_table_ref.current}
              onPaste={handlePaste}
              onCopy={getSelectionAsText}
              onCut={() => {
                const text = getSelectionAsText();
                clearSelection();
                return text;
              }}
              onUndo={() => excelDispatch({ type: "undo" })}
            />
            <FloatingRect
              className="root-overlay"
              x1={selection.root.x1}
              x2={selection.root.x2}
              y1={selection.root.y1}
              y2={selection.root.y2}
              error={selection.root.error}
            >
              {isEditing && (
                <input
                  type={excelState.config[selection.root.col_idx]?.type}
                  style={{
                    textAlign: excelState.config[selection.root.col_idx]?.align,
                  }}
                  ref={edit_input_el}
                  value={temporaryText}
                  onChange={(e) => setTemporaryText(e.target.value)}
                  onBlur={() => stopEditing(true)}
                />
              )}
            </FloatingRect>
            <FloatingRect
              className="selection-overlay"
              x1={selection.bounding.x1}
              x2={selection.bounding.x2}
              y1={selection.bounding.y1}
              y2={selection.bounding.y2}
            />
          </>
        )}

        <TableSetup>
          {!store.tadEns[0] && (
            <Column
              key="commoditycode"
              type="text"
              minWidth={14}
              align="center"
              tooltip={<CommodityCodeInfoTooltip />}
            >
              HS Code
            </Column>
          )}
          <Column key="origin" type="text" minWidth={10} align="center">
            Origin
          </Column>
          <Column key="description" type="text" stretch minWidth={12}>
            Description
          </Column>
          <Column key="packageCount" type="number" minWidth={10}>
            No. Items
          </Column>
          <Column
            key="packageType"
            type="text"
            minWidth={13}
            align="center"
            tooltip={<PackageTypeInfoTooltip />}
          >
            Pkg. Type
          </Column>
          <Column
            key="grossMass"
            type="number"
            minWidth={15}
            tooltip={<GrossMassInfoTooltip />}
          >
            Gross Mass
          </Column>
          <Column
            key="netMass"
            type="number"
            minWidth={14}
            tooltip={<NetMassInfoTooltip />}
          >
            Net Mass
          </Column>
          <Column key="itemValue" type="number" minWidth={12}>
            Value
          </Column>
          <Column key="quantity2" type="text" minWidth={10} align="right">
            Qty 2
          </Column>
          <Column key="healthCert" type="text" minWidth={13}>
            Health Cert
          </Column>
          <Column key="meursing" type="text" minWidth={10}>
            Meursing
          </Column>
          <Column key="organic" type="text"  default_text="NO" align="center" minWidth={9}>
            Organic
          </Column>
          <Column key="VAT" type="text" minWidth={9}
          align="center"
          tooltip={<PreferentialTooltip><h6>Valid Options</h6>
          <ul>
            <li>VATZ = Zero Rated</li> 
            <li>VATS = Standard Rated</li>
          </ul></PreferentialTooltip>}
          >
            VAT
          </Column>
          <Column key="RGR" type="text" minWidth={9}>
            RGR
          </Column>

          <Column
            key="preferential"
            type="text"
            align="center"
            minWidth={20}
            tooltip={<PreferentialTooltip><h6>Valid Options</h6>
            <ul>
              <li>Yes</li> 
              <li>No</li>
            </ul></PreferentialTooltip>}

          >
            Preferential
          </Column>
          <Column key="catchCert" hidden type="text" minWidth={12}>
            Catch Cert
          </Column>
          
        </TableSetup>

        <TheTable />
      </div>


    </CallbackContext.Provider>
  );
};

const TheTable = () => {
  const { excelState, excelDispatch } = useContext(ExcelGridContext);
  const { lastRowClicked } = useContext(CallbackContext);

  return (
    <>
      {excelState.config.map(({ children, hidden, key, ...rest }, idx) => 
      !hidden &&
      (
      <TableColumn key={key} title={children} col_idx={idx} {...rest} />
      ))}
     
  </>
  
  );
};

const TableColumn = ({
  col_idx,
  title,
  minWidth,
  stretch,
  align,
  type,
  tooltip,
}) => {
  const { excelState } = useContext(ExcelGridContext);
  const { lastRowClicked } = useContext(CallbackContext);
  const rows = excelState.rows;

  return (
    <div
      style={{
        minWidth: `${minWidth}ch`,
        flexGrow: stretch && 1,
        flexBasis: !stretch && "0%",
        textAlign: align ?? (type === "number" ? "right" : "left"),
      }}
    >
      <h1
        className="commodity-table-header"
        onMouseDown={(e) => e.preventDefault()}
      >
        <span>{title}</span>
        {tooltip}
      </h1>
      {rows.map((row, idx) => (
        <TableCell
          key={idx}
          cell_data={row[col_idx]}
          row_idx={idx}
          col_idx={col_idx}
        />
      ))}
      <div
        className="cell last"
        data-col-idx={col_idx}
        onClick={lastRowClicked}
      ></div>
    </div>
  );
};

const TableCell = React.memo(
  ({ col_idx, row_idx }) => {
    const { excelState } = useContext(ExcelGridContext);
    const { handleMouseDown } = useContext(CallbackContext);

    const cell_data = excelState.rows?.[row_idx]?.[col_idx];
      return (
        <OverlayTrigger
           trigger={["hover", "focus"]}
           show={(cell_data?.warn || cell_data?.error) ? undefined : false}
           placement="bottom"
           overlay={
             <Popover id="popover-basic">
               <div className={`px-2 py-1 ${cell_data?.warn ? 'alert-success' : 'alert-warning'}`}>
              {cell_data?.warn || cell_data?.error}
            </div>
             </Popover>
           }
         >
           <div
             className="cell"
             onMouseDown={handleMouseDown}
             data-cell-type="TODO"
             data-row-idx={row_idx}
             data-col-idx={col_idx}
             data-error={cell_data?.error ? true : undefined}
             data-warn={cell_data?.warn ? true : undefined}
           >
             {cell_data?.text}
           </div>
         </OverlayTrigger>
       );
  },
  (old, updated) => {
    return (
      _.isEqual(old.cell_data, updated.cell_data) &&
      old.col_idx === updated.col_idx &&
      old.row_idx === updated.row_idx
    );
  }
);

const FloatingRect = ({ className, x1, x2, y1, y2, children, error }) => {
  return ReactDOM.createPortal(
    <div
      className={className + (error ? " error" : "")}
      style={{
        transform: `translate(${x1}px, ${y1}px)`,
        top: 0,
        left: 0,
        height: y2 - y1 - 1 + "px",
        width: x2 - x1 - 1 + "px",
      }}
    >
      {children}
    </div>,
    document.body
  );
};

const RightClickMenu = ({ tableElement, onPaste, onCopy, onCut, onUndo }) => {
  const [show, setShown] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const rightClickMenu = useRef(null);

  const handleRightClick = useCallback((e) => {
    e.preventDefault();
    setShown(true);
    setPosition({ x: e.pageX, y: e.pageY });
  }, []);

  const handleMouseDown = useCallback((e) => {
    if (e.button === 2) {
      e.preventDefault();
      return;
    }

    if (rightClickMenu.current) {
      if (rightClickMenu.current.contains(e.target)) {
        e.preventDefault();
        return;
      }
    }
    setShown(false);
  }, []);

  const handlePaste = useCallback(async () => {
    setShown(false);
    const text = await navigator.clipboard.readText();
    onPaste({ type: "click-paste", text: text });
  }, [onPaste]);

  const handleCopy = useCallback(async () => {
    setShown(false);
    const text = onCopy();
    navigator.clipboard.writeText(text);
  }, [onCopy]);

  const handleCut = useCallback(async () => {
    setShown(false);
    const text = onCut();
    navigator.clipboard.writeText(text);
  }, [onCut]);

  useEffect(() => {
    if (!tableElement) return;
    tableElement.addEventListener("contextmenu", handleRightClick);
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      tableElement.removeEventListener("contextmenu", handleRightClick);
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [tableElement, handleRightClick, handleMouseDown]);

  if (!show) return null;

  return ReactDOM.createPortal(
    <div
      className="right-click-menu"
      ref={rightClickMenu}
      style={{
        top: 0,
        left: 0,
        transform: `translate(${position.x}px, ${position.y}px)`,
        zIndex: 200,
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div onClick={handleCopy}>Copy</div>
      <div onClick={handleCut}>Cut</div>
      <div onClick={handlePaste}>Paste</div>
      <div onClick={onUndo}>Undo</div>
      <spacer />
      {/* PLACEHOLDER */}
      <div onClick={() => setShown(false)}>Need Help?</div>
    </div>,
    document.body
  );
};

const TableSetup = React.memo(
  (props) => {
    const { excelDispatch } = useContext(ExcelGridContext);

    const hasRun = useRef(false);

    useEffect(() => {
      const config = [];
      React.Children.forEach(props.children, (child) => {
        if (!!child && typeof child === "object") {
          config.push({ ...child.props, key: child.key });
        }
      });

      excelDispatch({ type: "config", detail: config });
      if (hasRun.current) {
        excelDispatch({ type: "reset" });
      } else {
        hasRun.current = true;
      }
      excelDispatch({ type: "recalculate" });
    }, [props.children, excelDispatch]);

    return null;
  },
  (old, updated) => {
    const old_kids = old.children.filter((c) => !!c);
    const new_kids = updated.children.filter((c) => !!c);

    if (old_kids.length !== new_kids.length) return false;
    // Cannot compare functions, so remove them from the props.
    const old_props = old_kids.map((c) => removeFunctionProps(c.props));
    const new_props = new_kids.map((c) => removeFunctionProps(c.props));

    return _.isEqual(old_props, new_props);
  }
);

function Column() {
  return null;
}

function removeFunctionProps(props) {
  const obj = {};
  Object.entries(props).forEach(([key, prop]) => {
    if (typeof prop !== "function" && !React.isValidElement(prop)) {
      obj[key] = prop;
    }
  });
  return obj;
}

export default TableEditor;
