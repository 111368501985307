import { Modal } from "react-bootstrap";

const WarningModal = ({ redirectReason, onHide }) => {

  console.log("WarningModal called");

    function cancelClick() {
      onHide();
    }
    
    return (
      <Modal show={!!redirectReason} centered size={"md"} onHide={onHide} animation={false}>
        <Modal.Header>
          <Modal.Title className="d-flex gap-3 align-items-center py-2">
            <div className="warning" /> <span>
              {redirectReason?.title}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 py-4">
          {redirectReason?.body}
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
          <button class="stepper-button" onClick={cancelClick}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

export default WarningModal;
