import React from 'react';
import './table.css';
import "./report.css";
import { postUserDetails} from './apiCalls';
import { Input } from "./Input/Input.js";
import { useNavigate } from "react-router-dom";

const AddUser = (props) => {
 
  const navigate = useNavigate()

   const  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await postUserDetails(event.target,'add')
      if (data[0].completed === 'Inserted') {
        alert('Record Added') 
        navigate("/UserAdmin");
      }
      if (data[0].completed !== 'Inserted') {
        alert('User already exists') 
    }
    return data
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="full-width-container">
      <section className='floating-box' style={{ maxWidth: '1040px' }}>
      <div className='floating-box-header'>
          <span>Add user</span>
        </div>
        <form onSubmit={handleSubmit} id='userAdmin' >
          <div id="userAdmin-form">
            <Input type = 'text' name = 'firstname' label = 'Forename' id='firstName'/>
            <Input type = 'text' name = 'lastname' label = 'Surname' />
            <Input type = 'text' name = 'userEmail' label = 'Email' />
            <Input type = 'password' name = 'userPassword' label = 'Password'/>
            <label for = 'admin'>Admin</label>
            <input type = 'checkbox' name = 'admin' label = 'Admin'  />
            <div></div>
            <section className="buttons-group">
              <button type="submit" className="blue-button-teams" >Add</button>
            </section>
          </div>
        </form>
      </section>
    </div>
  )
}

export default AddUser;

