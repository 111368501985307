import DataTable from "../../../DataTable";
import GridTotals from "../../../GridTotals";
import React,{ useEffect, useMemo, useState} from "react";
import { Input } from "../../../Input/Input";
import BulkConfirmationModal from "./BulkConfirmationModal.js";

const BulkOperations = ({
    id,
    onBulkClose,
    invoiceNumber,
    itemCount,
    excelState,
    excelRef,
    totalValue,
  }) => {

    // excelState.config contains the Header names of the Spreadsheet table   

    const execelRows =  useMemo(()=> excelState.rows, [excelState?.rows]);

    const getIndex = (keyName) => {
      const index = excelState?.config.findIndex((item) => item.key === keyName);
      if (index === -1) {
        throw new Error(`Index for key '${keyName}' not found.`);
      }
      return index;
    };

    const gMassIndex = getIndex("grossMass");
    const nMassIndex = getIndex("netMass");
    const massIndexes = [gMassIndex, nMassIndex];

    const valueIndex = (id === "cabieLoads") ? getIndex("itemValue") : getIndex("loadValue");
    const initialWeights = [
      { id: 0, field: "Gross Mass", value: '', selected: true, disabled: false, key: "grossMass", keyIndex: getIndex("grossMass") },
      { id: 1, field: "Net Mass", value: '', selected: true, disabled: false, key: "netMass", keyIndex: getIndex("netMass") },
    ];

    const [weightData, setWeightData] = useState(initialWeights);
    const [msg, setMsg] = useState("");
    const [disableApplyBtn, setDisableApply] = useState(false);
    const itemsLength = execelRows?.length;  
 
    //check the Items empty or not based on the its key name
    const isItemsEmpty = (index) => {
      if (itemCount === 1) {
        if (execelRows[0][index].text) {
          return true;
        }
      } else {
      return execelRows.some((row) => row[index].text);   
    }   
    };

    const isLineItemsEmpty = (index) => {
        const temp = []
        execelRows.forEach((row, i) => {
          if (i <= (itemCount - 1)) {
             if(row[index].text == ""){
              temp.push(true)
             }
          }
         })          
        return (temp?.length > 0)       
    };

    const isLineItemsZero = (index) => {      
      const temp = []
      execelRows.forEach((row, i) => {
        if ( i <= (itemCount - 1)) {
              if(row[index].text === '0'){
            temp.push(true)
           }
        }
       })     
       return (temp?.length > 0)       
    }
    
  
    const doCalculation = (mass, totalineInvoiceValue, rows) => {
      return rows?.map(
        (row, i) => (i <= (itemCount - 1)) && ((mass / totalineInvoiceValue) * parseFloat
        (row[valueIndex]?.text)).toFixed(3)
      );
    };   
    
    const isNetMassLesser = () => {
      if (weightData[0].selected && weightData[1].selected) {
       return (weightData[1]?.value <= weightData[0]?.value) ? true: false;
      } else {
        return true;
      }
    };

    const isGrossEmpty = () => {
      if(weightData[0].selected){
        return ( (weightData[0].value > 0) && (weightData[0].value !== "") ) ? false : true;      
      }else{
        return false;
      }
    
    };

    const isNetEmpty = () => {
      if (weightData[1].selected) {
        return weightData[1].value > 0 && weightData[1].value !== "" ? false : true;
      } else {
        return false;
      }
    };

    let apportMass;
    const updateNewValue =(data, massIndex) => {
           // data.value is the value provided by the input text          
           apportMass = doCalculation(data.value, totalValue, execelRows);

           execelRows.forEach((row, i) => {
             if(i <= (itemCount-1)){
               row[massIndex].text = apportMass[i];
             }
           });    

           onBulkClose();
    }

    let msgText = "";

    const calculateWeights = () => {
      const allSelected = weightData.every((data) => !(data.selected));

      if (allSelected) {
        msgText = "At least one checkbox needs to be selected.";
      } else {                
        if(isLineItemsEmpty(valueIndex)) {
          msgText = "Unable to apportion the weight due to line Item values empty.";
        }else{
          const selectedWeight = weightData.filter(weight =>{
            if(weight.selected){
              return weight;
            }
          })
          const selectedCount = weightData.filter(weight => weight.selected).length;
          const isAllEmpty = massIndexes.every((item) => !isItemsEmpty(item))
 
          const updatedWeightData = selectedWeight?.map((data) => {
            let massIndex = data.keyIndex;
            if (isItemsEmpty(massIndex) || (selectedCount === 2 && !isAllEmpty)) {
              msgText = "Unable to apportion the weight due to values already entered.";
            } else if(isLineItemsZero(valueIndex)) {
              msgText = "Unable to apportion the weight due to line Item values 0.";
            }
            else if(selectedCount === 2 && isAllEmpty) {
                updateNewValue(data, massIndex);
            }
            else{
                updateNewValue(data, massIndex);
            }              
          
            return data;
          });
          if (msgText) {
            setMsg(msgText);
            setDisableApply(true);
            setWeightData(
              weightData.map((data) => ({ ...data, disabled: true }))
            );
          } else {
            // Update state with the new array of data
            setWeightData(updatedWeightData);
          }
        }
      }
    };
  
    const onApply = () => { 
      if (itemsLength > 0) {
        if (isGrossEmpty() && isNetEmpty())   {
          msgText = "Gross Mass and Net Mass should not be empty or 0.";
        }          
        else if(isGrossEmpty()){
          msgText = "Gross Mass should not be empty or 0.";
        }else if(isNetEmpty()){
          msgText = "Net Mass should not be empty or 0.";
        }            
        else {
          if (isNetMassLesser()) {
            calculateWeights();
          }  
          else {
            msgText = "Net Mass must be lesser than or equal to Gross Mass.";
          }
        }
        if (msgText) {
          setMsg(msgText);
          weightData[0].disabled = true;
          weightData[1].disabled = true;
          setDisableApply(true);
          return;
        }
      }
    };

    const handleReset = () => {
      setWeightData([...initialWeights]);
      setDisableApply(false);
      setMsg('');
    };
  
    const handleSelect = (e, id) => {
      const { value, type } = e.target;
      setWeightData((prev) =>
        prev.map((data) =>
          data.id === id ? type === "number" ? { ...data, value: parseFloat(value) } : { ...data, selected: !data.selected } : data
        )
      );
    };
    return (
      <BulkConfirmationModal
        header={""}
        show={true}
        onReset = {handleReset}
        onClose= {onBulkClose}
        onConfirm={onApply}        
        animation={false}
        buttonLabels={{ label1:"Reset", label2: "Apply" }}        
        msg={msg}
        disableApply = {disableApplyBtn}
      >
        <div>
          <GridTotals
            other={[
              <Input
                label="Invoice No."
                name="invoiceNumber"
                type="text"
                value={invoiceNumber}
                disabled={true}
              />,
            ]}
            itemCount={itemCount}
            additionalStyles={{ marginTop: "2rem", marginBottom: "1rem" }}
          />
          <DataTable data={weightData} className="table-striped table-bordered">
            <DataTable.Column
              colKey="field"
              header="Field"
              style={{ width: "100px" }}
            />
            <DataTable.Column colKey="value" header="Value">
              {({ id }) => {
                return (
                  <Input
                    hideLabel={true}
                    type="number"
                    style={{ width: "100px" }}
                    value={weightData[id]?.value}
                    disabled={weightData[id]?.disabled}
                    // onFocus={(e)=> setMsg('') } 
                    onChange={(e) => {
                      handleSelect(e, id);
                    }}
                  />
                );
              }}
            </DataTable.Column>
  
            <DataTable.Column colKey="apportion" header="Apportion">
              {({ id, selected }) => {
                return (
                  <div className="d-flex flex-row align-items-center justify-content-center pt-2">
                    <input
                      type="checkbox"
                      checked={selected}
                      disabled={weightData[id]?.disabled}
                      onChange={(e) => {
                        handleSelect(e, id);
                      }}
                    />
                  </div>
                );
              }}
            </DataTable.Column>
          </DataTable>
        </div>
      </BulkConfirmationModal>
    );
  };

export default BulkOperations;
