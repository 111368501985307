import React from 'react';
import "./main.css";
import { ShowIf } from './Security/security';
import EpoJobQueries from './EpoListLoads/Details/Tabs/EpoJobQueries';
import EpoListLoads from './EpoListLoads/EpoListLoads';
import Dashboard from './Dashboard/Dashboard';

const Main = () => {

  return (
    <>
    <ShowIf or={[ {department: "TRADER"}, {department: 'LSP', }, {department: "WLP"}, {department: "DCT"}, {department: 'OPS'}, {department: 'FIN'}, {department: 'QA'}]}>
        <section >
            <div className='px-4 pb-5 pt-2 d-flex flex-column align-items-end'>
                <ShowIf or={[{department: "DCT"}, {department: 'OPS'}, {department: 'QA'}]}>
                    <Dashboard />
                </ShowIf>
                <ShowIf or={[ {department: "TRADER"}, {department: 'LSP', }, {department: "WLP"}, {department: 'FIN'}]}>
                    <EpoListLoads />
                </ShowIf>
            </div> 
        </section>
    </ShowIf>
     {/* <ShowIf or={[{department: "DCT"}, {department: 'OPS'}]}>
     <div className="full-width-container" style={{ maxWidth: '1600px', marginTop: '2rem', marginLeft: 'auto', marginRight: 'auto'}}>
           <section className="floating-box" style={{ maxWidth: "1600px" }}>
             <div className='floating-box-header-newload'>
                 <span>Message service</span>
             </div>
                 <div className='px-4 pb-5 pt-2 d-flex flex-column align-items-center'>
                     <EpoJobQueries />
                 </div>
         </section>
     </div> 
     </ShowIf> */}
     </>
  )
}

export default Main;