import { useState, useRef, useEffect, useContext } from "react";
import Stepper, { useStepper } from "../../Stepper";
 
 
import { Trash } from "@styled-icons/fa-solid"
import { Modal } from "react-bootstrap";
import invoices from "../assets/invoices.png";
import { OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";
import { StoreContext } from "../../Store";
 
export function InvoicesUploadPage() {
  const { stepBackward, stepForward, gotoStep, localData, setDataByKey, emitData, activeStepKey, setStepListVisible } = useStepper();
  const [error, setError] = useState("");
  const [clickCount, setClickCount] = useState(0);
  const [btnText, setBtnText] = useState(""); 
  const [confirmationFinish, setConfirmationFinish] = useState(false); 
 
 
  const store = useContext(StoreContext);
 
  useEffect(() => {
    {
      localData.confirmedNoCG == "NCG" ? setBtnText("Submit") : setBtnText("Next");
    }
  }, [localData.confirmedNoCG]);
 
  async function finish() {
 
   if (!(localData.invoices?.length > 0)) {
    setError("Please select at least 1 document.");
    return;
  }  else {
    if
    (localData.ipaffsConfirmation && !localData.ipaffsConfirmationFinish ||
      localData.ehcConfirmation && !localData.ehcConfirmationFinish ||
      localData.catchConfirmation && !localData.catchConfirmationFinish)
     {
      setDataByKey("confirmationModal", true);
      return;
    }
      else
  {
    console.log('confirmationtion here latest ', localData.ipaffsConfirmation)
    setClickCount(((preCount) => preCount + 1))  
    const [result] = await emitData(localData);
    setDataByKey('returndata', {...result, submittedAt: new Date()});
    store.lockDirection[1](false);
    setStepListVisible(false);
    store.epoProgress[1](false);
    gotoStep('confirmation');
  }
  }
 
 
 
  return
 }
 
 
  const backwards = () => {
    setError("")
    setDataByKey("ipaffsConfirmation", false)
    setDataByKey("ehcConfirmation", false)
    setDataByKey("catchConfirmation", false)
    setDataByKey("ipaffsConfirmed", false);
    setDataByKey("ehcConfirmed", false);
    setDataByKey("catchConfirmed", false);
    setDataByKey("ipaffsConfirmationFinish", false);
    setDataByKey("ehcConfirmationFinish", false);
    setDataByKey("catchConfirmationFinish", false);    
    if (localData.confirmedNoCG == "NCG"){
      if (localData.movementType === "Import") {
        gotoStep("7. EtaPort")
      }
      else {
        gotoStep("8. EtaExportPort")
      }
    }
    else {
      if (localData.selectedCategoryOfGoods[0].category === 'Excise Goods - Wine, Beer, Spirits or Tobacco' && !localData.selectedCategoryOfGoods[1] && localData.movementType === "Export") {
            gotoStep("8. EtaExportPort");
          }
          else {
            gotoStep("9. Documents")
          }
    }
};
 
 
  function addFiles(files) {
console.log('files ', files)
 
 
      const prevFiles = [...localData.invoices];
      for (const f of files) {
        console.log('f', f)
        if (prevFiles.find((existingFile) => existingFile.name === f.name)) continue; // file is already in list.
 
      if (f.name.slice(-3).toLowerCase() === "xls" || f.name.slice(-3).toLowerCase() === "pdf" ||
    f.name.slice(-3).toLowerCase() === "png" || f.name.slice(-3).toLowerCase() === "jpg" ||
    f.name.slice(-4).toLowerCase() === "jpeg" || f.name.slice(-4).toLowerCase() === "xlsx") {
        prevFiles.push(f);
      }
      else {
        setError("We could not process your file, please review the file and file type.")
      }
    }
      setDataByKey("invoices", [...prevFiles]);
 
  }
 
  function removeFile(fileIdx) {
    const prevFiles = [...localData.invoices];
    setDataByKey("invoices", [...prevFiles.slice(0, fileIdx), ...prevFiles.slice(fileIdx + 1)]);
  }
 
  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
 
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "10. Commercial Documents" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "10. Commercial Documents",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])
 
 
  const SubmissionConfModal = ({ show, onHide, onContinue }) => {
    console.log('submission here ')
 
    function cancelClick() {
      setDataByKey("ipaffsConfirmed", false);
      setDataByKey("ehcConfirmed", false);
      setDataByKey("catchConfirmed", false);
      setDataByKey("ipaffsConfirmationFinish", false);
      setDataByKey("ehcConfirmationFinish", false);
      setDataByKey("catchConfirmationFinish", false);      
      onHide();
    }
   
 
    return (
      <Modal show={show} centered size={"md"} onHide={onHide} animation={false} >
        <Modal.Header>
          <Modal.Title className="d-flex gap-3 align-items-center py-2">
            {/* <div className="warning" />  */}
            <span>
              Please confirm the following information:
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 py-4">
            <>
            <div className="d-flex flex-column ml-2 mt-2">
            {localData.ipaffsConfirmation && (
                <label className="d-flex align-items-center mb-2 gap-1" style={{fontSize: '0.8rem', cursor: "pointer", color: "#0e2554"}}>
                <input type="checkbox" checked={localData.ipaffsConfirmed} onChange={() => setDataByKey("ipaffsConfirmed", !localData.ipaffsConfirmed)} />
                <span className="bold">IPAFFS - </span><span>Please confirm that CABIE will complete this for you.</span>
              </label>)}
 
              {localData.ehcConfirmation && (
                <label className="d-flex align-items-center mb-2 gap-1" style={{fontSize: '0.8rem', cursor: "pointer", color: "#0e2554"}}>
                <input type="checkbox" checked={localData.ehcConfirmed} onChange={() => setDataByKey("ehcConfirmed", !localData.ehcConfirmed)} />
                <span className="bold">Export Health Certificate - </span><span>Please confirm this is not required.</span>
              </label>)}
 
              {localData.catchConfirmation && (
                <label className="d-flex align-items-center mb-2 gap-1" style={{fontSize: '0.8rem', cursor: "pointer", color: "#0e2554"}}>
                <input type="checkbox" checked={localData.catchConfirmed} onChange={() => setDataByKey("catchConfirmed", !localData.catchConfirmed)} />
                <span className="bold">Catch Certificate - </span><span>Please confirm this is not required.</span>
              </label>)}
 
                </div>
                {/* <p className="bold">Warning - IPAFFS</p>
                <p>Do you confirm that you have uploaded the IPAFFS for these goods?</p> */}
 
            </>
        </Modal.Body>
        <Modal.Footer
          class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
 
          <button class="stepper-button" onClick={cancelClick}>
            Cancel
          </button>
 
          <button class="stepper-button yellow"
          disabled={localData.ipaffsConfirmation && !localData.ipaffsConfirmed ||
            localData.ehcConfirmation && !localData.ehcConfirmed ||
            localData.catchConfirmation && !localData.catchConfirmed}
            onClick={()=> onContinue()}>
            Confirm
          </button>
 
        </Modal.Footer>
      </Modal>
    );
  };
  
  useEffect(() => {
    if (confirmationFinish) {
      setConfirmationFinish(false); // Reset the local state
      finish();
    }
  }, [confirmationFinish]);
 
  return (
    <Stepper.Step stepKey="10. Commercial Documents" text="Commercial Documents">
        <div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{ fontSize:"1.8rem"}}>Commercial Documents</div>
        <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
          <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
            <input type="radio" disabled checked={localData.movementType === "Import"}/>
            <span>Import</span>
          </label>
 
          <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px'}}>
            <input type="radio" disabled checked={localData.movementType === "Export"} />
            <span>Export</span>
          </label>
        </div>
        <div className=' px-2 mt-1 pt-2 d-flex flex-column' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
 
        <div className="d-flex flex-row step-banner pt-4 justify-content-center">
        <img src={invoices} width="130" height="150" alt="" className=" mb-4" />
            {localData.movementType === "Import" && (
            <OverlayTrigger
              placement="auto"
              trigger={["click"]}
              rootClose
              overlay={
                <Popover style={{ width: "100%", maxWidth: "25rem" }}>
                  <PopoverContent>
                    <p>
                      Please ensure your invoice contains a valid preferential origin statement.
                      Without this you are not entitled to claim EU preference and may become liable for backdated Import duties.
                      An example can be found here <a href="https://www.eori.uk/proof_of_origin" target="_blank" rel="noreferrer">Brexit - Customised (eori.uk)</a>
                    </p>
                  </PopoverContent>
                </Popover>
              }
            >
              <div
                className="info"
                style={{ width: "1rem" }}
                title="Click to view"
              ></div>
            </OverlayTrigger>
            )}
            {/* </p> */}
        </div>
 
        <div class="d-flex align-self-center flex-column mt-4 px-4" style={{maxWidth: "400px"}}>
                      <span class="d-flex align-self-center flex-column  " style={{maxWidth: "380px", fontWeight: "500"}} >Please upload all commercial documents </span>
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "380px", fontWeight: "500"}}> related to this submission. These might include  </span>
 
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "380px", fontWeight: "500"}}> invoices and associated document types.</span>
            {/* <p style={{maxWidth: "280px", fontWeight: "500"}} >Please enter your Job Reference or billing ID. This will appear on all future communications and sales invoices.</p> */}
 
          <span class="d-flex align-self-center flex-row italic" style={{fontSize: '14px'}}>File types accepted are&nbsp;<span className="bold" style={{}}>.pdf, .png, .jpeg, .xls</span></span>
 
             </div>
 
        <div className="d-flex flex-column  align-items-center mt-3">
 
         
         
          <label name="name" className="stepper-button yellow d-flex align-self-auto align-items-center mt-auto mb-2" style={{borderRadius: '15px', cursor: 'pointer' }}>
            {/* <img src={uploadSvg} width="28" height="28" /> */}
            <span>Select</span>
            <input type="file" name="fileuploader" onChange={(e) => addFiles(e.target.files)} onClick={(e) => { e.target.value = null; setError("");}} accept=".pdf,.png,.jpeg,.xls,.xlsx" multiple />
          </label>
        </div>
 
 
        <div className="d-flex flex-column gap-1 mt-4 mb-2 step-banner pd-2" style={{minHeight: '5rem', minWidth: '220px', padding:"0.9rem" }}>
      <div className="section-title" style={{fontSize: '14px', marginBottom: '0.3rem' }}>Uploaded...</div>
            {localData.invoices.map((invoice, fileIdx) => (
              <span style={{fontSize: '0.9rem', fontWeight: '500'}} key={invoice.name} className="d-inline-flex w-100 justify-content-between gap-3" title={invoice.name}>
              <span className="text-nowrap" style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                {invoice.name}
                {/* {console.log("documents", localData.invoices)} */}
              </span>
              <button style={{appearance: 'none', border: 'none', backgroundColor: 'transparent'}} onClick={() => removeFile(fileIdx)}>
                <Trash width={"1rem"} height={"1rem"} style={{color: 'red'}} />
              </button>
              </span>
             
            ))}
          </div>
 
        <div className="px-3 mt-3 mb-3 w-75 mx-auto mt-auto">
          {error && (
            <div className="alert-danger px-3 py-1 w-100 text-center" style={{borderRadius: '10px'}}>{error}</div>
          )}
        </div>
 
        <section className="d-flex justify-content-between px-3 pb-4 mt-auto">
        <button type="button" className="stepper-button red" onClick={backwards}>
          Back
        </button>
        <button type="button" disabled={clickCount >= 1}className="stepper-button" onClick={finish}>
          Submit
        </button>
      </section>
      <SubmissionConfModal
          show={localData.confirmationModal}
          onHide={() => {
            setDataByKey("confirmationModal", false);
          }}
          onContinue={() => {
            setDataByKey("ipaffsConfirmationFinish", localData.ipaffsConfirmed);
            setDataByKey("ehcConfirmationFinish", localData.ehcConfirmed);
            setDataByKey("catchConfirmationFinish", localData.catchConfirmed);      
            setDataByKey("confirmationModal", false);
            setConfirmationFinish(true);
          }}
        ></SubmissionConfModal>
      </div>
    </Stepper.Step>
  );
}