import { Modal } from "react-bootstrap";
// import "./controlledgoodsmodal.css";
 import NeeNorDetails from './../neeNor/NeeNorDetails';
 import NeeNorList from './../neeNor/NeeNorList';
 import React, { useState, useEffect } from 'react';

export const NeeNorModal = ({ onNone, subTitle, animation, size, show, onHide, showButtons, onConfirm, header, hideBorder, hideHeader, isRow, ...rest }) => {
 
  const [neeNors, setNeeNors] = useState([{ "": "" }])
  const [selectedNeenor, setSelectedNeenor] = useState();
  const [editCreate, setEditCreate] = useState();
  const updateNeenor = (key, value) => {
      setSelectedNeenor(prev => {
        return {
          ...prev,
          [key]: value
        }
      })
  }
 
  return (
    <Modal centered  size={size} animation={animation} show={show} onHide={onHide} {...rest}>
      <Modal.Header style={{ display: (hideHeader ? "none" : "flex") }} >
        <Modal.Title>{header}</Modal.Title>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        />
       </Modal.Header>
      <Modal.Body>
      
<span>{subTitle}</span>
      
<div class='class="d-flex flex-row py-3 px-3 gap-3 '>
     <>
          {!selectedNeenor ?
             <>
              <NeeNorList
                  neeNors={neeNors}
                  setNeeNor={setSelectedNeenor}
                  setNeeNors={setNeeNors}
                  setEditCreate={setEditCreate}
              />
             
             </>
              :
              <>
                <NeeNorDetails 
                   neenor={selectedNeenor}
                   updateNeeNor={updateNeenor}
                   back={() => {setSelectedNeenor()}}
                   editCreate={editCreate}
                   isRow={isRow}
                />
               
              </> 
            }
     </>
</div>

      <div className='push-left-right'>
        <button 
          class="blue-button-newload green" onClick={(event) => {
            onNone(); 
            onHide(event);
          }}
          style={{ display: (showButtons ? "flex" : "none"), borderRadius: "10px" }} > None
        </button>

        <button
          class="blue-button-newload red"
          onClick={(event) => {
            onConfirm();
            onHide(event);
          }}
          style={{ display: (showButtons ? "flex" : "none"), borderRadius: "10px" }} 
        >Contains
        </button>
        </div>
   
      </Modal.Body>
    
    </Modal>
  );
};

export default NeeNorModal;
