import { useMemo, useEffect } from "react";
import { postDocusignReopen } from "../apiCalls"
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
  }

function DocusignReopen() {
    const query = useQuery();

    useEffect(() => {
        async function fetch() {
            const results = await postDocusignReopen(query.get('state'))
            window.location.href = results.redirect_uri;
        }
        fetch();
    }, [query])


  return (
    <div>
      Please wait while the page forwards
    </div>
  )
}

export default DocusignReopen
