import { useContext, useEffect, React } from "react";
import BPHeader from "./BPHeader";
import BPFooter from "./BPFooter";
import { useStepper } from "../Stepper";

// Context
import { BusinessPartnerContext } from "./BusinessPartnerCard";
import BPGrid from "./BPTestTable";

const BPCompanyDetails = () => {
  const { activeStepKey } = useStepper();
  const {
    localBPData,
    handleAddData,
    isEditing,
    handleOnChange,
    handleFetchBusinessPartnerDropdownValues,
    dropdownValues,
    errors,
    setCurrentStep
  } = useContext(BusinessPartnerContext);

  useEffect(() => {
    const fetchData = async () => {
      await handleFetchBusinessPartnerDropdownValues("CABIE Status");
      await handleFetchBusinessPartnerDropdownValues("Secondary Status");
      await handleAddData("Business Partner", activeStepKey);
      await handleAddData("Company", activeStepKey);
      await handleAddData("Main Contact Details", activeStepKey);
    }
    
    if (activeStepKey === "BPcompanydetails") {
      //if activeStepkey is the current step we are in, then load the data into the context
      fetchData();
      setCurrentStep("BPcompanydetails");
      
    }
  }, [activeStepKey]);

  const renderData = (title) => {
    const data = localBPData[title];
    if (!data) {
      return <BPGrid.Row title="Loading..." />;
    } else {
      return data.map((item, index) => {
        //set hasError to true if errors?.[item?.subtitle] exists
        const hasError = errors?.[item?.subtitle] ? true : false;
        return (
            <BPGrid.Row
              title={index === 0 ? title : ""}
              subTitle={item.subtitle}
              value={item.value}
              isEditingValue={isEditing}
              valueType={item.Type}
              dropdownValues={dropdownValues?.[item?.subtitle]}
              onChange={handleOnChange}
              tableTitle={title}
              hasError={hasError}
              errorMessage={errors?.[item?.subtitle]}
              className={`bp-row-${index}`}
            />
        );
      });
    }
  };

  return (
    <>
      <section className="bp-companydetails">
        <BPHeader />
        <div className="bp-section-heading">Business Partner</div>
        <BPGrid className="business-partners" numCols={2} isEditing={isEditing}>
          {renderData('Business Partner')}
        </BPGrid>

        <div className="bp-section-heading">Company</div>
        <BPGrid className="company" numCols={2} isEditing={isEditing}>
          {renderData("Company")}
        </BPGrid>

        <div className="bp-section-heading">Main Contact Details</div>
        <BPGrid className="main-contact-details" numCols={2} isEditing={isEditing}>
          {renderData("Main Contact Details")}
        </BPGrid>
      </section>
      <BPFooter />
    </>
  );
};

export default BPCompanyDetails;
