import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Input, Select } from "../../Input/Input";
import { UserProfileContext } from "../UserProfile";

export default function CompanyInformation() {
  const { companyInfo, updateCompanyInfo, companyInformationError } = useContext(UserProfileContext);
 
  return (
    <div className="px-4 pb-5 pt-2 bg-white">
        {companyInformationError && (
       <div>
       <span className="d-flex alert-danger justify-content-center mt-2" >
           {companyInformationError}
       </span>
     </div>
      )}
      <Row className="pt-2">
        <Col>
          <Input
            label={
              <>
                GB EORI Number <span style={{ fontSize: "120%" }}>*</span>
              </>
            } 
            disabled
            value={companyInfo.eoriNumber}
          />
        </Col>
        <Col />
      </Row>

      <Row className="pt-2">
        <Col>
          <Input label="GB VAT Number" 
          disabled ={sessionStorage.getItem('reg') === 'true'} 
          value={companyInfo.vatNumber} onChange={(e) => updateCompanyInfo("vatNumber", e.target.value)} />
        </Col>
        <Col>
          <Input
            label={
              <>
                Legal Name of GB Entity <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            required
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            value={companyInfo.gbEntity}
            onChange={(e) => updateCompanyInfo("gbEntity", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="pt-2">
        <Col>
          <Input
            label={
              <>
                Company Trading Name <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            disabled 
            value={companyInfo.companyName}
            onChange={(e) => updateCompanyInfo("companyName", e.target.value)}
          />
        </Col>
        <Col>
          <Select
            label={
              <>
                Company Type <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            data={companyInfo.formation}
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            setData={(data) => updateCompanyInfo("formation", data)}
            options={[
              { id: "PVLC", data: "Private Limited Company" },
              { id: "ST", data: "Sole Trader" },
              { id: "LLP", data: "Limited Liability Partnership" },
              { id: "PLC", data: "Public Limited Company" },
            ]}
          />
        </Col>
      </Row>

      <Row className="py-2">
        {/* <Col>
          <Select
            label={
              <>
                Company Category <span style={{ fontSize: "120%" }}>*</span>
              </>
            }
            data={companyInfo.companyCategory}
            setData={(data) => updateCompanyInfo("companyCategory", data)}
            disabled ={sessionStorage.getItem('reg') === 'true'} 
            options={[
              { id: "trader", data: "Trader" },
              { id: "haulier", data: "Haulier" },
              { id: "freight", data: "Freight/Forwarder" },
            ]}
          />
        </Col> */}
        
        <Col>
          <Input
            label="Company Registration Number"
            disabled ={sessionStorage.getItem('reg') === 'true'}  
            value={companyInfo.companyRegNumber}
            onChange={(e) => updateCompanyInfo("companyRegNumber", e.target.value)}
          />
        </Col>
        <Col />
      </Row>
    </div>
  );
}
