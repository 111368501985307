import {Row, Col} from 'react-bootstrap'
import {Input} from './Input/Input'

function GridTotals({ totalGrossMassLabel, grossKG, totalNetMassLabel, netKG, itemCount, totalValueLabel, value, totalPackageCountLabel, itemRowsCount, rows, totalStatValueLabel, totalStatValue, totalDutyLabel, totalDuty, totalVATLabel, totalVAT, packagesCount, additionalStyles, other=[]}) {
    function numberWithCommas(x, toFixed) {
        let floatValue = isNaN(parseFloat(x)) ? 0 : parseFloat(x);
        if (toFixed)
            floatValue = floatValue.toFixed(2);
        return floatValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

   const grossKGformatted = numberWithCommas(grossKG, true);
   const netKGformatted = numberWithCommas(netKG, true);
   const itemCountformatted = numberWithCommas(itemCount, false);
   const packagesCountformatted = numberWithCommas(packagesCount, false);
   const valueFormatted = numberWithCommas(value, true);
   const totalStatValueFormatted = numberWithCommas(totalStatValue, true);
   const totalDutyFormatted = numberWithCommas(totalDuty, true);
   const totalVATFormatted = numberWithCommas(totalVAT, true);

  return (
    <div className="d-flex flex-column" style={additionalStyles}>
    <Row>
      {
        other.map((item, index) => {
            return (
                <Col key={index}>
                  {item}
                </Col>
            )
        })
      }
        
      {/* PLEASE NOTE: itemRowsCount & rows show the same value but this is how to control the order in which fields are shown on the screen,
                        as GridTotals is a component used across the CABIE application. */}
      {itemRowsCount !== undefined && (<Col>
        <Input disabled label={`${totalPackageCountLabel ? totalPackageCountLabel : 'Item Rows'}`} value={itemRowsCount} />
      </Col>
      )}
      {packagesCount !== undefined && (<Col>
          <Input disabled label="Packages" value={packagesCountformatted} />
        </Col>
        )}
      {grossKG !== undefined && (<Col>
        <Input disabled label={`${totalGrossMassLabel ? totalGrossMassLabel : 'Gross Mass (kg)'}`} value={grossKGformatted} />
      </Col>
      )}
      {netKG !== undefined && (<Col>
        <Input disabled label={`${totalNetMassLabel ? totalNetMassLabel : 'Net Mass (kg)'}`} value={netKGformatted} />
      </Col>
      )}
      {itemCount !== undefined && (<Col>
          <Input disabled label="Number of Packages" value={itemCountformatted} />
        </Col>
        )}
      {value !== undefined && (<Col>
        <Input disabled label={`${totalValueLabel ? totalValueLabel : 'Value'}`} value={valueFormatted} />
      </Col>
      )}
      {totalStatValue !== undefined && (<Col>
        <Input disabled label={`${totalStatValueLabel ? totalStatValueLabel : 'Stat Value'}`} value={totalStatValueFormatted} />
      </Col>
      )}
      {totalDuty !== undefined && (<Col>
        <Input disabled label={`${totalDutyLabel ? totalDutyLabel : 'Total Duty'}`} value={totalDutyFormatted} />
      </Col>
      )}
      {totalVAT !== undefined && (<Col>
        <Input disabled label={`${totalVATLabel ? totalVATLabel : 'Total VAT'}`} value={totalVATFormatted} />
      </Col>
      )}
      {rows !== undefined && (<Col>
        <Input disabled label={`${totalPackageCountLabel ? totalPackageCountLabel : 'Item Rows'}`} value={rows} />
      </Col>
      )}
    </Row>
    </div>
  )
}

export default GridTotals