import customer from "../../images/customer.png";
import { StoreContext } from "../Store";
import { useContext, useState, useEffect, } from "react";
import { getNewLoad } from "../apiCalls";
import DataTable from "../DataTable";
import { PlusCircle } from '@styled-icons/fa-solid'
import checkmark from '../../images/checkmark.png'

export default function NeeNorClientID({updateNeenor, neenor, onHide}) {
   const store = useContext(StoreContext);
   const [value, setValue] = useState(null);

   useEffect(() => {
    const fetch = async () => {
        const data = await getNewLoad('clientIDs');
        store.clientIDoptions[1](data);
    }
    if (store.clientIDoptions[0].length === 0) {
        fetch();
    }
   }, [store.clientIDoptions[0]])

   useEffect(() => {
    setValue({
        id:neenor.clientID,
        companyName: neenor.customerName,
        eori: neenor.customerEORI
    })
   }, [])

    const submit = () => {
        updateNeenor('customerEORI', value.eori)
        updateNeenor('customerName', value.companyName)
        updateNeenor('clientID', value.id)
        onHide();
    }
  return (
    <>
      <div className="semi-fluid-container container-xl file-upload" style={{ maxWidth: '700px', marginTop: '4rem' }}>
        <div className='floating-box-newload'>
          <div className="clientID-error-section" >
              <div className='epo-steps-header'>
                  <span>Client ID</span>
              </div>

              <div className="step-banner with-img">
                <img src={customer} width="100" height="100" alt="port" />
                <p>Please indicate the client ID associated with this newload.</p>
              </div>

              <div className="d-flex flex-column justify-content-md-start py-3 " style={{paddingLeft: '6rem', paddingRight: '6rem'}}>
                <DataTable dontRefreshPagination sortable filterable data={store.clientIDoptions[0] ?? []} className="table-bordered">
                  <DataTable.Pagination itemsPerPage={[5, 10, 20]} />
                  <DataTable.Headers style={{fontSize: '11px'}} />
                  <DataTable.Row className={item => {
                    if (item.id === value?.id) {
                      return 'clientID-selected-row'
                    }
                    return ''
                  }} style={{fontSize: '16px'}} />
                  <DataTable.Column colKey="companyName" header="Companies" />

                  <DataTable.Column header="Select" style={{height: '1px', padding: 0}} headerStyle={{textAlign: 'center'}}>
                    {query => (
                      <button className="external-table-link" onClick={() => {setValue(query) }}>
                        {query.id !== value?.id ? <PlusCircle width="1.1rem" height="1.1rem" />
                                                : <img src={checkmark} width="20px" alt="check" />}
                      </button>
                    )}
                  </DataTable.Column>
                </DataTable>
              </div>

              <section className="d-flex justify-content-between mt-2">
                <button type="button" className="clientID-button" disabled={!value} onClick={submit}>
                  Save
                </button>
              </section>



            </div>
          </div>
        </div>
    </>)
}