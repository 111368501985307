import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { fetchBusinessPartnerDocumentDescriptionValues, fetchBusinessPartnerDocumentTypeValues, uploadBusinessPartnerFile } from "./BusinessPartnerController";

const BPUploadDocumentModal = ({
  show,
  onHide,
  hideHeader,
  customerId,
  currentStep,
  ...rest
}) => {
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const [descriptions, setDescriptions] = useState([]);
  const [types, setTypes] = useState([]);
  const [documentType, setDocumentType] = useState(1);
  const [documentDescription, setDocumentDescription] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const descriptionData = await fetchBusinessPartnerDocumentDescriptionValues();
      const typeData = await fetchBusinessPartnerDocumentTypeValues();
      setDescriptions(descriptionData);
      setTypes(typeData);
    };
    if (currentStep === "BPDocuments") {
      fetchData();
    }
  }, [currentStep]);

  //function to be called when the modal is closing. This function will reset all states and then call the onHide function
  const hideModal = () => {
    setError("");
    setFile(null);
    setDocumentType(1);
    setDocumentDescription(0);
    onHide();
  }


    const handleSetFile = (file) => {
        console.log('adding file', file[0].name)
        if ( file[0].name.slice(-3).toLowerCase() === "pdf" || file[0].name.slice(-3).toLowerCase() === "png" || 
        file[0].name.slice(-3).toLowerCase() === "jpg" || file[0].name.slice(-4).toLowerCase() === "jpeg") {
        setFile(file[0]);
      }
    }

    const checkFileName = () => {
        if (file !== null) {
            if (file.name.slice(-3).toLowerCase() === "pdf" || file.name.slice(-3).toLowerCase() === "png" || 
            file.name.slice(-3).toLowerCase() === "jpg" || file.name.slice(-4).toLowerCase() === "jpeg") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const handleConfirm = async (e) => {
        e.preventDefault();
        const validFile = checkFileName();
        if (!validFile) {
            setError("Invalid file format. Accepted files: .pdf, .png, .jpeg, .jpg");
            return;
        }
        if (documentDescription == 0){
            setError("Please select a description");
            return;
        }
        const formData = new FormData(); 
        formData.append('id', customerId);
        formData.append('data', file);
        formData.append('name', file.name);
        formData.append('type', documentType);
        formData.append('description', documentDescription);
        await uploadBusinessPartnerFile(formData);
        setError("");
        hideModal();
    }

  return (
    <Modal show={show} onHide={hideModal} {...rest} centered animation={false}>
      
      <Modal.Header style={{ display: hideHeader ? "none" : "flex" }}>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="document" /> <span>Upload a document</span>
        </Modal.Title>
        <button
          id="close"
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={hideModal}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="bpuploadDocumentModal-row">
          <label htmlFor="documentType">
            Type
          </label>
          <select
            id="documentType"
            className="select-element"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
          >
            {
                types.map((type) => {
                    return <option value={type.id}>{type.data}</option>
                })
            }
          </select>
        </div>
        <div className="bpuploadDocumentModal-row">
          <label htmlFor="documentDescription">
            Description
          </label>
          <select
            id="documentDescription"
            className="select-element"
            value={documentDescription}
            onChange={(e) => setDocumentDescription(e.target.value)}
          >
            <option value={0}>-- Select --</option>
            {
                descriptions.map((description) => {
                    return <option value={description.id}>{description.data}</option>
                }
                )
            }
          </select>
        </div>
        <div className="bpuploadDocumentModal-row">
          <label htmlFor="fileuploader">Upload Document</label>
          <input
            type="file"
            id="fileuploader"
            name="fileuploader"
            className="fileuploader"
            onChange={(e) => handleSetFile(e.target.files)}
            onClick={(e) => {
              e.target.value = null;
              setError("");
            }}
            accept=".pdf,.png,.jpeg,.jpg"
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row border-top bg-light justify-content-end align-items-center gap-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          padding: "1rem 1.75rem"
        }}
      >
        {error !== '' && <div className="d-flex flex-row justify-content-end">
          <span className="text-danger bold">{error}</span></div>}
        <button
          id="confirm"
          class="blue-button-teams"
          onClick={(event) => {
            handleConfirm(event);
            // onHide(event);
          }}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BPUploadDocumentModal;
