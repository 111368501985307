import DataTable, {useDataTableHandle} from '../DataTable';
import { ExternalLinkAlt } from "@styled-icons/fa-solid";
import { getNeeNorDetails, postNeeNorLazyLoad } from '../apiCalls'
import { Container } from 'react-bootstrap'
import isoDateConverter from '../isoDateConverter'
import { useSecurity } from "../Security/security"
import { useState, useEffect } from 'react';

function NeeNorList({neeNors, setNeeNor, setEditCreate, setNeeNors, origin='neenor'}) {
  const [paginationLoaded, setPaginationLoaded] = useState(false);
  
  const dth = useDataTableHandle();

  async function fetchData() {
    if (origin == 'bp') return;
    try {
      const data = await postNeeNorLazyLoad({
        skip: dth.current.pagination.pageNo * dth.current.pagination.itemsPerPage, 
        amount: dth.current.pagination.itemsPerPage,
        sort_by: dth.current.sortStatus.currentKey,
        sort_order: dth.current.sortStatus.direction
      });
      console.log('from neenor', data)
      dth.current.setServerDataLength(data.totalItems)
      setNeeNors(data.data);
    } catch (err) {
      console.log("Error retrieving data")
    }
  }

  async function updateData(setPaginationLength, pagination, sortStatus, search, filter) {
    if (origin == 'bp') return;
    try {
      const data = await postNeeNorLazyLoad({
        skip: pagination.pageNo * pagination.itemsPerPage, 
        amount: pagination.itemsPerPage,
        sort_by: sortStatus.currentKey,
        sort_order: sortStatus.direction,
        search,
        filter
      })
      setPaginationLength(data.totalItems)
      setNeeNors(data.data);
    }
    catch (err) {
      console.log("Error retrieving data")
    }

  }

  useEffect(() => {
    if (paginationLoaded)
      fetchData();
  }, [paginationLoaded]);

  const { securityState, validateRole } = useSecurity();

    const getNeenor = async (id) => {
        try {
          const data = await getNeeNorDetails (id)
          setNeeNor(data[0]);
        } catch (err) {
            console.log(err)
        }
      }

  return (
    <Container className="container-md">
    <section className='floating-box' style={{maxWidth: '1600px'}}>
      <div className="floating-box-header-general">
        <span>{origin == 'bp' ? 'Business Partner' : 'NeeNor'}</span>
      </div>
      <div className='px-3 pt-4'>

          {/* <DataTable handle={dth} lazyLoading sortable data={neeNors}  */}
          <DataTable handle={dth} lazyLoading filterable sortable data={neeNors} 
          className="table-striped" advancedSearch 
          showAdvnSearch={  !validateRole({department: 'TRADER'}) && !validateRole({department: 'LSP'})}
          onUpdate={updateData}
          onPaginationLoaded={() => {
            setPaginationLoaded(true);
          }}
          >
          <DataTable.Head>
          <section className="d-flex justify-content-end datatable-button">
              <button type="button" className="stepper-button yellow" onClick={() => {setEditCreate("create"); setNeeNor({})}}>
                      New
                  </button>
            </section>
          </DataTable.Head>
          <DataTable.Pagination itemsPerPage={[10, 15, 50]} areaPadding={'0px'} />
          <DataTable.Headers style={{fontSize: "14.4px", backgroundColor: '#fff', paddingBottom: '10px', borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd'}} />
          <DataTable.Column colKey="companyName" header="Company Name" searchOpts={{type: "text", sendAs: "companyName"}}>
          {item => {
                  return item.companyName?.slice(0, 30) + (item.companyName?.length > 30 ? "..." : "")
                }}

          </DataTable.Column>
          <DataTable.Column colKey="shortCode" header="Short Code" searchOpts={{type: "text", sendAs: "shortCode"}}>
          {item => {
                  return item.shortCode?.slice(0, 10) + (item.shortCode?.length > 10 ? "..." : "")
                }}

          </DataTable.Column>
          <DataTable.Column colKey="eori" header="EORI Number" searchOpts={{type: "text", sendAs: "eori"}} />
          <DataTable.Column colKey="lastmodified" header="Last Updated" >
            {item => {
              return isoDateConverter(item.lastmodified)
            }}
          </DataTable.Column>

          <DataTable.Column header="View" style={{height: '1px', padding: 0}} headerStyle={{textAlign: 'center'}}>
            {query => (
              <button className="external-table-link" onClick={() => {setEditCreate("edit"); getNeenor(query.id) }}>
                <ExternalLinkAlt width="1.1rem" height="1.1rem" color="#00857d" />
              </button>
            )}
        </DataTable.Column>
      </DataTable>
</div>

</section>
</Container>
  )
}

export default NeeNorList
