import { useState } from "react";
import Hierarchy from "../Hierarchy";
import MeasureModal from "./MeasureModal";
import CommodityModal from "./CommodityModal";

export function CommodityHierarchy(props) {

    const [selectedMeasure, setSelectedMeasure] = useState(null)
    const [selectedCommodity, setSelectedCommodity] = useState(null)

    const commodityKebabOptions = [
      {text: "View Details", onClick: (commodity) => (setSelectedCommodity(commodity))}
    ]
  
    const measureKebabOptions = [
      {text: "View Details", onClick: (measure) => (setSelectedMeasure(measure))}
    ];
    
    

        
        return (
            <>
            <CommodityModal
                commodity={selectedCommodity}
                onClose={() => {setSelectedCommodity(null)}}
            />
            <MeasureModal
                measure={selectedMeasure}
                onClose={() => {setSelectedMeasure(null)}}
            />
            {props.data.map((commodity) => (
      <Hierarchy item={commodity} root_level={true} label={<span title={commodity.commCodeDesc} className="text-nowrap text-truncate">Commodity Code - {commodity.commCode} - {commodity.commCodeDesc}</span>} kebabOptions={commodityKebabOptions}>
              {commodity.measure?.length > 0 && commodity.measure.map((measure, idx) => (
                  <Hierarchy key={measure.mId} label={`Type Description - ${measure.typeDescription} `}
                  item={measure}
                  isLast={idx === commodity.measure.length - 1}
                  kebabOptions={measureKebabOptions}
                  >
                </Hierarchy>                
                ))
            }
      </Hierarchy>
    ))}
            </>
    );
  }