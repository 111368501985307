import _ from "lodash";
/* State: {
  rows: [], undoStack: [], config: [], emptyRow: [], recalc: 0, files: [], onUpdate: () => {}
} */
export function excelReducer(state, action) {
  switch (action.type) {
    case "set": {
      if (typeof action.detail === "function") {
        return {
          ...state,
          rows: action.detail([...state.rows]),
          undoStack: [],
        };
      } else {
        const returnObj = { ...state, rows: action.detail, undoStack: [] };
        if (typeof state.onUpdate === 'function') {
          state.onUpdate(returnObj)
        }
        return returnObj;
      }
    }
    case "setOnUpdate" : {
      // console.log("setOnUpdate", action.detail)
      return {...state, onUpdate: action.detail};
    }

    case "trigger-update" : {
      if (typeof state.onUpdate === 'function') {
        state.onUpdate(state)
      }
      return state
    }

    case "log" : {
      console.log("Hello world")
    }

    case "recalculate": {
      return { ...state, recalc: (state.recalc + 1) % 3 };
    }
    case "new-row": {
     const emptyRows = state.emptyRow.map(row => ({ ...row, text: '' }));      
      return { ...state, rows: [...state.rows, [...emptyRows]] };
    }
    case "cell": {
      const rows = [...state.rows];

      const { x, y } = action.location;

      if (x >= state.config.length || x < 0) return { ...state };
      const undoAction = {
        type: "edit-single",
        col_idx: x,
        row_idx: y,
        value: _.cloneDeep(rows[y][x]),
      };
      const undoStack = [...state.undoStack.slice(-9), undoAction];

      if (!rows[y]) {
        // add the row if it doesn't exist TODO: is this required?
        rows[y] = [];
      }
      rows[y] = [
        ...rows[y].slice(0, x),
        { ...action.detail },
        ...rows[y].slice(x + 1),
      ];
      const returnObj = { ...state, rows: [...rows], undoStack };
      if (typeof state.onUpdate === 'function') {
        state.onUpdate(returnObj)
      }
      return returnObj
    }
    case "cells": {
      const rows = [...state.rows];

      const { x, y, width, height } = action.location;

      // ...length + 1 as width of 1 does not overflow, 0 width is not a thing
      if (x + width >= state.config.length + 1) return { ...state };

      let oldData = [];
      // single piece of data will span multiple cells
      if (typeof action.detail === "string") {
        const text = action.detail;
        // loop over area specified, adding rows as required
        for (let curr_y = y; curr_y < y + height; curr_y++) {
          if (curr_y >= rows.length) {
            rows.push([...state.emptyRow]);
          }
          // remember the old values in the current row
          oldData.push(rows[curr_y].slice(x, x + width));
          // set all cells to match the text value
          for (let curr_x = x; curr_x < x + width; curr_x++) {
            rows[curr_y] = [
              ...rows[curr_y].slice(0, curr_x),
              { ...rows[curr_y][curr_x], text },
              ...rows[curr_y].slice(curr_x + 1),
            ];
          }
        }
      }
      // array of data to set
      else if (typeof action.detail[Symbol.iterator] === "function") {
        const matrix = action.detail;
        // loop over area specified, adding rows as required
        for (let curr_y = y; curr_y < y + height; curr_y++) {
          if (curr_y >= rows.length) {
            rows.push([...state.emptyRow]);
          }
          // remember the old values in current row
          oldData.push(rows[curr_y].slice(x, x + width));
          // set all values to match those in the array
          for (let curr_x = x; curr_x < x + width; curr_x++) {
            rows[curr_y] = [
              ...rows[curr_y].slice(0, curr_x),
              { ...rows[curr_y][curr_x], text: matrix[curr_y - y][curr_x - x] },
              ...rows[curr_y].slice(curr_x + 1),
            ];
          }
        }
      }

      // append an undo to the stack, which revert this action
      const undoAction = {
        type: "edit-multiple",
        col_idx: x,
        row_idx: y,
        old_rows: [...oldData],
      };
      const undoStack = [...state.undoStack.slice(-9), undoAction];

      const returnObj =  { ...state, rows: [...rows], undoStack: [...undoStack] };

      if (typeof state.onUpdate === 'function') {
        state.onUpdate(returnObj)
      }

      return returnObj;
    }
    case "config": {
      // set/update configuration of columns
      if (_.isEqual(state.config, action.detail.config)) break;
      if (state.config.length === 0) {
        const rows = [...state.rows];
        let emptyRow = [];
        action.detail.config.forEach((_) => {
          emptyRow.push({ text: "" });
        });
        if (rows.length === 0) {
          for (let y = 0; y < action.detail.defaultRows; y++) {
            rows.push([...emptyRow]);
          }
        }
        return { ...state, config: action.detail.config, rows: [...rows], emptyRow, defaultRows: action.detail.defaultRows };
      }

      const rows = [...state.rows];
      // remove any configuration columns that have been deleted
      state.config.forEach((col1, i) => {
        const idx = action.detail.findIndex((col2) => col2.key === col1.key);
        if (idx === -1) {
          for (let y = 0; y < rows.length; y++) {
            rows[y] = [...rows[y].slice(0, i), ...rows[y].slice(i + 1)];
          }
        }
      });

      // add any new rows to match the new configuration
      action.detail.forEach((col2, i) => {
        const idx = state.config.findIndex((col1) => col2.key === col1.key);
        if (idx === -1) {
          for (let y = 0; y < rows.length; y++) {
            rows[y] = [
              ...rows[y].slice(0, i),
              { text: "" },
              ...rows[y].slice(i),
            ];
          }
        }
      });

      return {
        ...state,
        config: action.detail,
        rows: [...rows],
        undoStack: [],
      };
    }
    case "undo": {
      let undoStack = [...state.undoStack];

      if (undoStack.length === 0) break;
      const undo_action = undoStack[undoStack.length - 1];

      if (undoStack.length === 1) {
        undoStack = [];
      } else {
        undoStack = [...undoStack.slice(0, -1)];
      }

      const { type, col_idx, row_idx, ...rest } = undo_action;

      if (row_idx >= state.rows.length) break;
      const rows = [...state.rows];

      switch (type) {
        case "edit-single":
          const { value } = rest;
          rows[row_idx][col_idx] = value;
          break;
        case "edit-multiple":
          const { old_rows } = rest;
          const height = old_rows.length;
          if (height === 0 || height > state.rows.length) break;
          const width = old_rows[0].length;
          if (width === 0) break;
          for (let curr_y = row_idx; curr_y < row_idx + height; curr_y++) {
            for (let curr_x = col_idx; curr_x < col_idx + width; curr_x++) {
              rows[curr_y] = [
                ...rows[curr_y].slice(0, curr_x),
                { ...old_rows[curr_y - row_idx][curr_x - col_idx] },
                ...rows[curr_y].slice(curr_x + 1),
              ];
            }
          }
          break;
        default:
          break;
      }
      
      const returnObj = { ...state, undoStack, rows };
      if (typeof state.onUpdate === 'function') {
        state.onUpdate(returnObj)
      }
      return returnObj;
    }
    case "reset": {
      const rows = [];
      for (let y = 0; y < state.defaultRows; y++) {
        rows.push([...state.emptyRow]);
      }
      return {
        ...state,
        rows,
        undoStack: [],
      };
    }
    case "set-files": {
      return {
        ...state,
        files: [...action.detail]
      }
    }
    case "update-file-row": {
      const files = [...state.files];
      files.length = state.rows.length; // make sure we have at least as many indices as the amount of rows.
      const row_idx = action.detail.idx;
      files[row_idx] = action.detail.new_files;
      return {
        ...state,
        files: [...files]
      }
    }
    default:
      throw new Error("Invalid type supplied to TableEditor reducer.");
  }

  return state;
}
