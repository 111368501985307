import { useEffect, useState, useRef, useContext } from "react";
import Stepper, { useStepper } from "../../Stepper";

import euflag from "../assets/europe-flag.jpg";
import ukflag from "../assets/uk-flag.jpg";
import question from "../assets/question.png";
import dots from "../../../images/3dots.svg";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";
import { getEpoDropdown, postEPOExport } from "../../apiCalls";
import { Select, EPOFilterableSelect } from "../../Input/Input";
import { StoreContext } from "../../Store";
// import fr from "../../../../public/flags/fr.svg";

export function EpoDirectionPage({DDAStatus}) {
  const { stepBackward, stepForward, localData, setDataByKey, activeStepKey, gotoStep, unvisitStep } = useStepper();

  const store = useContext(StoreContext);

  const setToDropdown = async (e) => {
    if (store.lockDirection[0] === true) {
      // setDataByKey("lockDirection", false);
        localData.openMovementRedirectModal({
          title: "Movement Type Change",
          body: (<>
            <p className="bold">Warning - changes will be lost</p>
            <p>Are you sure you want to change your movement type? Doing so will lose your current changes.</p>
        </>)
        });
    }
    else {
      setDataByKey("movementType", e);
      store.exOrImport[1]("EPO - " + e)
      const fd = new FormData();
      fd.append("to", "false");
      fd.append("movementType", e);
      setDataByKey("exportId", "")
      setDataByKey("importId", "")
      unvisitStep("2. Exit Port");
      const updateDropdown = await postEPOExport('routeChoice', fd)
      if (updateDropdown && e === "Import") { 
        setDataByKey("exportCountry", "eu");
        setDataByKey("importCountry", "gb");
        store.fromFlagOptions[1](updateDropdown)
        const fd2 = new FormData();
        fd2.append("to", "true");
        fd2.append("movementType", "Import");
        
        const updateImportDropdown = await postEPOExport('routeChoice', fd2)
        if (updateImportDropdown) { 
          store.toFlagOptions[1](updateImportDropdown)
          updateImportDropdown.forEach( f => {
            if (f.abbreviation === "gb") {
              setDataByKey("importCountry", "gb");
              setDataByKey("importId", f.id)
              setDataByKey("toAreaCode", f.areaCode);
            }
          })
        }
      }
      else if (updateDropdown && e === "Export") {
        unvisitStep("eu_origin");
        unvisitStep("Preferential Origin");
        store.fromFlagOptions[1](updateDropdown)
        updateDropdown.forEach( async e => {
          if (e.abbreviation === "gb") {
            setDataByKey("exportCountry", "gb");
            setDataByKey("fromAreaCode", e.areaCode);
            setDataByKey("importCountry", "eu");
            setDataByKey("exportId", e.id)
            const fd1 = new FormData();
            fd1.append("from", e.id);
            fd1.append("to", "true");
            fd1.append("movementType", "Export");
            const updateImportDropdown = await postEPOExport('routeChoice', fd1)
            if (updateImportDropdown) { 
              store.toFlagOptions[1](updateImportDropdown)
            }
          }
        })
      }
    }
  };

  const changeExCountry = async (data) => {
    setDataByKey("exportId", data.id)
    setDataByKey("exportCountry", data.abbreviation);
    setDataByKey("fromAreaCode", data.areaCode);
    const fd = new FormData();
    fd.append("from", localData.exportId);
    fd.append("to", "true");
    fd.append("movementType", localData.movementType);
    
    const updateImportDropdown = await postEPOExport('routeChoice', fd)
    if (updateImportDropdown) { 
      store.toFlagOptions[1](updateImportDropdown)
      updateImportDropdown.forEach( e => {
        if (e.abbreviation === "gb") {
          setDataByKey("importCountry", "gb");
          setDataByKey("importId", e.id);
          setDataByKey("toAreaCode", e.areaCode);
        }
      })
    }

    postEPOExport('routeChoice', fd).then((data) => {    
      store.toFlagOptions[1](data)
    })
  }

  const changeImCountry = (data) => {
    setDataByKey("toAreaCode", data.areaCode);
    setDataByKey("importId", data.id)
    setDataByKey("importCountry", data.abbreviation);
    setDataByKey("addReqNeither", false)
    setDataByKey("tad", false)
    setDataByKey("ens", false)
    setDataByKey("officeDest", "")
    setDataByKey("inlandTransId", "")
    setDataByKey("sealNo", "")
    unvisitStep("4. AdditionalRequirements")
    unvisitStep("3. Entry Port")
    unvisitStep("2. Exit Port")
  }

  const nextPage = () => {    
    // Api call to 
    const fd = new FormData();
    fd.append("from", localData.exportId);
    fd.append("to", localData.importId);
    postEPOExport('validateRoute', fd).then((data) => {    
      if (data[0].isValid) {
        if (data[0].type === 'Export') {
          setDataByKey("movementType", "Export")
          localData.entryport = undefined
          store.epoDirection[1](data[0].direction)
          gotoStep("2. Exit Port")
        }
        if (data[0].type === 'Import') {
          setDataByKey("movementType", "Import")
          localData.exitport = undefined
          store.epoDirection[1](data[0].direction)
          if (DDAStatus === 0) {            
            // check not preferential origin country shortcode for Swiss and Turkey Norway Liechtenstein Iceland North Macedonia
            if (!["ch", "tr", "no", "is", "li", "mk"].includes(localData.exportCountry)) {
              setDataByKey("euPref", "yes");
              unvisitStep("Preferential Origin");
              setDataByKey("euPrefSelected", true);
              gotoStep("eu_origin");
            } else {
              setDataByKey("pref", "yes");
              setDataByKey("euPrefSelected", false);
              stepForward();
            }
          } else {
            stepForward();
          }       
        }
      }
      else {
        localData.openRedirectmodal({
          title: "Invalid route",
          body: (<>
            <p className="bold">Error - Not a supported route</p>
            <p>The movement type you have selected is not currently supported by CABIE. For the route you have selected, click <a target="_blank" rel="noreferrer" href="https://www.eori.uk/Customs_Entry">HERE</a> for data capture options.</p>
            <p>For more details please contact us below:
              <div className="bold text-eoriblue text-nowrap ms-2 mt-1">P: +44 (0) 333 012 4819</div>
              <div className="bold text-eoriblue ms-2">E: <a className="bold text-eoriblue" href="mailto:cabiesupport@eori.uk?subject=CABIE Issue">CABIEsupport@eori.uk</a></div>
            </p>
        </>)
        });
      }

  })}

  const [error, setError] = useState(false);

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "1. Direction" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "1. Direction",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])


  return (
    <Stepper.Step stepKey="1. Direction" text="Route">
     
     {/* <div className='  mb-4 '></div> */}
     <div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`} style={{fontSize:"1.8rem"}}>Type of Movement</div>
     <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
  <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
    <input type="radio" checked={localData.movementType === "Import"} onChange={() => setToDropdown("Import")}/>
    <span>Import</span>
  </label>

  <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px' }}>
    <input type="radio" checked={localData.movementType === "Export"} onChange={() => setToDropdown("Export")} />
    <span>Export</span>
  </label>
</div>

      
      <div className='px-2 mt-1 d-flex flex-column' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
        
        <div className='d-flex flex-row justify-content-center' >
        <div className="step-banner d-flex mt-2 mb-2 py-4 flex-column justify-content-center flags align-items-center px-4">
          <img style={{ marginTop: "25px" }} className="p-4"  src={`/flags/${localData.exportCountry}.svg`} alt="" />
          <div style={{ marginTop: "25px" }}>
          <EPOFilterableSelect
            label="Ship from"
            // name="exportCountry"
            data={localData.exportId}
            setData={(data) => changeExCountry(data)}
            options={store.fromFlagOptions[0]}
            filter={1}
            // dropdownWidth={"400px"}
            className={"epo-dropdown"}
            required  
            disabled={!localData.movementType}
            smallValue={true}


          />
        </div>
        </div>
        <div className=' step-banner-link d-flex' ><img src={dots} alt="" width="30" style={{ marginBottom: "85px" }} /></div>
        {/* */}
        <div className="step-banner d-flex mt-2 mb-2 py-4 flex-column justify-content-center flags align-items-center px-4">


          <img style={{ marginTop: "25px" }} className="p-4 " src={`/flags/${localData.importCountry}.svg`} alt="" />
          <div style={{ marginTop: "25px" }}>
          <EPOFilterableSelect
            label="Ship to"
            name="importCountry"
            data={localData.importId}
            setData={(data) => changeImCountry(data)}
            options={store.toFlagOptions[0]}
            className={"epo-dropdown"}
            // filter={1}
            required
            disabled={!localData.movementType}
            smallValue={true}

            // label={<>Country <span style={{ fontSize: "120%" }}>*</span></>}
            // data={neenor.country}
            // setData={(e) => {updateNeeNor('country', e)}}
            // options={countryList}
            // error={errors.country}
            // style={{width: '100px'}}

          />
        </div>
        </div>
        </div>
        {/* <div className="d-flex flex-column mb-3 px-5 py-1 ">
          <div className="d-flex flex-row space-start py-3">
            <span className="d-inline-flex justify-content-center w-100 px-2 text-center">You may filter your search by typing directly into each Select box.</span>
          </div>
          </div> */}
          {/* <label className="d-inline-flex align-items-center justify-content-center gap-2 my-2 px-4">
            <input type="radio" name="direction" value="yes" checked={localData.direction === "yes"} onChange={handleChange} />
            <span>Yes</span>
          </label>

          <label className="d-inline-flex align-items-center justify-content-center gap-2 px-4">
            <input type="radio" name="direction" value="no" checked={localData.direction === "no"} onChange={handleChange} />
            <span>No</span>
          </label> */}
                    {/* {error && (
            <div className="alert-danger px-3 py-1 w-100" style={{borderRadius: '10px'}}>{error}</div>
          )}    */}
        <section className="d-flex justify-content-between mt-auto px-3 pb-4" style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px"}}>
        <div>
        </div>
        <button  type="button" className="stepper-button " onClick={nextPage}>
          Next
        </button>
      </section>
      </div>
     
      {/* <div className='  mt-4 '></div> */}
    </Stepper.Step>
  );
}
