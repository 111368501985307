import React, {memo, useContext, useRef, useEffect} from 'react'
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { CallbackContext } from './Spreadsheet';

export const TableSetup = memo(
    (props) => {
      const { excelDispatch } = useContext(CallbackContext);
  
      const hasRun = useRef(false);
  
      useEffect(() => {
        const config = [];
        React.Children.forEach(props.children, (child) => {
          if (!!child && typeof child === "object") {
            config.push({ ...child.props, key: child.key });
          }
        });
  
        excelDispatch({ type: "config", detail: {config, defaultRows: props.defaultRows} });
        if (hasRun.current) {
          excelDispatch({ type: "reset" });
        } else {
          hasRun.current = true;
        }
        excelDispatch({ type: "recalculate" });
      }, [props.children, excelDispatch]);
  
      return null;
    },
    (old, updated) => {
      const old_kids = old.children.filter((c) => !!c);
      const new_kids = updated.children.filter((c) => !!c);
  
      if (old_kids.length !== new_kids.length) return false;
      // Cannot compare functions, so remove them from the props.
      const old_props = old_kids.map((c) => removeFunctionProps(c.props));
      const new_props = new_kids.map((c) => removeFunctionProps(c.props));
  
      return _.isEqual(old_props, new_props);
    }
  );

const TheTable = () => {
    const { excelState } = useContext(CallbackContext);
  
    return (
      <>
        {excelState.config.map(({ children, hidden, key, ...rest }, idx) => 
        !hidden &&
        (
        <TableColumn key={key} title={children} col_idx={idx} {...rest} />
        ))}
       
    </>
    
    );
  };

export default TheTable

const TableColumn = ({
    col_idx,
    title,
    minWidth,
    stretch,
    align,
    type,
    tooltip,
  }) => {
    const { excelState, lastRowClicked } = useContext(CallbackContext);
    const rows = excelState.rows;
  
    return (
      <div
        style={{
          minWidth: `${minWidth}ch`,
          flexGrow: stretch && 1,
          flexBasis: !stretch && "0%",
          textAlign: align ?? (type === "number" ? "right" : "left"),
        }}
      >
        <h1
          className="commodity-table-header1"
          onMouseDown={(e) => e.preventDefault()}
        >
          <span>{title}</span>
          {tooltip}
        </h1>
        {rows.map((row, idx) => (
          <TableCell
            key={idx}
            cell_data={row[col_idx]}
            row_idx={idx}
            col_idx={col_idx}
          />
        ))}
        <div
          className="cell last"
          data-col-idx={col_idx}
          onClick={lastRowClicked}
        ></div>
      </div>
    );
  };
  
  
  const TableCell = React.memo(
    ({ col_idx, row_idx }) => {
      const { excelState, handleMouseDown } = useContext(CallbackContext);
  
      const cell_data = excelState.rows?.[row_idx]?.[col_idx];
      return (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          show={(cell_data?.warn || cell_data?.error) ? undefined : false}
          placement="bottom"
          overlay={
            <Popover id="popover-basic">
            <div className={`px-2 py-1 alert-warning`}>
              {cell_data?.warn || cell_data?.error}
            </div>
            </Popover>
          }
        >
          <div
            className="cell"
            onMouseDown={handleMouseDown}
            data-cell-type="TODO"
            data-row-idx={row_idx}
            data-col-idx={col_idx}
            data-error={cell_data?.error ? true : undefined}
            data-warn={cell_data?.warn ? true : undefined}
          >
            {cell_data?.text}
          </div>
        </OverlayTrigger>
      );
    },
    (old, updated) => {
      return (
        _.isEqual(old.cell_data, updated.cell_data) &&
        old.col_idx === updated.col_idx &&
        old.row_idx === updated.row_idx
      );
    }
  );
  
  
  function removeFunctionProps(props) {
    const obj = {};
    Object.entries(props).forEach(([key, prop]) => {
      if (typeof prop !== "function" && !React.isValidElement(prop)) {
        obj[key] = prop;
      }
    });
    return obj;
  }