import { useState, useRef, useEffect, useContext } from "react";
import Stepper, { useStepper } from "../../Stepper";
import { StoreContext } from "../../Store";
import docs from "../assets/docupload.png";
import { Modal } from "react-bootstrap";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";
import UploadIcon from "../../../images/Upload.svg";
import { ConditionText } from "./ConditionText";
import { ExportConditionText } from "./ExportConditionText";
// import ponsonbyLogo from '../../../images/2-globes.jpg'
import { Trash } from "@styled-icons/fa-solid"

export function DocumentsUploadPage() {

  const { stepBackward, stepForward, localData, setDataByKey, emitData, activeStepKey, setStepListVisible, gotoStep, unvisitStep } = useStepper();
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [hasMandatoryCerts, setHasMandatoryCerts] = useState(false);
  const [conditionName, setConditionName] = useState('');
  const [clickCount, setClickCount] = useState(0);
  const [ipaffsModal, setIpaffsModal] = useState(false);

  

  const store = useContext(StoreContext);

  useEffect(() => {
    const mandatoryCount = localData.certsList.reduce((acc, cert) => acc + (cert.required === true ? 1 : 0), false)
    setHasMandatoryCerts(mandatoryCount)
  }, [localData.certsList])

  useEffect(()=>{
    const ids =  localData.selectedCategoryOfGoods.map(good => parseInt(good.id))  
    if(ids?.length === 1 && ids[0] === 6){
      unvisitStep("9. Documents");
    }
  },[localData.selectedCategoryOfGoods])



  useEffect(()=>{
    const ids =  localData.selectedCategoryOfGoods.map(good => parseInt(good.id))   
    if(ids?.length === 1 && ids[0] === 6){
      unvisitStep("9. Documents");
    }
  },[localData.selectedCategoryOfGoods])

const documentsCheck = async (doc1, doc2, doc3, doc4, doc5, name1, name2, name3) => {
  if (localData.selectedCategoryOfGoods.some(e => e.category === doc1 || e.category === doc2)) {
    if (localData.selectedCategoryOfGoods.some(e => e.category === doc3 || e.category === doc4 || e.category === doc5)) {
      if (localData.documents.some(e => e.name === name1 && localData.documents.some(f => f.name === name2) || e.name === name3)) {
        return 1
      }
      if (localData.documents.some(e => e.name === name1)) {
        return 4
      }
      if (localData.documents.some(e => e.name === name2)) {
        return 3
      }
      else {
        return 2
      }
      }
    else {
      if (localData.documents.some(e => e.name === name1 || e.name === name3)) {
        return 1
      }
      else {
        return 3
      }
    }
}

if (localData.selectedCategoryOfGoods.some(e => e.category === doc3 || e.category === doc4 || e.category === doc5)) {
  if (localData.selectedCategoryOfGoods.some(e => e.category === doc1 || e.category === doc2)) {
    if (localData.documents.some(e => e.name === name1 && localData.documents.some(f => f.name === name2) || e.name === name3)) {
      return 1
    }
    if (localData.documents.some(e => e.name === name1)) {
      return 4
    }
    if (localData.documents.some(e => e.name === name2)) {
      return 3
    }
    else {
      return 2
    }
    }
  else {
    if (localData.documents.some(e => e.name === name2 || e.name === name3)) {
      return 1
    }
    else {
      return 4
    }
  }
}

else {
  return 1
}
}

const importDocCheck = async () => {
  if (localData.selectedCategoryOfGoods.some(e => e.category === "Fresh Fruit and Vegetables" || 
  e.category === "Plants and Plant Products" || 
  e.category === "Plants and Plant Products (Medium or High Risk)" || e.category === "High-Risk Food of Non-Animal Origin (HRFNAO)" ||
  e.category === "Fishery Products - Fish, Molluscs or Crustaceans" || e.category === "Products Of Animal Origin (POAO)")) {
    if (localData.documents.some(e => e.name === "IPAFFS")) {
      return 3
    }
    
      if (localData.documents.some(e => e.name === "Phytosanitary Certificate")) {
      return 1}
    
    else {
      if (localData.selectedCategoryOfGoods.some(e => e.category === "Fresh Fruit and Vegetables" || 
  e.category === "Plants and Plant Products" || 
  e.category === "Plants and Plant Products (Medium or High Risk)")) {
    return 2
  }
  else {
    return 1
  }
      
      }
  }


}

const docCheckAndForward = () => {

    const ipaffsDoc = localData.documents.some(e => e.name === "IPAFFS")
    const ehcDoc = localData.documents.some(e => e.name === "Export Health Certificate")
    const catchDoc = localData.documents.some(e => e.name === "Catch Certificate")
    if (ipaffsDoc === false) {
      if (localData.selectedCategoryOfGoods.some(e => e.category === "Fresh Fruit and Vegetables" || 
      e.category === "Plants and Plant Products" || 
      e.category === "Plants and Plant Products (Medium or High Risk)" || e.category === "High-Risk Food of Non-Animal Origin (HRFNAO)" ||
      e.category === "Fishery Products - Fish, Molluscs or Crustaceans" || e.category === "Products Of Animal Origin (POAO)")) {
        setDataByKey("ipaffsConfirmation", true)
      }
    }

    if (ehcDoc === false && ipaffsDoc === false) {
      if (localData.selectedCategoryOfGoods.some(e => e.category === "High-Risk Food of Non-Animal Origin (HRFNAO)" ||
      e.category === "Fishery Products - Fish, Molluscs or Crustaceans" || e.category === "Products Of Animal Origin (POAO)")) { 
        setDataByKey("ehcConfirmation", true)
      }

    }

    if (catchDoc === false) {
      if (localData.selectedCategoryOfGoods.some(e => e.category === "Fishery Products - Fish, Molluscs or Crustaceans")) {
        setDataByKey("catchConfirmation", true)
      }
    }
  stepForward();
}

  const forward = async () => {
    const mandatoryNotTicked = localData.certsList.reduce((acc, cert) => acc || (cert.required === true && !cert.checked), false)

    if (mandatoryNotTicked) {
      setError("Please upload all mandatory documents.")
      return
    }

    if (localData.movementType === "Import") {
      const importCheck1 = await importDocCheck()
      if (importCheck1 === 1) {
        docCheckAndForward();
      }
      if (importCheck1 === 3) {
        setIpaffsModal(true)
        return
      }
      if (importCheck1 === 2)
      {
        setError("Please upload a Phytosanitary Certificate or an IPAFFS for this movement.")
        return
      }
      else {
        docCheckAndForward();
      }
    }
    if (localData.movementType === "Export" && localData.tad === true) {
      const check1 = await documentsCheck("Plants and Plant Products", 
                                          "Fresh Fruit and Vegetables", 
                                          "Products Of Animal Origin (POAO)", 
                                          "Fishery Products - Fish, Molluscs or Crustaceans", 
                                          "High-Risk Food of Non-Animal Origin (HRFNAO)", 
                                          "Phytosanitary Certificate", 
                                          "Export Health Certificate", 
                                          "CHED")
      if (check1 === 1) {
        stepForward();
      }
      if (check1 === 2) {
        setError("Please upload an Export Health Certificate or CHED for this movement.")
        setError2("Please upload an Phytosanitary Certificate or CHED for this movement.");
        return
      }
      if (check1 === 3) {
        setError("Please upload a Phytosanitary Certificate or CHED for this movement.")
        return
      }
      if (check1 === 4) {
        setError("Please upload an Export Health Certificate or CHED for this movement.")
        return
      }
      else {
        stepForward();
      }
    }
    else {
      stepForward();
    }
  }

  const backwards = () => {
    setError("");
    setError2("");
    if (localData.movementType === "Export") {
      gotoStep("8. EtaExportPort")
    }
  else {
    gotoStep("7. EtaPort")
  }
};

  function addFile(file, certName) {
    // console.log('file here ', file[0].name.slice(-3))
    if (file[0].name.slice(-3).toLowerCase() === "xls" || file[0].name.slice(-3).toLowerCase() === "pdf" || 
    file[0].name.slice(-3).toLowerCase() === "png" || file[0].name.slice(-3).toLowerCase() === "jpg" || 
    file[0].name.slice(-4).toLowerCase() === "jpeg" || file[0].name.slice(-4).toLowerCase() === "xlsx") {
      const prevDocs = [...localData.documents];
      prevDocs.push({name: certName, file: file[0]})
      setDataByKey("documents", [...prevDocs]);
      setDataByKey('certsList', localData.certsList.map(cert => {
        if (cert.name === certName)
          cert.checked = true
        return cert;
      }))
    }
    else {
      setError("We could not process your file, please review the file and file type.");
      return
    }

  }

  function removeFile(fileIdx, fileToRemove) {
    const prevFiles = [...localData.documents];
    const typeCount  = prevFiles.filter(cert => cert.name === fileToRemove).length;
    setDataByKey("documents", [...prevFiles.slice(0, fileIdx), ...prevFiles.slice(fileIdx + 1)]);
    setDataByKey('certsList', localData.certsList.map(cert => {
      if (typeCount === 1 && cert.name === fileToRemove)
        cert.checked = false
      return cert;
    }))
  }

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "9. Documents" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "9. Documents",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  return (
    <Stepper.Step hideUnlessVisited={true} stepKey="9. Documents" text="Licences and Certificates">
      <TooltipModal 
      show={showTooltip}
      text={tooltipText}
      conditionName={conditionName}
      onHide={() => {setShowTooltip(false)}}
      />
        <div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{ fontSize:"1.8rem"}}>Licences and Certificates</div>
        <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
          <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px' }}>
            <input type="radio" disabled checked={localData.movementType === "Import"} />
            <span>Import</span>
          </label>

          <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px'}}>
            <input type="radio" disabled checked={localData.movementType === "Export"} />
            <span>Export</span>
          </label>
        </div>
        <div className=' px-2 mt-1 pt-2 d-flex flex-column' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>

        <div className="d-flex step-banner  justify-content-center">
        <img src={docs} width="130" height="130" alt="" className="mt-4 mb-4" />  
        </div>

            <div class="d-flex align-self-center flex-column mt-3 px-4" style={{maxWidth: "400px"}}>
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "400px", fontWeight: "500"}} >Please upload all Licences and Certificates for </span>
            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "400px", fontWeight: "500"}}>this shipment. Click on the blue icon below for</span>

            <span class="d-flex align-self-center flex-column  " style={{maxWidth: "400px", fontWeight: "500"}}>further information on document requirements.</span>
            <span class="d-flex align-self-center flex-row italic" style={{fontSize: '14px'}}>File types accepted are&nbsp;<span className="bold" style={{}}>.pdf, .png, .jpeg, .xls</span></span>
             </div>
             
             <div className="label-text d-flex flex-column align-items-center  ">
            {/* <span>Select documents to upload</span> */}

          </div>
        <div className="d-flex flex-row justify-content-around mt-3 ">
        {!!hasMandatoryCerts && (
             <div className="d-flex flex-column gap-2">
          <div>
            <div className="section-title" style={{fontSize: '1rem', width: '50%', marginBottom: '0.3rem' ,fontWeight: "500"}}>Mandatory</div>
              <div className="p-1" style={{marginBottom: '1rem'}}>
              {localData.certsList.filter(cert => cert.required === true).map(cert =>
                    <label className="d-flex align-items-center gap-1">
                  
                    <div className="d-flex gap-1 justify-content-between doc-upload-button" style={{cursor: 'pointer'}}>
                    <label name="name"style={{cursor: 'pointer' }}>
                      <span className="certificate" style={{fontSize: '1rem'}}>{cert.name}</span>
                      <input type="file" 
                      name="fileuploader" 
                      onChange={(e) => addFile(e.target.files, cert.name)} 
                      onClick={(e) => {e.target.value = null;setError("");setError2("");}}
                      accept=".pdf,.png,.jpeg,.xls,.xlsx" />
                    </label>
                    {/* <img className="d-flex mt-1" style={{maxHeight: '15px'}} src={cloud} alt="" />  */}


                    {cert.checked  &&  (<div className="tick-success-docs mt-1"></div>)}
                    </div>
                  </label>
              )}
              </div>
            </div>
            </div>
            )}
       

           <div className="d-flex flex-column">
            <div className="section-title" style={{fontSize: '1rem', marginBottom: '0.3rem', fontWeight: "500"}}>Conditional</div>
            <div className="p-1" style={{marginBottom: '2rem'}}>
              
            {localData.certsList.filter(cert => cert.required === false).map(cert =>
            <div className="d-flex align-items-center justify-content-start gap-1" >
                  <label className="" style={{cursor: 'pointer',   width: '100%' }}>
                  <div className="d-flex gap-1 justify-content-between doc-upload-button" style={{cursor: 'pointer'}}>
                    <label  name="name"style={{cursor: 'pointer' }}>
                      <span className="certificate" style={{fontSize: '1rem'}}>{cert.name}</span>
                      <input type="file" 
                      name="fileuploader" 
                      onChange={(e) => addFile(e.target.files, cert.name)} 
                      onClick={(e) => {e.target.value = null;setError("");setError2("");}}
                      accept=".pdf,.png,.jpeg,.xls,.xlsx" />
                    </label>
                    <div className='d-flex'> 
                    {/* <img className="d-flex mt-1" style={{maxHeight: '15px'}} src={cloud} alt="" />  */}

                      
                  {cert.checked  &&  (<div className="tick-success-docs mt-1"></div>)}

                                   </div> </div>
                </label>
                {cert.condition ? (
                    <div className="info controlled docupload" style={{marginBottom: '8px'}} onClick={() => {
                      setError("");
                      setError2("");
                      setConditionName(cert.name)
                      setShowTooltip(true);
                      setTooltipText(cert.condition);
                    }
                    }
                    ></div>
                  ) : <div></div>}    
                </div>
            )}
            </div>
         </div>
         </div>

        
      <div className="d-flex flex-column gap-1 mb-1 step-banner pd-2 " style={{minHeight: '5rem', minWidth: '220px', padding:"0.9rem"}}>
      <div className="section-title" style={{fontSize: '14px', marginBottom: '0.3rem', marginLeft:"12px"}}>Uploaded...</div>
            {localData.documents.map((document, fileIdx) => (
              <span key={`${document.name}-${fileIdx}`} className="d-inline-flex w-100 justify-content-between gap-3" style={{fontSize: '0.8rem' , marginLeft:"12px"}} title={document.name}>
              <span className="text-nowrap" style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                <span style={{fontWeight: '600'}}>{document.file.name}</span> - <span style={{fontSize: '1rem', fontStyle: 'italic'}}>{document.name }</span>
              </span>
              <button style={{appearance: 'none', border: 'none', backgroundColor: 'transparent'}} onClick={() => {setError("");setError2("");removeFile(fileIdx, document.name);}}>
              <Trash width={"1rem"} height={"1rem"} style={{color: 'red'}} />
              </button>
              </span>
            ))}
          </div>


      
  <div className="px-3 mt-3 mb-3 w-100 mx-auto">
           {error && (
            <div className="alert-danger px-3 py-1 w-100 text-center" style={{borderRadius: '10px'}}>{error}</div>
            )}
            {error2 && (
            <div className="alert-danger px-3 py-1 w-100 text-center mt-1" style={{borderRadius: '10px'}}>{error2}</div>
            )}
          </div>
      
          <section className="d-flex justify-content-between px-3 mt-auto pb-4">
        <button type="button" className="stepper-button red" onClick={backwards}>
          Back
        </button>
        <button type="button" 
        className="stepper-button" 
        onClick={forward}>
          Next
        </button>
      </section>
      <IpaffsRedirectModal
          show={ipaffsModal}
          onHide={() => {
            setIpaffsModal(false);
          }}
          onContinue={() => {      
            setIpaffsModal(false);
            docCheckAndForward();
            
          }}
        ></IpaffsRedirectModal>
      </div>
    </Stepper.Step>
  );
}

const TooltipModal = ({ show, onHide, conditionName, localData }) => {
  console.log('movement type ', localData.movementType)
  console.log('conditionName ', localData.certsList)
  return (
    <Modal centered size={"md"} animation={false} show={show} onHide={onHide}>
      <Modal.Body>
        {localData.movementType === "Export" && (<ExportConditionText name={conditionName} />)}
        {localData.movementType === "Import" && (<ConditionText name={conditionName} />)}
      </Modal.Body>
      <Modal.Footer
        class="py-3 px-3"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <div className="d-flex justify-content-end">
          <button className="stepper-button" onClick={onHide}>
            OK
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};


const IpaffsRedirectModal = ({ show, onHide, onContinue }) => {

  function cancelClick() {
    onHide();
  }

  return (
    <Modal show={show} centered size={"md"} onHide={onHide} animation={false} >
      <Modal.Header>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="warning" /> <span>
            IPAFFS Upload
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 py-4">
          <>
              <p className="bold">Warning - You have uploaded an IPAFFS document</p>
              <p>Do you confirm the file uploaded contains the IPAFFS reference for these goods?</p>
          </>
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button class="stepper-button" onClick={cancelClick}>
          Not Yet
        </button>
        <button class="stepper-button yellow" onClick={()=> onContinue()}>
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );
};