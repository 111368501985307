import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom"
import { useSecurity } from "./Security/security";
import { MessagingContext} from './MessagingProvider'
import { StoreContext } from "./Store";

const Logout = () => {
  const navigate = useNavigate()
  const { dispatch } = useSecurity();
  const mp = useContext(MessagingContext);
  const store = useContext(StoreContext);
  useEffect(() => {
    dispatch({type: 'logout'});
    //used to reset the dropdown selected value in useraddmin
    store?.resetUserIdSelected()
    mp.logout()
    navigate("/");
  }, [navigate, dispatch]);

  return null;
}

export default Logout