import {useContext, useEffect} from 'react'
import BPHeader from './BPHeader'
import BPFooter from './BPFooter'
import { useStepper } from "../Stepper";
import BPGrid from "./BPTestTable";

// Context
import {BusinessPartnerContext } from './BusinessPartnerCard'

const BPAddress = () => {
  const { activeStepKey } = useStepper();
  const {
    localBPData,
    handleAddData,
    isEditing,
    handleOnChange,
    dropdownValues,
    errors,
    setCurrentStep
  } = useContext(BusinessPartnerContext);

  useEffect(() => {
    if (activeStepKey === "BPaddress") {
      handleAddData('Address', 'BPaddress');
      setCurrentStep('BPaddress');
    };
  }, [activeStepKey]);

  const renderData = (title) => {
    const data = localBPData[title];
    if (!data) {
      return <BPGrid.Row title="Loading..." />;
    } else {
      return data.map((item, index) => {
        return (
          <BPGrid.Row
            title={index === 0 ? title : ""}
            subTitle={item.subtitle}
            value={item.value}
            isEditingValue={isEditing}
            valueType={item.Type}
            dropdownValues={dropdownValues?.[item?.subtitle]}
            onChange={handleOnChange}
            tableTitle={title}
            hasError={errors?.[item?.subtitle]}
            errorMessage={errors?.[item?.subtitle]}
            className={`bp-row-${index}`}
          />
        );
      });
    }
  };

  return (
    <>
      <section className="bp-address">
        <BPHeader />
        <div className="bp-section-heading">Address</div>
        <BPGrid className="address" numCols={2} isEditing={isEditing}>
          {renderData('Address')}
        </BPGrid>
      </section>
      <BPFooter />
    </>
  )
}

export default BPAddress
