import { useContext, useEffect, useMemo, useState } from "react";
import Stepper, { useStepper } from "../Stepper";

import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Popover, PopoverContent, Modal } from "react-bootstrap";

import { DirectionPage, EntryPortPage, EtaPortPage, CogPage, ImporterPage, JobRefPage, EuOriginPage, InvoicesUploadPage, CongratsPage, LspPage, DocumentsUploadPage } from "./Steps";
import { postEPOHeaders, getDDAStatus} from "../apiCalls";
import { useSecurity } from "../Security/security";
import Confirmation from "./Steps/Confirmation";
import { StoreContext } from "../Store";

export default function EpoLoads() {
  const [redirectReason, setRedirectReason] = useState(null);
  const [dutyModalOpen, setDutyModalOpen] = useState(false);
  const [DDAStatus, setDDAStatus] = useState(0)
  const { securityState, validateRole } = useSecurity();
  const [trader, setTrader] = useState("");
  const isLsp = useMemo(() => validateRole({ department: "LSP" }), []);

  const defaultData = useMemo(() => ({
    direction: "yes",
    entryport: {id: undefined},
    importer: "yes",
    selectedCategoryOfGoods: [],
    euPref: "yes",
    pref: "yes",
    jobRef: "",
    date: "",
    lsp: "",
    // confirmedNoCG: "N",
    invoices: [],
    documents: [],
    certsList: [],

    openRedirectmodal: (text) => setRedirectReason(text),
    openDutyModal: () => setDutyModalOpen(true)
  }), [])

  useEffect(() => {
    async function fetchDDAStatus() {  
      const data = await getDDAStatus(securityState?.companyid);
       if(data){       
        setDDAStatus(data[0]?.DDA);
      }
    }
    fetchDDAStatus()
  }, []);

  const setDDAFlag = DDA => setDDAStatus(DDA);

  const covertToCamalCase = (sentence) => {
    if(sentence){
      const words = sentence.split(" ");
      const capitalizedWords = words.map((word) => {
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1).toLowerCase();
        return firstLetter + restOfWord;
      });
      return capitalizedWords.join(" ");
    }
  }
  
  const handleEmitData = async (data) => {
    const {direction, entryport, importer, euPref, jobRef, date, invoices, lsp, confirmedNoCG, certsList, documents} = data;
    const fd = new FormData();
    fd.append("euToGb", direction);
    fd.append("entryPortId", entryport?.id);
    fd.append("importer", importer);
    fd.append("euPref", euPref);
    fd.append("jobRef", jobRef);
    fd.append("etaDate", date);
    fd.append("lsp", lsp)
    fd.append("controlledGoods", confirmedNoCG ? 'N' : 'Y');
    fd.append("certsList", JSON.stringify(certsList));
    for (const file of invoices) {
      fd.append("invoices", file);
    }
    for (const file of documents) {
      fd.append("documents", file.file);
      fd.append("documentNames", file.name);
    }
    // fd.append("<name expected by backend>", <field found in data>);
    const result = await postEPOHeaders(fd)
    return result;
  };
  return (
    <>
      <DutyRedirectModal
        show={dutyModalOpen}
        onHide={() => setDutyModalOpen(false)}
      />

      <Stepper
        showFuture={false}
        emitData={handleEmitData}
        defaultData={defaultData}
        // stepHeader={header}
        stepClassName="bg-white d-flex flex-column justify-content-between pt-3"
        stepStyle={{
          borderRadius: "20px",
          padding: "2rem",
          minWidth: "500px",
          maxWidth: "500px",
          minHeight: "475px",
        }}
      >
        <RedirectModal
          redirectReason={redirectReason}
          onHide={() => {
            setRedirectReason(null);
          }}
        />

        <main
          className="d-flex w-100 mx-auto justify-content-center"
          style={ (isLsp && trader) ? {marginTop: "4rem"} : {marginTop: "6rem"}}
        >
          <div className="d-flex flex-column">
          {validateRole({ department: "LSP" }) && (<div
              className="mb-4"
              style={{ fontSize: "32px", color: "#fff", lineHeight: "32px" }}

            >
              {covertToCamalCase(trader)}
            </div>)}
            <div className="d-flex bg-white">
              {validateRole({ department: "LSP" }) && (
                <LspPage
                  DDAStatus={setDDAFlag}
                  showTrader={(trader) => setTrader(trader)}
                />
              )}
              <DirectionPage />
              {DDAStatus === 0 && <EuOriginPage />}
              {/* <EuOriginPage /> */}
              <EntryPortPage />
              {/* <ImporterPage /> */}
              <CogPage />
              <JobRefPage />
              <EtaPortPage />
              <InvoicesUploadPage />
              <DocumentsUploadPage />
              <Confirmation />
              <CongratsPage validateRole={validateRole} />
            </div>
          </div>
          <div style={{ minWidth: "20px" }} /> {/* spacer to ensure gap */}
          <div style={isLsp ? {marginTop: "9.5rem"} : {}}>
            <Stepper.StepList
              title={
                <div
                  className="epo-steps-stepper-header"
                  style={{
                    paddingTop: "1rem",
                    paddingLeft: "1.95rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  {/* Process Steps */}
                </div>
              }
            />
          </div>
        </main>
      </Stepper>
    </>
  );
}

export const EPOTooltip = () => {
  return (
    <OverlayTrigger
      placement="right"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "35rem", marginRight: "0.5rem !important" }}>
          <PopoverContent>
            <h6>Duty Calculation Required</h6>
            <p>
              If your goods are of mixed origin. you will need to calculate your duty if you do not have a registered DAN account. If you have a DAN account, we
              will automatically calculate the duty for you.
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info" style={{ width: "1rem", marginRight: "0.5rem" }}></div>
    </OverlayTrigger>
  );
};

const RedirectModal = ({ redirectReason, onHide }) => {
  function cancelClick() {
    onHide();
  }
  
  return (
    <Modal show={!!redirectReason} centered size={"md"} onHide={onHide} animation={false}>
      <Modal.Header>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="warning" /> <span>
            {redirectReason?.title}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 py-4">
        {redirectReason?.body}
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-4 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button class="stepper-button" onClick={cancelClick}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const DutyRedirectModal = ({ show, onHide }) => {
  const navigate = useNavigate();

  function cancelClick() {
    onHide();
  }
  function confirmClick() {
    navigate('/Userprofile/compliance');
  }


  return (
    <Modal show={show} centered size={"md"} onHide={onHide} animation={false}>
      <Modal.Header>
        <Modal.Title>
          More Information Required
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Sorry, without your C1207N form and Duty Deferment Number we aren't able to process Non-Preferential goods via EPO.</p>

        <p>
          If you have this information now, please add using the link below.
        </p>
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button class="stepper-button" onClick={cancelClick}>
          Close
        </button>
        <button class="stepper-button" onClick={confirmClick}>
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
};