import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import ComplianceChecklist from "./ComplianceChecklist";
import DataCheck from "./DataCheck";
import RevenuePayable from "./RevenuePayable";
import ItemChecklist from "./ItemChecklist";
import { StoreContext } from "../../Store";
import LoadHeader from '../../LoadList/LoadHeader';
import { getNeeNorDetails } from "../../apiCalls";
import "../../BusinessPartners/BusinessPartnerCard.css";
import "./QAChecklistTab.css";

const QAChecklistTab = (props) => {
    const [consigneeData, setConsigneeData] = useState({});
    const store = useContext(StoreContext);

    useEffect(() => {
        const fetchConsigneeData = async () => {
            try {
                const data = await getNeeNorDetails(store.consignee[0]);
                setConsigneeData(data[0]);
            } catch (error) {
                console.error('Error fetching consignee data from getNeeNorDetails for consignee:  ', store.consignee[0], ' | ', error);
            }
        };
        store.consignee[0] && fetchConsigneeData();
    }, [store.consignee[0]]);

    let totalPackageCount = 0;
    let totalGrossMass = 0;
    let totalNetMass = 0;
    let totalValue = 0;
    let totalStatValue = 0;
    let totalDuty = 0;
    let totalVAT = 0;

    const productList = store.validatedRowsReadyToSubmit[0].map((product, index) => {
        totalPackageCount += product.packageCount;
        totalGrossMass += product.grossMass;
        totalNetMass += product.netMass;
        totalValue += product.itemValue;
        
        return {
            'Item': index + 1,
            'Valid': true,
            'HS Code': product.commoditycode,
            'Origin': product.origin,
            'Packages': product.packageCount, 
            'Gross (Kg)': parseFloat(product.grossMass)?.toFixed(2), //Need a more elegant way as this needs to be done at back-end level - Like 'View Load' table
            'Net (Kg)': parseFloat(product.netMass)?.toFixed(2), //Need a more elegant way as this needs to be done at back-end level - Like 'View Load' table
            'Value': parseFloat(product.itemValue)?.toFixed(2), //Need a more elegant way as this needs to be done at back-end level - Like 'View Load' table
            'Preference': product.pref !== "" ?  product.pref === "1" ? "YES" : "NO" : "-", 
            'Duty Liability': '-', 
            'VAT Liability': '-'
        }
    });

    const status = true ? 'green-tick' : 'red-cross';
    const totalValueLabel = `Total Value ${store.invoicecurr[0] ? `(${store.invoicecurr[0]})` : ''}`;

    return (
        <div style={{ display: 'initial' }}>
            <Container fluid style={{ borderRadius: "20px", backgroundColor: "#f0f4f9" }}>
                <div className='d-flex flex-row justify-content-between align-items-start px-2'>
                    <div className={ `floating-box-header-newload flex-row ${status}` }
                        style={{ paddingTop: '18px 0 0 0', marginLeft: '0', height: 'auto' }}>
                        Quality Checklist
                    </div>
                    <div className="d-flex flex-column gap-2 justify-content-between align-items-end py-3">
                        <LoadHeader
                            //portalRef={`Value taken from /newload/save reseponse - transferid`}
                            jobRef={store.jobRef[0]}
                        />
                    </div>
                </div>
                <div className="business-partner-container qa-checklist">
                    <div class='main-checklist'>
                        <ComplianceChecklist 
                            companyId={store.clientID[0]}
                            companyName={store.clientIDname[0]}
                            consigneeData={consigneeData}
                        />
                        <DataCheck
                            route={store.directionSelected[0]} 
                            loadingDate={store.date[0]} 
                            entryPort={store.entry[0]} 
                            portModel={store.portsModal[0]} 
                            invoiceCurrency={store.invoicecurr[0]} 
                            incoterm={store.selectincoterm[0]} 
                            adjustments={store.adjustments[0]} 
                            controlledGoodsType={store.controlledGoods[0]} 
                        />
                        <RevenuePayable
                            consigneeID={store.consignee[0]}
                            totalLiability='??'
                            customsDuties='??'
                            vat='??'
                        />
                    </div>
                    <div class='items-checklist position-relative'>
                        <ItemChecklist 
                            totalPackageCountLabel='Item Rows' 
                            totalPackageCount={totalPackageCount}
                            totalGrossMassLabel='Gross (Kg)' 
                            totalGrossMass={totalGrossMass}  
                            totalNetMassLabel='Net (Kg)' 
                            totalNetMass={totalNetMass} 
                            totalValueLabel={totalValueLabel}
                            totalValue={totalValue}
                            totalStatValueLabel='Stat Value (GBP)'
                            totalStatValue={totalStatValue} 
                            totalDutyLabel='Total Duty (GBP)'
                            totalDuty={totalDuty}
                            totalVATLabel='Total VAT (GBP)'
                            totalVAT={totalVAT} 
                            productList={productList} 
                            prevPage={props.prevPage}
                            newLoadAction={props.newLoadAction}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default QAChecklistTab;