
import React, { useState, useEffect, useContext } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { EllipsisV } from "@styled-icons/fa-solid";
import { getCompanyCurrency, deleteCompanyCurrency } from '../../apiCalls';
import CurrencyModal from "./currencyModal";
import ConfirmationModal from "../../ConfirmationModal";
import { RefDataContext } from "../Maintenance";

const Currency = () => {
  const [currency, setCurrency] = useState([{ "": "" }])
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const {setOnAddNew} = useContext(RefDataContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCompanyCurrency();
        setCurrency(data);
      } catch (err) {
      }
    }
    fetchData();
    setOnAddNew(() => {
      setSelectedCurrency({id: -1})
    });
  }, []);

return (<>
<CurrencyModal currency={selectedCurrency} onCancel={() => setSelectedCurrency(null)} onSave={ async () => {
  const data = await getCompanyCurrency();
  setCurrency(data);
}}/>

<Table className="p-4 rounded-3" striped>
          <thead className="bold fs-5" style={{ color: "rgb(80, 80, 80)" }}>
            <tr>
              <th>Description</th>
              <th>Currency Code</th>
              <th className="text-nowrap" style={{ width: "0px" }}></th>
            </tr>
          </thead>
          <tbody>
            {/* TODO:PAGINATE ROWS */}
            {(currency).map((currency) => ( 
              <React.Fragment key={currency.client_id}>
                <CurrencyRow currency={currency} setSelectedCurrency={setSelectedCurrency} onDelete={ async () => {
  const data = await getCompanyCurrency();
  setCurrency(data);
}}/>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        </>)
}


function CurrencyRow ({currency, setSelectedCurrency, child, onDelete}) {
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);

  const handleDelete = async event => {
    try {
      const data = await deleteCompanyCurrency(currency.id)
      if (data[0].completed === 'deleted') {
        alert("Record Deleted");
        onDelete()
      }
    } catch (err) {
      console.log(err);
      alert('Error, unable to delete')
    }
  }
    return (
      <tr id={currency.id} className={`border-top fs-6 ${child ? "" : "bold"}`}>
        <td>{currency.description}</td>
        <td>{currency.code}</td>
        <td>
          <Dropdown className="w-100 h-100 d-grid center">
            <Dropdown.Toggle as="button" className="dot-menu-button">
              <EllipsisV width={"0.5rem"} />
            </Dropdown.Toggle>
  
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={() => setSelectedCurrency(currency)}>
                View/Edit
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={() => setShowConfirmResetModal(true)}>
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ConfirmationModal
        header="Confirm Deletion"
        show={showConfirmResetModal}
        onHide={() => setShowConfirmResetModal(false)}
        onConfirm={handleDelete}
        annimation={true}
      >
        Are you sure you want to delete this record? 
      </ConfirmationModal>
        </td>
      </tr>
    )
  }

  export default Currency; 