import React, { useContext, useState, useEffect } from 'react';
import "../Newload/newLoad.css";
import AdditionalFileLoader from '../AdditionalFileUpload/AdditionalFileLoader';
import "../Newload/newloadfileupload.css"
import information from '../../images/information.svg'
import { StoreContext } from "../Store";

const NewLoadOptions = () => {

    const store = useContext(StoreContext);

    return (
        <div className="semi-fluid-container container-xl file-upload" style={{ maxWidth: '700px', marginTop: '4rem' }}>
            <div className='floating-box-newload'>
                <div className='floating-box-header-newload'>
                    <span>Upload Additional Documents</span>
                </div>
                <div id="load-info-form" className='fileloader-header'>
                    <div className="inputs-group">
                        <span className='more-info'><img src={information} height="" width="30" alt="info" />&nbsp;&nbsp;
						<span>
                            {store.infoText[0]}
                            </span>
                            </span>
                    </div>
                    <div className="inputs-group">
                        <AdditionalFileLoader />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewLoadOptions;