import { useState, useEffect, useMemo } from "react";
import { Modal, Form, Popover, PopoverContent, OverlayTrigger } from "react-bootstrap";
import { useStepper } from "../../Stepper/index";
import "../epo.css";
import shipment from "../assets/shipment.png";
import ControlledGoodsModal from "../../ControlledGoodsModal";
import { QuestionCircle } from "@styled-icons/fa-solid";
import ControlsGoodsInformationLinks from "../../ControlsGoodsInformationLinks";
import ControlsGoodsExportLinks from "../../ControlsGoodsExportLinks";

const CogModal = ({ onSave, onCancel, show }) => {
  const { localData, setDataByKey } = useStepper();
  // const [categoryOptions, setCategoryOptions] = useState([]);
  const [confirmedNoCG, setConfirmNoCG] = useState("");

  const controlledGoods = useMemo(() => {
    return localData.categoryOptions.filter((category) => category.controlled);
  }, [localData.categoryOptions]);

  const checkedCategories = useMemo(() => {
    return localData.categoryOptions.filter(category => category.checked);
  }, [localData.categoryOptions])


  const setCategoryOptionCheckedById = (id, value) => {
    if (value === true) {
      setConfirmNoCG("CG");
    }
    
    const newCategoryOptions = (previous) => {
      const idx = previous.findIndex((category) => category.id === id);
      const updated = previous[idx];
      const result = [...previous.slice(0, idx), { ...updated, checked: value }, ...previous.slice(idx + 1)];
      return [...result];
    };

    setDataByKey("categoryOptions", newCategoryOptions(localData.categoryOptions));
  };

  const handleSaveSelection = () => {
    onSave(localData.categoryOptions.filter((category) => category.checked), confirmedNoCG);
  };

  const handleChange = (e) => {
    if (e === "CG") {
        setConfirmNoCG("CG")
    }
    if (e === "NCG") {
        setConfirmNoCG("NCG")
        setDataByKey("categoryOptions", localData.categoryOptionsDefault)
    }
  }

  return (
    <>
      <Modal onHide={onCancel} show={show} centered size="md" contentClassName="editModal" animation={false}>
        <Modal.Header>
          <Modal.Title >
            <span className="mx-4">Category of Goods</span>

            {/* TODO Not sure if an image is appropriate here`` */}
            {/* <img src={shipment} width="100" height="100" alt=""/> */}
          </Modal.Title>{" "}
          <OverlayTrigger
              placement="left"
              trigger={["click"]}
              rootClose
              overlay={
                <Popover style={{ width: "100%", maxWidth: "25rem" }}>
                  <PopoverContent>
                    {localData.movementType === "Import" && (<ControlsGoodsInformationLinks />) || (<ControlsGoodsExportLinks />)}
                  </PopoverContent>
                </Popover>
              }
            >
              <button type="button" style={{  paddingLeft: "0" }} className="invoice-add-btn green-text">

              <QuestionCircle width="1.4rem" height="1.7rem" />
              </button>
            </OverlayTrigger>

          <button type="button" class="btn-close" aria-label="Close" onClick={onCancel} />
        </Modal.Header>
        <Form onSubmit={null} className="flex-grow-1 d-flex flex-column overflow-auto">
          <input type="hidden" name="id" value={"Modal For Now"} />
          <Modal.Body className="flex-grow-1 d-flex flex-column pt-0">
            <section className="d-flex w-100 ps-4">
              <div className="text-eoriblue mt-4">

              <div className="flex-grow-1" />

            <div className="d-flex flex-column pb-4 mt-2">
            <label className="d-inline-flex align-items-center gap-2 my-2">
                <input type="radio" checked={ confirmedNoCG == "CG"} onChange={() => handleChange("CG")} />
                <span>Controlled Goods</span>
              </label>
              {(
            
            controlledGoods.map((category) => (
              <div className="d-flex flex-column ml-2 mt-2">
              <label className="d-flex align-items-center mb-2 gap-1" style={{fontSize: '1rem', cursor: "pointer"}}>
                <input type="checkbox" checked={category.checked ?? false} disabled={confirmedNoCG !== "CG"} onChange={() => setCategoryOptionCheckedById(category.id, !category.checked)} />
                <span>{category.category}</span>
              </label>
              </div>
            ))
          )}
              <label className="d-inline-flex align-items-center gap-2 my-2">
                <input type="radio" checked={ confirmedNoCG == "NCG"} onChange={() => handleChange("NCG")} />
                <span>Non-Controlled Goods</span>
              </label>
              </div>
              </div>
              
            </section>
            {/* // <div className="d-flex justify-content-start d-flex w-100 ps-4">
            // <input type="checkbox" checked={confirmedNoCG} onChange={e => setConfirmNoCG(e.target.checked)} disabled={checkedCategories.length > 0} style={{margin: 0, borderColor: checkedCategories.length > 0 ? '#999' : '', backgroundColor: checkedCategories.length > 0 ? '#eee' : ''}} />
            //   <label className="d-flex align-items-center justify-content-start label-text">
            //     <span style={{color: checkedCategories.length > 0 ? '#999' : '', fontSize: '1rem', marginLeft: '0.6rem'}}><div>This shipment DOES NOT contain any items subject to </div>
            //     {localData.movementType === "Import" && (<div>Import Control or Import Licensing requirements.</div>) || (<div>Export Control or Export Licensing requirements.</div>)}
                
            //     </span>
            
            //   </label>
            // </div> */}

          
           
          </Modal.Body>
          <Modal.Footer
            class="d-flex pb-2 pt-1 px-3 gap-3 border-top bg-light align-items-center"
            style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
          >
            <div className="flex-grow-1"/>
            <button type="button" class="cancel-button" onClick={onCancel} style={{ display: "flex", borderRadius: "10px" }}>
              Cancel
            </button>
            <button type="button" disabled={confirmedNoCG == "CG" && checkedCategories.length === 0} onClick={handleSaveSelection} class="blue-button-teams green-btn" style={{ borderRadius: "10px" }}>
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default CogModal;
