import React, { useState, useEffect, useContext } from 'react';
import { OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { getCM8Details } from "../apiCalls";
import { Input } from "../Input/Input.js";
import { useNavigate, useParams } from 'react-router-dom';
import { StoreContext } from '../Store.js';

const CM8CustomerValidation = () => {
    const store = useContext(StoreContext);
    const navigate = useNavigate();
    const { id } = useParams();

    const [status, setStatus] = useState({
        id: null,
        companyName: '',
        cabieStatus: '',
        cabieStatusValid: false,
        dbStatus: '',
        dbStatusValid: false,
        billableCustomerValid: '',
        billableCustomer: false,
        cm8Approved: '',
        cm8ApprovedValid: false
    });

    useEffect(() => {
        async function fetch() {
            try {
                const data = await getCM8Details(id);
                    setStatus({
                        id: data.id,
                        companyName: data.companyName,
                        cabieStatus: data.cabieStatus,
                        cabieStatusValid: data.cabieStatusValid,
                        dbStatus: data.dbStatus,
                        dbStatusValid: data.dbStatusValid,
                        billableCustomer: data.billableCustomer,
                        billableCustomerValid: data.billableCustomerValid,
                        cm8Approved: data.cm8Approved,
                        cm8ApprovedValid: data.cm8ApprovedValid
                    });
          
            } catch (error) {
              console.error('Error fetching NBP list:', error);
            }
        }
        
        fetch();
        
    }, [id]);

    const Warning = () => (
        <OverlayTrigger
          placement="top"
          trigger={["hover", "focus"]}
          overlay={
            <Popover style={{ minWidth: "auto" }}>
              <PopoverContent>
                <p>Please fix the highlighted customer settings.</p>
              </PopoverContent>
            </Popover>
          }
        >
          <p className="bp-warning-paragraph mx-2">
            <span className="warning bp-warning"></span>
          </p>
        </OverlayTrigger>
      );
    
    const cancelAction = () => {
        console.log("cancelAction");
        navigate(`/CM8Clients/`)
    };

    const createLoad = () => {
        console.log('CM8CreateNewLoad :: ', id);
        if (status.cm8ApprovedValid) navigate(`/CM8CreateNewLoad/${id}`)
            else {
                store.clientID[1](id);
                navigate(`/Newload`)
            }
        
    };

    const handleKebabClick = () => {
        store.clientID[1](id);
        console.log("Kebab Clicked");
        console.log('status id is :' + status.id);
        navigate('/BusinessPartners?id=' + status.id + '&companyid=' + id);
    }

    const customerIsCM8Valid =  status.cabieStatusValid && 
                                status.dbStatusValid && 
                                status.billableCustomerValid;

    
    return (
        <section className="floating-box-newload mt-4" style={{ width: "35%", margin: "0 auto" }}>
            <div className="floating-box-header-newload cm8">
                <span>CM8 Validation</span>
                {
                    !customerIsCM8Valid && <Warning />
                }
            </div>
            <div className="section-group">
                <div className="section">
                    <p className="title">Customer Validation</p>
                    <div className="content">
                        <div className="form-field">
                            <Input
                                label="Invoice Customer"
                                name="invoiceCustomer"
                                type="text"
                                value={(status.companyName && status.id) ? `${status.companyName} (${status.id})` : `-`}
                                required
                                disabled={true}
                                optionsButton={true}
                                optionsButtonCallback={handleKebabClick}
                            />
                        </div>
                        <div className="form-field">
                            <Input
                                label="CABIE Status"
                                name="cabieStatus"
                                className={!status.cabieStatusValid && "error"}
                                type="text"
                                value={status.cabieStatus}
                                required
                                disabled={true}
                            />
                        </div>
                        <div className="form-field">
                            <Input
                                label="Billable Customer"
                                name="billableCustomer"
                                type="text"
                                className={!status.billableCustomerValid && "error"}
                                value={status.billableCustomer}
                                required
                                disabled={true}
                            />
                        </div>
                        <div className="form-field">
                            <Input
                                label="CM8 Approved"
                                name="customaiteApproved"
                                className={!status.cm8ApprovedValid && "warning"}
                                type="text"
                                value={status.cm8Approved}
                                required
                                disabled={true}
                            />
                        </div>
                        <div className="form-field">
                            <Input
                                label="DB Status"
                                name="dbStatus"
                                type="text"
                                className={!status.dbStatusValid && "error"}
                                value={status.dbStatus}
                                required
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex pb-3 px-3 w-100 justify-content-between">
                <button className="epo-continue-button" style={{ fontSize: "1rem", width: "auto", padding: "0.25rem 1.45rem" }} type="button" onClick={() => {cancelAction()}}>Cancel</button>
                <button className="blue-button-newload" style={{ fontSize: "1rem", width: "auto", padding: "0.25rem 1.45rem" }} disabled={!customerIsCM8Valid} type="button" onClick={createLoad}>Create Load</button>
            </div>
        </section>
    );
}

export default CM8CustomerValidation;