import React, { createContext, useState, useEffect } from 'react'

export const StoreContext = createContext(null)

const StoreProvider = ({ children }) => {
    const [storeTest, setStoreTest] = useState('Gethin') 
    const [requestFailed, setRequestFailed] = useState(false);
    const [saved, setSaved] = useState('');
    const [selectName, setSelectName] = useState('');
    const [selectFcl, setSelectFcl] = useState('');
    const [truck, setTruck] = useState('');
    const [trailer, setTrailer] = useState('');
    const [shipStatus, setShipStatus] = useState('');
    const [date, setdate] = useState('');
    const [jobRef, setJobRef] = useState('');
    const [consignor, setConsignor] = useState('');
    const [consignee, setConsignee] = useState('');
    const [consignorRPA, setConsignorRPA] = useState('');
    const [consigneeRPA, setConsigneeRPA] = useState('');
    const [invoicenumber, setInvoicenumber] = useState('');
    const [invoicecurr, setInvoicecurr] = useState('');
    const [directionLoading, setDirectionLoading] = useState(true);
    const [direction, setDirection] = useState('');
    const [despatch, setDespatch] = useState('');
    const [destination, setDestination] = useState('');
    const [directionSelected, setDirectionSelected] = useState('');
    const [destinationSelected, setDestinationSelected] = useState('');
    const [despatchSelected, setDespatchSelected] = useState('');
    const [despatchLoading, setDespatchLoading] = useState(true);
    const [destinationLoading, setDestinationLoading] = useState(true);
    const [exitLoading, setExitLoading] = useState(true);
    const [entryLoading, setEntryLoading] = useState(true);
    const [incotermLoading, setIncotermLoading] = useState(true);
    const [exit, setExit] = useState('');
    const [entry, setEntry] = useState('');
    const [exit2, setExit2] = useState('');
    const [entry2, setEntry2] = useState('');
    const [exitSelected, setExitSelected] = useState('');
    const [entrySelected, setEntrySelected] = useState('');
    const [exitSelected2, setExit2Selected] = useState('');
    const [entrySelected2, setEntry2Selected] = useState('');
    const [details, setDetails] = useState([{ "": "" }]);
    const [exitPortProcess, setExitPortProcess] = useState([{ "": "" }]);
    const [entryPortProcess, setEntryPortProcess] = useState([{ "": "" }]);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [selectincoterm, setSelectincoterm] = useState('');
    const [incotermSelected, setIncotermSelected] = useState('');
    const [incotermtext, setIncotermtext] = useState([{ "": "" }]);
    const [incotermLocation, setIncotermLocation] = useState('');
    const [products, setProducts] = useState([{ "": "" }]);
    const [selectedProduct1, setSelectedProduct1] = useState();
    const [selectedProduct2, setSelectedProduct2] = useState();
    const [selectedProduct3, setSelectedProduct3] = useState();
    const [productsLoading, setProductsLoading] = useState(true);
    const [formRows, setFormRows] = useState([]);
    const [namesOptions, setNamesOptions] = useState([]);
    const [neeOptions, setNeeOptions] = useState([]);
    const [norOptions, setNorOptions] = useState([]);
    const [loadTypeOptions, setLoadTypeOptions] = useState([]);
    const [shippingStatusOptions, setShippingStatusOptions] = useState([]);
    const [movementOptions, setMovementOptions] = useState([]);
    const [despatchCountryOptions, setDespatchCountryOptions] = useState([]);
    const [entryCountryOptions, setEntryCountryOptions] = useState([]);
    const [dualCountryOptions, setDualCountryOptions] = useState([]);
    const [exitPortOptions, setExitPortOptions] = useState([]);
    const [entryPortOptions, setEntryPortOptions] = useState([]);
    const [reportOptions, setReportOptions] = useState([]);
    const [reportParams, setReportParams] = useState([]);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [toFlagOptions, setToFlagOptions] = useState([]);
    const [fromFlagOptions, setFromFlagOptions] = useState([]);
    const [lockDirection, setLockDirection] = useState(false);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [incotermsOptions, setIncotermsOptions] = useState([]);
    const [orderFormMovementDetails, setOrderFormMovementDetails] = useState([]);
    const [validatedRowsReadyToSubmit, setValidatedRowsReadyToSubmit] = useState([]);
    const [officeOfDest, setOfficeOfDest] = useState('');
    const [gridItems, setGridItems] = useState([{ "": "" }])
    const [newLoadFromFile, setNewLoadFromFile] = useState(false);
    const [pasteNewLoad, setPasteNewLoad] = useState(null);
    const [newLoadFileData, setNewLoadFileData] = useState([]);
    const [tadEns, setTadEns] = useState(false);
    const [epoProgress, setEpoProgress] = useState(true);
    const [imo, setImo] = useState('');
    const [exOrImport, setExOrImport] = useState('');
    const [trailerSeal, setTrailerSeal] = useState('');
    const [newLoadFileEIDR, setNewLoadFileEIDR] = useState(false);
    const [infoText, setInfoText] = useState("");
    const [isEidr, setIsEidr] = useState(false);
    const [eidrID, setEidrID] = useState('');
    const [controlledGoods, setControlledGoods] = useState('');
    const [container, setContainer] = useState("");
    const [GBimport, setGBimport] = useState(false);
    const [GBexport, setGBexport] = useState(false);
    const [RowImport, setRowImport] = useState(false);
    const [RowExport, setRowExport] = useState(false);
    const [portArrivalDate, setPortArrivalDate] = useState('');
    const [portArrivalTime, setPortArrivalTime] = useState('');
    const [loadItems, setLoadItems] = useState([{ "": "" }])
    const [importMixedPortsModal, setImportMixedPortsModal] = useState('');
    const [exportMixedPortsModal, setExportMixedPortsModal] = useState('');
    const [portsModalOptions, setPortsModalOptions] = useState([]);
    const [portsModal, setPortsModal] = useState('');
    const [exitPortDefault, setExitportdefault] = useState(false);
    const [entryPortDefault, setEntryportdefault] = useState('');
    const [loadId, setLoadId] = useState('');
    const [docTypes, setDocTypes] = useState([]);
    const [adjustments, setAdjustments] = useState([
        {name: "freightCharges",
        display: "Freight Charges",
        amount: "",
        currency: "",
        required: false},
        {name: "insuranceCharges",
        display: "Insurance Charges",
        amount: "",
        currency: "",
        required: false},
        {name: "vatAdjustment",
        display: "VAT Adjustment",
        amount: "",
        currency: "",
        required: false},
        {name: "otherCharges",
        display: "Other Additions",
        amount: "",
        currency: "",
        required: false},
        {name: "invoiceDiscount",
        display: "Other Deductions",
        amount: "",
        currency: "",
        required: false}
    ])
    const [VatOptions, setVatOptions] = useState([])
    const [PreferentialOptions, setPreferentialOptions] = useState([])

    const [newLoadID, setNewLoadID] = useState();
    const [clientID, setClientID] = useState(null);
    const [declarant, setDeclarant] = useState(null);
    const [clientIDoptions, setClientIDoptions] = useState([])
    const [clientIDerror, setClientIDerror] = useState(null);
    const [clientIDname, setClientIDname] = useState('');
    const [countryCodes, setCountryCodes] = useState([]);
    const [sdiSubmitted, setSdiSubmitted] = useState(0);
    const [sfdSubmitted, setSfdSubmitted] = useState(0);
    const [eadSubmitted, setEadSubmitted] = useState(0);
    const [epoJobRef, setEpoJobRef] = useState("");
    const [showWebinars, setShowWebinars] = useState(false);
    const [userIdSelected, setUserIdSelected] = useState(0);
    const [userReportIdSelected, setUserReportIdSelected] = useState(0);
    const [reportSelected, setReportSelected] = useState('');
    const [reportCreatedStartDate, setReportCreatedStartDate] = useState('');
    const [reportCreatedEndDate, setReportCreatedEndDate] = useState('');
    const [reportCompletedStartDate, setReportCompletedStartDate] = useState('');
    const [reportCompletedEndDate, setReportCompletedEndDate] = useState('');

    // const [reportCreatedStartDate1, setReportCreatedStartDate1] = useState('undefined');
    // const [reportCreatedEndDate1, setReportCreatedEndDate1] = useState('undefined');
    // const [reportCompletedStartDate1, setReportCompletedStartDate1] = useState('undefined');
    // const [reportCompletedEndDate1, setReportCompletedEndDate1] = useState('undefined');

    const [reportDepartment, setReportDepartment] = useState('');
    const [reportCustomerDropdown, setReportCustomerDropdown] = useState([]);
    const [reportUser, setReportUser] = useState('');
    const [reportCustomer, setReportCustomer] = useState('');
    const [stepTitleColor, setStepTitleColor] = useState("light");
    const [epoImport, setEpoImport] = useState();
    const [epoDirection, setEpoDirection] = useState(2);
    const [showAndFormatMainPageText, setShowAndFormatMainPageText] = useState(false);
    const [isWLP, setIsWLP] = useState(false);
    const [wlpName, setWlpName] = useState(false);
    const [packageTotal, setPackageTotal] = useState(0);
    const [customPackageTotal, setCustomPackageTotal] = useState(0);
    const [EPOPersist, setEPOPersist] = useState({})

    const[ffd, setFfd] = useState(false);

    const resetUserIdSelected = () =>{
        setUserIdSelected(0);
    }

    const resetUserReportIdSelected = () =>{
        setUserReportIdSelected(0);
    }

    const resetNewLoad = () => {
        setDirectionSelected('');
        setTrailerSeal('')
        setSelectName('')
        setExitPortProcess('')
        setEntryCountryOptions([]);
        setEntryPortProcess('')
        setExit('');
        setDespatchCountryOptions([]);
        setEntry('')
        setSelectName('')
        setSelectFcl('')
        setTruck('')
        setIncotermLocation('')
        setdate('')
        setDespatch('')
        setDestination('')
        setExitSelected('')
        setExit2Selected('')
        setEntrySelected('')
        setEntry2Selected('')
        setConsignor('')
        setJobRef('')
        setConsignee('')
        setConsigneeRPA('');
        setConsignorRPA('');
        setInvoicenumber('')
        setInvoicecurr('')
        setSelectincoterm('')
        setShipStatus('')
        setTrailer('')
        setFormRows([])
        setOfficeOfDest('');
        setPasteNewLoad(null);
        setValidatedRowsReadyToSubmit([]);
        setGridItems([]);
        setNewLoadFromFile(false);
        setTadEns(false);
        setImo('');
        setIsEidr(false);
        setEidrID('');
        setControlledGoods(false);
        setContainer('');
        setGBimport(false);
        setGBexport(false);
        setExportMixedPortsModal('');
        setImportMixedPortsModal('');
        setPortsModalOptions([]);
        setPortsModal('');
        setExitportdefault('');
        setEntryportdefault('');
        setLoadId('')
        setDocTypes([])
        setPackageTotal(0);
        setCustomPackageTotal(0);
        setAdjustments([
            {name: "freightCharges",
            display: "Freight Charges",
            amount: "",
            currency: "",
            required: false},
            {name: "insuranceCharges",
            display: "Insurance Charges",
            amount: "",
            currency: "",
            required: false},
            {name: "vatAdjustment",
            display: "VAT Adjustment",
            amount: "",
            currency: "",
            required: false},
            {name: "otherCharges",
            display: "Other Additions",
            amount: "",
            currency: "",
            required: false},
            {name: "invoiceDiscount",
            display: "Other Deductions",
            amount: "",
            currency: "",
            required: false}
        ])
        setClientID(null);
        setClientIDerror(null);
        setClientIDname('');
        setDeclarant(null);
    }
    const store = {
        resetUserIdSelected,
        resetUserReportIdSelected,
        resetNewLoad,
        requestFailed: [requestFailed, setRequestFailed],
        saved: [saved, setSaved],
        selectName: [selectName, setSelectName],
        selectFcl: [selectFcl, setSelectFcl],
        truck: [truck, setTruck],
        trailer: [trailer, setTrailer],
        shipStatus: [shipStatus, setShipStatus],
        date: [date, setdate],
        jobRef: [jobRef, setJobRef],
        consignor: [consignor, setConsignor],
        consignee: [consignee, setConsignee],
        consignorRPA: [consignorRPA, setConsignorRPA],
        consigneeRPA: [consigneeRPA, setConsigneeRPA],
        invoicenumber: [invoicenumber, setInvoicenumber],
        invoicecurr: [invoicecurr, setInvoicecurr],
        directionLoading: [directionLoading, setDirectionLoading],
        direction: [direction, setDirection],
        despatch: [despatch, setDespatch],
        destination: [destination, setDestination],
        directionSelected: [directionSelected, setDirectionSelected],
        destinationSelected: [destinationSelected, setDestinationSelected],
        despatchSelected: [despatchSelected, setDespatchSelected],
        despatchLoading: [despatchLoading, setDespatchLoading],
        destinationLoading: [destinationLoading, setDestinationLoading],
        exitLoading: [exitLoading, setExitLoading],
        entryLoading: [entryLoading, setEntryLoading],
        incotermLoading: [incotermLoading, setIncotermLoading],
        exit: [exit, setExit],
        entry: [entry, setEntry],
        exit2: [exit2, setExit2],
        entry2: [entry2, setEntry2],
        exitSelected: [exitSelected, setExitSelected],
        entrySelected: [entrySelected, setEntrySelected],
        exitSelected2: [exitSelected2, setExit2Selected],
        entrySelected2: [entrySelected2, setEntry2Selected],
        details: [details, setDetails],
        exitPortProcess: [exitPortProcess, setExitPortProcess],
        entryPortProcess: [entryPortProcess, setEntryPortProcess],
        detailsLoading: [detailsLoading, setDetailsLoading],
        selectincoterm: [selectincoterm, setSelectincoterm],
        incotermSelected: [incotermSelected, setIncotermSelected],
        incotermtext: [incotermtext, setIncotermtext],
        incotermLocation: [incotermLocation, setIncotermLocation],
        products: [products, setProducts],
        selectedProduct1: [selectedProduct1, setSelectedProduct1],
        selectedProduct2: [selectedProduct2, setSelectedProduct2],
        selectedProduct3: [selectedProduct3, setSelectedProduct3],
        productsLoading: [productsLoading, setProductsLoading],
        storeTest: [storeTest, setStoreTest],
        formRows: [formRows, setFormRows],
        namesOptions: [namesOptions, setNamesOptions],
        neeOptions: [neeOptions, setNeeOptions],
        norOptions: [norOptions, setNorOptions],
        loadTypeOptions: [loadTypeOptions, setLoadTypeOptions],
        shippingStatusOptions: [shippingStatusOptions, setShippingStatusOptions],
        movementOptions: [movementOptions, setMovementOptions],
        despatchCountryOptions: [despatchCountryOptions, setDespatchCountryOptions],
        entryCountryOptions: [entryCountryOptions, setEntryCountryOptions],
        dualCountryOptions: [dualCountryOptions, setDualCountryOptions],
        exitPortOptions: [exitPortOptions, setExitPortOptions],
        entryPortOptions: [entryPortOptions, setEntryPortOptions],
        reportOptions: [reportOptions, setReportOptions],
        reportParams: [reportParams, setReportParams],
        rolesOptions: [rolesOptions, setRolesOptions],
        userOptions: [userOptions, setUserOptions],
        currencyOptions: [currencyOptions, setCurrencyOptions],
        toFlagOptions: [toFlagOptions, setToFlagOptions],
        fromFlagOptions: [fromFlagOptions, setFromFlagOptions],
        exOrImport: [exOrImport, setExOrImport],
        lockDirection: [lockDirection, setLockDirection],
        incotermsOptions: [incotermsOptions, setIncotermsOptions],
        orderFormMovementDetails: [orderFormMovementDetails, setOrderFormMovementDetails],
        validatedRowsReadyToSubmit: [validatedRowsReadyToSubmit, setValidatedRowsReadyToSubmit],
        officeOfDest: [officeOfDest, setOfficeOfDest],
        gridItems: [gridItems, setGridItems],
        newLoadFromFile: [newLoadFromFile, setNewLoadFromFile],
        pasteNewLoad: [pasteNewLoad, setPasteNewLoad],
        newLoadFileData: [newLoadFileData, setNewLoadFileData],
        tadEns: [tadEns, setTadEns],
        epoProgress: [epoProgress, setEpoProgress],
        imo: [imo, setImo],
        newLoadFileEIDR: [newLoadFileEIDR, setNewLoadFileEIDR],
        infoText: [infoText, setInfoText],
        isEidr: [isEidr, setIsEidr],
        eidrID: [eidrID, setEidrID],
        controlledGoods: [controlledGoods, setControlledGoods],
        container: [container, setContainer],
        GBimport: [GBimport, setGBimport],
        GBexport: [GBexport, setGBexport],
        RowExport: [RowExport, setRowExport],
        RowImport: [RowImport, setRowImport],
        portArrivalDate: [portArrivalDate, setPortArrivalDate],
        portArrivalTime: [portArrivalTime, setPortArrivalTime],
        loadItems: [loadItems, setLoadItems],
        exportMixedPortsModal: [exportMixedPortsModal, setExportMixedPortsModal],
        importMixedPortsModal: [importMixedPortsModal, setImportMixedPortsModal],
        portsModal: [portsModal, setPortsModal],
        portsModalOptions: [portsModalOptions, setPortsModalOptions],
        exitPortDefault: [exitPortDefault, setExitportdefault],
        entryPortDefault: [entryPortDefault, setEntryportdefault],
        loadId: [loadId, setLoadId],
        docTypes: [docTypes, setDocTypes],
        adjustments: [adjustments, setAdjustments],
        newLoadID: [newLoadID, setNewLoadID],
        VatOptions: [VatOptions, setVatOptions],
        PreferentialOptions: [PreferentialOptions, setPreferentialOptions],
        clientID: [clientID, setClientID],
        clientIDoptions: [clientIDoptions, setClientIDoptions],
        clientIDerror: [clientIDerror, setClientIDerror],
        clientIDname: [clientIDname, setClientIDname],
        declarant: [declarant, setDeclarant],
        countryCodes: [countryCodes, setCountryCodes],
        sdiSubmitted: [sdiSubmitted, setSdiSubmitted],
        sfdSubmitted: [sfdSubmitted, setSfdSubmitted],
        eadSubmitted: [eadSubmitted, setEadSubmitted],
        epoJobRef: [epoJobRef, setEpoJobRef],
        showWebinars: [showWebinars, setShowWebinars], 
        userIdSelected: [userIdSelected, setUserIdSelected],
        userReportIdSelected: [userReportIdSelected, setUserReportIdSelected],
        reportSelected: [reportSelected, setReportSelected],
        reportCreatedStartDate: [reportCreatedStartDate, setReportCreatedStartDate],
        reportCreatedEndDate: [reportCreatedEndDate, setReportCreatedEndDate],
        reportCompletedStartDate: [reportCompletedStartDate, setReportCompletedStartDate],
        reportCompletedEndDate: [reportCompletedEndDate, setReportCompletedEndDate],
        reportDepartment: [reportDepartment, setReportDepartment],
        reportCustomerDropdown: [reportCustomerDropdown, setReportCustomerDropdown],
        reportUser: [reportUser, setReportUser],
        reportCustomer: [reportCustomer, setReportCustomer],
        stepTitleColor: [stepTitleColor, setStepTitleColor],
        epoImport: [epoImport, setEpoImport],
        epoDirection: [epoDirection, setEpoDirection],
        showAndFormatMainPageText: [showAndFormatMainPageText, setShowAndFormatMainPageText],
        isWLP: [isWLP, setIsWLP],
        wlpName: [wlpName, setWlpName],
        packageTotal: [packageTotal, setPackageTotal],
        customPackageTotal: [customPackageTotal, setCustomPackageTotal],
        EPOPersist: [EPOPersist, setEPOPersist],
        trailerSeal: [trailerSeal, setTrailerSeal],
        ffd: [ffd, setFfd]
    }

    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export default StoreProvider
