import { useLocation } from 'react-router-dom'
import { useEffect, useState, createContext } from 'react'
import { getGATrackingCode } from './apiCalls'

export const GoogleAnalyticsContext = createContext(null)

function GoogleAnalyticsProvider({children}) {
    const [gaInitialized, setGaInitialized] = useState(false);
    const [gaTrackingCode, setGaTrackingCode] = useState();
    const location = useLocation();
  
    useEffect(() => {
      const f1 = async () => {
        const results = await getGATrackingCode();
        setGaTrackingCode(results.trackingCode);
      }
      f1();
    }, [])

    useEffect(() => {
      if (gaTrackingCode) {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingCode}`
        document.body.appendChild(script1);
  
        const script2 = document.createElement('script');
        script2.async = true;
        script2.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        window.gtag = gtag;
        gtag('js', new Date());
      
        gtag('config', '${gaTrackingCode}', {
          cookie_flags: 'SameSite=None;Secure'
        });`
        document.body.appendChild(script2);
        setGaInitialized(true);
      }

    }, [gaTrackingCode])
  
    useEffect(() => {
      if (gaInitialized) {
        window.gtag('config', gaTrackingCode, {
          page_title: location.pathname + location.search,
          page_path: location.pathname + location.search
        })
      }
    }, [gaInitialized, location]);

    const store = {
      gaInitialized
    }
    return <GoogleAnalyticsContext.Provider value={store}>{children}</GoogleAnalyticsContext.Provider>;
  }

  export default GoogleAnalyticsProvider