import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import BusinessPartnersList from "./BusinessPartnersList";
import BusinessPartnerCard from "./BusinessPartnerCard";

const BusinessPartners = () => {
  const [requestFailed, setRequestFailed] = useState("");
  const [selectedNeenor, setSelectedNeenor] = useState(false);
  const [companyid, setcompanyid] = useState(null);
  const [editCreate, setEditCreate] = useState();
  const [fromCM8, setFromCM8] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //check if the url contains an id parameter
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get("id");
    const companyid = urlParams.get("companyid");
    if (id) {
      setSelectedNeenor(id);
      setcompanyid(companyid);
      // remove the id from the url
      window.history.replaceState({}, document.title, window.location.pathname);
      setFromCM8(true);
    }
  },[location]);

  const handleBackButton = () => {

    if (fromCM8) {
      navigate('/CM8CustomerValidation/' + companyid);
    } else {
    setSelectedNeenor(false);
    }
  }


  const [neeNors, setNeeNors] = useState([
  ]);

  if (requestFailed) return <p>Failed!</p>;
  console.log('selected neenor', selectedNeenor)
  console.log('selected neenor', !selectedNeenor)
  return (
    <>
    {selectedNeenor === false ? 
      <>
      <BusinessPartnersList
        neeNors={neeNors}
        setNeeNor={setSelectedNeenor}
        setNeeNors={setNeeNors}
        setEditCreate={setEditCreate}
        origin="bp"
      />
      </>
      : 
      <BusinessPartnerCard id={selectedNeenor} back={() => handleBackButton()} editCreate={editCreate} setNeeNor={setSelectedNeenor} />
      }
    </>
  );
};

export default BusinessPartners;
