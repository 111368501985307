import { useState, useEffect, useRef, useContext } from "react";
import { PreferentialTooltip } from "../../Newload/ProductInfo/Tooltips";
import { useSecurity } from "../../Security/security";
import Stepper, { useStepper } from "../../Stepper";
import { StoreContext } from "../../Store";
import rowflag from "../assets/row.png";
import { GoogleAnalyticsContext } from "../../GoogleAnalytics";

export function PreferentialOrigin () {
  const { stepBackward, localData, setDataByKey, activeStepKey, unvisitStep, gotoStep} = useStepper();
  const store = useContext(StoreContext);

  const handleChange = (e) => {
    setDataByKey("pref", e.target.value);
    if(!localData.euPrefSelected){
      setDataByKey("euPref", e.target.value);
    }
  };

  const nextPage = () => {
    if (localData.pref === null) {
      return;
    }
    unvisitStep("eu_origin");
    gotoStep("3. Entry Port");
    //stepForward();
  };

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext);
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "Preferential Origin" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "1. PrefOrigin",{
          event_category: "EPO"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized]);

  return (
    <Stepper.Step  hideUntilActive={true} hideUnlessVisited={true} text="Preferential Origin" stepKey="Preferential Origin">

<div className={`mb-3 ${store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}`}  style={{fontSize:"1.8rem", fontWeight:"400"}}>Preferential Origin</div>
<div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"}>
  <label className="d-inline-flex align-items-center gap-2 mb-2" style={{ marginBottom: '10px', marginRight: '86px'}}>
    <input type="radio" disabled checked={localData.movementType === "Import"}/>
    <span>Import</span>
  </label>

  <label className="d-inline-flex align-items-center gap-2" style={{ marginBottom: '10px'}}>
    <input type="radio" disabled checked={localData.movementType === "Export"} />
    <span>Export</span>
  </label>
</div>
      <div className=' px-2 mt-1 pt-2 d-flex flex-column' style={{borderRadius: '0.9rem', background: 'rgba(255, 255, 255)', minHeight: '485px'}}>
        
        <div className="d-flex step-banner justify-content-center" >
        <div className="d-flex justify-content-center" style={{ borderRadius: '15px', marginTop: "34px", marginBottom: "34px", backgroundColor:'#184795', }}>

             <img src={rowflag} width="110" height="110" alt="" className="" style={{ borderRadius: '15px', marginTop: "10px", marginBottom: "10px", marginRight:"48px", marginLeft:"43px" }}/>
             </div>

           </div>

        <div class="d-flex flex-column  align-self-center mt-auto" style={{maxWidth: "390px"}}>
            <span className="d-flex align-self-center" style={{fontWeight: "500"}}>Do all your invoice items have preferential origin?</span>
          <div className="d-flex align-self-center" style={{fontWeight: "500"}}>
            <span>Click&nbsp;
                <a
                  href="https://www.eori.uk/origin"
                  target="_blank"
                  rel="noreferrer"
                >HERE 
                </a> for more information.
              </span>
              </div>
            </div>

          <label className="d-inline-flex justify-content-center mt-auto gap-3 ">
            <input type="radio" name="preforigin" value="yes" checked={localData.pref === "yes"} onChange={handleChange} />
            <span>Yes</span>
          </label>

          <label className="d-inline-flex justify-content-center gap-3 mt-2">
            <input type="radio" name="preforigin" value="no" checked={localData.pref === "no"} onChange={handleChange} />
            <span>No</span>
          </label>

      <section className="d-flex justify-content-between mt-auto pb-4 px-3">
        <button type="button" className="stepper-button red" onClick={()=>{unvisitStep("Preferential Origin");stepBackward("1. Direction");}}>
          Back
        </button>
        <button type="button" className="stepper-button" onClick={nextPage}>
          Next
        </button>
      </section>
      </div>
    </Stepper.Step>
  );
};
