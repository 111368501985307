import { useEffect, useState, useRef, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { getNewLoad } from "../apiCalls";
import { Input, Select, InputSelect, FilterableSelect } from "../Input/Input";
import { useStepper } from "../Stepper";
import creditcard from "../Epo/assets/credit-card.png";
import { GoogleAnalyticsContext } from "../GoogleAnalytics";
import { StoreContext } from "../Store";

export default function FinanceInformation() {
  const { stepBackward, stepForward, localData, setDataByKey, gotoStep, activeStepKey } = useStepper();

  const store = useContext(StoreContext)
  const [errors, setErrors] = useState({});

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)

  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "addr3" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "2.2 InvoiceAddressDetails",{
          event_category: "Registration"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  const handleNext = async (e) => {
    e.preventDefault();
    const freshErrors = {};

    if (!localData.building3) freshErrors.building3 = "Required";
    if (!localData.street3) freshErrors.street3 = "Required";
    if (!localData.town3) freshErrors.town3 = "Required";
    if (!localData.postcode3) freshErrors.postcode3 = "Required";
    if (!localData.country3) freshErrors.country3 = "Required";
    
    setErrors(freshErrors);
    if (!Object.values(freshErrors).some(err => !!err)) {
      stepForward();
    }
  };

  const handleBack = () => {
    if (localData.sameAddress) {
      gotoStep('addr');
    } else {
      stepBackward();
    }
  }

  useEffect(() => {
    if (localData.sameInvoiceAddr)  {
      setDataByKey('building3', localData.building);
      setDataByKey('street3', localData.street);
      setDataByKey('town3', localData.town);
      setDataByKey('county3', localData.county);
      setDataByKey('postcode3', localData.postcode);
      setDataByKey('country3', localData.country);
    } else {
      setDataByKey('building3', '');
      setDataByKey('street3', '');
      setDataByKey('town3', '');
      setDataByKey('county3', '');
      setDataByKey('postcode3', '');
      setDataByKey('country3', '');
    }
  }, [localData.sameInvoiceAddr, localData.building, localData.street, localData.town, localData.county, localData.postcode, localData.country])

  return (
    <form onSubmit={handleNext} className="flex-grow-1 d-flex flex-column">
      <div>
      <span className="register-step-header">Finance Information</span>
        <div className="inputs-group register-info">
          <img src={creditcard} width="70" height="70" alt="" />
          <span>
            If your invoicing address is different from your trading address please enter it below. This could be your accountant's address or your own finance department's address.
          </span>
        </div>
        <Row className="py-2">
          <div className="d-flex justify-content-start mt-2">
            <label className="d-flex align-items-center gap-1 label-text">
              <input type="checkbox" checked={!!localData.sameInvoiceAddr} onChange={e => {setDataByKey('sameInvoiceAddr', e.target.checked); }} />
              <span>My invoicing address is the same as my trading address</span>
            </label>
          </div>
        </Row>

        <Row className="py-2">
          <Col>
            <Input label={<>Building name/number <span style={{ fontSize: "120%" }}>*</span></>} error={errors.building3} disabled={localData.sameInvoiceAddr} value={localData.building3} onChange={(e) => setDataByKey('building3', e.target.value)} />
          </Col>
          <Col>
            <Input label={<>Street <span style={{ fontSize: "120%" }}>*</span></>}  error={errors.street3} disabled={localData.sameInvoiceAddr} value={localData.street3} onChange={(e) => setDataByKey('street3', e.target.value)} />
          </Col>

        </Row>
        <Row className="py-2">
          <Col>
            <Input label={<>Town/City <span style={{ fontSize: "120%" }}>*</span></>} error={errors.town3} disabled={localData.sameInvoiceAddr} value={localData.town3} onChange={(e) => setDataByKey('town3', e.target.value)} />
          </Col>
          <Col>
            <Input label="County"  error={errors.county3} disabled={localData.sameInvoiceAddr} value={localData.county3} onChange={(e) => setDataByKey('county3', e.target.value)} />
          </Col>
        </Row>

        <Row className="py-2">
          <Col>
            <Input label={<>Postcode <span style={{ fontSize: "120%" }}>*</span></>} error={errors.postcode3} disabled={localData.sameInvoiceAddr} value={localData.postcode3} onChange={(e) => setDataByKey('postcode3', e.target.value)} />
          </Col>
          <Col>
            <FilterableSelect
              label={<>Country <span style={{ fontSize: "120%" }}>*</span></>}
              disabled={localData.sameInvoiceAddr}
              data={localData.country3}
              setData={(data) => setDataByKey('country3', data)}
              options={store.countryCodes[0]}
              error={errors.country3}
            />
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1" />
      <section className="d-flex justify-content-between mt-3">
        <button type="button" className="blue-button-teams green-btn" onClick={handleBack}>
          Back
        </button>
        <span className="py-1 text-danger">{Object.values(errors).some(err => !!err) && "Some errors were found, please correct above."}&nbsp;</span>
        <button type="submit" className="blue-button-teams green-btn">
          Next
        </button>
      </section>
    </form>
  );
}
