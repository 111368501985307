import { useEffect, useState, useContext, useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Input } from "../Input/Input";

export const UpdateEtaModal = ({ show, onHide, onConfirm, newArrivalDate, estimatedDateOfLoading, isImport }) => {
  
    const [errorText, setErrorText] = useState("");
  
    const [arrivalDate, setArrivalDate] = useState();

    const dateInputRef = useRef(null);

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); 
      const day = String(today.getDate()).padStart(2, '0');  
      return `${year}-${month}-${day}`;
    }
  
    useEffect(() => {
      setArrivalDate(newArrivalDate ?? estimatedDateOfLoading);
    }, [newArrivalDate, estimatedDateOfLoading]);
 
    const validateDateTime = () => {
      const selectedDT = new Date(`${arrivalDate}`);
  
      if (isNaN(selectedDT)) {
        setErrorText("Please select a valid date.");
        return false;
      }
  
      return true;
    };
  
    const tz = useMemo(() => {
      let mins = -(new Date()).getTimezoneOffset();
      const isNegative = mins < 0;
      mins = Math.abs(mins);
      let hours = Math.floor(mins / 60);
      mins = mins % 60;
  
      return `GMT${isNegative ? '-' : '+'}${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
    }, []);
  
    const newDateDiff = useMemo(() => {
      //const now = new Date();
      const newDate = new Date(arrivalDate);
      const estimatedDate =  new Date(estimatedDateOfLoading);    
      if (isNaN(newDate)) return 0;
      
      const diffDays = Math.floor(newDate.valueOf() / ((1000 * 60) * 60 * 24)) - Math.floor(estimatedDate.valueOf() / ((1000 * 60) * 60 * 24));

      return diffDays;
    }, [arrivalDate]);

    return (
      <Modal centered size="md" animation={false} show={show} onHide={() => null}>
        <Modal.Header>
          <Modal.Title> { isImport ? "Amend Arrival Date" : "Amend Exit Date"}</Modal.Title>
          <button type="button" class="btn-close" aria-label="Close" onClick={onHide} />
        </Modal.Header>
  
        <section>
          <Modal.Body>
            <div className="px-3">
              <div className="mb-2">{`If the estimated date of your ${isImport ? "arrival" : "exit"} has changed, please let us know as soon as possible.`}</div>
              <div><small>Please enter the below date in your <span class="boldblue">local timezone</span> ({tz}).</small></div>
              <div className="sub-heading arrival-error">{errorText}</div>
            </div>
  
            <div className="d-flex flex-row pb-3 px-3 gap-3 mt-2">
              <Input
                label={ isImport ? "Original Arrival Date" : "Original Exit Date" }
                type="date"
                value={estimatedDateOfLoading}
                disabled
              />
            </div>
  
            <div className='d-flex flex-row pb-3 px-3 gap-3'>
              <Input
                inputRef={dateInputRef}
                label={ isImport ? "New Arrival Date" : "New Exit Date" }
                type="date"
                required
                value={arrivalDate}
                onChange={(e) => setArrivalDate(e.target.value)} 
                onClick={() => dateInputRef.current.blur()}
                min = {getTodayDate()}                
              />
            </div>
  
            <div className="px-3">
              <small>
                {newDateDiff < 0 && (<span>Please note you are indicating your consignment to <span className="boldblue">{isImport ? "arrive": "exit"} earlier than estimated</span>.<br />&nbsp;</span>)}
                {newDateDiff === 0 && (<span>Please note you are indicating you are expecting your consignment to <span className="boldblue">{isImport ? "arrive": "exit"} on the estimated date</span>.</span>)}
                {newDateDiff > 0 && (<span>Please note you are indicating you are expecting your consignment to <span className="boldblue">{isImport ? "arrive": "exit"} later than estimated</span>.</span>)}
              </small>
            </div>
          </Modal.Body>
  
          <Modal.Footer
            class="py-3 px-3"
            style={{
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <div className="push-left-right">
              <button
                className="blue-button-newload red"
                onClick={(event) => {
                  onHide();
                  setErrorText("");
                }}
                style={{ display: "flex", borderRadius: "10px" }}
              >
                {" "}
                Back
              </button>
              <button
                className="blue-button-newload green"
                onClick={(event) => {
                  setErrorText("");
  
                  if (validateDateTime()) {
                    onConfirm({ date: arrivalDate });
                    onHide(event);
                  }
                }}
                style={{ display: "flex", borderRadius: "10px" }}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </section>
      </Modal>
    );
  };