import React, { useState, useEffect, createContext } from "react";
import "../userprofile.css";
import "../main.css";
import { getMasterData, putMasterData } from "../apiCalls";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LspPreCheck from "./Tabs/LspPreCheck";
import CompanyInformation from "./Tabs/CompanyInformation";
import FinanceDetails from "./Tabs/FinanceDetails";
import AddressDetails from "./Tabs/AddressDetails";
import OtherInfo from "./Tabs/OtherInfo";
import Compliance from "./Tabs/Compliance";
import { useParams } from "react-router-dom";
import { useSecurity } from "../Security/security";

export const UserProfileContext = createContext({
  companyInfo: {},
});

const UserProfile = () => {
  const navigate = useNavigate();

  const [companyInfo, setCompanyInfo] = useState({});
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  const updateCompanyInfo = (key, value) => {
    setCompanyInfo(prev => ({...prev, [key]: value}));
  }

  useEffect(() => {
    async function fetchData() {
      const data = await getMasterData("newload");
      
      if (data?.[0]) {
        let file;
        const {C1207N, C1207Ndata} = data[0];
        if (C1207N && C1207Ndata) {
          file = new File(C1207Ndata.data, C1207N);
        }
        setCompanyInfo({
          ...data[0],
          C1207N: file
        });
      } else {
        setCompanyInfo({});
      }
    }
    fetchData();
  }, []);

  const { currentTab } = useParams();
  const [tab, setTab] = useState(currentTab);
  const [complianceError, setComplianceError] = useState();
  const [companyInformationError, setCompanyInformationError] = useState();
  const [addressDetailsError, setAddressDetailsError] = useState();
  const { dispatch } = useSecurity();

  useEffect(() => {
    setTab(currentTab);
  }, [currentTab]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessText(""); 
    setErrorText("");
    setCompanyInformationError(""); 
    setAddressDetailsError("");
    
    if (companyInfo.gbEntity === null || companyInfo.companyName === null || companyInfo.formation === null ) {
      setCompanyInformationError("* Required fields must be filled in");
      setErrorText("Company information incomplete - tab highlighted");
      return;
    }

    if (companyInfo.building === null || companyInfo.street === null || companyInfo.town === null || companyInfo.postcode === null || companyInfo.country === null || companyInfo.building2 === null || companyInfo.street2 === null || companyInfo.town2 === null || companyInfo.postcode2 === null || companyInfo.country2 === null) {
      setAddressDetailsError("* Required fields must be filled in");
      setErrorText("Address details incomplete - tab highlighted");
      return;
    }

    try {
      const fd = new FormData();

      for (const [key, value] of Object.entries(companyInfo)) {
        if (!!value) {
          fd.append(key, value);
        }
      }

      await putMasterData(fd);
      dispatch({type: "ddaUploaded"})
      setSuccessText("Successfully updated");
      sessionStorage.setItem('reg', 'true')
      navigate("/main");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <UserProfileContext.Provider value={{ companyInfo, updateCompanyInfo, setComplianceError, complianceError, addressDetailsError, companyInformationError }}>
      <div className="full-width-container">
        <section className="floating-box" style={{ maxWidth: "980px" }}>
        <div className="floating-box-header-newload">
            <span>Company Information</span>
          </div>
          <Tabs activeKey={tab} onSelect={(newTab) => {
            navigate(`/Userprofile/${newTab}`);
          }}>
          
          {(sessionStorage.getItem('reg') === 'false') && (sessionStorage.getItem('type') === 'LSP') && (
          <Tab title={<>Registration Guide</>} eventKey="reg-guide">
              <LspPreCheck />
            </Tab>)}

            <Tab title={<>Company Information {companyInformationError && <span className="warning" style={{ fontSize: "0.7rem" }} ></span>}</>} eventKey="company-info">
              <CompanyInformation />
            </Tab>

            <Tab title={<>Address Details {addressDetailsError && <span className="warning" style={{ fontSize: "0.7rem" }} ></span>}</>} eventKey="address">
              <AddressDetails />
            </Tab>
          
             {(sessionStorage.getItem('reg') === 'true') && (sessionStorage.getItem('type') !== 'LSP') && (
            <Tab title="Finance Details" eventKey="finance">
              <FinanceDetails />
            </Tab> )}

            {(sessionStorage.getItem('reg') === 'true') && (sessionStorage.getItem('type') !== 'LSP') && (
            <Tab title={<>Customs &amp; Compliance {complianceError && <span className="warning" style={{ fontSize: "0.7rem" }} ></span>}</>} eventKey="compliance">
              <Compliance />
            </Tab>)}

            {(sessionStorage.getItem('reg') === 'true') && (sessionStorage.getItem('type') !== 'LSP') && (
            <Tab title="Other Information" eventKey="other">
              <OtherInfo />
            </Tab>)}

          </Tabs>
          <div
            class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
            style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
          >
              {(sessionStorage.getItem('type') !== 'LSP') && (
            <button type="button" class="cancel-button" onClick={() => navigate('/EpoListLoads')} style={{ display: "flex", borderRadius: "10px" }}>
              Back
            </button>)}
            <div>
              <span className="alert-success me-3">{successText}</span>
              <span className="red-text me-3">{errorText}</span>
              <button  type="button" class="blue-button-teams green-btn save-btn" style={{ borderRadius: "10px" }} onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </section>
      </div>
    </UserProfileContext.Provider>
  );
};

export default UserProfile;
