import { useEffect, useState, useRef, useContext } from "react";
import { useStepper } from "../Stepper";
import { GoogleAnalyticsContext } from "../GoogleAnalytics";
import { StoreContext } from "../Store"

export default function PreCheck() {
  const { stepForward, localData, setDataByKey, activeStepKey } = useStepper();

  const [errors, setErrors] = useState({});
  const ga = useContext(GoogleAnalyticsContext)
  const store = useContext(StoreContext);
  const isInitialized = useRef(false);
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "precheck" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "0. PreCheck",{
          event_category: "Registration"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  const handleNext = async (e) => {
    e.preventDefault();
    const freshErrors = {};
    if (!localData.applicationOnBehalf) freshErrors.applicationOnBehalf = "You must confirm you're able to complete this application on behalf of your business to continue.";
    if (!localData.termsAndConditions) freshErrors.termsAndConditions = "You must accept the terms and conditions to continue.";
    if (!localData.jobsOnBehalf && store.isWLP[0]) freshErrors.jobsOnBehalf = "You must confirm " + store.wlpName[0] + " are authorised to submit jobs on behalf of your business to continue.";

    setErrors(freshErrors);
    if (!Object.values(freshErrors).some(err => !!err)) {
      //setStepListVisible(true);
      //stepListVisible();
      stepForward();
    }
  };

  return (
    <>
      <div>
        <h3 className="text-eoriblue mb-4">Application Guide</h3>
      </div>

      <div className="inputs-group p-4">
        <div>
        You will be asked to provide information about your business to set up an account for commercial use. 

        It is important that you are authorised to do this on behalf of your business or employer.

        As a guide, you will require the following information to complete this stage of the application process.

        </div>

          <h5 className="d-flex pt-2">Company information</h5>
        <div className="d-flex pt-2">
          <ol className="flex-grow-1">
            <li className="text-eoriblue" style={{fontWeight: '500'}}>
            Company registration details and VAT number (if applicable)
            </li>
            <li className="text-eoriblue" style={{fontWeight: '500'}}>
            Company trading address and registered address if this is not the same location.
            </li>
            <li className="text-eoriblue" style={{fontWeight: '500'}}>
            Details about yourself to allow us to contact you if we need further information.
            </li>
          </ol>
        </div>

        <h5 className="d-flex pt-2">Finance information</h5>
        <div className="pt-1">
        To confirm where you require us to send your invoices and who to contact          
        </div>

        <h5 className="d-flex pt-2">Customs information</h5>
        This provides us with a minimum customs profile to create your CABIE unique ID
        <div className="d-flex pt-2">
          <ul className="flex-grow-1">
            <li className="text-eoriblue" style={{fontWeight: '500'}}>
            EORI Number
            </li>
          </ul>
        </div>
        {/* <p>There is a facility to upload a completed HMRC form C1207N to provide EORI (UK) Limited with authority to use your DDA as the method of payment for all import taxes except VAT.</p> */}
        <p>Finally, we will require a signed authorisation to act on your behalf as DIRECT Representatives when you open an account with us.</p>
        <p>This is part of the automated sign-up process.</p>
        <p>If you specifically require your VAT liability paid outright to HMRC at the time of importation, please discuss this with our customer onboarding team once your account is established.</p>

      <div className="pt-2 mt-1">
        <h5>Please confirm</h5>
        <label className="d-flex align-items-center gap-1">
          <input type="checkbox" checked={!!localData.termsAndConditions} onChange={e => setDataByKey('termsAndConditions', e.target.checked)} />
          <span style={{cursor: "pointer"}} >You have read and accept our&nbsp;</span>
          <a target="_blank" href="/assets/terms-and-conditions.pdf">Terms and Conditions</a>
        </label>
        <label className="d-flex align-items-center gap-1 mt-1">
          <input type="checkbox" checked={!!localData.applicationOnBehalf} onChange={e => setDataByKey('applicationOnBehalf', e.target.checked)} />
          <span style={{cursor: "pointer"}} >You are able to make this application on behalf of your business</span>
        </label>
        {store.isWLP[0] && (        <label className="d-flex align-items-center gap-1 mt-1">
          <input type="checkbox" checked={!!localData.jobsOnBehalf} onChange={e => setDataByKey('jobsOnBehalf', e.target.checked)} />
          <span style={{cursor: "pointer"}} >I authorise {store.wlpName[0]} to submit jobs on my behalf </span>
        </label>) }

      </div>
      </div>
      <span className="ms-auto text-danger">{errors.applicationOnBehalf}&nbsp;</span>
      <span className="ms-auto text-danger">{errors.termsAndConditions}&nbsp;</span>
      <span className="ms-auto text-danger">{errors.jobsOnBehalf}&nbsp;</span>

      <section className={"d-flex justify-content-between align-items-center"}>
        <div/>
        <span className="" style={{fontSize: '77%', color: 'gray'}}>Not quite ready to sign up or have some questions? Send us an email at <a href="mailto:CABIEsupport@eori.uk">CABIEsupport@eori.uk</a>.</span>
        <button type="button" className="blue-button-teams green-btn" disabled={!(localData.termsAndConditions && localData.applicationOnBehalf) || (!localData.jobsOnBehalf && store.isWLP[0])} onClick={handleNext}>
          Next
        </button>
    </section>
    </>
  );
}
