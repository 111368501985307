import { useContext, useState, useEffect, React } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "../Reports/DataTable1";

import {
  fetchBusinessPartnerDocumentDetails,
  fetchBusinessPartnerAvailableDocuments,
} from "./BusinessPartnerController";

// Context
import { BusinessPartnerContext } from "./BusinessPartnerCard";

const inputStyle = {
  width: "100%",
  boxSizing: "border-box",
  border: "none",
  backgroundColor: "transparent",
};
// Default Header Styles
const headerStyle = { fontSize: "12px" };
// Default Row Styles
const defaultStyle = { fontSize: '14px', width: 'auto'  };
const buttonStyle = {backgroundColor: 'transparent', border: 'none', height:'4rem', width: '4rem'}
const imageStyle = { height:'1rem', width: '1rem', marginRight:'8px' };
// Override individual row styles

const BPDocumentListModal = ({
  companyId,
  show,
  onHide,
  hideHeader,
  customerId,
  currentStep,
  isModalShowing,
  ...rest
}) => {
  
  const {
    companyName,
    eoriNumber
  } = useContext(BusinessPartnerContext);

  const [documents, setDocuments] = useState([]);

  const fetchData = async () => {
    if(companyId !== 0 || companyId != null)
    {
      const returnData = await fetchBusinessPartnerAvailableDocuments(companyId);
      setDocuments(returnData);
    }
  };

  useEffect(() => {
    if (show) {
      fetchData();
    }
  }, [show]);

  const handleViewDocument = async (event) => {
    const documentId = event.id;
    const returnData = await fetchBusinessPartnerDocumentDetails(documentId);
    const document = returnData[0];

    //if document.name is URL, then open a new tab with the URL that is located in document.data
    if (document.name === "URL") {
      window.open(document.data, "_blank");
    } else {
      const dataBuffered = Buffer.from(document.data.data, "binary").toString(
        "base64"
      );
      //if document.name ends with pdf then open the pdf in a new tab
      if (document.name.endsWith("pdf")) {
        // debugger
        console.log("document is pdf", document.name);
        const pdfData = atob(dataBuffered); // Decode base64
        const pdfArray = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          pdfArray[i] = pdfData.charCodeAt(i);
        }
        const blob = new Blob([pdfArray], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl);
      }

      //else, if document.name ends with xlsx or xls, displays the sheet's data in a new tab
      else if (
        document.name.endsWith("xlsx") ||
        document.name.endsWith("xls")
      ) {
        const xlsData = atob(dataBuffered); // Decode base64
        const xlsArray = new Uint8Array(xlsData.length);
        for (let i = 0; i < xlsData.length; i++) {
          xlsArray[i] = xlsData.charCodeAt(i);
        }
        const blob = new Blob([xlsArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const xlsUrl = URL.createObjectURL(blob);

        // Open the sheet in a new window or tab
        window.open(xlsUrl);
      }

      //else if the document.name is an image, then open the image in a new tab
      else if (
        document.name.endsWith("png") ||
        document.name.endsWith("jpg") ||
        document.name.endsWith("jpeg")
      ) {
        const imgData = atob(dataBuffered); // Decode base64
        const imgArray = new Uint8Array(imgData.length);
        for (let i = 0; i < imgData.length; i++) {
          imgArray[i] = imgData.charCodeAt(i);
        }
        const blob = new Blob([imgArray], { type: "image/png" });
        const imgUrl = URL.createObjectURL(blob);

        // Open the image in a new window or tab
        window.open(imgUrl);
      }
    }
  };

  const eoriNumberDisplay = eoriNumber === "" || eoriNumber === null ? `` : `(${eoriNumber})`;
  const companyNameDisplay = companyName === "" || companyName === null ? `this` : <span class='companyname-eorinumber'><span class='companyname'>{companyName}</span> {eoriNumberDisplay}</span>;

  return (
    <Modal show={show} onHide={onHide} {...rest} centered animation={false} className="documents-preview-modal">
      
      <Modal.Header style={{ display: hideHeader ? "none" : "flex" }}>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="document" /> <span>Documents</span>
        </Modal.Title>
        <button
          id="close"
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>
        {
          companyId === 0 || companyId == null || documents.length === 0 ? 
            <p>No documents uploaded for {companyNameDisplay} business partner.</p> : 
            (
              <DataTable
                // columns={columns}
                // containerRef={containerRef}
                initialData={documents}
                disabled={true}
                defaultStyle={defaultStyle}
                headerStyle={headerStyle}
                sortable={true}
                filterable={true}
                inputStyle={inputStyle}
                rootClose={true}
                downloadable={false}
                // buttonImage={copyIcon}
                buttonDisabled={false}
                buttonStyle={buttonStyle}
                imageStyle={imageStyle}
                buttonOnClick={handleViewDocument}
                hideNavigation={true}
                // returnPrev={() => history.push('/ReportsAdmin')}
              />
            )
        }
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          padding: "1rem 1.75rem"
        }}
      >
        <button
          id="confirm"
          class="stepper-button"
          onClick={(event) => {
            onHide(event);
          }}
        >
          Back
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BPDocumentListModal;
