import React, { useState, useEffect, useContext, useMemo } from "react";
import { StoreContext } from "./Store";
import { Select, Input } from "../components/Input/Input";
import { getCommcodeRefreshTable, getUpdateCommcodeSingle, getUpdateCommcodeAll } from "./apiCalls";
import { Table, Container, Row, Col, Button, Modal } from "react-bootstrap";
import  DataTable, {useDataTableHandle} from "./DataTable";
import { ExternalLinkAlt, Pen } from "@styled-icons/fa-solid";
import { useSecurity } from "./Security/security";
import DownloadIcon from "../images/download.svg";
import isoDateConverter from "./isoDateConverter";
import ConfirmationModal from "./ConfirmationModal";
import * as XLSX from 'xlsx';


const UserAdminCommcodes = () => {
  const [requestFailed, setRequestFailed] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmContent, setConfirmContent] = useState("");
  const [commodityCode, setCommodityCode] = useState("");
  const [refreshData, setRefreshData] = useState([])
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("")

  const store = useContext(StoreContext);

  const minWidthValue = '1500px';

  const fetchData = async () => {    
    try {
        const data = await getCommcodeRefreshTable();
        setRefreshData(data)
    } catch (err) {
      console.log(err);
      setRequestFailed(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [])

  const handleOneCommcodeFetch = async () => {
    try {
        setFetching(true);
        const data = await getUpdateCommcodeSingle(commodityCode);
        await fetchData()
        setFetching(false);
    }
    catch (e) {
        setError(e)
    }
  }
  const handleAllCommcodeFetch = async () => {
    try {
        setFetching(true);
        const data = await getUpdateCommcodeAll();
        await fetchData()
        setFetching(false);
    }
    catch (e) {
        setError(e)
    }
  }

  const fetchingInProgress = useMemo(() => {
    return refreshData.some((d) => d.status === 1)
  })


  if (requestFailed) return <p>Failed!</p>;
  return (
    <Container className="teamBackground" style = {{ minWidth: minWidthValue }}>
      {showConfirmModal && 
      <ConfirmationModal
      header={"Fetch All Commcodes?"} 
      show={showConfirmModal}
      onHide={(event) => {
        if((event?.target?.id === "confirm") ){         
          handleAllCommcodeFetch();
        }
          setShowConfirmModal(false);        
      }}
      onConfirm={() => {}}
      hideCancel={false}
      animation={true}
      >
        <p>This Operation will fetch all of the commcodes and may take several hours to complete. This screen will be disabled while fetching commcodes.
        </p>
      </ConfirmationModal>
      
      }
      {/* Unsure where the below classname comes from, doesn't seem to effect code */}
      <Row className="background ">
        <Col className="teamHeader ">
          <div className="d-flex justify-content-between">
            <span>Commcode Refresh</span>
          </div>
        </Col>

        {fetchingInProgress ?
        <div style={{marginBottom: '30px'}}>
            Fetching is currently in progress. Please return to this screen once fetching has been completed.
        </div>
        :
        <>
                <Row style={{marginTop: '30px'}}>
            <Col>
            <span className="section-title">Refresh One Commcode</span>
            <div className="d-flex flex-row">
            <Input 
            type="number"
            value={commodityCode}
            onChange={(e) => setCommodityCode(e.target.value)}/>
            <button className="blue-button-teams" style={{marginLeft: '10px'}} disabled={fetching} onClick={handleOneCommcodeFetch}>Refresh </button>
            </div>

            </Col>

        </Row>

        <Row style={{marginTop: '30px', marginBottom: '30px'}}>
            <span className="section-title"> Refresh all commcodes. </span>
            <Col>
            <button className="blue-button-teams" disabled={fetching} onClick={() => setShowConfirmModal(true)}>Refresh </button>
            </Col>

        </Row>
        </>
        }

        <Row className="adminTable"> 
        <h4>Refresh History</h4>
          <DataTable data={refreshData} sortable filterable dontRefreshPagination className="table-striped table-bordered">
            <DataTable.Pagination itemsPerPage={[10, 25, 50]} style={{fontSize:"4px"}} />
            <DataTable.Headers style={{backgroundColor: 'white',fontSize: '13px'}} />
            <DataTable.Column colKey="commoditycode" header="Commodity Code" />     
            <DataTable.Column colKey="startTime" header="Start Time" />  
            <DataTable.Column colKey="endTime" header="End Time" />    
            <DataTable.Column colKey="status" header="Status"> 
            {({status}) => {
                  if (status === 1) return "In Progress"
                  else if (status === 2) return "Completed"
                  else return "Error"
                }}
            </DataTable.Column>        
          </DataTable>
        </Row>
      </Row>
    </Container>
  );
};


export default UserAdminCommcodes;





