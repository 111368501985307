import React, { useState, useEffect } from 'react';
import "../main.css";
import "../neeNor/neenor.css";
import { getWLPLoads } from '../apiCalls';
import WLPLoadsList from './WLPLoadsList';



const WlpLoads = () => {

  const [requestFailed, setRequestFailed] = useState('')
  const [loads, setLoads] = useState([{ "": "" }])

  async function fetchData() {
    try {
      const data = await getWLPLoads()
      setLoads(data)
    } catch (err) {
      setRequestFailed(true);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

 
  if (requestFailed) return <p>Failed!</p>


  return (
    <>
               
              <WLPLoadsList
              loads={loads}

              />
            
    </>
      )
    
  
    
    }



export default WlpLoads;