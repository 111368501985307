/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './table.css';
import "./report.css";
import { getTeamMemberDetails, putTeamMembers, deleteTeamMemberDetails } from './apiCalls';
import { Input } from "./Input/Input.js";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Container, Row, Col }  from 'react-bootstrap';


const TeamDetails = (props) => {

  const [requestFailed, setRequestFailed] = useState(false);
  const [returnData, setReturnData] = useState('');
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userId = props.location.state.detail
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getTeamMemberDetails (userId)
        setReturnData(data[0])
        setName(data[0].teamName)
        setEmail(data[0].teamEmailAddress)
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [])

  if (requestFailed) return <p>Failed!</p>

   const  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await putTeamMembers(event.target)
      if (data[0].completed === 'Updated') {
          alert('Record updated') 
          navigate("/Team");
      }
      if (data[0].completed !== 'Updated') {
        alert('Error updating record') 
    }
      return data
    } catch (err) {
      console.log(err)
    }
  }

  const  handleCancel = async (event) => {
    event.preventDefault();    
    navigate("/Team");     
  }

  const handleDelete = async () => {
            const data = await deleteTeamMemberDetails(returnData.id)
            if (data[0].completed ==='Deleted') {
                alert('Record deleted') 
                navigate("/Team");
            }
            if (data[0].completed !=='Deleted') {
                alert('Error deleting record') 
            }
  }

  return (
    <Container fluid>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Are you sure you want to delete</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="justify-content-md-center">
      <Col md={{ span: 10 }} className='floating-box-header , justify-content-md-center'>
          <span>Team member details</span>
        </Col>
        <form onSubmit={handleSubmit} id='TeamMemberDetails'  >
          <Row className="justify-content-md-center">
            <input type = 'number' name = 'id' value = {returnData.id} hidden />
            <Col></Col>  
            <Col md={{ span: 5 }}>
              <Input type = 'text' name = 'teamName' label = 'Name' value = {name} onChange={e => setName(e.target.value)}  />  
            </Col >
            <Col md={{ span: 5 }}>
              <Input type = 'text' name = 'teamEmail' label = 'Email' value = {email} onChange={e => setEmail(e.target.value)} />
            </Col>
            <Col></Col>
          </Row>
          <Row>
          <Col md={{ span: 3 }}>
          
          <Button variant="custom" type="submit">Update</Button>
        
          <Button variant="eori"  onClick={handleShow}>Delete</Button>
        
          <Button variant="warning" onClick={handleCancel} >Cancel</Button>
      </Col>

          </Row>
          
              
            
        </form>
      </Row>
      
    </Container>
  )
}

export default TeamDetails;

