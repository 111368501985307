import "./controlledgoodsmodal.css";
import { Popover, OverlayTrigger, PopoverContent } from "react-bootstrap";

export const ControlsGoodsExportLinks = () => {
  return (
    <div class="circle-container">
      <span class="deg1">
        <p>POAO</p>
        <p className="subtitle">Products of animal origin</p>
        <div className="chicken position-svg"></div>{" "}
        <OverlayTrigger
          placement="auto"
          trigger={["click"]}
          rootClose
          overlay={
            <Popover style={{ minWidth: "35rem" }}>
              <PopoverContent>
                <h6>Products Of Animal Origin (POAO)</h6>
                <p>
                  This includes live animals and germinal products, food and
                  drink for human consumption and composite products.
                </p>
                <div> Please see below for further information:</div>
                <div>
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/export-live-animals-special-rules#export-or-move-live-animals-to-the-eu-or-northern-ireland"
                    rel="noreferrer"
                  >
                    Live animals
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/export-food-and-agricultural-products-special-rules"
                    rel="noreferrer"
                  >
                    Export food, drink and agricultural products
                  </a>
                </div>
                <div style={{ paddingBottom: "15px" }}>
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/export-food-and-agricultural-products-special-rules"
                    rel="noreferrer"
                  >
                    Composite products
                  </a>
                </div>
              </PopoverContent>
            </Popover>
          }
        >
          <div
            className="important-info controlled"
            style={{ position: "relative", top: -14 }}
          ></div>
        </OverlayTrigger>
      </span>
      <span href="#" class="deg2">
        <p>Fish</p>
        <div className="fish position-svg"></div>
        <OverlayTrigger
          placement="auto"
          trigger={["click"]}
          rootClose
          overlay={
            <Popover style={{ minWidth: "35rem" }}>
              <PopoverContent>
                <h6>Fishery Products</h6>
                <p>
                  This includes Fish, Molluscs or Crustaceans. There are strict requirements for importing or exporting these goods into or out of Great Britain.
                </p>
                <div>Please see below for further information:</div>
                <div>
                  {" "}
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/importing-or-moving-fish-to-the-uk"
                    rel="noreferrer"
                  >
                    Importing or moving fish to the UK - GOV.UK (www.gov.uk)
                  </a>
                </div>
                <div style={{ paddingBottom: "15px" }}>
                  {" "}
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/exporting-or-moving-fish-from-the-uk"
                    rel="noreferrer"
                  >
                    Exporting or moving fish from the UK - GOV.UK (www.gov.uk)
                  </a>
                </div>
              </PopoverContent>
            </Popover>
          }
        >
          <div className="important-info controlled"></div>
        </OverlayTrigger>
      </span>
      <span href="#" class="deg3">
        <p>Military</p>
        <div className="tank position-svg"></div>
        <OverlayTrigger
          placement="auto"
          trigger={["click"]}
          rootClose
          overlay={
            <Popover style={{ minWidth: "35rem" }}>
              <PopoverContent>
                <h6>Restricted Goods</h6>
                <p>
                  These are restricted or dual-use goods which include guns,
                  knives, swords and other offensive weapons.
                </p>

                <div>Please see below for further guidance:</div>
                <div style={{ paddingBottom: "15px" }}>
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/export-controls-military-goods-software-and-technology"
                    rel="noreferrer"
                  >
                    Export controls
                  </a>
                </div>
              </PopoverContent>
            </Popover>
          }
        >
          <div className="important-info controlled"></div>
        </OverlayTrigger>
      </span>
      <span href="#" class="deg4">
        <p>Plants</p>
        <div className="plant position-svg"></div>
        <OverlayTrigger
          placement="auto"
          trigger={["click"]}
          rootClose
          overlay={
            <Popover style={{ minWidth: "35rem" }}>
              <PopoverContent>
                <h6>Plant and plant products</h6>
                <p>
                  Plant health controls apply to goods that are regulated. Unregulated plants and plant products
                  do not need to go through any plant health controls. A
                  phytosanitary certificate is required if your products are
                  classed as high priority.
                </p>

                <div>Please see below for further information:</div>
                
                <div style={{ paddingBottom: "15px" }}>
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/export-plants-and-plant-products-from-great-britain-and-northern-ireland"
                    rel="noreferrer"
                  >
                    Export plants and plant products
                  </a>
                </div>
              </PopoverContent>
            </Popover>
          }
        >
          <div className="important-info controlled"></div>
        </OverlayTrigger>
      </span>
      <span href="#" class="deg5">
        <p>Fruit-Veg</p>
        <div className="veg position-svg"></div>
        <OverlayTrigger
          placement="auto"
          trigger={["click"]}
          rootClose
          overlay={
            <Popover style={{ minWidth: "35rem" }}>
              <PopoverContent>
                <h6>Fresh Fruit and Vegetables</h6>
                <p>
                  Some Fresh Fruit and Vegetables will require a certificate of
                  conformity (CoC).
                </p>

                <div>Please see below for further information:</div>

                <div style={{ paddingBottom: "15px" }}>
                  {" "}
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/importing-and-exporting-fresh-fruit-and-vegetables#exporting-fruit-and-vegetables-from-great-britain-to-the-eu"
                    rel="noreferrer"
                  >
                    Exporting Fresh Fruit and Vegetables
                  </a>
                </div>
              </PopoverContent>
            </Popover>
          }
        >
          <div className="important-info controlled"></div>
        </OverlayTrigger>
      </span>
      <span href="#" class="deg6">
        <p>CITES</p>
        <div className="cites"></div>
        <OverlayTrigger
          placement="auto"
          trigger={["click"]}
          rootClose
          overlay={
            <Popover style={{ minWidth: "35rem" }}>
              <PopoverContent>
                <h6>Endangered Species (CITES)</h6>
                <p>
                  The Convention on International Trade in Endangered Species of
                  Wild Fauna and Flora (CITES) is an agreement that regulates
                  trade of wildlife products.
                </p>
                <div>Please see below for further guidance:</div>
                <div style={{ paddingBottom: "15px" }}>
                  {" "}
                  <a
                    target="_blank"
                    href="https://www.gov.uk/guidance/cites-imports-and-exports"
                    rel="noreferrer"
                  >
                    {" "}
                    CITES Imports and Exports
                  </a>
                </div>
              </PopoverContent>
            </Popover>
          }
        >
          <div className="important-info controlled"></div>
        </OverlayTrigger>
      </span>
      <span href="#" class="deg7">
        <p>Excise</p>
        <div className="beer position-svg"></div>
        <OverlayTrigger
          placement="auto"
          trigger={["click"]}
          rootClose
          overlay={
            <Popover style={{ minWidth: "35rem" }}>
              <PopoverContent>
                <h6>Excise Goods</h6>
                <div style={{ paddingBottom: "15px" }}>
                  These goods include Wine, Beer, Spirits or Tobacco. These
                  goods usually attract excise duties.
                </div>
              </PopoverContent>
            </Popover>
          }
        >
          <div className="important-info controlled"></div>
        </OverlayTrigger>
      </span>
    </div>
  );
};

export default ControlsGoodsExportLinks;
