import React, { useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { postFileUpload } from '../apiCalls'

import FileUploadError from "../Errors/FileUploadError";

const FileUpload =() => {

    const [file, setFile] = useState('')
    const el = useRef()

    const handleChange = (e) => {
        const file = e.target.files[0]
        setFile(file)
    }

    const uploadFile = async() => {
    const data = await postFileUpload('csv',file)
    console.log(data)
    }

    return (
        <ErrorBoundary FallbackComponent={FileUploadError} onReset={()=>window.location.reload()}>
        <div>
            <div className="file-upload">
                <input type="file" ref={el} onChange={handleChange} />
                <button onClick={uploadFile} className="upbutton">
                   Upload
                </button>
            </div>
        </div>
        </ErrorBoundary>
    );
}

export default FileUpload;