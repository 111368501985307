import { useEffect, useState, useRef, useContext } from "react";
import { Col, Row, OverlayTrigger, Popover, PopoverContent } from "react-bootstrap";
import { getNewLoad } from "../apiCalls";
import { Input, Select, FilterableSelect } from "../Input/Input";
import { useStepper } from "../Stepper";
import warehouse from "../Epo/assets/data-warehouse.png";
import { GoogleAnalyticsContext } from "../GoogleAnalytics";
import { StoreContext } from "../Store";
import { addClient } from "./_RegController"


export default function AddressDetails() {
  const { stepBackward, localData, setDataByKey, gotoStep, unvisitStep, activeStepKey, stepForward } = useStepper();

  const [errors, setErrors] = useState({});
  const store = useContext(StoreContext)

  const isInitialized = useRef(false);
  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "addr" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "2. AddressDetails",{
          event_category: "Registration"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  const handleNext = async (e) => {
    e.preventDefault();
    const freshErrors = {};

    if (!localData.building) freshErrors.building = "Required";
    if (!localData.street) freshErrors.street = "Required";
    if (!localData.town) freshErrors.town = "Required";
    if (!localData.postcode) freshErrors.postcode = "Required";
    if (!localData.country) freshErrors.country = "Required";

    setErrors(freshErrors);
    
    if (!Object.values(freshErrors).some(err => !!err)) {
      if (localData.sameAddress) {
         unvisitStep('addr2');
         console.log("Trading Address", localData);
        if (localData.useFullRegProcess) {
          gotoStep('addr2')
        } else if (localData.companyCategory === 'trader') {
          stepForward();} 
      } else {
        gotoStep('addr2');
      }
     }    
  };

  useEffect(() => {
    if (localData.sameAddress)   {
      setDataByKey('building2', localData.building);
      setDataByKey('street2', localData.street);
      setDataByKey('town2', localData.town);
      setDataByKey('county2', localData.county);
      setDataByKey('postcode2', localData.postcode);
      setDataByKey('country2', localData.country);
    } else {
      setDataByKey('building2', '');
      setDataByKey('street2', '');
      setDataByKey('town2', '');
      setDataByKey('county2', '');
      setDataByKey('postcode2', '');
      setDataByKey('country2', '');
    }
  }, [localData.sameAddress, localData.building, localData.street, localData.town, localData.county, localData.postcode, localData.country])

  useEffect(() => {
    async function fetch () {
      const data = await getNewLoad('countrycodesNeenor')
      store.countryCodes[1](data);
    }
    fetch();
  }, [])

  return (
    <form onSubmit={handleNext} className="flex-grow-1 d-flex flex-column">
      <div>
      <span className="register-step-header">Trading Address</span>
        <div className="inputs-group register-info">
          <img src={warehouse} width="90" height="90" alt="" />
          <span>
            Your trading address is your main location of operations, it's most likely where you're filling this out now.
          </span>
        </div>
        <Row className="py-2">
          <Col>
            <Input label={<>Building name/number <span style={{ fontSize: "120%" }}>*</span></>} placeholder="e.g. 221b" error={errors.building} value={localData.building} onChange={(e) => setDataByKey('building', e.target.value)} />
          </Col>
          <Col>
            <Input label={<>Street <span style={{ fontSize: "120%" }}>*</span></>} placeholder="e.g. Baker Street" error={errors.street} value={localData.street} onChange={(e) => setDataByKey('street', e.target.value)} />
          </Col>

        </Row>
        <Row className="py-2">
          <Col>
            <Input label={<>Town/City <span style={{ fontSize: "120%" }}>*</span></>} placeholder="e.g. London" error={errors.town} value={localData.town} onChange={(e) => setDataByKey('town', e.target.value)} />
          </Col>
          <Col>
            <Input label="County" placeholder="e.g. Greater London"  error={errors.county} value={localData.county} onChange={(e) => setDataByKey('county', e.target.value)} />
          </Col>
        </Row>

        <Row className="py-2">
          <Col>
            <Input label={<>Postcode <span style={{ fontSize: "120%" }}>*</span></>} placeholder="e.g. NW1 6XE" error={errors.postcode} value={localData.postcode} onChange={(e) => setDataByKey('postcode', e.target.value)} />
          </Col>
          <Col>
            <FilterableSelect
              label={<>Country <span style={{ fontSize: "120%" }}>*</span></>}
              data={localData.country}
              setData={(data) => setDataByKey('country', data)}
              options={store.countryCodes[0]}
              error={errors.country}
            />
          </Col>
        </Row>

        <Row className="py-2">
          <div className="d-flex justify-content-start">
            <label className="d-flex align-items-center gap-1 label-text">
              <input type="checkbox" checked={!!localData.sameAddress} onChange={e => {setDataByKey('sameAddress', e.target.checked)}} />
              <span>Is your registered office address the same as the above?</span>
            </label>
          </div>
        </Row>
      </div>
      <div className="flex-grow-1" />
      <section className="d-flex justify-content-between mt-3">
        <button type="button" className="blue-button-teams green-btn" onClick={stepBackward}>
          Back
        </button>
        <span className="py-1 text-danger">{Object.values(errors).some(err => !!err) && "Some errors were found, please correct above."}&nbsp;</span>
        <button type="submit" className="blue-button-teams green-btn">
          Next
        </button>
      </section>
    </form>
  );
}
