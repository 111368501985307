import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import "./login.css";
import { Input } from "./Input/Input.js";
import 'whatwg-fetch';
import { postActivatedAndReset } from './apiCalls';
import { useParams } from 'react-router-dom';


const Activated = () => {

  const [params, setParams] = useState(useParams())
  console.log(params.token);
  const navigate = useNavigate()
  const [activationText, setActivationText] = useState("")
  const [passwordOne, setPasswordOne] = useState("")
  const [passwordTwo, setPasswordTwo] = useState("")
  const [passwordText, setPasswordText] = useState("")

  const validatePassword = () =>{
    if (passwordOne !== passwordTwo) {
     setPasswordText("Passwords do not match")
     setPasswordOne('')
     setPasswordTwo('')
     return false;
    }
    if (passwordOne.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$") == null) {
      setPasswordText("Password must be minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character from (@ $ ! % * ? &)")
      return false;
     }
    return true;
  }

  useEffect(() => {
    setActivationText("Please create a password to activate your account.  Password must be minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character from (@ $ ! % * ? &).")
  }, [])

  async function handleSubmit(event) {
    event.preventDefault();
    if (validatePassword()) {
      try {
        const data = await postActivatedAndReset(event.target)
        if (data[0].completed === 'Token is invalid or has expired') {
          setActivationText('We are sorry but we were unable to activate your account.  Please note that you must complete the process within one hour of receiving our activation email.')
          navigate("/login");
        }
        navigate("/login");
      } catch (err) {
        console.log(err)
      }
    }
 }

  return (
    <div className="full-width-container ">
      <div className="floating-box login-container">
        <div className="floating-box-header push-center">
          <span className='mt-1'>Registration Successful</span>
        </div>
        <span className='completed positions'></span>
        <div className="sub-heading mb-3">{activationText}</div>
        <div className="sub-heading password-error">{passwordText}</div>
        <form onSubmit={handleSubmit} id='login-contents'>
        <Input label="Choose a password" 
            type='password' 
            name='password' 
            id='password' 
            value={passwordOne} 
            onChange={e => setPasswordOne(e.target.value)} required />
        <Input label="Confirm password" 
            type='password' 
            name='confirm' 
            id='confirm' 
            value={passwordTwo} 
            onChange={e => setPasswordTwo(e.target.value)} required />
           <input id="token" type="hidden" name="token" value={params.token} />
           <div className='push-left-right'>
              <button type="submit" className="blue-button">Continue</button>
           </div>
        </form>
      </div>
    </div>
  );
}

export default Activated

