import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import BPGrid from "../../BusinessPartners/BPTestTable";
import { StoreContext } from "../../Store";
import FormatDate from "../../Util/FormatDate";

const DataCheck = ({ route, loadingDate, entryPort, portModel, invoiceCurrency, incoterm, adjustments, controlledGoodsType }) => {
    const store = useContext(StoreContext);
    //let listOfAdjustments = "";
    let adjustmentProps = "";
        
    let listOfAdjustments = adjustments.map((adjustment, index) => {
        //console.log('adjustment :: ', adjustment);
        adjustmentProps = '';
        for (const [key, value] of Object.entries(adjustment)) {
            adjustmentProps += `<li>${key}: ${value}</li>`;
        }
        //console.log('adjustmentProps  :: <ul>', adjustmentProps, '</ul>');
        
        //console.log('adjustment :: ', adjustment);
        return false; //`<li>${adjustmentProps}|</li>`;
    });

    listOfAdjustments = `<ul>${listOfAdjustments}</ul>`;
    //console.log('adjustments  :: ', adjustments);
    //console.log('listOfAdjustments  :: ', listOfAdjustments);

    const showAdjustments = adjustments.length > 0;

    const fieldStatus_Warning = 'warningHighlight';
    const fieldStatus_Error = 'invalidHighlight';

    return (
        <Container fluid>
            <div className="bp-section-heading">Data Check</div>
            <div className="bp-table-grid bp-grid-default" style={{ display: 'grid', gridTemplateColumns: '45% 55%', padding: '0' }}>
                <BPGrid.Row
                    title={""}
                    subTitle='Route'
                    value={route}
                    isEditingValue={true}
                    valueType='string'
                    //dropdownValues={dropdownValues?.[item?.subtitle]}
                    //onChange={handleOnChange}
                    //tableTitle={title}
                    hasError={false}
                    //errorMessage={errors?.[item?.subtitle]}
                    className='bp-row-1'
                />
                {/* <div className="bp-table-grid-header bp-grid-default">Route</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{route}</div> */}
                <div className="bp-table-grid-header bp-grid-default">Loading Date</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{FormatDate(loadingDate)}</div>
                <div className="bp-table-grid-header bp-grid-default">Entry Port</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{entryPort}</div>
                <div className="bp-table-grid-header bp-grid-default">Port Model</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{portModel}</div>
                <div className="bp-table-grid-header bp-grid-default">Invoice Currency</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{invoiceCurrency}</div>
                <div className={ `bp-table-grid-header bp-grid-default ${fieldStatus_Warning}` }>Incoterm</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{incoterm}</div>
                <div className="bp-table-grid-header bp-grid-default">Adjustments</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>
                    {
                        showAdjustments ? <button class="button-text-link view-notes-button" title="Click to view all adjustments">View</button> : "-"
                    
                    }</div>
                <div className="bp-table-grid-header bp-grid-default">Type</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{controlledGoodsType === 'Y' ? 'Controlled' : 'Non-Controlled'}</div>
                <div className={ `bp-table-grid-header bp-grid-default ${fieldStatus_Error}` }>Preference</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>
                    <button class="button-text-link view-notes-button" title="Click to check product preferences">Check</button>
                </div>
            </div>
        </Container>
    );
};

export default DataCheck;