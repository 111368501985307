import { OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';

export const DeclarationsButton = ({C21, SDI, CHED, SFD, EAD, ENS, TAD, FFD, Status, InventoryLinked, formSubmitted, handleOpenModal, handleC21Submit, handleDocSubmit, handleEADSubmit, handleFFDSubmit}) => {

  // SDI == START==================================================================
  if (SDI === 1 && Status === "Submitted")
  {
    return (<button className="status-button yellow" onClick={handleOpenModal} >SDI <OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={ 
        <Popover style={{ minWidth: "10rem" }}>
          <PopoverContent>
            <h6>Note</h6>
            <p>Do not forget to enter the returned SDI Reference Number into the SDI System Ref box.</p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }

  else if (SDI === 1 && formSubmitted === 1)
  {
    return (<button className="status-button light">SDI <OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "36rem" }}>
          <PopoverContent>
            <h6>SDI Submitted</h6>
            <p>
              <ul>
                <li>You have submitted an SDI for this load.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>
    </OverlayTrigger></button>)
  }
  
  else if (SDI === 0 && formSubmitted === 0)
  {
    return (<button className="status-button grey">SDI<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "46rem" }}>
          <PopoverContent>
            <h6>Before you can submit an SDI:</h6>
            <p>
              <ul>
                <li>Enter the returned SFD Reference Number into the SFD Sys Ref No.box.</li>
                <li>Press "Update".</li>
                <li>Now you can submit the enabled SDI button.</li>
                <li>Enter the returned SDI Reference Number into the SDI System Ref No. box.</li>
                <li>Press "Update".</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  } 
  // SDI == END ==================================================================


  // C21 == START==================================================================
  else if (C21 === 0 && formSubmitted === 0 && InventoryLinked === 1)
  {
    return (<button className="status-button grey">C21<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "46rem" }}>
          <PopoverContent>
            <h6>Before you can submit an C21:</h6>
            <p>
              <ul>
                <li>Enter the MUCR/UCN (Destin8) into the row titled "Inventory Linked Release".</li>
                <li>Select Yes/No from the Port Arrived drop down.</li>
                <li>Press "Update".</li>
                <li>Press the yellow "C21" button to submit the C21.</li>
                <li>Enter the returned C21 Reference Number into the C21 Sys Ref No.box.</li>
                <li>Press "Update".</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (C21 === 0 && formSubmitted === 1 && InventoryLinked === 0)
  {
    return (<button className="status-button light">C21<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "33rem" }}>
          <PopoverContent>
            <h6>C21 Submitted</h6>
            <p>
              <ul>
                <li>C21 has automatically been submitted for GVMS port type</li>
                <li>Enter the returned C21 Reference Number into the C21 System Ref No.box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>
    </OverlayTrigger></button>)
  }
  else if (C21 === 1 && Status === "Submitted")
  {
    return (<button className="status-button yellow" onClick={handleC21Submit}>C21<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "10rem" }}>
          <PopoverContent>
            <h6>Note</h6>
            <p>
              <ul>
                <li>Do not forget to enter the returned C21 Reference Number into the C21 System Reference box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (C21 === 1 && formSubmitted === 1)
  {
    return (<button className="status-button light">C21<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "20rem" }}>
          <PopoverContent>
            <h6>C21 Submitted</h6>
            <p>
              <ul>
                <li>You have submitted an C21 for this load.</li>
                <li>Do not forget to enter the returned C21 Reference Number into the C21 System Reference box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>
    </OverlayTrigger></button>)
  }
  // C21 == END ==================================================================


  // SFD == START==================================================================
  else if (SFD === 0 && formSubmitted === 0 && InventoryLinked === 1)
  {
    return (<button className="status-button grey">SFD<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "46rem" }}>
          <PopoverContent>
            <h6>Before you can submit an SFD:</h6>
            <p>
              <ul>
                <li>Enter the MUCR/UCN (Destin8) into the row titled "Inventory Linked Release".</li>
                <li>Select Yes/No from the Port Arrived drop down.</li>
                <li>Press "Update".</li>
                <li>Press the yellow "SFD" button to submit the SFD.</li>
                <li>Enter the returned SFD Reference Number into the SFD Sys Ref No.box.</li>
                <li>Press "Update".</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (SFD === 0 && formSubmitted === 1 && InventoryLinked === 0)
  {
    return (<button className="status-button light">SFD<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "33rem" }}>
          <PopoverContent>
            <h6>SFD Submitted</h6>
            <p>
              <ul>
                <li>SFD has automatically been submitted for GVMS port type</li>
                <li>Enter the returned SFD Reference Number into the SFD System Ref No.box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>  
    </OverlayTrigger></button>)
  }
  else if (SFD === 1 && Status === "Submitted")
  {
    return (<button className="status-button yellow" onClick={handleDocSubmit}>SFD<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "10rem" }}>
          <PopoverContent>
            <h6>Note</h6>
            <p>
              <ul>
                <li>Do not forget to enter the returned SFD Reference Number into the SFD System Reference box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (SFD === 1 && formSubmitted === 1)
  {
    return (<button className="status-button light">SFD<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "20rem" }}>
          <PopoverContent>
            <h6>SFD Submitted</h6>
            <p>
              <ul>
                <li>You have submitted an SFD for this load.</li>
                <li>Do not forget to enter the returned SFD Reference Number into the SFD System Reference box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>
    </OverlayTrigger></button>)
  }
  // SFD == END ==================================================================


  // EAD == START==================================================================
  else if (EAD === 1 && Status === "Submitted")
  {
    return (<button className="status-button yellow" onClick={handleEADSubmit}>EAD <OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "10rem" }}>
          <PopoverContent>
            <h6>Note</h6>
            <p>Do not forget to enter the returned EAD Reference Number into the EAD System Ref box.</p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (EAD === 1 && Status === "In-Progress")
  {
    return (<button className="status-button grey">EAD <OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "36rem" }}>
          <PopoverContent>
            <h6>EAD Submitted</h6>
            <p>
              <ul>
                <li>You have submitted a EAD for this load.</li>
                <li>Do not forget to enter the returned EAD Reference Number into the EAD System Reference box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (EAD === 1 && Status === "Completed")
  {
    return (<button className="status-button light">EAD <OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "20rem" }}>
          <PopoverContent>
            <h6>EAD Submitted</h6>
            <p>
              <ul>
                <li>You have submitted a EAD for this load.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>
    </OverlayTrigger></button>)
  }
  // EAD == END ==================================================================


  // CHED == START==================================================================
  else if (CHED === 1 && formSubmitted === 0)
  {
    return (<button className="status-button grey">CHED <OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "36rem" }}>
          <PopoverContent>
            <h6>CHED Submitted</h6>
            <p>
              <ul>
                <li>You have submitted an CHED for this load.</li>
                <li>Do not forget to enter the returned CHED Reference Number into the CHED System Reference box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (CHED === 1 && formSubmitted === 1)
  {
    return (<button className="status-button light">CHED <OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "20rem" }}>
          <PopoverContent>
            <h6>CHED Submitted</h6>
            <p>
              <ul>
                <li>You have submitted an CHED for this load.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>
    </OverlayTrigger></button>)
  }
  // CHED == END ==================================================================
  
  
  // ENS == START==================================================================
  else if (ENS === 1 && formSubmitted === 0) {
    return (<button className="status-button grey">ENS<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "36rem" }}>
          <PopoverContent>
            <h6>ENS Submitted</h6>
            <p>
              <ul>
                <li>You have submitted an ENS for this load.</li>
                <li>Do not forget to enter the returned ENS Reference Number into the ENS System Reference box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
    <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (ENS === 1 && formSubmitted === 1) {
    return (<button className="status-button light">ENS<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "20rem" }}>
          <PopoverContent>
            <h6>ENS Submitted</h6>
            <p>
              <ul>
                <li>You have submitted an ENS for this load.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>
    </OverlayTrigger></button>)
  }
  // ENS == END ==================================================================


  // TAD == START==================================================================
  else if (TAD === 1 && formSubmitted === 0)
  {
    return (<button className="status-button grey">TAD<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "36rem" }}>
          <PopoverContent>
            <h6>TAD Submitted</h6>
            <p>
              <ul>
                <li>You have submitted a TAD for this load.</li>
                <li>Do not forget to enter the returned TAD Reference Number into the TAD System Reference box.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="info"></div>
    </OverlayTrigger></button>)
  }
  else if (TAD === 1 && formSubmitted === 1)
  {
    return (<button className="status-button light">TAD<OverlayTrigger
      placement="left"
      trigger={["hover", "focus"]}
      overlay={
        <Popover style={{ minWidth: "20rem" }}>
          <PopoverContent>
            <h6>TAD Submitted</h6>
            <p>
              <ul>
                <li>You have submitted a TAD for this load.</li>
              </ul>
            </p>
          </PopoverContent>
        </Popover>
      }
    >
      <div className="tick-success"></div>
    </OverlayTrigger></button>)
  }
  // TAD == END ==================================================================


  // FFD == START==================================================================
  else if (FFD === 0 && formSubmitted === 0 && InventoryLinked === 1)
    {
      return (<button className="status-button grey">FFD<OverlayTrigger
        placement="left"
        trigger={["hover", "focus"]}
        overlay={
          <Popover style={{ minWidth: "46rem" }}>
            <PopoverContent>
              <h6>Before you can submit an FFD:</h6>
              <p>
                <ul>
                  <li>Enter the MUCR/UCN (Destin8) into the row titled "Inventory Linked Release".</li>
                  <li>Select Yes/No from the Port Arrived drop down.</li>
                  <li>Press "Update".</li>
                  <li>Press the yellow "FFD" button to submit the FFD.</li>
                  <li>Enter the returned FFD Reference Number into the FFD Sys Ref No.box.</li>
                  <li>Press "Update".</li>
                </ul>
              </p>
            </PopoverContent>
          </Popover>
        }
      >
        <div className="info"></div>
      </OverlayTrigger></button>)
    }
    else if (FFD === 0 && formSubmitted === 1 && InventoryLinked === 0)
    {
      return (<button className="status-button light">FFD<OverlayTrigger
        placement="left"
        trigger={["hover", "focus"]}
        overlay={
          <Popover style={{ minWidth: "33rem" }}>
            <PopoverContent>
              <h6>FFD Submitted</h6>
              <p>
                <ul>
                  <li>FFD has automatically been submitted for GVMS port type</li>
                  <li>Enter the returned FFD Reference Number into the FFD System Ref No.box.</li>
                </ul>
              </p>
            </PopoverContent>
          </Popover>
        }
      >
        <div className="tick-success"></div>  
      </OverlayTrigger></button>)
    }
    else if (FFD === 1 && Status === "Submitted")
    {
      return (<button className="status-button yellow" onClick={handleFFDSubmit}>FFD<OverlayTrigger
        placement="left"
        trigger={["hover", "focus"]}
        overlay={
          <Popover style={{ minWidth: "10rem" }}>
            <PopoverContent>
              <h6>Note</h6>
              <p>
                <ul>
                  <li>Do not forget to enter the returned FFD Reference Number into the FFD System Reference box.</li>
                </ul>
              </p>
            </PopoverContent>
          </Popover>
        }
      >
        <div className="info"></div>
      </OverlayTrigger></button>)
    }
    else if (FFD === 1 && formSubmitted === 1)
    {
      return (<button className="status-button light">FFD<OverlayTrigger
        placement="left"
        trigger={["hover", "focus"]}
        overlay={
          <Popover style={{ minWidth: "20rem" }}>
            <PopoverContent>
              <h6>FFD Submitted</h6>
              <p>
                <ul>
                  <li>You have submitted an FFD for this load.</li>
                  <li>Do not forget to enter the returned FFD Reference Number into the FFD System Reference box.</li>
                </ul>
              </p>
            </PopoverContent>
          </Popover>
        }
      >
        <div className="tick-success"></div>
      </OverlayTrigger></button>)
    }
  // FFD == END ==================================================================

  else return null;
}