import React, { useEffect, useState } from 'react';
import "./main.css";
import './table.css'
import Footer from './Footer';
import { getInvoiceDetails } from './apiCalls';
import tableHeaders from "./tableHeaders";
import createTable from "./createTable";

const InvoiceDetails = (props) => {

  const invoiceNumber = props.location.state.detail

  const [requestFailed, setRequestFailed] = useState(false)
  const [invoices, setInvoices] = useState('[{"":""}]')

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getInvoiceDetails(invoiceNumber)
        setInvoices(data)
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [invoiceNumber])

  if (requestFailed) return <p>Failed!</p>

  const returnData = (invoices)

  return (
    <div className="main">
      <section className='tableLayOut'>
        <table className='tablePlace'>
          <tbody>
            <tr>
              {tableHeaders(returnData)}
            </tr>
            {createTable(returnData)}
          </tbody>
        </table>
      </section>
      <Footer />
    </div>
  );
}

export default InvoiceDetails