import { useEffect, useState } from "react";
import * as XLSX from 'xlsx';

const InvoiceViewer = ({ fileBuffer }) => {
  const [data, setData] = useState([]);
  const [columnLetters, setColumnLetters] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      let maxColumns = 0;
      let maxRow = 0;
      data.forEach((row, rowIndex) => {
        const numColumns = Object.keys(row).length;
        if (numColumns > maxColumns) {
          maxColumns = numColumns;
          maxRow = rowIndex;
        }
      });
      const letters = [...Array(data[maxRow].length).keys()].map(
        (item, index) => getLetter(index)
      );
      setColumnLetters(letters);
    }
  }, [data]);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      const data = new Uint8Array(reader.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const filteredData = parsedData.map((row) => {
        return row
          .filter(
            (cell) => typeof cell !== "string" || !cell.startsWith("mailto:")
          )
          .map((cell) => cell || "");
      });
      setData(filteredData);
    };
    reader.readAsArrayBuffer(new Blob([fileBuffer]));
  }, [fileBuffer]);

  const getLetter = (index) => {
    let letter = "";
    while (index >= 0) {
      letter = String.fromCharCode(65 + (index % 26)) + letter;
      index = Math.floor(index / 26) - 1;
    }
    return letter;
  };

  if (!fileBuffer) return null;

  return (
    <div>
      {data.length > 0 && (
        <table>
          <thead>
            <tr>
              {data[0].map((header, index) => (
                <th key={header + "_" + index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, index) => (
              <tr key={row + "_" + index}>
                {columnLetters?.map((letter, index) => {
                  const cellData = row[index] || "";
                  return <td key={cellData + "_" + index}>{cellData}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InvoiceViewer;
