import "./maintenance.css";
import React, { useState, useEffect } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import CommodityCodes from "./tabs/commodityCodes";
import MovementType from "./tabs/movementType";
import Load from "./tabs/load";
import Country from "./tabs/country";
import Ports from "./tabs/ports";
import ShipStatus from "./tabs/shipStatus";
import Currency from "./tabs/currency";
import Incoterm from "./tabs/incoterm";
import { getCommCode } from "../apiCalls";

export const RefDataContext = React.createContext();

const RefData = () => {

  const [onAddNew, setOnAddNew] = useState(() => null);
  // TODO: fix this hack
  const refDataState = {
    setOnAddNew: (f) => {
      setOnAddNew(() => f)
    }
  };

  return (
    <RefDataContext.Provider value={refDataState}>
    <Container fluid="2xl" className=" mt-5 semi-fluid-container medium-middle-container ">
      <section className="floating-box-userProfile pb-2 px-4">
        <div className="floating-box-header-userProfile ps-0 ms-0">
          <span>Reference Data</span>
          <span className="flex-grow-1"></span>
          <button className="blue-button-teams green-btn"
          onClick={onAddNew}
          >
            Add
          </button>
        </div>

<Tabs defaultActiveKey={"commodity-codes"}  mountOnEnter={true} unmountOnExit={true}
id="uncontrolled-tab-example" className="mb-3" 
>
  <Tab eventKey="commodity-codes" title="Commodity Codes">
    <CommodityCodes />
  </Tab>
  <Tab eventKey="movement" title="Movement Type">
    <MovementType />
  </Tab>
  <Tab eventKey="load" title="Load">
    <Load />
  </Tab>
  <Tab eventKey="country" title="Country">
    <Country />
  </Tab>
  <Tab eventKey="ports" title="Ports">
    <Ports />
  </Tab>
  <Tab eventKey="ship-status" title="Ship Status">
    <ShipStatus />
  </Tab>
  <Tab eventKey="currency" title="Currency">
    <Currency />
  </Tab>
  <Tab eventKey="incoterm" title="Incoterm">
    <Incoterm />
  </Tab>
</Tabs>
      </section>
    </Container>
    </RefDataContext.Provider>
  );
};


export default RefData;