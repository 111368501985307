import { PDFDownloadLink } from "@react-pdf/renderer";
import { Download } from "@styled-icons/fa-solid";
import JobReceipt from "../../../JobReceipt";
import { useContext, useEffect, useState } from "react";
import { EpoJobDetailContext } from "../EpoJobDetails";
import { getEPO } from "../../../apiCalls";


export function Confirmation({}) {     
  const { jobDetails, tab } = useContext(EpoJobDetailContext);
  const [receiptDetails, setReceiptDetails] = useState(
    {

  }
);

  useEffect(() => {
    async function fetchData() {
      if (tab === 'receipt') {
      try {
        const getEPOConfTab = await getEPO(jobDetails.newLoadId, tab + 'tab')
        if (getEPOConfTab) {
          setReceiptDetails( getEPOConfTab[0] );
        }
      } catch (err) {
        console.log('Receipt error: ', err);
      }
    }
    else return
  }
    fetchData();
  }, [tab])

  const newData = { ...receiptDetails, port: receiptDetails.exitPort, euPrefSelected: (receiptDetails?.exportAreaCode === 'EU') ? true: false};
  receiptDetails.controlledGoods === "N" ? newData.controlledGoods = false : newData.controlledGoods = true;
  return (
    <div className="d-flex flex-column w-75 my-4 p-3 border shadow" style={{ maxWidth: "640px", marginLeft: "100px", marginBottom: "2rem" }}>
      <div className="registrationText mb-2" style={{ fontSize: '1.2rem' }}>Receipt</div>
      <div>
        <div className="inputs-group p-4 d-flex flex-row align-items-start gap-3">
          <div className="d-flex flex-column">
        <p>Here is a summary of the information you have provided:</p>
       {receiptDetails?.isImport ? <ResultAtoF jobDetails={newData}/> : <ResultGtoJ jobDetails={receiptDetails}/>}
       {receiptDetails?.euPref==="yes" && !(["E","F"].includes(receiptDetails?.epoScore)) &&
       <div>
        Your Transaction Reference is: <span className="green-text fw-bold font-monospace" style={{ fontSize: "20px" }}>{receiptDetails?.TransferID}</span>
       </div>}
          </div>
        </div>
      </div>
      <section className="d-flex justify-content-between mt-1 pt-2">
          <PDFDownloadLink
            document={<JobReceipt jobDetails={newData} id = "confirmation"/>}
            fileName={"confirmation.pdf"}
            className="invoice-add-btn green-text fw-bolder"
            style={{ fontSize: "16px" }}
          >
            {({ loading }) =>
              loading ? (
                "Loading..."
              ) : (
                <>
                  <Download width="1em" height="1em" />
                  <span>Confirmation & Receipt</span>
                </>
              )
            }
          </PDFDownloadLink>
        </section>
    </div>
  );
}

export const ResultAtoF =({jobDetails})=>{

  const {jobReference,submittedBy, entryPort, estimatedDateOfLoading, euPref, controlledGoods, submittedAt, euPrefSelected} = jobDetails;
  
  return(<ol className="text-eoriblue font-monospace" style={{fontSize: '18px'}}>
  <li>Your job reference : {jobReference}</li>
  <li>CABIE job created by : {submittedBy}</li>
  <li>Expected port of arrival : {entryPort}</li>
  <li>Expected date of arrival : {new Date(estimatedDateOfLoading)?.toLocaleDateString()}</li>
  {euPrefSelected ? (<li>Origin of goods status : {euPref === "yes" ? "EU Origin Only" : "Not All EU Origin Only"}</li>):
   (<li>Origin of goods status : {euPref === "yes" ? 'All Preferential Origin' : 'Not all Preferential Origin'}</li>)}  
  <li>Type of Goods: {controlledGoods ? "Controlled" : "Non-Controlled" }</li>
  <li>Time and date created : {new Date(submittedAt)?.toLocaleString()}</li>
</ol>)
}

export const ResultGtoJ = ({jobDetails}) =>{
  const {officeDest, inlandTransId, sealNo, jobReference, submittedBy, exitPort, scoreValue, loadDate, submittedAt,confirmedNoCG } = jobDetails

  const additionalReqText = {
    G: "None",
    H: "ENS Required",
    I: "Transit Required",
    J: "Transit & ENS Required",
  };
  const resultIandJ = () => {
    return (
      <>      
          <li>Office of Destination : {officeDest} </li>
          <li>Inland Transport ID : {inlandTransId} </li>
          <li>Seal Number : {sealNo === "" ? "Not Declared" : sealNo} </li>
      </>
    );
  };

  return(<ol className="text-eoriblue font-monospace" style={{fontSize: '18px'}}>
  <li>Your job reference : {jobReference}</li> 
  <li>created by : {submittedBy}</li>
  <li>Expected port of Exit : {exitPort}</li>
  <li>Type of Goods : {(confirmedNoCG ==="N") ? "Non-Controlled" : "Controlled"}</li>
  <li>Additional Requirements : {additionalReqText[scoreValue]}</li>
  {["I","J"].includes(scoreValue) && resultIandJ()}
  <li>Expected date of Exit : {new Date(loadDate).toLocaleDateString()}</li>
  {/* <li>Time and date created : {new Date(submittedAt).toLocaleDateString()+", "+new Date(submittedAt).toLocaleTimeString()}</li> */}
  <li>Time and date created : {new Date(submittedAt).toLocaleString()}</li>
</ol>)
}
