import { Modal } from "react-bootstrap";

const BPWarningModal = ({
  show,
  onHide,
  hideHeader,
  hideCancel,
  onConfirm,
  children,
  header,
  ...rest
}) => {
  return (
    <Modal show={show} onHide={onHide} {...rest} centered animation={false}>
      <Modal.Header style={{ display: hideHeader ? "none" : "flex" }}>
        <Modal.Title className="d-flex gap-3 align-items-center py-2">
          <div className="warning" /> <span>Unsaved changes</span>
        </Modal.Title>
        <button
          id="close"
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        />
      </Modal.Header>
      <Modal.Body>
        Do you really want to discard your unsaved changes?
      </Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      >
        <button
          id="cancel"
          class="blue-button-teams green-btn"
          onClick={onHide}
          style={{
            display: hideCancel ? "none" : "flex",
            borderRadius: "10px",
          }}
        >
          Cancel
        </button>
        <button
          id="confirm"
          class="blue-button-teams"
          onClick={(event) => {
            onConfirm();
            onHide(event);
          }}
          style={{ borderRadius: "10px" }}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BPWarningModal;
