import { Col, Modal, Row } from "react-bootstrap";

const EpoConfirmationModal = ({ onClose, details, onBack }) => {
  return (
    <Modal
      show={details !== null}
      onHide={onClose}
      centered
      size="md"
    >
      <Modal.Header>
        <Modal.Title>{details?.title}</Modal.Title>{" "}
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body>
          <div>
              <Row className="d-flex p-4">
                  <Col className="d-flex justify-content-center">
                    <img 
                    alt=""
                    src={details?.icon}
                    width="75"
                    height="75"
                    />
                  </Col>
              </Row>
              <Row>
              <Col className="d-flex p-4 justify-content-center">
                    {details?.body}
                  </Col>
              </Row>
          </div>
      </Modal.Body>
      
        <Modal.Footer
          class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
        >
                      <button
            type="submit"
            onClick={onClose}
            class="blue-button-teams green-btn"
            style={{ borderRadius: "10px" }}
          >
            Go Back
          </button>
          <button
            type="submit"
            onClick={onBack}
            class="blue-button-teams green-btn"
            style={{ borderRadius: "10px" }}
          >
            View
          </button>
        </Modal.Footer>
    </Modal>
  );
};
export default EpoConfirmationModal;
