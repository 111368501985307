import { forwardRef, useContext, useCallback, useEffect } from "react"
import { CallbackContext } from './Spreadsheet'
import _ from "lodash";

const Mouse = forwardRef(({},ref) => {
    const { setSelection,
        excelState,
        excelDispatch,
        editable,
        setHandleMouseDown,
        setLastRowClicked,
        getBoundingClientOffset } = useContext(CallbackContext);



    useEffect(() => {
        setLastRowClicked(
            () =>  (event) => {
                if (!editable) return;
                excelDispatch({ type: "new-row" });
                setSelection((prev) => ({
                  ...prev,
                  root: {
                    ...prev.root,
                    col_idx: 0,
                    row_idx: excelState.rows.length,
                  },
                  bounding: {
                    ...prev.root,
                    col_idx: 0,
                    row_idx: excelState.rows.length,
                  },
                }));
              }
        )
    }, [excelState.rows, excelDispatch])
    
      const handleMouseMove = useCallback(
        _.throttle((event) => {
          const { cellType, rowIdx, colIdx } = event.target.dataset;
          if (!cellType) return;
    
          const { x, y, bottom, right } = getBoundingClientOffset(event.target);
    
          setSelection((prev) => {
            let x_left, x_right, y_top, y_bottom;
    
            // If the LEFT-SIDE of the newly MOUSED OVER CELL is FURTHER RIGHT than the ROOT:
            if (x > prev.root.x_left) {
              // then the LEFT-SIDE of the SELECTION BOX is the same as the ROOT's
              x_left = prev.root.x_left;
              // and the RIGHT-SIDE of the SELECTION BOX is the same as the MOUSED OVER CELL's
              x_right = right;
            } else {
              // else the LEFT-SIDE of the SELECTION BOX is the same as the MOUSED OVER CELL's
              x_left = x;
              // and the RIGHT-SIDE of the SELECTION BOX is the same as the ROOT's
              x_right = prev.root.x_right;
            }
    
            // If the TOP-SIDE of the newly MOUSED OVER CELL is FURTHER DOWN than the ROOT:
            if (y > prev.root.y_top) {
              // then the TOP-SIDE of the SELECTION BOX is the same as the ROOT's
              y_top = prev.root.y_top;
              // and the BOTTOM-SIDE of the SELECTION BOX is the same as the MOUSED OVER CELL's
              y_bottom = bottom;
            } else {
              // else the TOP-SIDE of the SELECTION BOX is the same as the MOUSED OVER CELL's
              y_top = y;
              // and the BOTTOM-SIDE of the SELECTION BOX is the same as the ROOT's
              y_bottom = prev.root.y_bottom;
            }
    
    
            return {
              ...prev,
              bounding: {
                col_idx: parseInt(colIdx),
                row_idx: parseInt(rowIdx),
                x_left,
                x_right,
                y_top,
                y_bottom,
              },
            };
          });
        }, 50),
        []
      );
    
      const handleMouseUp = useCallback(
        (event) => {
          window.removeEventListener("mouseup", handleMouseUp);
          window.removeEventListener("mousemove", handleMouseMove);
        },
        [handleMouseMove]
      );
    
      useEffect(() => {
        setHandleMouseDown(() => 
        (event) => {
            ref.current.focus();
            event.preventDefault();
            if (event.button !== 0) {
              return;
            }
      
            const { cellType, rowIdx, colIdx } = event.target.dataset;
            const { x, y, bottom, right } = getBoundingClientOffset(event.target);
      
            setSelection((prev) => ({
              ...prev,
              root: {
                ...prev.root,
                x_left: x,
                x_right: right,
                y_top: y,
                y_bottom: bottom,
                col_idx: parseInt(colIdx),
                row_idx: parseInt(rowIdx),
              },
              bounding: {
                x_left: x,
                x_right: right,
                y_top: y,
                y_bottom: bottom,
                col_idx: parseInt(colIdx),
                row_idx: parseInt(rowIdx),
              },
            }));
      
            window.addEventListener("mouseup", handleMouseUp);
            window.addEventListener("mousemove", handleMouseMove);
      
            return () => {
              window.removeEventListener("mouseup", handleMouseUp);
              window.removeEventListener("mousemove", handleMouseMove);
            };
          },
        
        )
      }, [handleMouseUp, handleMouseMove])
      
    return null;
})

export default Mouse