import { useContext } from "react";
import { Alert, Container, Button } from "react-bootstrap";
import ConfirmationModal from "../ConfirmationModal";
import { StoreContext } from "../Store";

export default function FileUploadError({ error, children, resetErrorBoundary }) {
  return (
    <>
      <ConfirmationModal
        header="Error Occured"
        show={true}
        onHide={() => null}
        hideCancel={true}
        onConfirm={resetErrorBoundary}
      >
        <p>(
          <span>
            Sorry, we weren't able to process that file; please check you are using the correct version of the RPA file, and that your file is not corrupted.
          </span>
        </p>

        <p>
          Error code:
          <pre>
            <code>{JSON.stringify(error?.message)}</code>
          </pre>
        </p>
      </ConfirmationModal>
    </>
  );
}
