import ReactDOM from "react-dom";

import { useState } from "react";

const Toast = ({ className, style, children }) => {
  const [isOpen, setOpen] = useState(false);


  return (
    ReactDOM.createPortal(
      <div className={className} style={style}>
        {children}
      </div>, document.body)
  );
};

export default Toast