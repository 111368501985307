import "../Clients/clients.css";
import React, { useState } from "react";
import { Collapse, Table, Dropdown } from "react-bootstrap";
import { EllipsisV, ChevronDown } from "@styled-icons/fa-solid";

export default function Hierarchy({ root_level, label, kebabOptions, deleteClient, isLast, children, render, item, ...rest }) {
  const [expanded, setExpanded] = useState(false);
  if (!label) {
    console.log(children);
  }

  return (
    <>
      <div className={`d-flex align-items-stretch ${root_level ? "border-top border-start border-end" : ""} ${root_level && isLast ? "border-bottom" : ""}`}>
        {!root_level && (
          <div style={{ width: "2rem", gridTemplateColumns: "1fr 1fr", gridTemplateRows: "1fr 1fr" }} className="d-grid">
            <div />
            <div className="w-100 h-100 border-2 border-info border-start" />
            <div />
            <div className={`w-100 h-100 border-2 border-info ${isLast ? "border-top" : "border-start"}`} />
          </div>
        )}

        <div className="w-100 d-flex flex-column">
          {label && (
            <div className={`d-flex w-full border-start border-top ${expanded ? "border-0" : ""}`}>
              <div
                onClick={() => setExpanded((prev) => !prev)}
                className={`overflow-hidden flex-fill d-inline-flex align-items-center py-1 ${children || root_level ? "text-eoriblue bold" : ""} ${
                  children ? "has-children parent-row " : ""
                }`}
              >
                {children ? (
                  <ChevronDown
                    width={"1rem"}
                    className="gray-text mx-2"
                    style={{ transition: "transform 0.3s", transform: expanded ? "" : "rotate(-90deg)", minWidth: '1rem' }}
                  />
                ) : (
                  <span class="mx-2" style={{ width: "1rem" }} />
                )}
                {label}
              </div>
              {kebabOptions?.length > 0 &&
                (<KebabMenu kebabOptions={kebabOptions} item={item} />)
              }
            </div>
          )}

          {render?.()}

          <Collapse in={expanded}>
            <div className="child-list" onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
}


function KebabMenu ({kebabOptions, item, ...rest}) {
  return (
    <>
      
      <Dropdown className="h-100 d-grid center">
        <Dropdown.Toggle as="button" type="button" className="dot-menu-button py-1 px-2">
          <EllipsisV width={"0.5rem"} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {kebabOptions.map(option => (
            <Dropdown.Item as="button" type="button" onClick={() => option.onClick(item)}>
              {option.text}
            </Dropdown.Item>  
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
