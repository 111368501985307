import { useEffect, useState, useRef, useContext } from "react";
import { Col, OverlayTrigger, Popover, PopoverContent, Row } from "react-bootstrap";
import { getNewLoad } from "../apiCalls";
import { Input, Select, FilterableSelect } from "../Input/Input";
import { useStepper } from "../Stepper";
import warehouse from "../Epo/assets/data-warehouse.png";
import { GoogleAnalyticsContext } from "../GoogleAnalytics";
import { StoreContext } from "../Store";

export default function RegisteredOfficeDetails() {
  const { stepBackward, stepForward, localData, setDataByKey, gotoStep, activeStepKey } = useStepper();
  const store = useContext(StoreContext);

  const [errors, setErrors] = useState({});

  const isInitialized = useRef(false);

  const ga = useContext(GoogleAnalyticsContext)
  
  useEffect(() => {
    if (ga.gaInitialized) {
      if (activeStepKey === "addr2" && isInitialized.current === false) {
        isInitialized.current = true;
        window?.gtag?.("event", "2.1. RegisteredOfficeDetails",{
          event_category: "Registration"
        })
      }
    }
  }, [activeStepKey, ga.gaInitialized])

  const handleNext = async (e) => {
    e.preventDefault();
    const freshErrors = {};

    if (!localData.building2) freshErrors.building2 = "Required";
    if (!localData.street2) freshErrors.street2 = "Required";
    if (!localData.town2) freshErrors.town2 = "Required";
    if (!localData.postcode2) freshErrors.postcode2 = "Required";
    if (!localData.country2) freshErrors.country2 = "Required";

    setErrors(freshErrors);
    if (!Object.values(freshErrors).some(err => !!err)) {
      // stepForward();
      localData.isFullRegProcess? stepForward() : gotoStep('smart')
    }
  };


  return (
    <form onSubmit={handleNext} className="flex-grow-1 d-flex flex-column">
      <div>
        <span className="register-step-header">Registered Office Address</span>
        <div className="inputs-group register-info">
          <img src={warehouse} width="90" height="90" alt="" />
          <span>If your registered office address is different from your trading address, please let us know below.</span>
        </div>
        <Row className="py-2">
          <Col>
            <Input label={<>Building name/number <span style={{ fontSize: "120%" }}>*</span></>} error={errors.building2} value={localData.building2} onChange={(e) => setDataByKey('building2', e.target.value)} />
          </Col>
          <Col>
            <Input label={<>Street <span style={{ fontSize: "120%" }}>*</span></>}  error={errors.street2} value={localData.street2} onChange={(e) => setDataByKey('street2', e.target.value)} />
          </Col>

        </Row>
        <Row className="py-2">
          <Col>
            <Input label={<>Town/City <span style={{ fontSize: "120%" }}>*</span></>} error={errors.town2} value={localData.town2} onChange={(e) => setDataByKey('town2', e.target.value)} />
          </Col>
          <Col>
            <Input label="County"  error={errors.county2} value={localData.county2} onChange={(e) => setDataByKey('county2', e.target.value)} />
          </Col>
        </Row>

        <Row className="py-2">
          <Col>
            <Input label={<>Postcode <span style={{ fontSize: "120%" }}>*</span></>} error={errors.postcode2} value={localData.postcode2} onChange={(e) => setDataByKey('postcode2', e.target.value)} />
          </Col>
          <Col>
            <FilterableSelect
              label={<>Country <span style={{ fontSize: "120%" }}>*</span></>}
              data={localData.country2}
              setData={(data) => setDataByKey('country2', data)}
              options={store.countryCodes[0]}
              error={errors.country2}
            />
          </Col>
        </Row>
      </div>
      <div className="flex-grow-1" />
      <section className="d-flex justify-content-between mt-2">
        <button type="button" className="blue-button-teams green-btn" onClick={stepBackward}>
          Back
        </button>
        <span className="py-1 text-danger">{Object.values(errors).some(err => !!err) && "Some errors were found, please correct above."}&nbsp;</span>
        <button type="submit" className="blue-button-teams green-btn">
          Next
        </button>
      </section>
    </form>
  );
}
