/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import "./main.css";
import DisplayLoads from "./DisplayLoads";

import { StoreContext } from './Store';

const ListLoads = () => {

  const [requestFailed, setRequestFailed] = useState(false)

  const navigate = useNavigate();
  const store = useContext(StoreContext)

  useEffect(() => {
    async function fetchData() {
      try {
        store.resetNewLoad();
      } catch (err) {
        setRequestFailed(true);
      }
    }
    fetchData();
  }, [])

  if (requestFailed) return <p>Failed!</p>

  return (
    <div className="main">
      <section>
        <DisplayLoads
          className='results'
          name='results'
          history={navigate}
          url='/loadDetails' />
      </section>
    </div>
  );
}

export default ListLoads;