import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";


const Autocomplete = ({onChange, onBlur, options, onType, filter, data, containerStyle, label, error, style, name, displayKey, displayFn, searchFn}) => {

  /* -- Stateful values -- */
  const [isOpen, setOpen] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [highlightedRow, setHighlightedRow] = useState(0);
  const [showStr, setShowStr] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const popdownEl = useRef(null);
  const inputEl = useRef(null);

  useEffect(() => {
    if (typeof displayFn === "function" && !!displayKey) {
      console.error("`displayKey` prop is ignored if `displayFn` prop is set")
    }
  }, [displayFn, displayKey]);

  /* -- Functions -- */
  const emitChange = useCallback((clicked_option) => {
    onChange?.(clicked_option);
  }, [onChange])

  const displayText = useCallback((option) => {
    if (typeof displayFn === "function") return displayFn(option);
    return option?.[displayKey] ?? "";
  }, [displayFn, displayKey])

  /**
   * Called when an element gains focus
   * @param {Event} event contains information about the element which was focused
   */
  const handleFocus = (event) => {
    // Only concerned with the input gaining focus
    if (event.target.type === "text") {
      // Open the options box, set flag, and empty the internal text field for a fresh search
      setOpen(true);
      setFocused(true);
      setSearchStr("");
      setShowStr("");
    }
  }
  /**
   * Called when a click event happens, this involves a mousedown and mouseup event.
   * @param {Event} event contains information about the element which was clicked
   */
  const handleClick = (event) => {
    let target = event.target;
    if (event.currentTarget !== event.target) {
      target = event.currentTarget
    }

    if (target.dataset?.autocomplete) {
      if (isFocused) {
        setOpen(true);
      }
      else {
        inputEl.current.focus(); // otherwise, grab focus on the input
      }
      // Select all the text currently in the input box, allows user to very quickly enter a new search term
      inputEl.current.setSelectionRange(0, inputEl.current.value.length);
    }
  }
  /**
   * Called immediately when a mouse button is pressed.
   * @param {Event} event contains information about the element which was clicked
   */
  const handleMouseDown = useCallback((option) => {
    return (event) => {
      // Only concerned with elements which are not the input box
      if (event.currentTarget.type !== "text") {
        // Prevent the event, this stops the `blur` action happening automatically
        event.preventDefault();
        // Emit change event, and close the options box
        emitChange(option);
        setOpen(false);
        setShowStr(displayText(option));
      }
    }
  }, [emitChange, displayText]);
  /**
   * Called when input element's value is changed (via typing)
   * @param {Event} event contains information about the element
   */
  const handleOnChange = (event) => {
    // When the user types, open the options box, highlight the top value, and update the internal search term
    setOpen(true);
    setHighlightedRow(0);
    setSearchStr(event.target.value);
    setShowStr(event.target.value);
    if (onType) {
      onType(event.target.value);
    }
  }

  /**
   * Called when a key is pressed down from inside the input box
   * @param {KeyboardEvent} event contains information about the key pressed
   */
  const handleKeyDown = (event) => {
    switch (event.key) {
      case "PageDown": {
        if (!isOpen || !popdownEl.current || !popdownEl.current.children[highlightedRow]) return;
        const elemsPerPage = Math.floor(popdownEl.current.clientHeight / popdownEl.current.children[highlightedRow].clientHeight / 2);
        if (highlightedRow + elemsPerPage < filteredOptions.length) {
          setShowStr(displayText(filteredOptions[highlightedRow + elemsPerPage]));
          setHighlightedRow(highlightedRow + elemsPerPage);
        } else {
          setShowStr(displayText(filteredOptions[filteredOptions.length - 1]));
          setHighlightedRow(filteredOptions.length - 1);
        }
        return;
      }
      case "PageUp": {
        if (!isOpen || !popdownEl.current || !popdownEl.current.children[highlightedRow]) return;
        const elemsPerPage = Math.floor(popdownEl.current.clientHeight / popdownEl.current.children[highlightedRow].clientHeight / 2);
        if (highlightedRow - elemsPerPage >= 0) {
          setShowStr(displayText(filteredOptions[highlightedRow - elemsPerPage]));
          setHighlightedRow(highlightedRow - elemsPerPage);
        } else {
          setShowStr(displayText(filteredOptions[0]));
          setHighlightedRow(0);
        }
        return;
      }
      case "ArrowUp": // up arrow
        if (!isOpen) {
          setOpen(true);
          return;
        }
        if (highlightedRow > 0 && filteredOptions.length > 0) {
          setShowStr(displayText(filteredOptions[highlightedRow - 1]));
          setHighlightedRow(highlightedRow - 1);
        }
        break;
      case "ArrowDown": // down arrow
        if (!isOpen) {
          setOpen(true);
          return;
        }
        if (highlightedRow + 1 < filteredOptions.length) {
          setShowStr(displayText(filteredOptions[highlightedRow + 1]));
          setHighlightedRow(highlightedRow + 1);
        }
        break;
      case "Enter": // enter
        if (isOpen) {
          event.preventDefault();
        }
      // fall through
      case "Tab": // or tab
        if (!(filteredOptions.length > 0 && highlightedRow < filteredOptions.length)) return;

        const selected_option = filteredOptions[highlightedRow];
        emitChange(selected_option);
        setOpen(false);
        setShowStr(displayText(selected_option));
        return;
      case "Escape":
        if (isOpen) {
          setOpen(false);
        }
        return;
      default:
        return;
    }
  }

  const loseFocus = useCallback((event) => {
    if (popdownEl.current?.contains(event.target) || inputEl.current === event.target) {
      return;
    } else {
        onBlur?.(event);
        // Close the options box, set flag, and empty the search text
        setOpen(false);
        setFocused(false);
        setShowStr("");
    }
  }, [onBlur])

  useEffect(() => {
    document.body.addEventListener('click', loseFocus);

    return () => document.body.removeEventListener('click', loseFocus);
  }, [isOpen, loseFocus])

  /**
   * Calculates new dropdown options based on user input
   */
  useEffect(() => {
    if (!isOpen) return;

    if (!filter) return;

    const needle = searchStr?.toLowerCase();

    let row = 0;
    let results = [];
    const ordering = {};
    let doSort = false;

    if (!searchStr) {
      setFilteredOptions(options);
      setHighlightedRow(options.findIndex(opt => opt?.id?.toString() === data?.id?.toString()));
      return;
    }

    options.forEach((option, index) => {
      if (typeof searchFn === "function") {
        const rank = searchFn(searchStr, option);
        if (rank !== null && rank !== false && rank !== undefined) {
          results.push(option);
          if (rank !== true) { // something that can be sorted meaningfully
            ordering[option.id] = rank;
            doSort = true;
          }
        }
      } else {
        const haystack = (typeof displayFn === "function" ? displayFn(option) : option?.[displayKey])?.toLowerCase();
        if (!haystack)  {
          console.warn(`Unable to find key ${displayKey} for option with id ${option.id} - consider using a different key.`);
          return;
        }
        
        if (haystack.includes(needle)) {
          results.push(option);
        }
      }
    });

    if (doSort) {
      results.sort((a, b) => ordering[b?.id] - ordering[a?.id]);
    }

    setHighlightedRow(row);
    setFilteredOptions(results);

  }, [filter, options, data, searchStr, isOpen, displayKey, displayFn, searchFn]);

  /**
   * Scrolls highlighted row into view within popdown.
   */
  useLayoutEffect(() => {
    if (isOpen && popdownEl.current !== null && popdownEl.current.children.length > highlightedRow) {
      popdownEl.current.children[highlightedRow]?.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
    }
  }, [highlightedRow, isOpen]);

  /**
   * Runs when entire option list changes.
   */
  useEffect(() => {
    // Tell parent we no longer have anything selected.
    if (options.findIndex(other => other.id.toString() === data?.id?.toString()) === -1) {
      emitChange(null)
    }
  }, [options, data, emitChange]);


  const optionsJSX = useMemo(() => {
    return (<>
      {filteredOptions.map((option, index) =>
      (<div
        key={option.id}
        className={`
            ${index === highlightedRow
            ? 'popdown-option-active'
            : 'popdown-option'}`}
        onMouseDown={handleMouseDown(option)}
      >
        {typeof displayFn === "function" ? displayFn(option) : option?.[displayKey]}
      </div>))}
    </>
    );
  }, [displayFn, displayKey, filteredOptions, highlightedRow, handleMouseDown]);

  const shownText = useMemo(() => {
    if (isFocused) {
      return showStr;
    } else {
      if (!data) return "";
      if (typeof displayFn === "function") {
        return displayFn(data);
      }
      return (data?.[displayKey]) ?? "";
    }
  }, [data, displayFn, displayKey, isFocused, showStr])

  return (
    <div className="input-outer-container" style={{ ...containerStyle }}>
      <label className="label-text input-label">
        <span>
          {label}
        </span>
        <span style={{ flexGrow: 1 }}></span>
        <span className="input-error-box">
          {error ? error : "\u00A0"}
        </span>
      </label>
      <div style={{ position: 'relative' }}>
        <div
          onClick={handleClick}
          data-autocomplete
          className={`
            input-inner-container 
            ${isFocused ? 'input-focused' : ''}`}
          style={{
            borderBottomRightRadius: isOpen ? '0px' : '0.375rem',
            borderBottomLeftRadius: isOpen ? '0px' : '0.375rem',
            border: error ? '1px red solid' : '',
            ...style
          }}
        >
          <input
            ref={inputEl}
            type="text"
            placeholder="- Select -"
            className="input-element no-shadow"
            // className={`bg-transparent outline-none sm:text-sm border-transparent flex-grow rounded-t ${isOpen ? '' : 'rounded-b'}`}
            value={shownText}
            onFocus={handleFocus}
            onMouseDown={handleMouseDown}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            name={name}
          />
        </div>
        {isOpen &&
          (
            <div
              className="popdown-box"
              ref={popdownEl}
            >
              {optionsJSX}
            </div>
          )}
      </div>
    </div>
  );
}

export default Autocomplete;