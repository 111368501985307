import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { StoreContext } from "../../Store";
import { Tabs, Tab, Spinner} from "react-bootstrap";
import { useParams } from "react-router-dom";
import EpoJobCombinedInvoices from "./Tabs/EpoJobCombinedInvoices";
import EpoJobHeaders from "./Tabs/EpoJobHeaders";
import EpoJobInvoices from "./Tabs/EpoJobInvoices";
import EpoJobSummary from "./Tabs/EpoJobSummary";
import EpoJobStatus from "./Tabs/EpoJobStatus";

import { getEPO, putArrivalDate, putEPO } from "../../apiCalls";
import { useNavigate } from "react-router-dom";
import { useSecurity } from "../../Security/security";

import { UpdateEtaModal } from "../UpdateEtaModal";
import { Confirmation } from "./Tabs/Confirmation"

export const EpoJobDetailContext = createContext();


const EpoJobDetails = () => {
  const { id } = useParams();
  const { validateRole } = useSecurity();
  const [jobDetails, setJobDetails] = useState({});
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoiceId, setInvoiceId] = useState(0);
  const [serverData, setServerData] = useState({});
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  const [modalItem, setModalItem] = useState(null);
  const [error, setError] = useState();
  const [openedInvoice, setOpenedInvoice] = useState(null);
  const [showUpdateEta, setShowUpdateEta] = useState(false);
  const [showArrivalModal, setShowArrivalModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [adjustmentsIncoLoaded, setAdjustmentsIncoLoaded] = useState(false);
  const [showAdjustmentsModal, setShowAdjustmentsModal] = useState(false);
  const [isFreightChargesInco, setIsFreightChargesInco] = useState(false);
  const [checkForm, setCheckForm] = useState(false);  
  const formRef = useRef();

  const [tab, setTab] = useState('headers');
  const [sumInvoice, setSumInvoice] = useState(0);
  const [firstHeadLoad, setFirstHeadLoad] = useState(0);
  const [freightErrorState, setFreightErrorState] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [showBulkBtn, setShowBulkBtn] = useState(false);
  const [fileBuffer, setBuffer] = useState(null);
  const [cycle, setCycle] = useState(false);
  
  const updateJobDetails = (key, val) => setJobDetails(prev => ({ ...prev, [key]: val }));


  async function fetchDetails() {
    try {
    const data = await getEPO(id, "Header");
    setJobDetails({ id, ...data.Parents[0], });
    setServerData({ id, ...data.Parents[0] });
    setAdjustmentsIncoLoaded(true)
    setFirstHeadLoad(1)
  }
  catch (err) {
    console.log('jobDetails load error', err)
  }
  }

  useEffect(() => {
    fetchDetails();
  }, [id]);


  useEffect(() => {
    async function fetchDetails() {
      // setJobDetails(null)

      if (tab === 'headers' && firstHeadLoad === 1) {
              try {
                const data = await getEPO(id, "Header");
                if (data) {
                setJobDetails({ id, ...data.Parents[0], });
                setServerData({ id, ...data.Parents[0] });
                }
                setAdjustmentsIncoLoaded(true)
                }
              catch (err) {
                      console.log('Header error: ', err);
                    }
            }
            else return
    }
    fetchDetails();
  }, [tab]);



  const handleSaveHeaders = async () => {   
    const {adjustments} = jobDetails;
    const hasEmptyCurrency = adjustments.some(({ amount, currency, name }) => amount && !currency && name !== 'freightCharges');
    const fc = jobDetails.adjustments.find((adj) => adj.name === 'freightCharges');
    
    if(hasEmptyCurrency){
      updateJobDetails(
        "adjustments",
        jobDetails.adjustments.map((adjustment) => {
          if (adjustment.amount && !adjustment.currency && adjustment.name !== 'freightCharges')
            adjustment.required = true;
          return adjustment;
        })
      );

      setShowAdjustmentsModal(true);
      jobDetails.adjustments.filter(({amount, currency, required, name}) => {
        if (amount && !currency && required && name !== 'freightCharges')
          setCheckForm(true);
      });
    }
    else if (!hasEmptyCurrency && isFreightChargesInco && (fc.amount === '' || fc.currency === '' || fc.amount < 1)) {
      if (fc.amount === '' || fc.currency === '') {
        setShowAdjustmentsModal(true);
        setCheckForm(true);
      }
      else if (fc.amount < 1) {
        setFreightErrorState('loading')
        updateJobDetails('adjustments',
          jobDetails.adjustments.map((adjustment) => {
            if (adjustment.name === 'freightCharges')
              adjustment.error = "Must be greater than 0."
            return adjustment
          })
        )
      }
    }
    else {
      const result = await putEPO({
        currency: jobDetails.currency,
        incoterm: jobDetails.incoterm,
        consignorId: jobDetails.consignor,
        countryOfDespatchId: jobDetails.despatchCountry,
        id: jobDetails.newLoadId,
        isImport: (jobDetails.isImport ? 1 : 0),
        adjustments: jobDetails.adjustments
      }, "Header", jobDetails.newLoadId);
      if (result[0].completed === "Success") {
        setSuccessText("Successfully saved.");
        const data = await getEPO(id, "Header");
        setJobDetails({ id, ...data.Parents[0] });
        setServerData({ id, ...data.Parents[0] });
        setAdjustmentsIncoLoaded(true);
      }
    }
  }

  useEffect(() => {
    if (checkForm) {
      setTimeout(() => {
        formRef.current.reportValidity();
        setCheckForm(false);
      }, 100)

    }
  }, [checkForm])

  useEffect(() => {
    if (freightErrorState === 'loading') {
      if (jobDetails.adjustments.reduce((acc, cur) => {
        return acc || !!cur.error
      }, false))
        setFreightErrorState('loaded');
    }

  }, [jobDetails?.adjustments, freightErrorState])

  useEffect(() => {
    if (freightErrorState === 'loaded') {

      setFreightErrorState();
      setShowAdjustmentsModal(true);
    }
  }, [freightErrorState])

  const handleUpdateArrivalDate = async ({ date }) => {

    await putArrivalDate({
      id: jobDetails.newLoadId,
      newArrivalDate: date
    });
    const data = await getEPO(id, "Header");
    setJobDetails({ id, ...data.Parents[0] });
    setAdjustmentsIncoLoaded(true);

    setSuccessText("Successfully updated.");
  }

  useEffect(() => {
    setSuccessText("");
  }, [tab]);

  const confirmEPO = () => {
    setConfirmed(true);
    setTab("status")
  }

  const isCustomer = validateRole({ isCustomer: true });

  let canSubmit = true;
  let canSave = true;
  let canEditInvoiceData = false;

  if (jobDetails?.EPOStatus === "Submitted") {
    // if a job is fully submitted, it should be read-only
    canSave = false;
    canSubmit = false;

    
  } else if (validateRole({ department: "DCT" })) {
    // DCT team
    canSave = false;
    canSubmit = false;
    switch (jobDetails?.EPOStatus) {
      // if job is "Ready to be checked" for DCT it should be read-only
      case "Ready to be checked":
        canSave = false;
        canSubmit = false;
        break;
      // 
      case "Ready to be checked (Potential Duty)":
        canSubmit = false;
        canSave = false;
        canEditInvoiceData = false;
        break;
      // if job is "New", allow saving headers but not submitting.
      case "New":
        canSave = true;
        canSubmit = false;
        canEditInvoiceData = true;
        break;
      case "In progress":
        canSave = true;
        canSubmit = true;
        canEditInvoiceData = true;
        break;
      case "On Hold (Potential Duty)":
        canSubmit = true;
        canSave = true;
        canEditInvoiceData = true;
        break;
     
      default:
        break;
    }
  } else if (validateRole({ department: "OPS" }) || validateRole({ department: "QA" })) {
    canSave = false;  
    canSubmit = false;    

    switch (jobDetails?.EPOStatus) {
      // if job is "New", allow saving headers but not submitting.
      case "New":
        canSave = true;
        canSubmit = false;
        canEditInvoiceData = true;
        break;
      case "In progress":
        canSave = true;
        canSubmit = true;
        break;
      case "Ready to be checked":
        canSave = true;
        canSubmit = true;
        canEditInvoiceData = true;
        break
      case "Ready to be checked (Potential Duty)":
        canSave = true;
        canSubmit = true;
        canEditInvoiceData = true;
        break
      case "On Hold (Potential Duty)":
        canSubmit = true;
        canSave = true;
        canEditInvoiceData = true;
        break
      case "Awaiting Proof of Payment":
        canSubmit = true;
        break
         // if SUPEROPS then allow these 2 futher steps (previously finance)
        case "On Hold (Awaiting Duty Invoice)":
          if (validateRole({ department: 'OPS', role: 'SUPERUSER' })) {
            canSubmit = true;
            canSave = true
            canEditInvoiceData = true;
          }
          break;
        case "Awaiting Payment Confirmation":
          if (validateRole({ department: 'OPS', role: 'SUPERUSER' })) {
          canSubmit = true;
          canSave = true
          }
          break;
      default:
        break;
    }  
  }
  
  if (jobDetails === null) return null;

  // const changeTab = async ({ tab }) => {

  //   await putArrivalDate({
  //     id: jobDetails.newLoadId,
  //     newArrivalDate: date
  //   });
  //   const data = await getEPO(id, "Header");
  //   setJobDetails({ id, ...data.Parents[0] });
  //   setAdjustmentsIncoLoaded(true);

  //   setSuccessText("Successfully updated.");
  // }

  return (
    <EpoJobDetailContext.Provider value={{ jobDetails, setJobDetails, openedInvoice, 
    setOpenedInvoice, setTab, updateJobDetails, canSave, isCustomer, 
    setShowUpdateEta, setShowArrivalModal, showArrivalModal, setModalItem, modalItem, setError, error,
    setSuccessText, adjustmentsIncoLoaded, setAdjustmentsIncoLoaded, 
    showAdjustmentsModal, setShowAdjustmentsModal, isFreightChargesInco, 
    setIsFreightChargesInco, formRef, confirmEPO, tab, invoiceDetails, setInvoiceDetails, sumInvoice, setSumInvoice, 
    invoiceId, setInvoiceId, showBulkBtn, setShowBulkBtn,fileBuffer, setBuffer, cycle, setCycle
    }}>


      <UpdateEtaModal newArrivalDate={jobDetails.newArrivalDate}
      estimatedDateOfLoading={jobDetails.estimatedDateOfLoading} 
      show={showUpdateEta} 
      onHide={() => setShowUpdateEta(false)} 
      onConfirm={handleUpdateArrivalDate} />

      {/* <div style={{...(loading ? {filter: 'blur(2px)'} : {})}}> */}
      <div className="full-width-container">
      <div className="align-left" style={{ maxWidth: "1600px" }}>
      <div className={store.stepTitleColor[0] === "dark" ? "dark-text-theme": "light-text-theme"} style={{fontSize:"26px"}}><span style={{fontSize:"22px" }}>Reference No: </span> {jobDetails?.TransferID}</div>
      </div>
        <section className="floating-box" style={{ maxWidth: "1600px" }}>
          <Tabs activeKey={tab} onSelect={newTab => setTab(newTab)} style={{paddingTop:"0.8rem"}}>
            <Tab eventKey="headers" title="Headers">
              <div className="p-3 bg-white">
                <EpoJobHeaders editInvoiceData={canEditInvoiceData} />
              </div>
            </Tab>

            {(!isCustomer) && (<Tab eventKey="invoices" title="Documents">
              <div className="p-3 bg-white">
                <EpoJobInvoices />
              </div>
            </Tab>)}

            <Tab eventKey="items" title="Summary">
              <div className="p-3 bg-white">
                <EpoJobCombinedInvoices />
              </div>
            </Tab>  

            {/* CONFIRMATION SCREEN */}
            {canSubmit && (!isCustomer) && (!serverData.confirmed && serverData.consignor && serverData.despatchCountry && serverData.currency && serverData.incoterm && (jobDetails?.hasItems === "true")) && (
              <Tab eventKey="confirm" title="Confirmation">
                <div className="p-3 bg-white">
                  <EpoJobSummary />
                </div>
              </Tab>
               )
            }
            
            { (confirmed || (serverData.confirmed)) && (
              
              <Tab eventKey="status" title="Status">
                <div className="p-3 bg-white">
                  <EpoJobStatus />
                </div>
              </Tab>
            )}

            <Tab eventKey="receipt" title="Receipt">
              <div className="p-3 bg-white">
                <Confirmation />
              </div>
            </Tab>
          </Tabs>

          <div
            class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-between align-items-center"
            style={{ borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}
          >
            <button type="button" class="cancel-button" onClick={() => navigate('/EpoListLoads')} style={{ display: "flex", borderRadius: "10px" }}>
              Back
            </button>
            <div>
              <span className="text-success bold me-3">{successText}</span>
              {tab === "headers" && canSave && (!isCustomer) && (
                <button disabled={!jobDetails.consignor || !jobDetails.despatchCountry || !jobDetails.currency || !jobDetails.incoterm} type="button" class="blue-button-teams green-btn save-btn" style={{ borderRadius: "10px" }} onClick={handleSaveHeaders}>
                  Save
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
      {/* </div> */}
      {/* { loading && 
         <div className="centreDiv" style={{top: 300}}>
           <Spinner animation="border" role="status"></Spinner>
            <span>Loading...</span>
          </div>
        }   */}
    </EpoJobDetailContext.Provider>
  );
};



export default EpoJobDetails;