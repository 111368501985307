import { Modal } from "react-bootstrap";

const BulkConfirmationModal = ({ show, onReset, onClose, hideHeader, hideCancel, onConfirm, children, header, buttonLabels, msg, disableApply, ...rest }) => {
  return (
    <Modal centered data-bs-backdrop="static" show={show} onHide={() => null} {...rest}>
      <Modal.Header style={{ display: (hideHeader ? "none" : "flex") }} >
        <Modal.Title>{header}</Modal.Title>
        <button
          id="close"
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body><>{children}{msg && <div className="alert-danger px-3 py-1 w-100 text-center" style={{borderRadius:"10px"}}>{msg}</div>}</></Modal.Body>
      <Modal.Footer
        class="d-flex flex-row py-3 px-3 gap-3 border-top bg-light justify-content-end align-items-center"
        style={{
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
        
      >
        <button
          id="cancel"   
          class="cancel-button"
          onClick={onReset}
          style={{ display: (hideCancel ? "none" : "flex"),
                  borderRadius: "10px" }} 
           >
        
        {buttonLabels.label1}
        </button>
        <button
          id="confirm"   
          class="blue-button-teams"
          disabled = {disableApply}
          onClick={(event) => {
            onConfirm();
          }}
          style={{ borderRadius: "10px" }}
        >
         {buttonLabels.label2}   
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkConfirmationModal; 
