import { useEffect, useState } from "react";
import "./ConditionText.css";

export function ExportConditionText({ name }) {

  function renderContent(name) {
    switch (name) {
      case "Export Health Certificate":
        return (
          <>
            <p style={{ marginBottom: "0px" }}>
              If you would like us to complete your CHED document, please upload 
              an Export Health Certificate.
            </p>
          </>
        );
      case "Phytosanitary Certificate":
        return (
          <>
            <p style={{ marginBottom: "1rem" }}>
              If you would like us to complete your CHED document, please upload 
              a Phytosanitary Certificate.
            </p>       
          </>
        );
      case "Catch Certificate":
        return (
          <p style={{ marginBottom: "0px" }}>
            If this document is not provided the goods will be selected as
            exempt.
          </p>
        );
      case "CHED":
        return (
          <>
            <p style={{ marginBottom: "0px" }}>
              Please upload an Excel file with your reference number(s) and the
              items this relates to. The reference number(s) will be used on the 
              Transit Accompanying Document (TAD). 
            </p>
          </>
        );

      case "Original Export Document":
        return (
          <p style={{ marginBottom: "0px" }}>
            Please upload if you would like to claim Returned Goods Relief (RGR).
          </p>
        );
      default:
        return <div></div>;
    }
  }

  return (
    <div className="txt-container">
      <h6>{name}</h6>
      {renderContent(name)}
    </div>
  );
}
